import React, { memo, useCallback } from 'react'
import './MyListItem.scss'
import clsx from 'clsx'
import ImgCoin from '@/images/coin.png';
interface PropsType {
    items: {
        nick: string,
        avatar: string,
        num: number
    }[]
}
function MyListItem({ items }: PropsType) {
    return (
        <div className='cmp-guild-invite-data-my-list-item'>
            {items.map((item, i) =>
                <div className='cmp-guild-invite-data-my-list-item__item' key={i}>
                    <div className='cmp-guild-invite-data-my-list-item__item-left'>
                        <div className='cmp-guild-invite-data-my-list-item__item-avatar'>
                            <img src={item.avatar} alt="" />
                        </div>
                        <div className='cmp-guild-invite-data-my-list-item__item-user-info'>
                            <span>{item.nick}</span>
                            <span>ID:{item.nick}</span>
                        </div>
                    </div>
                    <div className='cmp-guild-invite-data-my-list-item__item-right'>
                        {/* 隐藏以下元素时，添加 align-items: center; */}
                        <div className='cmp-guild-invite-data-my-list-item__item-num'>
                            <span>+{item.num}</span>
                            <img src={ImgCoin} alt="" />
                        </div>
                        <span className='cmp-guild-invite-data-my-list-item__item-pay'>google pay</span>
                        <span className='cmp-guild-invite-data-my-list-item__item-time'>2023-03-06 19:22:22</span>
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default memo(MyListItem)