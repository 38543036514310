import clsx from 'clsx'
import ImageLv1Icon from '../../../images/guild-lv1-icon.png'
import ImageLv2Icon from '../../../images/guild-lv2-icon.png'
import ImageLv3Icon from '../../../images/guild-lv3-icon.png'
import ImageLv4Icon from '../../../images/guild-lv4-icon.png'
import ImageLv5Icon from '../../../images/guild-lv5-icon.png'
import ImageLv1IconAr from '../../../images/guild-lv1-icon-ar.png'
import ImageLv2IconAr from '../../../images/guild-lv2-icon-ar.png'
import ImageLv3IconAr from '../../../images/guild-lv3-icon-ar.png'
import ImageLv4IconAr from '../../../images/guild-lv4-icon-ar.png'
import ImageLv5IconAr from '../../../images/guild-lv5-icon-ar.png'
// import ImageLv5ProgressBg from '../../../images/guild-lv5-progress-bg.png'
import './VipProgress.scss'

type VipProgressProps = {
  level:number
  progress:number
}

function getIcons() {
  switch(window.lang) {
    case 'ar':
      return {
        '1': ImageLv1IconAr,
        '2': ImageLv2IconAr,
        '3': ImageLv3IconAr,
        '4': ImageLv4IconAr,
        '5': ImageLv5IconAr,
      }
    default:
      return {
        '1': ImageLv1Icon,
        '2': ImageLv2Icon,
        '3': ImageLv3Icon,
        '4': ImageLv4Icon,
        '5': ImageLv5Icon,
      }
  }
}

const iconMap:{[x:string]:string} = getIcons()

export default function VipProgress(props:VipProgressProps) {
  return <div className="cmp-vip-progress">
    <img className='cmp-vip-progress__icon' alt="vip progress" src={iconMap[props.level.toString()]} />
    <div className={clsx('cmp-vip-progress__content', {
      lv1: props.level === 1,
      lv2: props.level === 2,
      lv3: props.level === 3,
      lv4: props.level === 4,
      lv5: props.level === 5
    })}>
      {/* <img className='cmp-vip-progress__content-bg' src={ImageLv5ProgressBg} alt="vip" /> */}
      <div className='cmp-vip-progress__content-val' style={{width: props.progress + '%'}} />
    </div>
  </div>
}