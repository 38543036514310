import { useMemo } from 'react'
import ScrollList from '@/components/ScrollList';
import ImgCoin from '@/images/coin.png';
import ImgPearl from '@/images/pearl.png';
import ImgBean from '@/images/bean.png';
import moment from 'moment';
import { SpinLoading } from 'antd-mobile'
import { useTrans } from '@/hooks/useTranslation';
import './InfoList.scss'

interface PropsType {
    hasMore: () => void
    listInfo: {
        list: Wallet.BillRecordInfoList[]
        hasMore: boolean
    },
    pageTitle?: string
    loading?: boolean
}
function InfoList({ hasMore, listInfo, pageTitle, loading }: PropsType) {
    const { t } = useTrans()
    const iconImg = useMemo(() => {
        const title = pageTitle?.toLowerCase()
        return title === 'coin' ? ImgCoin :
            title === 'pearl' ? ImgPearl :
                title === 'bean' ? ImgBean : ImgCoin
    }, [pageTitle])
    return (
        <div className='wallet-info-list'>
            {loading ? <div className="antd-loading">
                <SpinLoading></SpinLoading>
            </div>
                :
                <ScrollList
                    hasMore={listInfo.hasMore}
                    loadMore={hasMore}
                    empty={!listInfo.list.length}
                    emptyText={t('Guild.Tips.NoOverallDataYet')}>
                    {
                        listInfo.list.map((item, i) => (
                            <div className='list-item' key={i}>
                                <div className="left">
                                    <span className='name'>{item.billDetailName}</span>
                                    <span className='type'>{item.billTypeName ? `type:${item.billTypeName}` : ''}</span>
                                </div>
                                <div className="right">
                                    <div className="coin">
                                        <span>{item.billAmount.toString().includes('-') ? item.billAmount : `+${item.billAmount}`}</span>
                                        <img src={iconImg}></img>
                                    </div>
                                    <span className='time'>{moment(item.billTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                            </div>
                        ))
                    }
                </ScrollList>
            }
        </div>
    )
}

export default InfoList