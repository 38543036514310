import React, { useCallback, useEffect, useRef, useState } from 'react';
import './CoinsTransferCoinDetail.scss';
import ScreenTypes from '@/pages/Wallet/components/ScreenTypes';
import InfoList from '@/pages/Wallet/components/InfoList';
import WalletHeader from '@/pages/Wallet/components/Header';
import { getBillRecordTypeList } from '@/services/wallet';
import { toast } from '@/utils/bridge';
import useBillRecordInfoList from '@/pages/Wallet/hooks/useBillRecordInfoList ';

interface RechargeDetailType {
    firstBillTree: {
        billTypeId: number;
        billTypeName: string;
        secondBillTree: Wallet.ThirdBillTree[];
    }[];
}

function Index() {
    const {
        loading,
        time,
        handleTimeRangeChange,
        listInfo,
        setListInfo,
        setBillTypeId,
        fetchBillList,
    } = useBillRecordInfoList();

    const [selectedType, setSelectedType] = useState<number>(0);
    const selectedTypeRef = useRef(selectedType);
    selectedTypeRef.current = selectedType;

    const [typeItems, setTypeItems] = useState<Wallet.ThirdBillTree[]>([]);

    const [homeData, setHomeData] = useState<RechargeDetailType>({
        firstBillTree: [
            {
                billTypeId: 0,
                billTypeName: '',
                secondBillTree: [
                    {
                        billTypeId: 0,
                        billTypeName: '',
                    },
                ],
            },
        ],
    });

    /* 三级分类confirm确定事件 */
    const handleTypeConfirm = useCallback(
        (i: number) => {
            if (selectedTypeRef.current === i) return
            setBillTypeId(typeItems[i]?.billTypeId);
            setListInfo({
                list: [],
                init: true,
                hasMore: true,
            });
            setSelectedType(i);
        },
        [typeItems]
    );

    /* 请求 */
    const fetchHomeData = useCallback(async () => {
        const { code, message, data } = (await getBillRecordTypeList(2)) as Response<RechargeDetailType>;
        if (code !== 200) {
            toast(message);
            return;
        }
        if (!data) return;
        setHomeData(data);
        setTypeItems(
            data?.firstBillTree[0]?.secondBillTree?.map((item) => ({
                billTypeName: item?.billTypeName,
                billTypeId: item?.billTypeId,
            }))
        );
        setBillTypeId(data.firstBillTree[0].secondBillTree[selectedTypeRef.current].billTypeId);
    }, []);

    useEffect(() => {
        fetchHomeData();
    }, [fetchHomeData]);

    return (
        <div className='pg-agentrecharge-coin-detail'>
            <WalletHeader title={homeData.firstBillTree[0]?.billTypeName}></WalletHeader>
            <ScreenTypes
                time={time}
                setTime={handleTimeRangeChange}
                coinSelectedType={selectedType}
                handleTypeConfirm={handleTypeConfirm}
                typeItems={typeItems}
            />
            <InfoList hasMore={fetchBillList} listInfo={listInfo} loading={loading}></InfoList>
        </div>
    );
}

export default Index;
