import { memo, useCallback, useEffect, useState } from 'react'
import ImageDelta from '@/images/delta.png'
import { Popup } from 'antd-mobile'
import { px2rem } from '@/utils/tool';
import TypeItems from '@/pages/Wallet/components/TypeItems';
import { useTrans } from '@/hooks/useTranslation'
import './SelectType.scss'

interface PropsType {
    coinSelectedType: number
    handleTypeConfirm: (selectedType: number) => void
    typeItems: Wallet.ThirdBillTree[]
}
function SelectType({ handleTypeConfirm, coinSelectedType, typeItems }: PropsType) {
    const { t } = useTrans()
    const [visible, setVisible] = useState(false)
    const [selectedType, setSelectedType] = useState<number>(0)
    const handleConfirm = useCallback(() => {
        handleTypeConfirm(selectedType)
        setVisible(false)
    }, [selectedType])
    useEffect(() => {
        setSelectedType(coinSelectedType)
    }, [coinSelectedType])
    const handleCancel = useCallback(() => {
        setVisible(false)
        setSelectedType(coinSelectedType)
    }, [coinSelectedType])
    return <>
        <div className='wallet-select-type' onClick={() => setVisible(true)}>
            <span className='wallet-select-type__name'>{typeItems[coinSelectedType]?.billTypeName}</span>
            <img className='wallet-select-type__delta' alt='delta' src={ImageDelta} />
        </div>
        <Popup
            visible={visible}
            className="wallet-select-type wallet-select-type__popup"
            onMaskClick={handleCancel}
            bodyStyle={{
                backgroundColor: '#fff',
                height: px2rem(350),
                borderTopLeftRadius: px2rem(12),
                borderTopRightRadius: px2rem(12)
            }}>
            <div className="wallet-select-type__popup-header">
                <div className="wallet-select-type__popup-cancel" onClick={handleCancel}>
                    {t('Common.Cancel')}
                </div>
                <div className="wallet-select-type__popup-confirm" onClick={handleConfirm}>
                    {t('Common.Confirm')}
                </div>
            </div>
            <div className="wallet-select-type__popup-content">
                <TypeItems typeItems={typeItems} selectedType={selectedType} setSelectedType={setSelectedType}></TypeItems>
            </div>
        </Popup>
    </>
}

export default memo(SelectType)