import ImagePkRuleEn from './images/pk-rule.webp'
import ImagePkRuleAr from './images/pk-rule-ar.webp'
import { useTrans } from '@/hooks/useTranslation'
import './Pk.scss'

export default function RuleTurntable() {
  const { i18n } = useTrans()

  return (
    <>
      <div className="pg-rule-pk">
        <img src={i18n.language === 'ar' ? ImagePkRuleAr : ImagePkRuleEn} />
      </div>
    </>
  )
}
