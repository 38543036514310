import React, { memo, useMemo } from 'react';
import './CoinDetail.scss';
import TabButtons from '@/components/ScrollTabs';
import ScreenTypes from '@/pages/Wallet/components/ScreenTypes';
import InfoList from '@/pages/Wallet/components/InfoList';
import useDetailLogic from './hooks/useDetailLogic';
import clsx from 'clsx';

interface PropsType {
    linkageData: Wallet.FirstBillTree;
    tab?: number;
    type?: number;
    hide?: boolean
}

function CoinDetail(props: PropsType) {
    const {
        loading,
        selectedTab,
        selectedType,
        time,
        handleTimeRangeChange,
        typeItems,
        listInfo,
        handleTabsClick,
        handleTypeConfirm,
        fetchBillList
    } = useDetailLogic(props.linkageData, props.tab, props.type);
    const tabsTitle = useMemo(() => {
        return props.linkageData?.secondBillTree?.map(item => item.billTypeName)
    }, [props.linkageData])
    return (
        <div className={clsx('pg-wallet-coin-detail', { hide: props.hide })}>
            <TabButtons
                tabsTitle={tabsTitle}
                selectedTab={selectedTab}
                handleTabsClick={handleTabsClick}
            />
            <ScreenTypes
                time={time}
                setTime={handleTimeRangeChange}
                coinSelectedType={selectedType}
                handleTypeConfirm={handleTypeConfirm}
                typeItems={typeItems || []}
            />
            <InfoList hasMore={fetchBillList} listInfo={listInfo} loading={loading} pageTitle="coin"></InfoList>
        </div>
    );
}

export default memo(CoinDetail);
