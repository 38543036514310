import React, { memo } from 'react'
import './Banner.scss'

function Banner({ amount }: { amount: number }) {
    return (
        <div className='cmp-wallet-banner'>
            <div className="info">
                <div className='img' ></div>
                <span>{amount?.toFixed(2)}</span>
            </div>
        </div>
    )
}

export default memo(Banner)