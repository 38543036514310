import { CascadePicker } from 'antd-mobile'
import { useEffect, useState } from 'react'
import ImageCalendar from '../../../images/calendar2.png'
import ImageDelta from '../../../images/delta.png'
import moment from 'moment'
import { useTrans } from '@/hooks/useTranslation'
import './RoomDataSelectTime.scss'

type SelectTimeRangeProps = {
  time: string
  onChange?: (time: string) => void
}
type CascadePickerOption = {
  label: string
  value: string
  children?: CascadePickerOption[]
}
export default function SelectTimeRange(props: SelectTimeRangeProps) {
  const { t } = useTrans()
  const [time, setTime] = useState<string>(props.time)
  const [visible, setVisible] = useState(false)
  const options: CascadePickerOption[] = []
  let months: CascadePickerOption[] = []
  // 获取当前年份
  const currentYear = moment().format('YYYY');
  // 从初始年份开始，一直到当前年份，添加年份对象
  for (let year = 2023; year <= Number(currentYear); year++) {
    if (year === 2023) {
      months = [
        { label: '9', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    } else {
      months = [
        { label: '1', value: '01' },
        { label: '2', value: '02' },
        { label: '3', value: '03' },
        { label: '4', value: '04' },
        { label: '5', value: '05' },
        { label: '6', value: '06' },
        { label: '7', value: '07' },
        { label: '8', value: '08' },
        { label: '9', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ]
    }
    options.push({ label: year.toString(), value: year.toString(), children: months });
  }
  useEffect(() => {
    setTime(props.time)
  }, [props])

  return <>
    <div className='cmp-select-time-room' onClick={() => setVisible(true)}>
      <img className='cmp-select-time-room__calendar' alt='delta' src={ImageCalendar} />
      <span className='cmp-select-time-room__time'>{t('Room.TotalTime') + ` (${time})`}</span>
      <img className='cmp-select-time-room__delta' alt='delta' src={ImageDelta} />
    </div>
    <CascadePicker
      options={options}
      visible={visible}
      onClose={() => {
        setVisible(false)
      }}
      onConfirm={(val) => {
        props.onChange && props.onChange(val.join('-'))
      }}
      value={[time.split('-')[0], time.split('-')[1]]}
    />
  </>
}