import { useTrans } from "@/hooks/useTranslation"
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Pane from '@/components/Pane';
import { getUser } from '@/utils/request'
import { getUserInfo, toast } from '@/utils/bridge';
import './CashTransfer.scss';
import { Popup } from 'antd-mobile'
import TransferContent from './components/TransferContent';
import { useNavigate } from 'react-router'
import { px2rem } from '@/utils/tool';
import { isEmpty } from 'lodash-es';
import WalletHeader from '@/pages/Wallet/components/Header';
import { useCashTransfer } from './hooks/useCashTransfer';
import WalletBanner from '@/pages/Wallet/components/Banner';
import { getAnchormanWalletData } from '@/services/wallet'
import { onlyNumber } from '@/utils/format';

export default function CashTransfer() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [transferVisible, setTransferVisibleVisible] = useState(false)
  const [state, setState] = useState<{
    appID?: string
    user?: AgentRecharge.UserInfo
    inputNum?: string
    cashAmount?: number
    agencyMaxCount?: number
    agencyMsg?: string
  }>({
    appID: '', inputNum: '', cashAmount: 0, agencyMaxCount: 0, agencyMsg: ''
  })
  const refState = useRef(state)
  refState.current = state

  const [user, setUser] = useState<UserBasicInfo>()
  const refUser = useRef(user)
  refUser.current = user

  // hooks
  const { handleRecharge, hanndleAmountChange, fetchConf } = useCashTransfer(state, setState, transferVisible, setTransferVisibleVisible, setLoading);
  const handleFetchUser = useCallback(async () => {
    if (isEmpty(refState.current.appID)) return
    const res = await getUser({ erbanNo: refState.current.appID, searchType: 2 })

    const { code, message, data } = res as Response<AgentRecharge.UserInfo>
    if (code !== 200) {
      toast(message)
      return
    }

    setState({
      ...refState.current,
      user: data
    })
  }, [])
  const handleClearUser = useCallback(() => {
    setState({
      ...refState.current,
      user: undefined,
      appID: '',
      inputNum: ''
    })
  }, [])
  const hanndleAppIDChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...refState.current,
      appID: onlyNumber(e.target.value)
    })
  }, [])
  const fetchAnchormanWalletData = useCallback(async () => {
    const res = await getAnchormanWalletData()

    const { code, message, data } = res as Response<Wallet.AnchormanWalletData>
    if (code !== 200) {
      toast(message)
      return
    }

    setState(prevState => ({
      ...prevState,
      cashAmount: data.cashAmount
    }));
  }, [])
  const fetchRecharge = useCallback(() => {
    handleRecharge()
  }, [handleRecharge])
  useEffect(() => {
    fetchAnchormanWalletData()
    fetchConf()
  }, [fetchAnchormanWalletData, fetchConf])
  const onDetailClick = useCallback(async () => {
    navigate(`/guild/wallet-data/member-detail/${refUser.current?.uid}?title=1`)
  }, [])

  useEffect(() => {
    getUserInfo().then((auth) => {
      if (auth) {
        setUser(auth)
      }
    })
  }, [])
  return <div className="pg-agent-recharge-cash-transfer">
    <WalletHeader title='AgentRecharge.CashTransfer' jumpText={'Common.Details'} jumpTextClick={onDetailClick}></WalletHeader>
    <Pane title={t('Wallet.CashBalance')}>
      <WalletBanner amount={state.cashAmount || 0}></WalletBanner>
    </Pane>
    <TransferContent isBD={false} AmountSend={'AgentRecharge.AmountSend'} confirmText={'Wallet.SendingTo'} AttentionInfo2TipName='Common.Cash' AttentionInfo2TipAction={'AgentRecharge.Send'} readOnly={false} state={state} hanndleAppIDChange={hanndleAppIDChange} handleFetchUser={handleFetchUser} handleClearUser={handleClearUser} hanndleAmountChange={hanndleAmountChange} handleRechage={fetchRecharge} agencyMsg={state.agencyMsg}></TransferContent>
    <Popup
      visible={transferVisible}
      className="pg-agent-recharge-cash-transfer pg-agent-recharge-cash-transfer-top-up-popup__popup"
      onMaskClick={() => setTransferVisibleVisible(false)}
      bodyStyle={{
        backgroundColor: '#fff',
        height: px2rem(533),
        borderTopLeftRadius: px2rem(12),
        borderTopRightRadius: px2rem(12)
      }}>
      <div className="pg-agent-recharge-cash-transfer-top-up-popup__popup-header">
        <div className="pg-agent-recharge-cash-transfer-top-up-popup__popup-title">{t('AgentRecharge.CashTransfer')}</div>
        <div className="pg-agent-recharge-cash-transfer-top-up-popup__popup-cancel" onClick={() => setTransferVisibleVisible(false)}>
          {t('Common.Cancel')}
        </div>
      </div>
      <div className="pg-agent-recharge-cash-transfer-top-up-popup__popup-content">
        <TransferContent isBD={true} confirmText={'Wallet.SendingTo'} AmountSend={'AgentRecharge.AmountSend'} AttentionInfo2TipName='Common.Cash' AttentionInfo2TipAction={'AgentRecharge.Send'} readOnly={true} state={state} transferVisible={transferVisible} loading={loading} handleRechage={handleRecharge}></TransferContent>
      </div>
    </Popup>
  </div>
}