import React, { ChangeEvent, memo, useCallback, useRef, useState } from 'react'
import { isAr } from '@/utils/tool';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';
import { getUserDetail } from '@/services/agentRecharge'
import { toast } from '@/utils/bridge';
import { onlyNumber } from '@/utils/format';
import { useTrans } from '@/hooks/useTranslation';
import './SearchUser.scss'

type StateType = {
    user: AgentRecharge.UserInfo | undefined
    [propName: string]: any;//任意属性，一个或多个
}
type PropsType = {
    state: StateType
    setState?: (stata: any) => void
    disable?: boolean//是否禁用
}
function SearchUser({ state, setState, disable }: PropsType) {
    const { t } = useTrans()
    const [appID, setAppID] = useState('')
    const refAppID = useRef(appID)
    refAppID.current = appID

    const refState = useRef(state)
    refState.current = state

    const hanndleAppIDChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setAppID(onlyNumber(e.target.value))
    }, [])
    const handleFetchUser = useCallback(async () => {
        if (isEmpty(refAppID.current)) return
        const res = await getUserDetail({ erbanNo: refAppID.current })

        const { code, message, data } = res as Response<AgentRecharge.UserInfo>
        if (code !== 200) {
            toast(message)
            return
        }

        setState && setState({
            ...refState.current,
            user: data
        })
    }, [getUserDetail])
    const handleClearUser = useCallback(() => {
        setAppID('')
        setState && setState({
            ...refState.current,
            user: undefined,
        })
    }, [])
    return (
        <div className='cmp-search-user'>
            <div className='cmp-search-user__content'>
                {!state.user && <div className='cmp-search-user__input'>
                    <input type='tel' inputMode="numeric" pattern="\d*" onChange={hanndleAppIDChange} placeholder={t('AgentRecharge.UserID').toString()} value={appID} />
                </div>}
                {state.user && <div className='cmp-search-user__user'>
                    <img className='cmp-search-user__user-avatar' alt='img' src={state.user.avatar} />
                    <div className="cmp-search-user__user-info">
                        <span className={clsx('cmp-search-user__user-info-name', { isAr: isAr(state.user.nick) })}>{state.user.nick}</span>
                        <span className='cmp-search-user__user-info-id'>{'ID:' + state.user.erbanNo}</span>
                    </div>
                </div>}
                {!state.user && <div className='cmp-search-user__confirm' onClick={handleFetchUser}>{t('Common.Confirm')}</div>}
                {state.user && !disable && <div className='cmp-search-user__cancel' onClick={handleClearUser}></div>}
            </div>
        </div>
    )
}

export default memo(SearchUser)