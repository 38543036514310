import React, { useMemo, useCallback } from 'react';

type PropsType = {
    keys: string[];
    children: string;
    color?: string;
    onClick?: (i: string) => void;
    className?: string;
};

const Highlight = ({
    keys = [],
    color = '#fff',
    onClick,
    children,
    className,
}: PropsType) => {
    const keyNodes = useMemo(() => {
        return keys.map((key, index) => (
            <span
                key={index}
                style={{ color }}
                onClick={() => onClick && onClick(key)}
            >
                {key}
            </span>
        ));
    }, [keys, color, onClick]);

    const parseText = useCallback((text: string) => {
        let replacedText = text;
        const replacements: Record<string, string> = {};

        keys.forEach((key, index) => {
            const placeholder = `__PLACEHOLDER_${index}__`;
            const regexp = new RegExp(key, 'g');
            replacedText = replacedText.replace(regexp, placeholder);
            replacements[placeholder] = key;
        });

        return { replacedText, replacements };
    }, [keys]);

    const renderText = useCallback(() => {
        if (!children) return null;
        const { replacedText, replacements } = parseText(children);

        const nodes = replacedText
            .split(/(__PLACEHOLDER_\d+__)/)
            .map((part, index) => {
                if (replacements[part]) {
                    const matchingNode = keyNodes.find(
                        (node) => node.props.children === replacements[part]
                    );
                    return matchingNode
                        ? React.cloneElement(matchingNode, { key: index })
                        : part;
                }
                return part;
            });

        return <div className={className}>{nodes}</div>;
    }, [children, parseText, keyNodes, className]);

    return renderText();
};

export default Highlight;
