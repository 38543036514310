import { useCallback, ChangeEvent, useState, useRef, useEffect, useMemo } from 'react'
import WalletHeader from '@/pages/Wallet/components/Header';
import Pane from '@/components/Pane';
import WithdrawBanner from '../components/Banner';
import { setBusinessStatus, toast } from '@/utils/bridge';
import clsx from 'clsx';
import Card from './components/Withdraw/Card';
import { getAnchormanWalletData, getWithdrawalAccount, getWithdrawalConf, withdrawalCash, getWithdrawFee } from '@/services/wallet'
import { formatMoney } from '@/utils/format';
import { BusinessConst } from '@/utils/constant';
import { useNavigate } from 'react-router';
import { abnormalReportWithdraw } from '@/utils/report';
import Loading from '@/components/Loading';
import { useTrans } from '@/hooks/useTranslation';
import './Withdraw.scss'

function Withdraw() {
    const { t } = useTrans()
    const navigate = useNavigate()

    const [loading, setLoading] = useState<boolean>(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [state, setState] = useState<{
        inputMoney: string
        cashAmount: number
        agencyCount: number
        agencyMsg: string
        withdrawalFee: number
        withdrawalArrival: number
        realityCashAmount: number
        feeCashAmount: number
        realityFeeCashAmount: number
        isQuota: boolean
        quotaFeeDeduct: number
    } & Wallet.WithdrawalAccount>({
        inputMoney: '', cashAmount: 0,
        agencyCount: 0, agencyMsg: '',
        withdrawalFee: 0, withdrawalArrival: 0,
        realityCashAmount: 0,
        feeCashAmount: 0,
        realityFeeCashAmount: 0,
        quotaFeeDeduct: 0,
        countryCode: '',
        realName: '',
        fullName: '',
        accountNo: '',
        auditStatus: 0,
        countryName: '',
        channelPict: '',
        channelName: '',
        channelExtendPictArr: '',
        whatsApp: '',
        currency: '',
        channelType: null,
        existApplyAccount: 0,
        isQuota: false
    })
    const refState = useRef(state)
    refState.current = state

    const hanndleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/,/g, '')
        if (isNaN(Number(value))) return
        if (Number(value) > refState.current.cashAmount) {
            toast(t('Wallet.ExchangeCannotBalance'))
            value = Math.floor(refState.current.cashAmount).toString()
        }

        setState({
            ...refState.current,
            inputMoney: formatMoney(value)
        })
    }, [])

    /*获取主播钱包信息、cash配置、当前用户提现账号信息 */
    const fetchHomeData = useCallback(async () => {
        const [walletData, confData, withdrawalAccountData]: [Response<Wallet.AnchormanWalletData>, Response<Wallet.WithdrawalConf>, Response<Wallet.WithdrawalAccount>] = await Promise.all([
            getAnchormanWalletData(),
            getWithdrawalConf(),
            getWithdrawalAccount()
        ])
        if (!walletData || !confData || !withdrawalAccountData) return

        if (walletData.code !== 200) {
            toast(walletData.message)
            return
        }
        if (confData.code !== 200) {
            toast(confData.message)
            return
        }
        if (withdrawalAccountData.code !== 200) {
            toast(withdrawalAccountData.message)
            return
        }
        setState({
            ...refState.current,
            ...walletData.data,
            ...withdrawalAccountData.data,
            ...confData.data,
            agencyCount: confData.data.cashWithdrawalMinCount,
            agencyMsg: confData.data.cashWithdrawalMinMsg,
        })
    }, [])

    const isCanExchange = useMemo(() => {
        const money = state.inputMoney.replace(/,/g, '')
        let flag = true
        if (Number(money) < state.agencyCount || !money) {
            flag = false
        }
        return flag
    }, [state])

    /* 请求成功跳转事件 */
    const goSuccessPage = useCallback(() => {
        setBusinessStatus(BusinessConst.CoinChange)
        navigate(`/wallet/withdraw/success?inputNum=${refState.current.inputMoney}`);
    }, [])

    /* 请求失败跳转事件 */
    const goWarningPage = useCallback((res: any) => {
        abnormalReportWithdraw(res, { userId: '', inputNum: refState.current.inputMoney });
        navigate(`/wallet/withdraw/warning`);
    }, [])

    /* 提现申请 */
    const handleExchange = useCallback(async () => {
        if (refLoading.current) return

        const money = refState.current.inputMoney.replace(/,/g, '')
        if (!money) return
        // 拦截SWIFT支付方式
        if (refState.current.channelType === 1 && Number(money) <= 700) {
            toast(t('Wallet.Withdraw.SWIFTTips'))
            return
        }

        setLoading(true)
        const res = await withdrawalCash(Number(money), refState.current.isQuota) as Response
        setLoading(false)

        const { code, message } = res
        // 请求超时或断网
        if (code.toString() === 'ECONNABORTED' || code.toString() === 'ERR_NETWORK' || message.includes('timeout')) {
            goWarningPage(res)
            return;
        }
        if (code !== 200) {
            toast(message)
            return
        }
        goSuccessPage()
    }, [])

    const updateWithdrawalFee = useCallback(async () => {
        const money = refState.current.inputMoney.replace(/,/g, '')
        if (!money) return

        const res = await getWithdrawFee(Number(money), refState.current.isQuota)
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setState({
            ...refState.current,
            realityCashAmount: res.data.realityCashAmount,
            feeCashAmount: res.data.freeCashAmount,
            realityFeeCashAmount: res.data.realityFreeCashAmount,
            quotaFeeDeduct: res.data.quotaFeeDeduct
        })
    }, [])

    const refHandler = useRef<NodeJS.Timeout>()

    useEffect(() => {
        if (refHandler.current) {
            clearTimeout(refHandler.current)
        }
        refHandler.current = setTimeout(updateWithdrawalFee, 200)
    }, [state.inputMoney])

    useEffect(() => {
        updateWithdrawalFee()
    }, [state.isQuota])

    const handleAmountMax = useCallback(() => {
        setState({
            ...refState.current,
            inputMoney: refState.current.cashAmount.toString()
        })
    }, [])

    const handleQuotaCheck = useCallback(() => {
        setState({
            ...refState.current,
            isQuota: !refState.current.isQuota
        })
    }, [])

    useEffect(() => {
        fetchHomeData()
    }, [])

    return (
        <div className='pg-wallet-withdraw pg-fa'>
            {loading && <Loading />}
            <WalletHeader title={'Wallet.Withdraw.Title'}></WalletHeader>
            <Pane title={t('Wallet.CashBalance')}>
                <WithdrawBanner amount={state.cashAmount || 0}></WithdrawBanner>
            </Pane>
            <Pane title={t('Wallet.Withdraw.AmountWithdraw')}>
                <div className="pg-wallet-withdraw__amount">
                    <input type='tel' inputMode="numeric" pattern="\d*" onChange={hanndleAmountChange} placeholder={state.agencyMsg} value={state.inputMoney} />
                    <div className='pg-wallet-withdraw__amount-max' onClick={handleAmountMax}>{t('Wallet.Withdraw.AmountMax')}</div>
                </div>
                {/* <div className="pg-wallet-withdraw__fee">
                    <div className='pg-wallet-withdraw__fee-amount'>
                        {t('Wallet.Withdraw.Amount')}
                    </div>
                    <div className='pg-wallet-withdraw__fee-amount-num'>
                        {state.realityCashAmount}
                    </div>
                    <div className='pg-wallet-withdraw__fee-info'>
                        <span>{t('Wallet.Withdraw.ArrivalDays', { day: state.withdrawalArrival })}</span>
                        <span><Trans i18nKey='Wallet.Withdraw.Fee' values={{
                            fee: (state.withdrawalFee * 100) + '%'
                        }}><span /></Trans></span>
                    </div>
                    {<div className='pg-wallet-withdraw__fee-val'>
                        <Trans i18nKey='Wallet.Withdraw.Fee' values={{
                            fee: state.feeCashAmount
                        }}>
                            Fee: <span className='pg-wallet-withdraw__fee-val-un'>{state.feeCashAmount === state.realityFeeCashAmount ? '' : state.feeCashAmount}</span>
                        </Trans>
                    </div>}
                    <div className='pg-wallet-withdraw__fee-quota'>{t('Wallet.Withdraw.QuotaDeducition', { quota: state.quotaFeeDeduct })}<span className={clsx('pg-wallet-withdraw__fee-quota-checkbox', {
                        checked: state.isQuota
                    })} onClick={handleQuotaCheck}></span></div>
                    <div className='pg-wallet-withdraw__tips'>{t('Wallet.Withdraw.Tips')}</div>
                </div> */}
            </Pane>
            <Pane title={t('Wallet.Withdraw.CheckExchange')}>
                <Card cardInfo={{ ...state }}></Card>
            </Pane>
            <div className='pg-wallet-withdraw__bottom'>
                <button onClick={handleExchange} className={clsx('pg-wallet-withdraw__btn', {
                    'activate-btn': isCanExchange,
                    'unactivated-btn': !isCanExchange
                })} disabled={!isCanExchange}>
                    {t('Common.Exchange')}
                </button>
            </div>
        </div>
    )
}

export default Withdraw