import { memo } from 'react'
import ImgArrow from '@/images/arrow.png';
import { useTrans } from '@/hooks/useTranslation';
import './HrefButton.scss';

type PropsType = {
    text: string
    onClick?: () => void
}
function HrefButton({ text, onClick }: PropsType) {
    const { t } = useTrans()

    return (
        <div className='cmp-anchorman-wallet-detail-href-button' onClick={onClick}>
            <span>{t(text)}</span>
            <img src={ImgArrow}></img>
        </div>
    )
}

export default memo(HrefButton)