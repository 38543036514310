import Header from '@/components/Header'
import RuleTable from './components/RuleTable';
import { useTrans } from '@/hooks/useTranslation';
import './Rule.scss'

export default function GuildRule() {
  const { t } = useTrans()

  return <div className='pg-guild-agency-reward-rule'>
    <Header title={t('Guild.Rule.Rules').toString()} />
    <div className='pg-guild-agency-reward-rule__item'>
      <div className='pg-guild-agency-reward-rule__item-title'>{t('Guild.AgencyReward.Rule.Title1')}</div>
      {new Array(8).fill(0).map((item, i) =>
        <div key={i} className='pg-guild-agency-reward-rule__item-content'>{t(`Guild.AgencyReward.Rule.Rule${i + 1}`)}</div>
      )}
    </div>
    <div className='pg-guild-agency-reward-rule__item'>
      <div className='pg-guild-agency-reward-rule__item-title'>{t('Guild.AgencyReward.Rule.Title2')}</div>
      {new Array(10).fill(0).map((item, i) =>
        <div key={i} className='pg-guild-agency-reward-rule__item-content'>{t(`Guild.AgencyReward.Rule.Rule${i + 1 + 8}`)}</div>
      )}
    </div>
    <div className='pg-guild-agency-reward-rule__item'>
      <div className='pg-guild-agency-reward-rule__item-title'>{t('Guild.AgencyReward.Rule.Title3')}</div>
      <div className='pg-guild-agency-reward-rule__item-content'>
        <RuleTable />
      </div>
    </div>
  </div>
}