import { useCallback } from 'react'
import MemberCard from './MemberCard'
import { useTrans } from '@/hooks/useTranslation'
import './MessageCard.scss'

type MessageCardProps = {
  memberId: number
  applyId: number
  avatar: string
  username: string
  icons: string[]
  time: number
  onAudit: (applyId: number, auditStatus: Guild.AuditStatus) => void
}

export default function MessageCard(props: MessageCardProps) {
  const { t } = useTrans()

  const handleAudit = useCallback((auditStatus: Guild.AuditStatus) => {
    props.onAudit && props.onAudit(props.applyId, auditStatus)
  }, [props])

  return <div className='cmp-message-card'>
    <MemberCard
      memberId={props.memberId}
      avatar={props.avatar}
      username={props.username}
      icons={props.icons}
      time={props.time}
    />
    <div className='cmp-message-card__actions'>
      <div className='cmp-message-card__btn-reject' onClick={() => handleAudit(2)}>{t('Reject')}</div>
      <div className='cmp-message-card__btn-consent' onClick={() => handleAudit(1)}>{t('Consent')}</div>
    </div>
  </div>
}