import ImageNoticIcon from '../../../images/guild-notice-icon.png'
import './Announce.scss'

type AnnounceProps = {
  text:string
}

export default function Announce(props:AnnounceProps) {
  return <div className='cmp-announce'>
    <div className='cmp-announce__line-top' />
    <div className='cmp-announce__content'>
      <div className='cmp-announce__icon'>
        <img alt='announce icon' src={ImageNoticIcon} />
      </div>
      <div className='cmp-announce__text'>
        {props.text}
      </div>
    </div>
    <div className='cmp-announce__line-bottom' />
  </div>
}