import React, { memo } from 'react'
import { useTrans } from '@/hooks/useTranslation';
import './CardItem.scss';

type PropsType = {
    contentNum?: string
    contentIcon?: string
    onlyDetail?: boolean
    detailClick?: (titleNum: number) => void
    title?: string
    titleNum: number
    titleExtra?: React.ReactNode
    className?: string
    extraContent?: React.ReactNode
    hideDetailContent?: boolean
}
function CardItem({ contentNum, contentIcon, onlyDetail, title, titleExtra, titleNum, className, detailClick, extraContent, hideDetailContent }: PropsType) {
    const { t } = useTrans()

    return (
        <div className={`cmp-anchorman-wallet-detail-card-item ${className}`}>
            <div className="title">
                <div className={`num num${titleNum}`}>{titleNum}</div>
                <span>{t(title ? title : '')}</span>
                {titleExtra}
            </div>
            {!hideDetailContent &&
                <div className="content">
                    {!onlyDetail && <div className="left">
                        <img src={contentIcon}></img>
                        <span>{contentNum}</span>
                    </div>}
                    <div className="right" onClick={() => detailClick && detailClick(titleNum)}>
                        {t('Common.Details')}
                    </div>
                </div>
            }
            {extraContent && extraContent}
        </div>
    )
}

export default memo(CardItem)