export const BusinessConst = {
  GuildExit: 1,
  CoinChange: 2,
  GemExchange: 3
}

export const PageConst = {
  Reward: 1,
  PersonalHome: 2,
  Mine: 3,
  IMChat: 4
}

export const defaultAvatarSrc = process.env.REACT_APP_DEFAULT_AVATAR

export const downloadLink = process.env.REACT_APP_DOWNLOAD_LINK