import { service } from "../utils/net";

export function getUserDetail(params:ThirdRecharge.SearchUserParam) {
  return service<Response<AgentRecharge.UserInfo>>({
    url: '/user/search/user',
    params
  }).then(res => res.data).catch(e => e)
}

export function recharge(params:ThirdRecharge.RechargeParam) {
  return service<Response<{redirectUrl:string}>>({
    method: 'POST',
    url: '/official/website/place',
    params
  }).then(res => res.data).catch(e => e)
}

export function getPayList() {
  return service<Response<ThirdRecharge.PayItem[]>>({
    method: 'POST',
    url: '/chargeProd/list/h5'
  }).then(res => res.data).catch(e => e)
}