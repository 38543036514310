import { useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Meta from '../../components/Meta'
import Image1En from '../../images/rule-level1-en.webp'
import Image2En from '../../images/rule-level2-en.webp'
import Image3En from '../../images/rule-level3-en.webp'
import Image1Ar from '../../images/rule-level1-ar.webp'
import Image2Ar from '../../images/rule-level2-ar.webp'
import Image3Ar from '../../images/rule-level3-ar.webp'
import SafeArea from '../../components/SafeArea'
import { useTrans } from '@/hooks/useTranslation'
import './Level.scss'

export default function RuleLevel() {
  const { t, i18n } = useTrans()
  const [imgs, setImgs] = useState<[string, string, string]>([
    Image1En,
    Image2En,
    Image3En
  ])

  useEffect(() => {
    switch (i18n.language) {
      case 'ar':
        setImgs([Image1Ar, Image2Ar, Image3Ar])
        break
      default:
    }
  }, [i18n.language])

  return (
    <HelmetProvider>
      <Meta title={t('Guild.Level.UserLevelDescription').toString()} />
      <SafeArea position="top" />
      <div className="pg-rule-level">
        <img className="pg-rule-level__img1" alt="img1" src={imgs[0]} />
        <img className="pg-rule-level__img2" alt="img2" src={imgs[1]} />
        <img className="pg-rule-level__img3" alt="img3" src={imgs[2]} />
      </div>
      <SafeArea position="bottom" />
    </HelmetProvider>
  )
}
