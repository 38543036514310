import React, { memo, useCallback, useRef, useState } from 'react'
import { Swiper } from 'antd-mobile'
import Pane from '@/components/Pane';
import SearchUser from '@/components/SearchUser';
import CoinExchangeSelect from '../../../components/CoinExchangeSelect';
import CurrencyExchange from '../../../components/CurrencyExchange';
import clsx from 'clsx';
import AnchormanWalletDetailPopup from './AnchormanWalletDetailPopup';
import { isEqual } from 'lodash-es';
import { useTrans } from '@/hooks/useTranslation';
import './PopupSwiper.scss'

interface PropsType {
    visible: boolean
    setVisible: (visible: boolean) => void
    state: any
    setState: (state: any) => void
    handleNext: () => void
    title: string
    money: number
    titleExtra?: string
    searchUserDisable?: boolean
}
function PopupSwiper({ state, setState, handleNext, visible, setVisible, titleExtra, title, searchUserDisable, money }: PropsType) {
    const { t } = useTrans()
    const refCoinSelect = useRef<{ initSelected: () => void }>(null)
    const refState = useRef(state)
    refState.current = state
    const refSwiper = useRef<React.ElementRef<typeof Swiper>>(null)
    const [swiperTab, setSwiperTab] = useState(0)
    const refSwiperTab = useRef(swiperTab)
    refSwiperTab.current = swiperTab
    const handleThisNext = useCallback(() => {
        if (refSwiperTab.current === 0) {
            setSwiperTab(1)
            refSwiper.current?.swipeTo(1)
            return
        }
        setSwiperTab(2)
        handleNext()
    }, [handleNext])
    const handleBack = useCallback(() => {
        if (!refSwiperTab.current) return
        setSwiperTab(0)
        refSwiper.current?.swipeTo(0)
    }, [])
    const handleCancel = useCallback(() => {
        refCoinSelect.current && refCoinSelect.current?.initSelected()
        setSwiperTab(0)
        refSwiper.current?.swipeTo(0)
        setState({
            ...refState.current,
            user: undefined,
            selectedPro: undefined
        })
    }, [])
    const handleSelectCoin = useCallback((i: number) => {
        if (isEqual(refState.current.selectedPro, refState.current.proList[i])) return
        setState({
            ...refState.current,
            selectedPro: refState.current.proList[i],
        })
    }, [])
    return (
        <div className='cmp-anchorman-wallet-detail-popup-swiper'>
            <AnchormanWalletDetailPopup arrow={swiperTab > 0} title={title} titleExtra={titleExtra} visible={visible} setVisible={setVisible} handleBack={handleBack} handleCancel={handleCancel}>
                <Swiper
                    direction="horizontal"
                    loop
                    indicator={() => null}
                    ref={refSwiper}
                    defaultIndex={0}
                    allowTouchMove={false}
                >
                    <Swiper.Item>
                        <div className="swiper__content">
                            <Pane title={t('Wallet.CheckUserID')} className='popup-pane'>
                                <SearchUser state={state} setState={setState} disable={searchUserDisable}></SearchUser>
                            </Pane>
                            <Pane title={t('Wallet.ExchangeForCoins')} className='pane-prefix'>
                                <CoinExchangeSelect ref={refCoinSelect} prodList={state.proList} handleSelectCoin={handleSelectCoin}></CoinExchangeSelect>
                            </Pane>
                        </div>
                    </Swiper.Item>
                    <Swiper.Item>
                        <div className="swiper__content">
                            <Pane title={t('Wallet.CheckUserID')} className='popup-pane'>
                                <SearchUser state={state} setState={setState} disable={searchUserDisable}></SearchUser>
                            </Pane>
                            <Pane title={t('Wallet.CheckExchange', { money })} className='popup-pane'>
                                <CurrencyExchange before={state.selectedPro?.cashAmount || 0} after={state.selectedPro?.goldNum || 0}></CurrencyExchange>
                                <div className="check-tips">
                                    {new Array(3).fill(0).map((_, i) => (
                                        <span key={i}>{t(`Wallet.AnchormanWalletDetailRule${i + 1}`)}</span>
                                    ))}
                                </div>
                            </Pane>
                        </div>
                    </Swiper.Item>
                </Swiper>
                <button className={clsx('bottom-btn', { 'btn-blue': state.user && swiperTab !== 2 && state.selectedPro })} disabled={!state.user || swiperTab === 2 || !state.selectedPro} onClick={handleThisNext}>{swiperTab === 0 ? t('Wallet.Next') : swiperTab === 1 ? t('Common.Exchange') : t('Wallet.Exchangeing')}</button>
            </AnchormanWalletDetailPopup>
        </div>
    )
}

export default memo(PopupSwiper)