import LoveCardHeader from "./Components/Header";
import { useCallback, useContext, useEffect, useRef } from 'react';
import { Tabs } from 'antd-mobile';
import List from './Components/List';
import { LoveCardContext } from '@/store/lovecard';
import { useTrans } from '@/hooks/useTranslation';
import './Home.scss';

export default function LoveCard() {
  const { t } = useTrans()
  const { dispatch } = useContext(LoveCardContext)
  const refGiveList = useRef<React.ElementRef<typeof List>>(null)
  const refRecvList = useRef<React.ElementRef<typeof List>>(null)
  const handleTabChange = useCallback((key: string) => {
    switch (key) {
      case 'Give':
        refGiveList.current?.fetch()
        break;
      case 'Recv':
        refRecvList.current?.fetch()
        break;
    }
  }, [])

  let tabs = [{
    key: 'Give',
    ref: refGiveList
  }, {
    key: 'Recv',
    ref: refRecvList
  }]
  if (window.lang === 'ar') {
    tabs = tabs.reverse()
  }

  useEffect(() => {
    dispatch && dispatch({
      type: 'update',
      data: {
        selectedFriend: null,
        isUpdate: true
      }
    })
  }, [])

  return <div className="pg-lovecard">
    <LoveCardHeader />
    <Tabs defaultActiveKey="Give" onChange={handleTabChange}>
      {tabs.map(tab => <Tabs.Tab title={t(`Wallet.LoveCard.${tab.key}`)} key={tab.key}>
        <List ref={tab.ref} type={tab.key} />
      </Tabs.Tab>)}
    </Tabs>
  </div>
}