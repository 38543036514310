import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import MemberCard from '@/pages/Guild/Components/MemberCard'
import SelectTimeRange from '@/components/SelectTimeRange'
import moment from 'moment'
import TabButtons from '@/pages/Guild/Components/TabButtons'
import MemberDetailData from './components/MemberDetailData';
import SafeArea from '@/components/SafeArea';
import { getGuildMemberMonthBillDetail, getMemberBillRecord, getMemberDayIncome } from '@/utils/request';
import { toast } from '@/utils/bridge'
import { useParams, useSearchParams } from 'react-router-dom'
import { getBillRecordTypeList } from '@/services/wallet';
import clsx from 'clsx'
import Loading from '@/components/Loading';
import NumberCard from '../Components/NumberCard'
import ImgGem from '@/images/gem.png';
import ImgMoney from '@/images/money.webp';
import { useTrans } from '@/hooks/useTranslation'
import './MemberDetail.scss'

interface RechargeDetailType {
    firstBillTree: {
        billTypeId: number;
        billTypeName: string;
        secondBillTree: Wallet.ThirdBillTree[];
    }[];
}

function MemberDetail() {
    const { t } = useTrans()
    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading
    const [loadingHome, setLoadingHome] = useState(0)

    const [params] = useSearchParams()
    const { memberId } = useParams()
    const refMemberId = useRef(memberId)
    refMemberId.current = memberId

    //账单数据list请求控制器
    const refBillRecordAbortController = useRef<AbortController>()

    const [selectedTitle, setSelectedTitle] = useState<number>(Number(params.get('title')))
    const refSelectedTitle = useRef(selectedTitle)
    refSelectedTitle.current = selectedTitle

    const [selectedTab, setSelectedTab] = useState<number>(0)
    const refSelectedTab = useRef(selectedTab);
    refSelectedTab.current = selectedTab;

    const [memberBillDetail, setMemberBillDetail] = useState<Guild.GuildMemberMonthBill>()
    const [memberDayIncome, setMemberDayIncome] = useState<Guild.GuildMemberDayIncome[]>([])

    // const [time, setTime] = useState<{ start: Date; end: Date }>({
    //     start: moment().startOf('month').toDate(),//每月的1号0点
    //     end: moment().startOf('date').toDate()//当天0点
    // })
    const [time, setTime] = useState<{ start: Date; end: Date }>({
        start: moment().subtract(7, 'days').toDate(),//7天前的
        end: moment().endOf('date').toDate()//当天0点
    })
    const refTime = useRef(time)
    refTime.current = time

    const [state, setState] = useState<{
        titleItems: string[]
        tabItems: Array<Wallet.ThirdBillTree[]>
    }>()
    const refState = useRef(state);
    refState.current = state

    const [listInfo, setListInfo] = useState<{
        list: Guild.GuildMemberBillRecordListItem[];
        hasMore: boolean;
        canRequest: boolean
    }>({
        list: [],
        hasMore: true,
        canRequest: false
    });
    const refListInfo = useRef(listInfo);
    refListInfo.current = listInfo;

    const [billTypeId, setBillTypeId] = useState(0);
    const billTypeIdRef = useRef(billTypeId);
    billTypeIdRef.current = billTypeId;

    const [currentData, setCurrentData] = useState({
        index: 0,//当前高亮柱子下标
        time: '',//当前高亮柱子时间
    })
    const refCurrentData = useRef(currentData)
    refCurrentData.current = currentData

    /* 给图表的数据 */
    const chartsData = useMemo(() => {
        return memberDayIncome.map(item => {
            return selectedTitle === 0 ? { key: item.dayInt, value: item.todayGemIncome } : { key: item.dayInt, value: item.todayCashIncome }
        })
    }, [memberDayIncome, selectedTitle])

    useEffect(() => {
        /* 因为在一级title切换那里，有个setSelectedTab(0)，所以初始默认值不行，得重新设置一下 */
        setTimeout(() => {
            setSelectedTab(Number(params.get('tab')))
        }, 0)
    }, [params])

    /* 移除用户 */
    // const handleRemove = useCallback(() => {
    //     console.log('remove');
    // }, [])

    const handleTimeRangeChange = useCallback((start: Date, end: Date) => {
        setListInfo({
            ...refListInfo.current,
            list: [],
            hasMore: true
        })
        setTime({ start, end })
    }, [])

    /* 用户信息请求 */
    const fetchMemberBillDetail = useCallback(async () => {
        if (!refMemberId.current) return
        const { code, data, message } = await getGuildMemberMonthBillDetail(refMemberId.current) as Response<Guild.GuildMemberMonthBill>
        setLoadingHome(prev => prev + 1)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setMemberBillDetail(data)
    }, [])
    /* 账单类型请求 */
    const fetchBillRecordTypeList = useCallback(async () => {
        const { code, message, data } = await getBillRecordTypeList(3) as Response<RechargeDetailType>;
        setLoadingHome(prev => prev + 1)
        if (code !== 200) {
            toast(message);
            return;
        }
        if (!data) return;

        const tabItemsArr: Array<Wallet.ThirdBillTree[]> = []
        data.firstBillTree.forEach(item => {
            const itemArr = item?.secondBillTree?.map((item) => ({
                billTypeName: item?.billTypeName,
                billTypeId: item?.billTypeId,
            }))
            tabItemsArr.push(itemArr)
        })

        setState({
            titleItems: data.firstBillTree?.map(item => item.billTypeName),
            tabItems: tabItemsArr
        })
        setBillTypeId(data.firstBillTree[refSelectedTitle.current]?.secondBillTree[refSelectedTab.current].billTypeId);
    }, []);
    /* 账单数据list请求 */
    const fetchMemberBillRecordList = useCallback(async (refresh = false) => {
        if (!refListInfo.current.hasMore || !billTypeIdRef.current || !refMemberId.current) return

        const beginDate = moment(refCurrentData.current.time, 'MM-DD').year(moment(refTime.current.start).year())
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

        const endDate = moment(refCurrentData.current.time, 'MM-DD').year(moment(refTime.current.end).year())
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

        const lastTime = refresh ? undefined : refListInfo.current.list[refListInfo.current.list.length - 1]?.billTime;

        setLoading(true)
        //取消上次请求，防止上一次请没到，就切换tab导致数据错乱了
        const controller = new AbortController()
        if (refBillRecordAbortController.current) {
            refBillRecordAbortController.current.abort()
            refBillRecordAbortController.current = undefined
            if (refLoading.current) {
                setLoading(false)
                setTimeout(() => {
                    setLoading(true)
                }, 0)
            }
        }
        refBillRecordAbortController.current = controller

        const { code, data, message } = await getMemberBillRecord({
            memberUid: refMemberId.current!,
            billType: billTypeIdRef.current,
            lastTime,
            beginDate,
            endDate
        }, controller.signal) as Response<PageList<Guild.GuildMemberBillRecordListItem>>

        setLoading(false)

        if (code !== 200 && message !== 'canceled') {
            toast(message);
            return;
        }

        if (!data) return;

        setListInfo({
            ...refListInfo.current,
            list: [
                ...refListInfo.current.list,
                ...(data.list || []),
            ],
            hasMore: !data.whetherLastPage,
            canRequest: data.whetherLastPage,
        });

    }, [])
    const refreshMemberBillRecordList = useCallback(() => {
        setListInfo({
            ...refListInfo.current,
            list: [],
            hasMore: true,
        });
        setTimeout(() => {
            fetchMemberBillRecordList(true)
        }, 0);
    }, [])
    /* 图表信息请求 */
    const fetchMemberDayIncome = useCallback(async () => {
        if (!refMemberId.current) return
        const startTimeInt = moment(refTime.current.start)
            .startOf('day')
            .format('YYYYMMDD')

        const endTimeInt = moment(refTime.current.end)
            .endOf('day')
            .format('YYYYMMDD')

        const { code, data, message } = await getMemberDayIncome({
            memberUid: refMemberId.current,
            startTimeInt,
            endTimeInt
        })
        setLoadingHome(prev => prev + 1)
        if (code !== 200) {
            toast(message);
            return;
        }

        if (!data) return;
        setMemberDayIncome(data)
    }, [])
    //清空listInfo
    const clearListInfo = useCallback(() => {
        setListInfo({
            ...refListInfo.current,
            list: [],
            hasMore: true
        })
    }, [])
    /* 一级title切换 */
    useEffect(() => {
        clearListInfo()
        if (refSelectedTab.current === 0) {
            setBillTypeId(refState.current?.tabItems[refSelectedTitle.current][0]?.billTypeId || 0);
        }
        setSelectedTab(0)
    }, [selectedTitle])
    /* 二级tab切换 */
    useEffect(() => {
        clearListInfo()
        setBillTypeId(refState.current?.tabItems[refSelectedTitle.current][refSelectedTab.current]?.billTypeId || 0);
    }, [selectedTab])
    /* 初始请求 */
    useEffect(() => {
        fetchMemberBillDetail()
        fetchBillRecordTypeList()
    }, [])
    /* 类型id变换请求 */
    useEffect(() => {
        fetchMemberBillRecordList()
    }, [billTypeId])
    /* 柱状图切换时间请求列表数据 */
    useEffect(() => {
        if (currentData.time && refListInfo.current.canRequest) {
            fetchMemberBillRecordList()
        }
    }, [currentData.time])
    /* 时间切换请求柱状图数据 */
    useEffect(() => {
        fetchMemberDayIncome()
    }, [time])
    return (
        <HelmetProvider>
            <Meta title={t('Guild.WalletData').toString()} />
            <div className='pg-guild-wallet-data-member-detail'>
                {loadingHome < 3 && <Loading />}
                <Header title={t('Guild.WalletData').toString()} />
                <div className="content">
                    <div className="top">
                        <MemberCard
                            memberId={Number(memberBillDetail?.uid) || 0}
                            avatar={memberBillDetail?.avatar || ''}
                            username={memberBillDetail?.nick || ''}
                            erbanNo={memberBillDetail?.erbanNo}
                        // rightBtn={
                        //     {
                        //         text: 'Common.Remove',
                        //         onRightBtn: handleRemove
                        //     }
                        // }
                        />
                        <div className='balance'>
                            <div className="left">
                                <span>{t('Guild.GemsBalance')}</span>
                                <img src={ImgGem} alt="" />
                                <span>{memberBillDetail?.gemBalance}</span>
                            </div>
                            <div className='line'></div>
                            <div className="right">
                                <span>{t('Wallet.CashBalance')}</span>
                                <img src={ImgMoney} alt="" />
                                <span>{memberBillDetail?.cashBalance}</span>
                            </div>
                        </div>
                        <NumberCard
                            items={[
                                {
                                    key: 'Guild.TodaysGemIncome',
                                    val: `+${memberBillDetail?.todayGemIncome || 0}`
                                },
                                {
                                    key: 'Guild.YesterdaysGemIncome',
                                    val: `+${memberBillDetail?.yesDayGemIncome || 0}`
                                },
                                {
                                    key: 'Guild.ThisMonthlyGemIncome',
                                    val: `+${memberBillDetail?.thisMonthDiamondIncome || 0}`
                                }
                            ]}
                        />
                        <NumberCard
                            class="last"
                            items={[
                                {
                                    key: 'Guild.TodaysCashIncome',
                                    val: `+${memberBillDetail?.todayCashIncome || 0}`
                                },
                                {
                                    key: 'Guild.YesterdaysCashIncome',
                                    val: `+${memberBillDetail?.yesDayCashIncome || 0}`
                                },
                                {
                                    key: 'Guild.ThisMonthlyCashIncome',
                                    val: `+${memberBillDetail?.thisMonthCashAmountIncome || 0}`
                                }
                            ]}
                        />
                    </div>
                    <SelectTimeRange
                        startTime={time.start}
                        endTime={time.end}
                        onChange={handleTimeRangeChange}
                        min={moment('2024-01-11').toDate()}
                    />
                    <div className="bottom">
                        <TabButtons titleItems={state?.titleItems || []} selectedTab={selectedTitle} setSelectedTab={setSelectedTitle}></TabButtons>
                        <MemberDetailData fetchMemberBillRecordList={refreshMemberBillRecordList} clearListInfo={clearListInfo} setCurrentData={setCurrentData} currentData={currentData} chartsData={chartsData} time={time} loading={loading} listInfo={listInfo} hasMore={fetchMemberBillRecordList} className={clsx({ 'cash': selectedTitle === 1 })} selectedTab={selectedTab} setSelectedTab={setSelectedTab} titleItems={state?.tabItems[selectedTitle]?.map(item => item.billTypeName) || []} ></MemberDetailData>
                    </div>
                </div>
                <SafeArea position="bottom" />
            </div>
        </HelmetProvider>
    )
}

export default MemberDetail