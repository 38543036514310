import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './Detail.scss'
import { useSearchParams } from 'react-router-dom';
import WalletHeader from './components/Header';
import { getBillRecordTypeList } from '@/services/wallet';
import { toast } from '@/utils/bridge'
import CoinDeatail from './CoinDetail';
import GemDeatail from './PearlDetail';
import BeanDeatail from './BeanDetail';
import Loading from '@/components/Loading';
function index() {
    const [params] = useSearchParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [homeData, setHomeData] = useState<Wallet.BillRecordTypeList>({
        firstBillTree: [{
            billTypeId: 0,
            billTypeName: '', secondBillTree: [{
                billTypeId: 0,
                billTypeName: '', thirdBillTree: [{
                    billTypeName: '',
                    billTypeId: 0
                }]
            }]
        }]
    })
    const titleItems = useMemo(() => homeData.firstBillTree.map(item => item.billTypeName), [homeData]);
    const [selectedTitle, setSelectedTitle] = useState<number>(Number(params.get('title')) > 2 ? 0 : Number(params.get('title')))//一级
    const linkageData1 = useMemo(() => homeData.firstBillTree[0], [homeData]);
    const linkageData2 = useMemo(() => homeData.firstBillTree[1], [homeData]);
    const linkageData3 = useMemo(() => homeData.firstBillTree[2], [homeData]);

    const tab = useMemo(() => Number(params.get('tab')), [params])
    const type = useMemo(() => Number(params.get('type')), [params])
    const fetchHomeData = useCallback(async () => {
        setLoading(true)
        const { code, message, data } = await getBillRecordTypeList(1) as Response<Wallet.BillRecordTypeList>
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setHomeData(data)
    }, [])
    useEffect(() => {
        fetchHomeData()
    }, [fetchHomeData])
    return (
        <div className='pg-wallet-detail'>
            {loading && <Loading />}
            <WalletHeader titleItems={titleItems} selectedTitle={selectedTitle} setSelectedTitle={setSelectedTitle}></WalletHeader>
            <CoinDeatail linkageData={linkageData1} tab={tab} type={type} hide={selectedTitle !== 0} />
            <GemDeatail linkageData={linkageData2} tab={tab} type={type} hide={selectedTitle !== 1} />
            <BeanDeatail linkageData={linkageData3} tab={tab} type={type} hide={selectedTitle !== 2} />
        </div>
    )
}

export default index