import ImgArrow from '@/images/arrow.png';
import clsx from 'clsx';
import './ClickCard.scss'

type PropsType = {
    top: {
        topNum: string
        topTitle?: string | null
        onClick?: () => void
    }
    bottom: {
        bottomTitle: string
        isBtn?: boolean
        onClick?: () => void
    }
}
function ClickCard({ top, bottom }: PropsType) {
    return (
        <div className='cmp-click-card'>
            <div className='cmp-click-card__top' onClick={top.onClick}>
                {top.topTitle &&
                    <span className='cmp-click-card__top-title'>{top.topTitle}</span>
                }
                <span className='cmp-click-card__top-num'>{top.topNum}</span>
                <img className='cmp-click-card__top-arrow' src={ImgArrow} alt="" />
            </div>
            <div className='cmp-click-card__line'></div>
            <div className='cmp-click-card__bottom'>
                <div className={clsx({ 'btn': bottom.isBtn })} onClick={bottom.onClick}>{bottom.bottomTitle}</div>
            </div>
        </div>
    )
}

export default ClickCard