import { SpinLoading } from 'antd-mobile'
import './Loading.scss'

export default function Loading() {
  return (
    <div className="cmp-loading">
      <SpinLoading />
    </div>
  )
}
