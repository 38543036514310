import { useNavigate, useParams } from "react-router"
import LoveCardHeader from './Components/Header'
import Pane from '@/components/Pane'
import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { toast } from '@/utils/bridge'
import { getDetail, giveCard, modifyCard } from '@/services/LoveCard'
import ImageCoin from '@/images/coin.png'
import LoveCardUser from './Components/User'
import { LoveCardContext } from '@/store/lovecard'
import { useTrans } from '@/hooks/useTranslation'
import './Create.scss'

export default function LoveCardCreate() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const { cardId } = useParams()
  const { state: ctxState } = useContext(LoveCardContext)
  const [state, setState] = useState<{
    goldLimit: number
    card?: LoveCard.Item
  }>({
    goldLimit: 0
  })
  const refState = useRef(state)
  refState.current = state

  const handleCoinsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...refState.current,
      goldLimit: Number(e.target.value)
    })
  }, [])

  const handleCreate = useCallback(async () => {
    if (!ctxState.selectedFriend) return

    const res = await giveCard({
      giveToUid: ctxState.selectedFriend.uid,
      goldLimit: refState.current.goldLimit
    })
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    toast(t('Common.Success'))
    setTimeout(() => {
      navigate('/wallet/love-card')
    }, 2000)
  }, [state?.goldLimit])

  const handleModify = useCallback(async () => {
    if (!cardId) return

    const res = await modifyCard(cardId, refState.current.goldLimit)
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    toast(t('Common.Success'))
  }, [state.goldLimit])

  const handleConfirm = useCallback(async () => {
    if (ctxState.selectedFriend) {
      handleCreate()
    }
    if (refState.current.card) {
      handleModify()
    }
  }, [])

  const getCardInfo = useCallback(async (cardId: string) => {
    const res = await getDetail(cardId)
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    setState({
      card: res.data,
      goldLimit: res.data.goldLimit
    })
  }, [])

  useEffect(() => {
    if (!cardId) return

    getCardInfo(cardId)
  }, [])

  return <div className='pg-lovecard-create'>
    <LoveCardHeader />
    {ctxState.selectedFriend && <>
      <Pane title={t('Wallet.LoveCard.CreateSelectTitle')}>
        {ctxState.selectedFriend && <LoveCardUser user={ctxState.selectedFriend} />}
      </Pane>
      <Pane title={t('Wallet.LoveCard.CreateCoinsTitle')}>
        <div className='pg-lovecard-create__coins'>
          <img className='pg-lovecard-create__coins-icon' src={ImageCoin} />
          <input type='tel' value={state.goldLimit} pattern="\d*" inputMode="numeric" placeholder={t('Wallet.LoveCard.CreateCoinsPlaceholder').toString()} onChange={handleCoinsChange} />
        </div>
      </Pane>
    </>}
    {state.card && <>
      <Pane title={t('Wallet.LoveCard.CreateSelectTitle')}>
        <LoveCardUser user={{
          uid: state.card.uid,
          nick: state.card.nick,
          avatar: state.card.avatar,
          erbanNo: state.card.erbanNo
        }} />
      </Pane>
      <Pane title={t('Wallet.LoveCard.CreateCoinsTitle')}>
        <div className='pg-lovecard-create__coins'>
          <img className='pg-lovecard-create__coins-icon' src={ImageCoin} />
          <input type='tel' value={state.goldLimit} pattern="\d*" inputMode="numeric" placeholder={t('Wallet.LoveCard.CreateCoinsPlaceholder').toString()} onChange={handleCoinsChange} />
        </div>
      </Pane>
    </>}
    <div className='pg-lovecard-create__bottom'>
      <div className='pg-lovecard-create__bottom-tips'>{t('Wallet.LoveCard.Attention')}<br />{t('Wallet.LoveCard.CreateTips')}</div>
      <div className='pg-lovecard-create__bottom-confirm' onClick={handleConfirm}>{t('Wallet.LoveCard.Confirm')}</div>
    </div>
  </div>
}