import clsx from 'clsx'
import moment from 'moment'
import ScrollList from '@/components/ScrollList';
import { useTrans } from '@/hooks/useTranslation';
import './MemberDetailList.scss'
import { useCallback } from 'react';
import { alert, toast } from '@/utils/bridge';
import { cashWithdrawRevocation } from '@/utils/request';

interface PropsType {
    hasMore: () => void
    listInfo: {
        list: Guild.GuildMemberBillRecordListItem[]
        hasMore: boolean
    },
    loading?: boolean
    fetchMemberBillRecordList: () => void
}
enum CashTradeStatusEnum {
    // 订单状态,1打款中2打款成功3打款失败4审核拒绝5用户自己取消提现
    PaymentTransferring = 1,
    PaymentSuccessful = 2,
    PaymentFailed = 3,
    AuditRejected = 4,
    UserCancelled = 5
}

const failStatus = [CashTradeStatusEnum.PaymentFailed, CashTradeStatusEnum.AuditRejected, CashTradeStatusEnum.UserCancelled]

function MemberDetailList({ listInfo, hasMore, loading, fetchMemberBillRecordList }: PropsType) {
    const { t } = useTrans()

    const handleCancel = useCallback(async (billId: number) => {
        const ret = await alert(t('Guild.AreYouSureCancelTransfer'))
        if (!ret) return
        const { code, message } = await cashWithdrawRevocation(billId)
        if (code !== 200) {
            toast(message)
            return
        }
        toast(t('Common.Success'))
        await fetchMemberBillRecordList()
    }, [fetchMemberBillRecordList])
    return (
        <div className='cmp-guild-wallet-data-member-detail-list'>
            <ScrollList
                loading={loading}
                className={clsx({ 'cmp-guild-wallet-data-member-detail-list__scroll': !listInfo.list.length })}
                hasMore={listInfo.hasMore}
                loadMore={hasMore}
                empty={!listInfo.list.length}
                emptyText={t('Guild.Tips.NoOverallDataYet')}>
                {listInfo.list.map((item, i) => <div key={i} className='cmp-guild-wallet-data-member-detail-list__list-item'>
                    <div className="left">
                        <div>
                            <span>{item.billDetailName}</span>
                            {!!item.cashTradeStatus && item.billTypeName === 'withdraw' &&
                                <div className={clsx({ 'ok activate-btn': item.cashTradeStatus === CashTradeStatusEnum.PaymentSuccessful, 'fail': failStatus.includes(item.cashTradeStatus), 'unactivated-btn': item.cashTradeStatus === CashTradeStatusEnum.PaymentTransferring })}>
                                    {item.cashTradeStatus === CashTradeStatusEnum.PaymentTransferring ? t('Guild.InProgress') : item.cashTradeStatus === CashTradeStatusEnum.PaymentSuccessful ? t('Guild.Completed') : t('Guild.Fail')}
                                </div>
                            }
                        </div>
                        <span>type:{item.billTypeName}</span>
                    </div>
                    {(item.cashTradeStatus === CashTradeStatusEnum.PaymentTransferring && item.tradeStatus === 0) ? <div className='right-btn'>
                        <div onClick={() => handleCancel(item.billId)} className='right-transferring-cancel'>
                            {t('Common.Cancel')}
                        </div>
                    </div> :
                        <div className="right">
                            <div>
                                <span className='num'>{item.billAmount.toString().includes('-') ? item.billAmount : `+${item.billAmount}`}</span>
                                <div className='img'></div>
                            </div>
                            <span>{moment(item.billTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                        </div>
                    }
                </div>)
                }
            </ScrollList >
        </div >
    )
}

export default MemberDetailList