import React, { memo, useEffect, useRef } from 'react'
import clsx from 'clsx';
import { useTrans } from '@/hooks/useTranslation';
import './ScrollTabs.scss';

type TabButtonsProps = {
    tabsTitle: string[]
    selectedTab: number
    handleTabsClick: (i: number) => void
}
function ScrollTabs({ handleTabsClick, selectedTab, tabsTitle }: TabButtonsProps) {
    const { t } = useTrans()
    const tabsRef = useRef<HTMLDivElement>(null);
    //更新钩子，[selectedTab]这种副作用url带参选中tabs不能跟着滚动
    useEffect(() => {
        const selectedTabDiv = tabsRef.current && tabsRef.current.querySelector('.selected') as HTMLElement;
        if (selectedTabDiv) {
            const containerWidth = tabsRef.current.offsetWidth;
            const tabWidth = selectedTabDiv.offsetWidth;
            const tabLeft = selectedTabDiv.offsetLeft;
            const scrollTo = tabLeft - (containerWidth - tabWidth) / 2;
            tabsRef.current.scrollLeft = scrollTo;
        }
    })
    return (
        <div className='cmp-scroll-tabs' ref={tabsRef}>
            {tabsTitle?.map((title, key) => (
                <div
                    className={clsx('tab-item', {
                        selected: selectedTab === key
                    })}
                    key={key}
                    onClick={() => handleTabsClick(key)}>
                    <span>{t(title)}</span>
                </div>
            ))}
        </div >
    )
}

export default memo(ScrollTabs)