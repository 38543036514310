import React, { useCallback } from 'react'
import './CoinsTransferWarning.scss';
import { useNavigate, useParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import StatusUserInfo from './components/StatusUserInfo';
function CoinsTransferWarning() {
    const navigate = useNavigate()
    const { appID, inputNum } = useParams()
    const goDetails = useCallback(() => {
        navigate("/agent-recharge/coins-transfer/coin-detail")
    }, [])
    return (
        <div className='agent-recharge-coins-transfer-warning pg-fa'>
            <Status title='AgentRecharge.CoinsTransfer' status='warning' paneTitle='Wallet.WarningTips' goDetails={goDetails}>
                <StatusUserInfo state={{ appID, inputNum }}></StatusUserInfo>
            </Status>
        </div >
    )
}

export default CoinsTransferWarning