import { useCallback } from 'react'
import NumberCard from './NumberCard'
import MemberCard from './MemberCard'
import './MemberHistoricalCard.scss'

type MemberHistoricalCardProps = {
  memberId: number
  avatar: string
  username: string
  erbanNo?: string
  showArrow?: boolean
  rightBtn?: {
    text: string
  }
  historicalItems: Guild.NumberCardItem[]
  onClick?: (memberId: number) => void
}

export default function MemberHistoricalCard(props: MemberHistoricalCardProps) {
  const handleClick = useCallback(() => {
    props.onClick && props.onClick(props.memberId)
  }, [props])

  return <div className='cmp-member-historical-card' >
    <MemberCard
      memberId={props.memberId}
      avatar={props.avatar}
      username={props.username}
      erbanNo={props.erbanNo}
      rightBtn={
        {
          text: props.rightBtn?.text,
          onRightBtn: handleClick
        }
      }
    />
    <NumberCard items={props.historicalItems} />
  </div>
}