import { memo } from 'react'
import ImgExchange from '@/images/exchange.webp';
import { useTrans } from '@/hooks/useTranslation';
import './IdentityCard.scss'

type PropsType = {
    avatar: string
    nick: string
    erbanNo: string
    identity: string
    hasSwitch?: boolean
    switchClick?: () => void
}
function IdentityCard(props: PropsType) {
    const { t } = useTrans()
    return (
        <div className='cmp-identity-card'>
            <div className='cmp-identity-card-user'>
                <img className='cmp-identity-card-user__avatar' src={props.avatar} alt=""></img>
                <div className='cmp-identity-card-user__info'>
                    <span className='cmp-identity-card-user__nick'>{props.nick}</span>
                    <span className='cmp-identity-card-user__id'>ID:{props.erbanNo}</span>
                    <div className='cmp-identity-card-user__identity'>{t('Common.Identity')}:{props.identity}</div>
                </div>
            </div>
            {props.hasSwitch &&
                <div className='cmp-identity-card-switch' onClick={props.switchClick}>
                    <img src={ImgExchange} alt="" />
                    <span>{t('Common.SwitchIdentity')}</span>
                </div>
            }
        </div >
    )
}

export default memo(IdentityCard)