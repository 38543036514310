import { InfiniteScroll } from 'antd-mobile'
import { useEffect, useState } from 'react'
import ImageListEmpty from '../images/list-empty.png'
import './ScrollList.scss'
import clsx from 'clsx'
import { SpinLoading } from 'antd-mobile'
import { useTrans } from '@/hooks/useTranslation'

type ScrollListProps = {
  children?: any
  loadMore: any
  empty?: boolean
  emptyText?: string | null
  hasMore: boolean
  className?: string
  loading?: boolean//小loading使用事项：先清空list=[]
}

export default function ScrollList(props: ScrollListProps) {
  const { t } = useTrans()

  const [info, setInfo] = useState({
    hasMore: props.hasMore,
    empty: props.empty
  })

  useEffect(() => {
    setInfo({ empty: props.empty, hasMore: props.hasMore })
  }, [props.empty, props.hasMore]);

  return <div className={clsx('cmp-scroll-list', props.className)}>
    {props.children}
    {!info.empty && <InfiniteScroll loadMore={props.loadMore} hasMore={info.hasMore} />}
    {props.loading && props.empty && <div className="cmp-scroll-list__antd-loading">
      <SpinLoading></SpinLoading>
    </div>}
    {!props.loading && info.empty && <div className='cmp-scroll-list__empty'>
      <img className='cmp-scroll-list__empty-img' alt='empty' src={ImageListEmpty} />
      <span className='cmp-scroll-list__empty-text'>{props.emptyText || t('Common.NoData')}</span>
    </div>}
  </div>
}