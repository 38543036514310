import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Header from '@/components/Header'
import Input from '../components/Input';
import { Popup } from 'antd-mobile';
import { px2rem } from '@/utils/tool';
import CountryList from './components/CollectionInformation/Country'
import Channel from './components/CollectionInformation/Channel'
// import Currency from './components/CollectionInformation/Currency'
import TargetOrg from './components/CollectionInformation/TargetOrg'
import clsx from 'clsx';
import { getWithdrawalAccount, withdrawalAccountSave, getCountryList, getBankTransferTargetOrg } from '@/services/wallet';
import { toast } from '@/utils/bridge';
import { cloneDeep } from 'lodash-es';
import Loading from '@/components/Loading';
import { goBack } from '@/utils/bridge';
import { useTrans } from '@/hooks/useTranslation';
import './CollectionInformation.scss';

function CollectionInformation() {
    const { t } = useTrans()
    // const [openCurrency, setOpenCurrency] = useState(false)
    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const refCountry = useRef<React.ElementRef<typeof CountryList>>(null)
    const [countryControl, setCountryControl] = useState<{
        openCountry: boolean
        countryList: Wallet.Country[]
    } & Wallet.Country>({
        openCountry: false,
        countryList: [],
        countryCode: '',
        countryName: '',
        countryNationalFlag: '',
        currencyList: [],
    })
    const refCountryControl = useRef(countryControl)
    refCountryControl.current = countryControl

    const refChannel = useRef<React.ElementRef<typeof Channel>>(null)
    const [channelControl, setChannelControl] = useState<{
        openChannel: boolean
    } & Wallet.Channel>({
        openChannel: false,
        channelId: '',
        channelName: '',
        channelPict: '',
        channelExtendPictArr: '',
        channelType: null
    })
    const refChannelControl = useRef(channelControl)
    refChannelControl.current = channelControl

    const refTargetOrg = useRef<React.ElementRef<typeof TargetOrg>>(null)
    const [targetOrgControl, setTargetOrgControl] = useState<{
        openTargetOrg: boolean
        targetOrgList: Wallet.BankTransferTargetOrg[]
    } & Wallet.BankTransferTargetOrg>({
        openTargetOrg: false,
        targetOrgList: [],
        targetOrgId: 0,
        targetOrgName: '',
    })
    const refTargetOrgControl = useRef(targetOrgControl)
    refTargetOrgControl.current = targetOrgControl

    const [state, setState] = useState<Wallet.WithdrawalAccountSaveParams>({
        realName: '',
        countryCode: '',
        channelId: '',
        accountNo: '',
        fullName: '',
        currency: '',
        whatsApp: '',//选填
        swiftCode: undefined,//channeltype=1时必传
        targetOrgId: undefined,//channelType=3时加个目标机构
        address: undefined,//channelType=3时加个目标机构
    })
    const refState = useRef(state)
    refState.current = state

    //是否审核中
    const refExistApplyAccount = useRef<0 | 1>(0)

    /* 控制Save按钮是否可用 */
    const isCanSave = useMemo(() => {
        const obj = cloneDeep(state)
        // obj.channelId = refChannelControl.current.channelId
        obj.countryCode = refCountryControl.current.countryCode
        delete obj.currency;//币种隐藏
        delete obj.whatsApp;//非必填
        delete obj.address;//channelType=3非必填
        delete obj.channelId;//回显时没有channelId，传null或者不传后端不作更改
        delete obj.targetOrgId;//channelType=3必填
        if (refChannelControl.current.channelType !== 1) {
            delete obj.swiftCode;
        }
        if (refChannelControl.current.channelType === 3 && !refTargetOrgControl.current.targetOrgName && refTargetOrgControl.current.targetOrgList.length > 0) {
            obj.targetOrgId = refTargetOrgControl.current.targetOrgId
        }
        let flag = true
        Object.values(obj).forEach(val => {
            if (!val) {
                flag = false
            }
        })
        return flag
    }, [state])

    /* 回显信息 */
    const fetchHomeData = useCallback(async () => {
        setLoading(true)
        const [countryListData, withdrawalAccountData]: [Response<Wallet.Country[]>, Response<Wallet.WithdrawalAccount>] = await Promise.all([
            getCountryList(),
            getWithdrawalAccount()
        ])
        setLoading(false)
        if (!countryListData || !withdrawalAccountData) return

        if (countryListData.code !== 200) {
            toast(countryListData.message)
            return
        }

        if (withdrawalAccountData.code !== 200) {
            toast(withdrawalAccountData.message)
            return
        }
        setCountryControl({
            ...refCountryControl.current,
            ...countryListData.data.filter(item => item.countryCode === withdrawalAccountData.data?.countryCode)[0],
            countryList: countryListData.data
        })
        setChannelControl({
            ...refChannelControl.current,
            channelId: undefined,
            channelName: withdrawalAccountData.data?.channelName,
            channelPict: withdrawalAccountData.data?.channelPict,
            channelExtendPictArr: withdrawalAccountData.data?.channelExtendPictArr,
            channelType: withdrawalAccountData.data?.channelType
        })
        setTargetOrgControl({
            ...refTargetOrgControl.current,
            targetOrgName: withdrawalAccountData.data?.targetOrgName || ''
        })
        setState({
            ...refState.current,
            realName: withdrawalAccountData.data?.realName,
            countryCode: withdrawalAccountData.data?.countryCode,
            channelId: undefined,
            accountNo: withdrawalAccountData.data?.accountNo,
            fullName: withdrawalAccountData.data?.fullName,
            whatsApp: withdrawalAccountData.data?.whatsApp,
            currency: withdrawalAccountData.data?.currency,
            swiftCode: withdrawalAccountData.data?.swiftCode || undefined,
            address: withdrawalAccountData.data?.address || undefined,
        })
        refExistApplyAccount.current = withdrawalAccountData.data?.existApplyAccount
    }, [])

    /* 获取银行卡目标机构 */
    const fetchBankTransferTargetOrg = useCallback(async (countryCode: string) => {
        setLoading(true)
        const res = await getBankTransferTargetOrg(countryCode)
        setLoading(false)
        const { code, message, data } = res as Response<Wallet.BankTransferTargetOrg[]>
        if (code !== 200) {
            toast(message)
            return
        }
        setTargetOrgControl({
            ...refTargetOrgControl.current,
            targetOrgList: data
        })
    }, [])

    const clearState = useCallback(() => {
        setState({
            ...refState.current,
            accountNo: '',
            fullName: '',
            currency: '',
            swiftCode: undefined,
            targetOrgId: undefined,
            address: undefined
        })
    }, [])
    const handleOpenCountry = useCallback(() => {
        if (refExistApplyAccount.current === 1) {
            toast(t('Wallet.UnderReviewContactCustomer'))
            return
        }
        let newData: object = { openCountry: !refCountryControl.current.openCountry }

        // 选中的国家与原定位国家不同
        if (
            refCountry.current &&
            refCountry.current?.countryCode !== refCountryControl.current.countryCode
        ) {
            newData = {
                ...newData,
                countryName: refCountry?.current?.countryName,
                countryCode: refCountry?.current?.countryCode,
                countryNationalFlag: refCountry?.current?.countryNationalFlag,
                currencyList: refCountry?.current?.currencyList
            }
            // 清空数据
            setChannelControl({
                openChannel: false,
                channelId: '',
                channelName: '',
                channelPict: '',
                channelExtendPictArr: '',
                channelType: null
            })
            setTargetOrgControl({
                ...refTargetOrgControl.current,
                targetOrgId: 0,
                targetOrgName: '',
            })
            clearState()
        }
        setCountryControl({ ...refCountryControl.current, ...newData })
    }, [])
    const handleOpenChannel = useCallback(() => {
        if (refExistApplyAccount.current === 1) {
            toast(t('Wallet.UnderReviewContactCustomer'))
            return
        }
        let newData: object = { openChannel: !refChannelControl.current.openChannel }

        if (
            refChannel.current?.channelId &&
            refChannel.current?.channelId !== refChannelControl.current.channelId
        ) {
            newData = {
                ...newData,
                channelId: refChannel.current.channelId,
                channelName: refChannel.current.channelName,
                channelPict: refChannel.current.channelPict,
                channelExtendPictArr: refChannel.current.channelExtendPictArr,
                channelType: refChannel.current.channelType
            }
            clearState()
        }
        setChannelControl({
            ...refChannelControl.current,
            ...newData
        })
    }, [])
    const handleOpenTargetOrg = useCallback(() => {
        if (refExistApplyAccount.current === 1) {
            toast(t('Wallet.UnderReviewContactCustomer'))
            return
        }
        let newData: object = { openTargetOrg: !refTargetOrgControl.current.openTargetOrg }

        if (
            refTargetOrg.current?.targetOrgId &&
            refTargetOrg.current?.targetOrgId !== refTargetOrgControl.current.targetOrgId
        ) {
            newData = {
                ...newData,
                targetOrgId: refTargetOrg.current.targetOrgId || 0,
                targetOrgName: refTargetOrg.current.targetOrgName || '',
            }
            setState({
                ...refState.current,
                targetOrgId: refTargetOrg.current.targetOrgId || undefined
            })
        }
        setTargetOrgControl({
            ...refTargetOrgControl.current,
            ...newData
        })
    }, [])
    /* 信息保存 */
    const handleSave = useCallback(async () => {
        if (refExistApplyAccount.current === 1) {
            toast(t('Wallet.UnderReviewContactCustomer'))
            return
        }
        if (refLoading.current) return

        refState.current.channelId = refChannelControl.current.channelId
        refState.current.countryCode = refCountryControl.current.countryCode
        refState.current.targetOrgId = refTargetOrgControl.current.targetOrgId || undefined
        refState.current.currency = undefined //币种不要
        refState.current.accountNo = refState.current.accountNo.replace(/\s/g, '')//清除空格
        setLoading(true)
        const { code, message } = await withdrawalAccountSave(refState.current) as Response
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        goBack()
    }, [])

    const handleInputClick = useCallback(() => {
        if (refExistApplyAccount.current === 1) {
            toast(t('Wallet.UnderReviewContactCustomer'))
        }
    }, [])

    // const handleSelectCurrency = useCallback((str: string) => {
    //     setState({
    //         ...refState.current,
    //         currency: str
    //     })
    //     setOpenCurrency(false)
    // }, [])

    useEffect(() => {
        fetchBankTransferTargetOrg(countryControl.countryCode)
    }, [countryControl.countryCode])
    useEffect(() => {
        fetchHomeData()
    }, [fetchHomeData])
    return <>
        <div className='pg-wallet-collection-information'>
            <Header title={t('Wallet.Information').toString()} />
            {loading && <Loading />}
            <div className="pg-wallet-collection-information__content" >
                <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Wallet.RealName' name='realName' value={state.realName} setValue={setState} />
                <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Guild.WhatsApp' name='whatsApp' value={state.whatsApp} setValue={setState} />
                <Input readOnly={!!refExistApplyAccount.current} title='Common.Country' infoImg={countryControl.countryNationalFlag} infoSpan={countryControl.countryName} isArrow={true} onClick={handleOpenCountry} />
                {countryControl.countryCode &&
                    <Input title='Wallet.Channel' name='channelId' infoImg={channelControl.channelPict} infoExtendImg={channelControl.channelExtendPictArr?.split(';')} infoSpan={channelControl.channelName} isArrow={true} onClick={handleOpenChannel} />
                }
                {
                    channelControl.channelName &&
                    <>
                        <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Wallet.Account' name='accountNo' value={state.accountNo} setValue={setState} />
                        <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Wallet.FullName' name='fullName' value={state.fullName} setValue={setState} />
                        {/* <Input className='pg-wallet-collection-information__content-currency' title='Wallet.Currency' infoSpan={state.currency} isArrow={true} onClick={() => setOpenCurrency(true)} /> */}
                    </>
                }
                {/* 不同支付支付方式需要额外填写的 */}
                {
                    channelControl.channelType === 3 && !!targetOrgControl.targetOrgList.length &&
                    <Input className='pg-wallet-collection-information__content-target-org' title='Wallet.BankName' infoSpan={targetOrgControl.targetOrgName} isArrow={true} onClick={handleOpenTargetOrg} />
                }
                {
                    channelControl.channelType === 3 &&
                    <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Wallet.Address' name='address' value={state.address} setValue={setState} />
                }
                {
                    (channelControl.channelType === 1 || channelControl.channelType === 3) &&
                    <Input readOnly={!!refExistApplyAccount.current} onClick={handleInputClick} title='Wallet.BankCodeSWIFTCode' name='swiftCode' value={state.swiftCode} setValue={setState} />
                }
            </div>
            <div className='place'></div>
            <button className={clsx('pg-wallet-collection-information__save', {
                'activate-btn': isCanSave,
                'unactivated-btn': !isCanSave
            })} disabled={!isCanSave} onClick={handleSave}>{t('Common.Save')}</button>
        </div>
        {/* Country */}
        <Popup
            visible={countryControl.openCountry}
            className="pg-wallet-collection-information__country"
            onMaskClick={handleOpenCountry}
            bodyStyle={{
                backgroundColor: ' #1A1426',
                height: px2rem(509),
                borderRadius: `${px2rem(12)} ${px2rem(12)} 0 0`,
                padding: `0 ${px2rem(15)}`,
                boxSizing: 'border-box'
            }}
        >
            <CountryList
                ref={refCountry}
                {...{
                    handleOpenCountry,
                    countryCode: countryControl.countryCode,
                    countryList: countryControl.countryList
                }}
            />
        </Popup>
        {/* Select Channel */}
        <Popup
            visible={channelControl.openChannel}
            className="pg-wallet-collection-information__channel"
            onMaskClick={handleOpenChannel}
            bodyStyle={{
                backgroundColor: ' #1A1426',
                height: px2rem(533),
                borderRadius: `${px2rem(12)} ${px2rem(12)} 0 0`,
                padding: `0 ${px2rem(15)}`,
                boxSizing: 'border-box'
            }}
        >
            <Channel
                ref={refChannel}
                channelId={channelControl.channelId || ''}
                onCancel={handleOpenChannel}
                countryCode={countryControl.countryCode}
            />
        </Popup>
        {/* Currency */}
        {/* <Popup
            visible={openCurrency}
            className="pg-wallet-collection-information__channel"
            onMaskClick={() => setOpenCurrency(false)}
            bodyStyle={{
                backgroundColor: ' #1A1426',
                height: px2rem(533),
                borderRadius: `${px2rem(12)} ${px2rem(12)} 0 0`,
                padding: `0 ${px2rem(15)}`,
                boxSizing: 'border-box'
            }}
        >
            <Currency
                currency={state.currency}
                currencyList={countryControl.currencyList}
                handleSelect={handleSelectCurrency}
            />
        </Popup> */}
        {/* TargetOrg */}
        <Popup
            visible={targetOrgControl.openTargetOrg}
            className="pg-wallet-collection-information__target-org"
            onMaskClick={handleOpenTargetOrg}
            bodyStyle={{
                backgroundColor: ' #1A1426',
                height: px2rem(533),
                borderRadius: `${px2rem(12)} ${px2rem(12)} 0 0`,
                padding: `0 ${px2rem(15)}`,
                boxSizing: 'border-box'
            }}
        >
            <TargetOrg
                ref={refTargetOrg}
                targetOrgName={targetOrgControl.targetOrgName}
                targetOrgList={targetOrgControl.targetOrgList}
                onCancel={handleOpenTargetOrg}
            />
        </Popup>
    </>
}

export default CollectionInformation