import { memo, useCallback, useMemo, useState } from 'react'
import ScrollTabs from '@/components/ScrollTabs';
import ImgGem from '@/images/gem.png';
import GiftProgress from '../Components/GiftProgress';
import { useTrans } from '@/hooks/useTranslation';
import './TaskProgress.scss'

interface PropsType {
    missionList: Guild.MissionUserHomeMissionListItem[]
}
enum GoodsTypeEnum {
    Gem = 1//钻石
}
function TaskProgress({ missionList }: PropsType) {
    const { t } = useTrans()
    const [selectedTab, setSelectedTab] = useState<number>(0)

    const titleItems = useMemo(() => {
        setSelectedTab(0)//初始化tab选中项
        return missionList?.map(item => item?.missionTypeName)
    }, [missionList])
    const taskItems = useMemo(() => {
        return missionList[selectedTab]?.userMissionList || []
    }, [missionList, selectedTab])

    const handleTabsClick = useCallback((tabIndex: number) => {
        setSelectedTab(tabIndex)
    }, [])

    const formatTaskProgress = useCallback((item: Guild.MissionUserHomeTaskMapListItem) => {
        const conditionNumArray = item.conditionNum.toString().split(',')
        const numberArray = item.number.toString().split(',')
        const resultArray = []
        for (let i = 0; i < conditionNumArray.length; i++) {
            resultArray.push({
                target: Number(conditionNumArray[i]),
                reward: Number(numberArray[i])
            })
        }
        return resultArray
    }, [])
    return <>
        <div className='cmp-guild-task-progress'>
            <ScrollTabs tabsTitle={titleItems} selectedTab={selectedTab} handleTabsClick={handleTabsClick}></ScrollTabs>
            {taskItems.map((item, i) => <div className="progress-item" key={i}>
                <div className="top">
                    <div className="left">
                        <span className='name'>{item.missionName}</span>
                    </div>
                    <div className="right">
                        <span className='name'>{t('Guild.AgencyReward.Reward')}:{(item.number || '').includes(',') ? item.number.split(',').reduce((a, b) => a + (Number(b) || 0), 0) : item.number}</span>
                        {/* <div className='img'></div> */}
                        <img className='img' src={item.goodsType === GoodsTypeEnum.Gem ? ImgGem : ImgGem}></img>
                    </div>
                </div>
                <div className="middle">
                    {/* <ProgressBar percent={((item.userCompleteNum / item.conditionNum) * 100) || 0} /> */}
                    <GiftProgress userCompleteNum={item.userCompleteNum} taskProgressData={formatTaskProgress(item)} />
                </div>
                <div className="bottom">
                    {item.missionDescription}
                </div>
            </div>)}
        </div>
    </>
}

export default memo(TaskProgress)