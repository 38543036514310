import clsx from "clsx"
import { useEffect, useRef, useState } from "react"
import './SafeArea.scss'

type SafeAreaProps = {
  position: 'top'|'bottom'
}

export default function SafeArea(props:SafeAreaProps) {
  const [ state, setState ] = useState<{
    top?:string
    bottom?:string
  }>({})
  const refState = useRef(state)
  refState.current = state

  useEffect(() => {
    if (props.position === 'top' && window.query.safeTop) {
      setState({
        top: window.query.safeTop + 'px'
      })
    } 
    if (props.position === 'bottom' && window.query.safeBottom) {
      setState({
        bottom: window.query.safeBottom + 'px'
      })
    }
  }, [ props ])

  return <div className={clsx("cmp-safe-area", {
    top: props.position === 'top',
    bottom: props.position === 'bottom'
  })} style={{ paddingTop: state.top, paddingBottom: state.bottom }}/>
}