import React, { useCallback, useState, ChangeEvent, useRef, useEffect, useMemo } from 'react'
import WalletHeader from '@/pages/Wallet/components/Header';
import Pane from '@/components/Pane';
import WithdrawBanner from '../components/Banner';
import CoinExchangeSelect from '@/pages/Wallet/components/CoinExchangeSelect';
import ImgCoin from '@/images/coin.png';
import { getCashExchangeRate } from '@/services/wallet';
import { getUserInfo, setBusinessStatus, toast } from '@/utils/bridge';
import clsx from 'clsx';
import { Popup } from 'antd-mobile'
import { px2rem } from '@/utils/tool';
import { getAnchormanWalletData, getAnchormanWalletExchangeAgencyProList, cashExchange } from '@/services/wallet'
import { isEqual, isEmpty } from 'lodash-es';
import SearchUser from '@/components/SearchUser';
import CurrencyExchange from '@/pages/Wallet/components/CurrencyExchange';
import { getMyUserInfo } from '@/utils/request'
import { BusinessConst } from '@/utils/constant';
import { useNavigate } from 'react-router';
import { abnormalReportCashExchange } from '@/utils/report';
import { formatMoney } from '@/utils/format';
import { useTrans } from '@/hooks/useTranslation';
import './CashToCoins.scss'

function CashToCoins() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState<{
        inputAmount: string
        inputAmountGold: number
        cashAmount: number
        cashExchangeRate: Wallet.CashExchangeRate
        proList: Wallet.ExchangeProList[]
        selectedPro?: Wallet.ExchangeProList
        user: AgentRecharge.UserInfo | undefined
    }>({
        inputAmount: '',
        inputAmountGold: 0,
        cashAmount: 0,
        cashExchangeRate: {} as Wallet.CashExchangeRate,
        proList: [],
        selectedPro: {
            prodId: '',
            goldNum: 0,
            cashAmount: 0
        },
        user: undefined
    })
    const refState = useRef(state)
    refState.current = state

    const [user, setUser] = useState<UserBasicInfo>()
    const refUser = useRef(user)
    refUser.current = user

    /*获取主播钱包信息、cash兑换列表、cash兑换比例、当前用户信息 */
    const fetchHomeData = useCallback(async () => {
        const [walletData, proListData, cashExchangeRateData, userData]: [Response<Wallet.AnchormanWalletData>, Response<Wallet.ExchangeProList[]>, Response<Wallet.CashExchangeRate>, Response<AgentRecharge.UserInfo[]>] = await Promise.all([
            getAnchormanWalletData(),
            getAnchormanWalletExchangeAgencyProList(),
            getCashExchangeRate(),
            getMyUserInfo()
        ])
        if (!walletData || !proListData || !cashExchangeRateData || !userData) return

        if (walletData.code !== 200) {
            toast(walletData.message)
            return
        }

        if (proListData.code !== 200) {
            toast(proListData.message)
            return
        }

        if (cashExchangeRateData.code !== 200) {
            toast(cashExchangeRateData.message)
            return
        }

        if (userData.code !== 200) {
            toast(userData.message)
            return
        }

        setState({
            ...refState.current,
            ...walletData.data,
            proList: proListData.data,
            cashExchangeRate: cashExchangeRateData.data,
            user: userData.data[0]
        })
    }, [])
    /* 请求成功跳转事件 */
    const goSuccessPage = useCallback(() => {
        setBusinessStatus(BusinessConst.CoinChange)
        navigate(`/wallet/anchorman-wallet-detail/success/${refState.current.user?.erbanNo}/${refState.current.selectedPro?.goldNum}`);
    }, [])
    /* 请求失败跳转事件 */
    const goWarningPage = useCallback((res: any) => {
        abnormalReportCashExchange(res, { userId: refState.current.user?.uid, inputNum: refState.current.selectedPro?.goldNum.toString() });
        navigate(`/wallet/anchorman-wallet-detail/warning/${refState.current.user?.erbanNo}/${refState.current.selectedPro?.goldNum}`);
    }, [])
    /* cash兑换金币 */
    const fetchCashExchangeCoins = useCallback(async () => {
        const prodId = refState.current.selectedPro?.prodId === '0' ? undefined : refState.current.selectedPro?.prodId
        const customCashAmount = isEmpty(refState.current.inputAmount) ? undefined : Number(refState.current.inputAmount.replace(/,/g, ''))
        setLoading(true)
        try {
            const res = await cashExchange({ tagUid: Number(refUser.current?.uid) || 0, prodId, goldType: 1, customCashAmount }) as Response
            setLoading(false)
            // 请求超时或断网
            if (res.code.toString() === 'ECONNABORTED' || res.code.toString() === 'ERR_NETWORK' || res.message.includes('timeout')) {
                goWarningPage(res)
                return;
            }
            if (res.code !== 200) {
                toast(res.message)
                return
            }
            goSuccessPage()
        }
        catch (error) {
            goWarningPage(error)
        }
    }, [])
    const handleSelectCoin = useCallback((i: number) => {
        if (isEqual(refState.current.selectedPro, refState.current.proList[i])) return
        setState({
            ...refState.current,
            inputAmount: '',
            inputAmountGold: 0,
            selectedPro: refState.current.proList[i],
        })
    }, [])
    const calculateGold = useCallback((numVal: string) => {
        let calNum = Number(numVal) * refState.current.cashExchangeRate.userGoldExchangeRate
        const goldTotal = refState.current.cashExchangeRate.userGoldExchangeRate * refState.current.cashAmount
        if (Number(numVal) >= goldTotal) {
            calNum = goldTotal
        }
        return calNum
    }, [])
    const hanndleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/,/g, '')
        if (isNaN(Number(value))) return
        if (Number(value) > refState.current.cashAmount) {
            toast(t('Wallet.ExchangeCannotBalance'))
            value = Math.floor(refState.current.cashAmount).toString()
        }
        const calNum = calculateGold(value)
        console.log(`calNum -->`, calNum);
        setState({
            ...refState.current,
            inputAmount: formatMoney(value),
            inputAmountGold: calNum,
            selectedPro: {
                prodId: '0',
                goldNum: calNum,
                cashAmount: Number(value)
            }
        })
    }, [])
    const canExchange = useMemo(() => {
        let bol = false
        if (isEmpty(state.selectedPro) && !isEmpty(state.inputAmount)) {
            bol = true
        }
        if (state.selectedPro?.cashAmount !== 0 && !isEmpty(state.selectedPro)) {
            bol = true
        }
        return bol
    }, [state])
    const handleExchange = useCallback(() => {
        if (!refState.current.user) return
        fetchCashExchangeCoins()
    }, [])
    useEffect(() => {
        fetchHomeData()
        getUserInfo().then((auth) => {
            if (auth) {
                setUser(auth)
            }
        })
    }, [fetchHomeData])
    return <>
        <div className='pg-wallet-cash-to-coins pg-fa'>
            <WalletHeader title={'Wallet.ExchangeForResellerCoins'}></WalletHeader>
            <Pane title={t('Wallet.CashBalance')}>
                <WithdrawBanner amount={state.cashAmount}></WithdrawBanner>
            </Pane>
            <Pane title={t('Wallet.NumberOfExchanges')} className='pane-prefix'>
                <CoinExchangeSelect customText='Wallet.Customize' prodList={state.proList} handleSelectCoin={handleSelectCoin}></CoinExchangeSelect>
                {(state.selectedPro?.prodId === '0' || isEmpty(state.selectedPro)) && <div className='pg-wallet-cash-to-coins__calculate'>
                    <div className="pg-wallet-cash-to-coins__max-box">
                        <input type='tel' inputMode="numeric" pattern="\d*" onChange={hanndleAmountChange} placeholder={t('Wallet.InputTheAmountOfCash').toString()} value={state.inputAmount} />
                    </div>
                    <div className='pg-wallet-cash-to-coins__gold'>
                        <span>=</span>
                        <div className='pg-wallet-cash-to-coins__coin'>
                            <img src={ImgCoin}></img>
                            <span>{state.inputAmountGold}</span>
                        </div>
                    </div>
                </div>}
                <div className='pg-wallet-cash-to-coins__tips'>
                    <span>{t(`Wallet.CashToCoinsTips0`)}</span>
                    {!!state.cashExchangeRate.goldExchangeRate && state.cashExchangeRate.goldExchangeRate.map((item, i) => <div key={i} className='pg-wallet-cash-to-coins__tips-item'>
                        <span>Lv{i + 1}：{t(`Wallet.CashToCoinsTips1`)}</span>
                        <div className='pg-wallet-cash-to-coins__tips-gold'>
                            <img src={ImgCoin}></img>
                            <span>{item}</span>
                        </div>
                    </div>)}
                </div>
            </Pane>
            <div className='place'></div>
            <button className={clsx('unactivated-btn pg-wallet-cash-to-coins__btn', { 'activate-btn': canExchange })} disabled={!canExchange} onClick={() => setVisible(true)}>{t('Common.Exchange')}</button>
        </div>
        <Popup
            visible={visible}
            className="pg-wallet-cash-to-coins pg-wallet-cash-to-coins-popup"
            bodyStyle={{
                backgroundColor: '#fff',
                borderTopLeftRadius: px2rem(12),
                borderTopRightRadius: px2rem(12)
            }}>
            <div className="pg-wallet-cash-to-coins-popup-header">
                <div className="pg-wallet-cash-to-coins-popup-title"><span>{t('Wallet.ForResellerCoins')}</span></div>
                <div className="pg-wallet-cash-to-coins-popup-cancel" onClick={() => setVisible(false)}>
                    {t('Common.Cancel')}
                </div>
                {/* {arrow && <img alt="arrow" src={ImageArrowGray} className='pg-wallet-cash-to-coins-popup-arrow' onClick={handleBack} />} */}
            </div>
            <div className="pg-wallet-cash-to-coins-popup-content">
                <div className="swiper__content">
                    <Pane title={t('Wallet.CheckUserID')} className='popup-pane'>
                        <SearchUser state={state} disable={true} ></SearchUser>
                    </Pane>
                    <Pane title={t('Wallet.CheckExchange', { money: state.cashAmount })} className='popup-pane'>
                        <CurrencyExchange before={state.selectedPro?.cashAmount || 0} after={state.selectedPro?.goldNum || 0}></CurrencyExchange>
                        <div className="check-tips">
                            {new Array(3).fill(0).map((_, i) => (
                                <span key={i}>
                                    {t(`Wallet.AnchormanWalletDetailRule${i + 1}`)}
                                </span>
                            ))}
                        </div>
                    </Pane>
                </div>
                <button className={clsx('unactivated-btn pg-wallet-cash-to-coins__btn', { 'activate-btn': state.user && !loading })} disabled={!state.user || loading} onClick={handleExchange}>{loading ? t('Wallet.Exchangeing') : t('Common.Exchange')}</button>
            </div>
        </Popup>
    </>
}

export default CashToCoins