import { useCallback, ChangeEvent, useRef } from 'react';
import { setBusinessStatus, toast } from '@/utils/bridge';
import { recharge } from '@/services/agentRecharge'
import { BusinessConst } from '@/utils/constant';
import { useNavigate } from 'react-router'
import { getConf } from '@/services/agentRecharge'
import { abnormalReportAgent } from '@/utils/report';
import { formatMoney } from '@/utils/format';

interface StateType {
    user?: AgentRecharge.UserInfo
    inputNum?: string
    coin?: number
    agencyMaxCount?: number
    agencyMsg?: string
}
export const useCoinsTransfer = (state: StateType, setState: (state: any) => void, transferVisible: boolean, setTransferVisibleVisible: (transferVisible: boolean) => void, setLoading: (loading: boolean) => void) => {
    const refState = useRef(state)
    refState.current = state
    const refTransferVisible = useRef(transferVisible)
    refTransferVisible.current = transferVisible
    const navigate = useNavigate()
    /* 请求 */
    const handleRecharge = useCallback(async () => {
        if (!refState.current.user || !refState.current.inputNum || Number(refState.current.inputNum.replace(/,/g, '')) === 0) return;
        if (!refTransferVisible.current) {
            setTransferVisibleVisible(true);
            return;
        }

        try {
            setLoading(true);
            const res = await recharge({
                targetUid: refState.current.user.uid,
                goldNum: Number(refState.current.inputNum.replace(/,/g, '')) || 0
            });
            setLoading(false);
            const { code, message, data } = res as Response<{ purseGoldNum: number }>;
            // 请求超时
            if (code.toString() === 'ECONNABORTED' || res.code.toString() === 'ERR_NETWORK' || message.includes('timeout')) {
                abnormalReportAgent(res, { userId: refState.current.user.uid, inputNum: refState.current.inputNum });
                navigate(`/agent-recharge/coins-transfer/warning/${refState.current.user.erbanNo}/${refState.current.inputNum}`);
                return;
            }
            if (code !== 200) {
                toast(message);
                return;
            }

            setState({
                ...refState.current,
                coin: data.purseGoldNum
            });
            setBusinessStatus(BusinessConst.CoinChange);
            navigate(`/agent-recharge/coins-transfer/success/${refState.current.user.erbanNo}/${refState.current.inputNum}`);
        } catch (error) {
            // 其他情况
            abnormalReportAgent(error, { userId: refState.current.user.uid, inputNum: refState.current.inputNum });
            navigate(`/agent-recharge/coins-transfer/warning/${refState.current.user.erbanNo}/${refState.current.inputNum}`);
        }
    }, [navigate, setBusinessStatus, abnormalReportAgent, setLoading]);

    /* 请求：拿配置参数 */
    const fetchConf = useCallback(async () => {
        const res = await getConf()
        const { code, message, data } = res as Response<AgentRecharge.Conf>
        if (code !== 200) {
            toast(message)
            return
        }

        setState((prevState: StateType) => ({
            ...prevState,
            agencyMaxCount: data.agencyMaxCount,
            agencyMsg: data.agencyMsg
        }));
    }, [])

    /* input事件 */
    const hanndleAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (!refState.current.agencyMaxCount) return
        let value = e.target.value.replace(/,/g, '')
        if (Number(value) > refState.current.agencyMaxCount) {
            toast(refState.current.agencyMsg as string)
            value = refState.current.agencyMaxCount.toString()
        }
        setState({
            ...refState.current,
            inputNum: formatMoney(value)
        })
    }, [])
    return { handleRecharge, hanndleAmountChange, fetchConf };
};
