import { useTrans } from "@/hooks/useTranslation"
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Pane from '@/components/Pane';
import { getUserPurse, getUserDetail, withdrawalList, messageSendGreet } from '@/services/agentRecharge'
import { toast, alert, openPage } from '@/utils/bridge';
import ImageCoin from '@/images/wallet/coin-pic.png'
import { Popup } from 'antd-mobile'
import TransferContent from './components/TransferContent';
import { useNavigate } from 'react-router'
import { px2rem } from '@/utils/tool';
import { isEmpty } from 'lodash-es';
import WalletHeader from '@/pages/Wallet/components/Header';
import { useCoinsTransfer } from './hooks/useCoinsTransfer';
import { onlyNumber } from '@/utils/format';
import './CoinsTransfer.scss';
import ScrollTabs from '@/components/ScrollTabs';
import ImageArrow from '@/images/arrow.png'
import ImageMoney from '@/images/money.webp'
import clsx from 'clsx';
import Country from './components/Country';
import { PageConst } from '@/utils/constant';
import { debounce } from 'lodash-es';

export default function CoinsTransfer() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [transferVisible, setTransferVisibleVisible] = useState(false)
  const [disabledRecycle, setDisabledRecycle] = useState<number[]>([])
  const [state, setState] = useState<{
    appID?: string
    user?: AgentRecharge.UserInfo
    inputNum?: string
    coin?: number
    agencyMaxCount?: number
    agencyMsg?: string
    userPurse: AgentRecharge.UserPurse
    withdrawalList: AgentRecharge.WithdrawalList[]
  }>({
    appID: '', inputNum: '', coin: 0, agencyMaxCount: 0, agencyMsg: '', userPurse: {} as AgentRecharge.UserPurse,
    withdrawalList: []
  })
  const refState = useRef(state)
  refState.current = state

  const [countryControl, setCountryControl] = useState<{
    openCountry: boolean
    countryCode: string
  }>({
    openCountry: false,
    countryCode: '',
  })
  const refCountryControl = useRef(countryControl)
  refCountryControl.current = countryControl

  const titleItems = useMemo(() => {
    return ['AgentRecharge.Transfer', 'Wallet.Order']
  }, [])

  // hooks
  const { handleRecharge, hanndleAmountChange, fetchConf } = useCoinsTransfer(state, setState, transferVisible, setTransferVisibleVisible, setLoading);
  const handleFetchUser = useCallback(async () => {
    if (isEmpty(refState.current.appID)) return
    const res = await getUserDetail({ erbanNo: refState.current.appID })

    const { code, message, data } = res as Response<AgentRecharge.UserInfo>
    if (code !== 200) {
      toast(message)
      return
    }

    setState({
      ...refState.current,
      user: data
    })
  }, [])
  const handleClearUser = useCallback(() => {
    setState({
      ...refState.current,
      user: undefined,
      appID: '',
      inputNum: ''
    })
  }, [])
  const hanndleAppIDChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...refState.current,
      appID: onlyNumber(e.target.value)
    })
  }, [])
  const fetchGetUserPurse = useCallback(async () => {
    const res = await getUserPurse()

    const { code, message, data } = res as Response<AgentRecharge.UserPurse>
    if (code !== 200) {
      toast(message)
      return
    }

    setState(prevState => ({
      ...prevState,
      coin: data.purseGoldNum,
      userPurse: data
    }));
    setCountryControl({ ...refCountryControl.current, countryCode: data.agencyCountryList?.map(item => item.countryCode)?.join(',') || '' })
  }, [])

  const openCountryPopup = useCallback((bol: boolean) => {
    setCountryControl({ ...refCountryControl.current, openCountry: bol })
  }, [])

  const handleHelpWithdrawal = useCallback(async (e: React.MouseEvent, item: AgentRecharge.WithdrawalList) => {
    e.stopPropagation()
    const ret = await alert(t('Wallet.SureYouHelpHimWithdraw'))
    if (!ret) return

    const res = await messageSendGreet(item.easeMobId)
    const { code, message } = res as Response
    if (code !== 200) {
      toast(message)
    }

    setDisabledRecycle(prev => {
      return [...prev, item.uid]
    })
  }, [])

  const confirmCountry = useCallback((countryCode: string) => {
    setCountryControl({ ...refCountryControl.current, openCountry: false, countryCode })
    fetchGetUserPurse()
  }, [])

  const goToIM = useCallback(debounce((item: AgentRecharge.WithdrawalList) => {
    // 需要传 uid、昵称、imid
    openPage(PageConst.IMChat, item.uid, { receiveName: item.nick, receiveImId: item.easeMobId })
  }, 300), [])

  const fetchWithdrawalList = useCallback(async () => {
    const res = await withdrawalList() as Response<{ withdrawalList: AgentRecharge.WithdrawalList[] }>

    const { code, message, data } = res
    if (code !== 200) {
      toast(message)
      return
    }

    setState(prevState => ({
      ...prevState,
      withdrawalList: data?.withdrawalList || []
    }));
  }, [])

  useEffect(() => {
    fetchGetUserPurse()
    fetchConf()
    fetchWithdrawalList()
  }, [fetchGetUserPurse, fetchConf, fetchWithdrawalList])
  const onDetailClick = useCallback(() => {
    navigate("/agent-recharge/coins-transfer/coin-detail")
  }, [])

  return <div className="pg-agent-recharge-coins-transfer">
    <WalletHeader title='Wallet.ShippingAgent' jumpText={'Common.Details'} jumpTextClick={onDetailClick}></WalletHeader>
    <ScrollTabs tabsTitle={titleItems} selectedTab={selectedTab} handleTabsClick={(i) => setSelectedTab(i)}></ScrollTabs>
    {selectedTab === 0 &&
      <>
        <div className="pg-agent-recharge-coins-transfer__shipping" onClick={() => openCountryPopup(true)}>
          <div className="pg-agent-recharge-coins-transfer__shipping-item">
            <div className="pg-agent-recharge-coins-transfer__shipping-item__top">
              <img className="pg-agent-recharge-coins-transfer__shipping-item__avatar" src={state.userPurse.avatar} alt="" />
              <div>
                <span>{state.userPurse.nick}</span>
                <span>ID:{state.userPurse.erbanNo}</span>
              </div>
              <img className="pg-agent-recharge-coins-transfer__shipping-item__arrow" src={ImageArrow} alt="" />
            </div>
            <div className="pg-agent-recharge-coins-transfer__shipping-item__bottom">
              <span>{t('Wallet.SupportCountry')}</span>
              {state.userPurse.agencyCountryList?.map((item, i) => {
                return <div key={i} className="pg-agent-recharge-coins-transfer__shipping-item__country">
                  <img src={item.countryNationalFlag} alt="" />
                  <span>({item.countryCode})</span>
                </div>
              })}
            </div>
          </div>
        </div>
        <Pane title={t('AgentRecharge.ResellerCoinsBalance')}>
          <div className='pg-agent-recharge-coins-transfer__balance'>
            <img alt='img' className='icon' src={ImageCoin} />
            <span className='num'>{state.coin}</span>
            <div className='bg'></div>
          </div>
        </Pane>
        <TransferContent isBD={false} AttentionInfo2TipName='AgentRecharge.Coins' AttentionInfo2TipAction={'AgentRecharge.Transferred'} readOnly={false} state={state} hanndleAppIDChange={hanndleAppIDChange} handleFetchUser={handleFetchUser} handleClearUser={handleClearUser} hanndleAmountChange={hanndleAmountChange} handleRechage={handleRecharge} agencyMsg={state.agencyMsg}></TransferContent>
        <Popup
          visible={transferVisible}
          className="pg-agent-recharge-coins-transfer pg-agent-recharge-coins-transfer-top-up-popup__popup"
          onMaskClick={() => setTransferVisibleVisible(false)}
          bodyStyle={{
            backgroundColor: '#29005D',
            height: px2rem(533),
            borderTopLeftRadius: px2rem(12),
            borderTopRightRadius: px2rem(12)
          }}>
          <div className="pg-agent-recharge-coins-transfer-top-up-popup__popup-header">
            <div className="pg-agent-recharge-coins-transfer-top-up-popup__popup-title">{t('AgentRecharge.CoinsTransfer')}</div>
            <div className="pg-agent-recharge-coins-transfer-top-up-popup__popup-cancel" onClick={() => setTransferVisibleVisible(false)}>
              {t('Common.Cancel')}
            </div>
          </div>
          <div className="pg-agent-recharge-coins-transfer-top-up-popup__popup-content">
            <TransferContent isBD={true} AttentionInfo2TipName='AgentRecharge.Coins' AttentionInfo2TipAction={'AgentRecharge.Transferred'} readOnly={true} state={state} transferVisible={transferVisible} loading={loading} handleRechage={handleRecharge}></TransferContent>
          </div>
        </Popup>
        {/* Country */}
        <Popup
          visible={countryControl.openCountry}
          className="pg-wallet-collection-information__country"
          bodyStyle={{
            backgroundColor: '#37144C',
            height: px2rem(509),
            borderRadius: `${px2rem(12)} ${px2rem(12)} 0 0`,
            padding: `0 ${px2rem(15)}`,
            boxSizing: 'border-box'
          }}
        >
          <Country
            {...{
              countryCode: countryControl.countryCode,
              openCountryPopup,
              confirmCountry
            }}
          />
        </Popup>
      </>
    }
    {selectedTab === 1 && <div className="pg-agent-recharge-coins-transfer__recycle">
      {state.withdrawalList?.map(item => {
        return item ? <div key={item.uid} className="pg-agent-recharge-coins-transfer__recycle-item" onClick={() => goToIM(item)}>
          <div className="pg-agent-recharge-coins-transfer__recycle-item__top">
            <img className="pg-agent-recharge-coins-transfer__recycle-item__avatar" src={item.avatar} alt="" />
            <div className="pg-agent-recharge-coins-transfer__recycle-item__user">
              <span>{item.nick}</span>
              <span>ID:{item.erbanNo}</span>
            </div>
            <div className="pg-agent-recharge-coins-transfer__recycle-item__money">
              <img src={ImageMoney} alt="" />
              <span>{item.cashAmount}</span>
            </div>
          </div>
          <div className="pg-agent-recharge-coins-transfer__recycle-item__bottom">
            <div className="pg-agent-recharge-coins-transfer__recycle-item__country">
              <span>{t('Common.Country')}:</span>
              <img src={item.countryNationalFlag} alt="" />
              <span className='name'>({item.countryCode})</span>
            </div>
            <button disabled={disabledRecycle.includes(item.uid)} onClick={(e) => handleHelpWithdrawal(e, item)} className={clsx('pg-agent-recharge-coins-transfer__recycle-item__btn', { disabled: disabledRecycle.includes(item.uid) })}>
              {t('Wallet.HelpWithdrawal')}
            </button>
          </div>
        </div> : null
      })}
    </div>}
  </div>
}