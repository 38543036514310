import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Meta from '../../components/Meta'
import ImageHeaderEn from './images/header-en.webp'
import ImageHeaderAr from './images/header-ar.webp'
import ImageUpBtnAr from './images/updata-btn-ar.webp'
import ImageUpBtnEn from './images/updata-btn-en.webp'
import ImageContentBtnEn from './images/content-en.webp'
import ImageContentBtnAr from './images/content-ar.webp'
import ImageDescriptionEn from './images/description-en.webp'
import ImageDescriptionAr from './images/description-ar.webp'
import ImageArrow from './images/arrow.webp';
import ImageContentImg1 from './images/content1-img.webp';
import ImageContentImg2 from './images/content2-img.webp';
import { useTrans } from '@/hooks/useTranslation'
import './index.scss'

export default function Download() {
  const { t, i18n } = useTrans()
  const refTimeout = useRef<NodeJS.Timeout>()
  const [link, setLink] = useState<string>()
  const content1Ref = useRef<HTMLDivElement>(null)
  const content2Ref = useRef<HTMLDivElement>(null)
  const handleDownload = useCallback(() => {
    // const isoUrl = process.env.REACT_APP_APPLE_URL
    const androidSchema = process.env.REACT_APP_ANDROID_SCHEMA!
    const androidUrl = process.env.REACT_APP_GOOGLE_URL!
    // if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
    //   window.location.href = isoUrl
    //   // } else if (navigator.userAgent.match(/android/i)) {
    // } else {
    refTimeout.current = setTimeout(function () {
      window.location.href = androidUrl;
    }, 500);
    setLink(androidSchema)
    // }
  }, [])

  const handleScroll = useCallback((btnText: string) => {
    switch (btnText) {
      case 'pk':
        content1Ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
      case 'newgame':
        content2Ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        break;
    }
  }, [])

  useEffect(() => {
    function onVisibilityChange() {
      if (document.hidden) {
        clearTimeout(refTimeout.current)
      }
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
  return <HelmetProvider>
    <Meta title={t('Guild.Tips.GuildPage').toString()} />
    <div className="pg-update">
      <img
        src={i18n.language === 'ar' ? ImageHeaderAr : ImageHeaderEn}
        className="header"
      />
      <div className="update-box">
        <img
          src={i18n.language === 'ar' ? ImageUpBtnAr : ImageUpBtnEn}
          className="up-btn"
          onClick={handleDownload}
        />
        <div className="description">
          <img
            src={i18n.language === 'ar' ? ImageDescriptionAr : ImageDescriptionEn}
            className="title-img"
          />
          <div className="pk-btn" onClick={() => handleScroll('pk')}>
            <span>{t('Update.PK')}</span>
            <img src={ImageArrow} />
          </div>
          <div className="newgame-btn" onClick={() => handleScroll('newgame')}>
            <span>{t('Update.NewGame')}</span>
            <img src={ImageArrow} />
          </div>
        </div>
      </div>
      <div className="content">
        <img
          src={i18n.language === 'ar' ? ImageContentBtnAr : ImageContentBtnEn}
          className="title-btn"
        />
        <div className="content1" ref={content1Ref}>
          <span >{t('Update.Content1')}</span>
          <img src={ImageContentImg1} />
        </div>
        <div className="content2" ref={content2Ref}>
          <span >{t('Update.Content2')}</span>
          <img src={ImageContentImg2} />
        </div>
      </div>
      <div className="bottom-tip">{t('Update.ExplanationTip')}</div>
    </div>
    {link && <iframe style={{ display: 'none' }} src={link} title='download' />}
  </HelmetProvider>
}