import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from '@/utils/bridge';
import { getBillRecordInfoList } from '@/services/wallet';

const pageSize = 20//每页数量

const useBillRecordInfoList = (initialBillTypeId = 0, fetchBillRecordInfoList = getBillRecordInfoList) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [firstRender, setFirstRender] = useState(true)
    const refFirstRender = useRef(firstRender);
    refFirstRender.current = firstRender

    const [time, setTime] = useState(moment().startOf('day').format('YYYY-MM-DD'));
    const refTime = useRef(time);
    refTime.current = time;

    const [listInfo, setListInfo] = useState<{
        list: Wallet.BillRecordInfoList[];
        hasMore: boolean;
        init: boolean
    }>({
        list: [],
        hasMore: true,
        init: true,
    });
    const refListInfo = useRef(listInfo);
    refListInfo.current = listInfo;

    const [billTypeId, setBillTypeId] = useState(initialBillTypeId);
    const billTypeIdRef = useRef(billTypeId);
    billTypeIdRef.current = billTypeId;

    /* 日期时间切换 */
    const handleTimeRangeChange = useCallback((timeParam: string) => {
        if (timeParam === 'Invalid date') return;
        setFirstRender(true)
        setTime(timeParam);
        setListInfo({
            list: [],
            init: true,
            hasMore: true,
        });
    }, []);

    /* 账单数目请求 */
    const fetchBillList = useCallback(async () => {
        if (!refListInfo.current.hasMore || !billTypeIdRef.current) return;

        const lastTime = refListInfo.current.list[refListInfo.current.list.length - 1]?.billTime;
        try {
            if (refFirstRender.current)
                setLoading(true)
            const res = await fetchBillRecordInfoList({
                billType: billTypeIdRef.current,
                lastTime: lastTime,
                pageSize,
                date: refTime.current,
            });
            if (refFirstRender.current)
                setLoading(false)
            const { code, message, data } = res as Response<PageList<Wallet.BillRecordInfoList>>;

            if (code !== 200) {
                toast(message);
                return;
            }

            if (!data) return;
            setListInfo({
                list: [
                    ...(refListInfo.current.init === true ? [] : refListInfo.current.list),
                    ...(data.list || []),
                ],
                hasMore: !data.whetherLastPage,
                init: false,
            });
            if (refFirstRender.current)
                setFirstRender(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [fetchBillRecordInfoList]);

    useEffect(() => {
        fetchBillList();
    }, [time, fetchBillList, billTypeId]);

    return {
        loading,
        setFirstRender,
        time,
        setTime,
        handleTimeRangeChange,
        listInfo,
        setListInfo,
        billTypeId,
        setBillTypeId,
        fetchBillList,
    };
};

export default useBillRecordInfoList;
