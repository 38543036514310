import { useEffect, useState } from 'react'
import { useTrans } from '@/hooks/useTranslation'
import './RoomDataTable.scss'

type PropsType = {
  items: Room.RoomFlowRecord[]
}

export default function MemberDataTable(props: PropsType) {
  const { t } = useTrans()
  const [list, setList] = useState<Room.RoomFlowRecord[]>([])

  useEffect(() => {
    setList(props.items)
  }, [props])

  return (
    <div className="cmp-rdt">
      <div className="title">
        <span>{t('Common.Date')}</span>
        <span>{t('Common.Gem')}</span>
      </div>
      <div className='content'>
        {list.map((item, i) => (
          <div className='item' key={i}>
            <span>{item?.createDay}</span>
            <span>{item?.totalDiamond}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
