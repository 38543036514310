import { memo, useMemo } from 'react'
// import ImageLevel1 from '@/images/LV1.webp'
// import ImageLevel2 from '@/images/LV2.webp'
// import ImageLevel3 from '@/images/LV3.webp'
// import ImageLevel4 from '@/images/LV4.webp'
// import ImageLevel5 from '@/images/LV5.webp'
import { useTrans } from '@/hooks/useTranslation'
import './AgencyRewardGrade.scss'

interface PropsType {
    levelNumber: number//等级
    agencyRewardRate: number//奖励比例
}
function AgencyRewardGrade({ levelNumber, agencyRewardRate }: PropsType) {
    const { t } = useTrans()
    const lv = useMemo(() => {
        switch (levelNumber) {
            case 1:
                return 'D'
            case 2:
                return 'C'
            case 3:
                return 'B'
            case 4:
                return 'A'
            case 5:
                return 'S'
            // case 1:
            //     return ImageLevel1
            // case 2:
            //     return ImageLevel2
            // case 3:
            //     return ImageLevel3
            // case 4:
            //     return ImageLevel4
            // case 5:
            //     return ImageLevel5
        }
    }, [levelNumber])
    return (
        <div className='cmp-agency-reward-grade'>
            <div className="lv">
                {/* <img src={lv}></img> */}
                <span>{lv}</span>
            </div>
            <div className="rate">
                {t('Common.Rate')}:{(agencyRewardRate * 100).toFixed(0)}%
            </div>
        </div>
    )
}

export default memo(AgencyRewardGrade)