import { useCallback, useState } from 'react';

export function useClipboard() {
  /* 是否已复制 */
  const [copied, setCopied] = useState(false)

  /* 复制函数 */
  const copy = useCallback((str: string) => {
    if (navigator.clipboard) {
      // 如果可用，使用现代剪贴板API
      navigator.clipboard.writeText(str).then(() => {
        setCopied(true);
      });
      return;
    }

    // 不可用，则使用document.execCommand('copy')兼容性写法：缺点是只能将选中的内容复制到剪贴板
    // 生成选中要复制的内容
    const input = document.createElement('input');
    input.setAttribute('readOnly', 'readOnly');
    input.setAttribute('value', str);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 9999);//设置了选择的文本范围，这里是从位置0到9999（实际上是一个足够大的值，以确保选择整个文本）

    if (document.execCommand('copy')) {
      document.execCommand('copy');
      setCopied(true);
    }

    document.body.removeChild(input);
  }, [])

  return { copy, copied }
}