import { service } from '../utils/net'

//gem
export function getGemAnchorDiamond() {
  return service<Response<Wallet.GemAnchorDiamond>>({
    method: 'GET',
    url: '/chargeProd/anchorDiamond'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

export function gemBuyCoin(params: { chargeProdId: string }) {
  return service<Response>({
    method: 'POST',
    url: '/anchorDiamond/buy',
    params: params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//明细
export function getBillRecordTypeList(type: number) {
  return service<Response<Wallet.BillRecordTypeList>>({
    method: 'GET',
    url: '/billRecord/getType',
    params: { type }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
export function getBillRecordInfoList(params: Wallet.BillRecordInfoListParams) {
  return service<Response<PageList<Wallet.BillRecordInfoList>>>({
    method: 'GET',
    url: '/billRecord/getForType',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//主播钱包
export function getAnchormanWalletData() {
  return service<Response<Wallet.AnchormanWalletData>>({
    method: 'GET',
    url: '/purse/agency/query'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//cash兑换普通金币列表
export function getAnchormanWalletExchangeProList() {
  return service<Response<Wallet.ExchangeProList[]>>({
    method: 'GET',
    url: '/cash/exchange/prod/list'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//cash兑换可售卖金币列表
export function getAnchormanWalletExchangeAgencyProList() {
  return service<Response<Wallet.ExchangeProList[]>>({
    method: 'GET',
    url: '/cash/exchange/agency/prod/list'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

//cash兑换
export function cashExchange(params: {
  tagUid: number
  prodId?: string
  goldType: number
  customCashAmount?: number
}) {
  return service<Response>({
    method: 'POST',
    url: '/cash/exchange',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// cash自定义兑换比例
export function getCashExchangeRate() {
  return service<Response<Wallet.CashExchangeRate[]>>({
    method: 'GET',
    url: '/cash/exchange/rate'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

/* 提现 */
// 提现国家列表
export function getCountryList() {
  return service<Response<Wallet.Country[]>>({
    method: 'GET',
    url: '/withdrawal/all/country'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 支付方式
export function getPayChannelList(countryCode: string) {
  return service<Response<Wallet.Channel>>({
    method: 'GET',
    url: '/withdrawal/pay/channel',
    params: {
      countryCode
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 银行卡目标机构
export function getBankTransferTargetOrg(countryCode: string) {
  return service<Response<Wallet.BankTransferTargetOrg>>({
    method: 'GET',
    url: '/withdrawal/bankTransfer/targetOrg',
    params: {
      countryCode
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 提现账号保存
export function withdrawalAccountSave(
  params: Wallet.WithdrawalAccountSaveParams
) {
  return service<Response>({
    method: 'POST',
    url: '/withdrawal/account/save',
    params
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取提现账号
export function getWithdrawalAccount() {
  return service<Response<Wallet.WithdrawalAccount>>({
    method: 'POST',
    url: '/withdrawal/account/get'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取Cash提现配置
export function getWithdrawalConf() {
  return service<Response<Wallet.WithdrawalConf>>({
    method: 'POST',
    url: '/withdrawal/conf'
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 获取提现费率
export function getWithdrawFee(cashAmount: number, useQuota: boolean) {
  return service<Response<Wallet.WithdrawalFee>>({
    method: 'GET',
    url: '/withdrawal/fee',
    params: {
      cashAmount,
      useQuota
    }
  }).then((res) => res.data)
}

// Cash提现
export function withdrawalCash(cashAmount: number, useQuota: boolean) {
  return service<Response>({
    method: 'POST',
    url: '/withdrawal/cash',
    params: {
      cashAmount,
      useQuota
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}