import { useTrans } from "@/hooks/useTranslation"
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useParams } from 'react-router'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import ScrollList from '@/components/ScrollList'
import { getGuildMemberHistoricalData, kickOutGuild } from '@/utils/request'
import { NumberCard2 } from './Components/NumberCard'
import SelectTimeRange from '../../components/SelectTimeRange'
import './MemberHistoricalData.scss'
import { getUserInfo, toast } from '@/utils/bridge'
import { formatCharmValue, formatGuildTime } from '@/utils/format'
import MemberCard from './Components/MemberCard'
import NumberCard from './Components/NumberCard'
import MemberDataTable from './Components/MemberDataTable'

export default function MemberHistoricalData() {
  const { t } = useTrans()
  const [user, setUser] = useState<UserBasicInfo>()
  const { memberId = '' } = useParams()
  const [time, setTime] = useState<{ start: Date; end: Date }>({
    start: moment().startOf('month').toDate(),
    end: moment().startOf('date').toDate()
  })
  const refTime = useRef(time)
  refTime.current = time

  const [listInfo, setListInfo] = useState<{
    member: Guild.HistoricalDataItem | null
    list: Guild.MemberHistoricalDataItem[]
    page: number
    hasMore: boolean
  }>({ page: 1, hasMore: true, member: null, list: [] })
  const refListInfo = useRef(listInfo)
  refListInfo.current = listInfo

  const handleTimeRangeChange = useCallback((start: Date, end: Date) => {
    setListInfo({
      ...refListInfo.current,
      page: 1,
      hasMore: true
    })
    setTime({ start, end })
  }, [])

  const fetchGuildMemberHistoricalData = useCallback(async () => {
    if (!refListInfo.current.hasMore) return

    const startTime = moment(refTime.current.start)
      .startOf('day')
      .format('YYYYMMDD')
    const endTime = moment(refTime.current.end)
      .startOf('day')
      .format('YYYYMMDD')
    const res = await getGuildMemberHistoricalData(
      memberId,
      Number(startTime),
      Number(endTime),
      refListInfo.current.page,
      20
    )
    if (!res) return
    const { code, message, data } = res as Response<{
      memberSummaryData: Guild.HistoricalDataItem
      pageResult?: PageList<Guild.MemberHistoricalDataItem>
    }>
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setListInfo({
      ...refListInfo.current,
      member: data.memberSummaryData,
      list: [
        ...(refListInfo.current.page === 1 ? [] : refListInfo.current.list),
        ...(data.pageResult?.list || [])
      ],
      page: data.pageResult?.whetherLastPage
        ? refListInfo.current.page
        : refListInfo.current.page + 1,
      hasMore: !data.pageResult?.whetherLastPage
    })
  }, [memberId])

  const handleRemove = useCallback(async (memberId: number) => {
    const res = await kickOutGuild(memberId)
    if (!res) return

    const { code, message } = res as Response
    if (code !== 200) {
      toast(message)
      return
    }

    toast(t('Common.Success'))
  }, [])

  useEffect(() => {
    getUserInfo().then((auth) => {
      if (auth) {
        setUser(auth)
      }
    })
  }, [])

  useEffect(() => {
    fetchGuildMemberHistoricalData()
  }, [fetchGuildMemberHistoricalData, time])

  return (
    <HelmetProvider>
      <Meta title={t('Guild.MonthlyData').toString()} />
      <div className="pg pg-member-historical-data">
        <Header title={t('Guild.MonthlyData').toString()} />
        {listInfo.member && (
          <>
            <div className='user-info'>
              <MemberCard
                memberId={listInfo.member.uid}
                avatar={listInfo.member.avatar}
                username={listInfo.member.nick}
                erbanNo={listInfo.member.erbanNo}
                remove={
                  listInfo.member.uid !== Number(user?.uid)
                    ? {
                      onRemove: handleRemove
                    }
                    : undefined
                }
              />
              <NumberCard2
                items={[
                  {
                    key: 'Guild.TodaysGem',
                    val: formatCharmValue(listInfo.member.todayDiamondNum || 0)
                  },
                  {
                    key: 'Guild.YesterdayGem',
                    val: formatCharmValue(
                      listInfo.member.yesterdayDiamondNum || 0
                    )
                  }
                ]}
              />
            </div>
            <SelectTimeRange
              startTime={time.start}
              endTime={time.end}
              onChange={handleTimeRangeChange}
            />
            {/* <NumberCard2
              items={[
                {
                  key: 'Guild.ExchangeGem',
                  val: formatCharmValue(
                    listInfo.member.exchangeDiamondNum || 0
                  )
                },
                {
                  key: 'Guild.WalletsGem',
                  val: formatCharmValue(
                    listInfo.member.purseDiamondNum || 0
                  )
                },
                {
                  key: 'Guild.FrozenGem',
                  val: formatCharmValue(listInfo.member.frozenDiamondNum || 0)
                }
              ]}
            /> */}
            <NumberCard
              items={[
                {
                  key: 'Guild.ThisMonthlyGem',
                  val: formatCharmValue(
                    listInfo.member.thisMonthDiamondNum || 0
                  )
                },
                {
                  key: 'Guild.ThisMonthlyHours',
                  val: formatGuildTime(
                    listInfo.member.thisMonthMicBroadcastDuration || 0
                  )
                },
                {
                  key: 'Guild.ThisMonthlyDays',
                  val: listInfo.member.thisMonthValidDays || 0
                }
              ]}
            />
            <NumberCard
              class="last"
              items={[
                {
                  key: 'Guild.LastMonthlyGem',
                  val: formatCharmValue(
                    listInfo.member.lastMonthDiamondNum || 0
                  )
                },
                {
                  key: 'Guild.LastMonthlyHours',
                  val: formatGuildTime(
                    listInfo.member.lastMonthMicBroadcastDuration || 0
                  )
                },
                {
                  key: 'Guild.LastMonthlyDays',
                  val: listInfo.member.lastMonthValidDays || 0
                }
              ]}
            />
          </>
        )}
        <div className="pg-member-historical-data__list">
          <ScrollList
            hasMore={listInfo.hasMore}
            loadMore={fetchGuildMemberHistoricalData}
          >
            <MemberDataTable items={listInfo.list} />
          </ScrollList>
        </div>
      </div>
    </HelmetProvider>
  )
}
