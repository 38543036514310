import ImageLevel1 from '../../images/guild-lv1-icon.png'
import ImageLevel2 from '../../images/guild-lv2-icon.png'
import ImageLevel3 from '../../images/guild-lv3-icon.png'
import ImageLevel4 from '../../images/guild-lv4-icon.png'
import ImageLevel5 from '../../images/guild-lv5-icon.png'
import ImageLevel1Ar from '../../images/guild-lv1-icon-ar.png'
import ImageLevel2Ar from '../../images/guild-lv2-icon-ar.png'
import ImageLevel3Ar from '../../images/guild-lv3-icon-ar.png'
import ImageLevel4Ar from '../../images/guild-lv4-icon-ar.png'
import ImageLevel5Ar from '../../images/guild-lv5-icon-ar.png'
import { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import { useTrans } from '@/hooks/useTranslation'
import './Rule.scss'

type LevelItem = {
  level: string
  medal: string
  experience: string
}
export default function GuildRule() {
  const [t, i18n] = useTrans()
  const [state, setState] = useState<{
    levelItems: LevelItem[]
  }>({
    levelItems: []
  })
  const refState = useRef(state)
  refState.current = state

  useEffect(() => {
    setState({
      ...refState.current,
      levelItems: [
        { level: 'LV.1', medal: i18n.language === 'ar' ? ImageLevel1Ar : ImageLevel1, experience: "0" },
        { level: 'LV.2', medal: i18n.language === 'ar' ? ImageLevel2Ar : ImageLevel2, experience: "9,000,000" },
        { level: 'LV.3', medal: i18n.language === 'ar' ? ImageLevel3Ar : ImageLevel3, experience: "27,000,000" },
        { level: 'LV.4', medal: i18n.language === 'ar' ? ImageLevel4Ar : ImageLevel4, experience: "45,000,000" },
        { level: 'LV.5', medal: i18n.language === 'ar' ? ImageLevel5Ar : ImageLevel5, experience: "63,000,000" }
      ]
    })
  }, [i18n.language])

  return <div className='pg-guild-rule'>
    <Header title={t('Guild.Rule.Rules').toString()} />
    <div className='pg-guild-rule__item'>
      <div className='pg-guild-rule__item-title'>{t('Guild.Rule.Title1')}</div>
      <div className='pg-guild-rule__item-content'>{t('Guild.Rule.Content1')}</div>
    </div>
    <div className='pg-guild-rule__item'>
      <div className='pg-guild-rule__item-title'>{t('Guild.Rule.Title2')}</div>
      <div className='pg-guild-rule__item-content'>{t('Guild.Rule.Content2')}</div>
    </div>
    <div className='pg-guild-rule__item'>
      <div className='pg-guild-rule__item-title'>{t('Guild.Rule.Title3')}</div>
      <div className='pg-guild-rule__item-content'>{t('Guild.Rule.Content3')}</div>
    </div>
    <div className='pg-guild-rule__item'>
      <div className='pg-guild-rule__item-title'>{t('Guild.Rule.Title4')}</div>
      <div className='pg-guild-rule__item-content'>
        <table cellPadding={0} cellSpacing={0}>
          <thead><tr>
            {['Level', 'Medal', 'Experience'].map(key => <th key={key}>{t(`Common.${key}`)}</th>)}
          </tr></thead>
          <tbody>{state.levelItems.map((item, i) => <tr key={i}>
            <td>{item.level}</td>
            <td><div><img alt='img' src={item.medal} /></div></td>
            <td>{item.experience}</td>
          </tr>)}</tbody>
        </table>
      </div>
    </div>
  </div>
}