import { useCallback, useEffect, useRef, useState } from 'react'
import WalletHeader from '../components/Header';
import { useNavigate } from 'react-router'
import CardItem from './components/AnchormanWalletDetail/CardItem';
import HrefButton from './components/AnchormanWalletDetail/HrefButton';
import ImgGem from '@/images/gem.png';
import ImgMoney from '@/images/money.webp'
import Highlight from '@/components/Highlight';
import { toast, setBusinessStatus, getUserInfo } from '@/utils/bridge';
import { BusinessConst } from '@/utils/constant';
import { getMyUserInfo } from '@/utils/request'
import { getAnchormanWalletData, getAnchormanWalletExchangeProList, cashExchange } from '@/services/wallet'
import { abnormalReportCashExchange } from '@/utils/report';
import PopupSwiper from './components/AnchormanWalletDetail/PopupSwiper';
import Loading from '@/components/Loading';
import { useTrans } from '@/hooks/useTranslation';
import './AnchormanWalletDetail.scss';

function AnchormanWalletDetail() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const refVisible = useRef(visible)
    refVisible.current = visible

    const [user, setUser] = useState<UserBasicInfo>()
    const refUser = useRef(user)
    refUser.current = user

    const [state, setState] = useState<{
        user: AgentRecharge.UserInfo | undefined,
        proList: Wallet.ExchangeProList[]
        selectedPro?: Wallet.ExchangeProList
    } & Wallet.AnchormanWalletData>({ user: undefined, anchorDiamondNum: 0, cashAmount: 0, cashChangeBase: 0, diamondChangeRate: 0, proList: [], userTypeRole: 1, monthMoney: 0, residualQuota: 0, totalQuota: 0, cashWithdrawalSwitch: 0, withdrawalFee: '' })
    const refState = useRef(state)
    refState.current = state
    /* 当前用户信息请求 */
    const fetchMyUser = useCallback(async () => {
        const { code, data, message } = await getMyUserInfo() as Response<AgentRecharge.UserInfo[]>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setState({
            ...refState.current,
            user: data[0]
        })
    }, [])
    /*获取主播钱包信息和cash兑换列表 */
    const fetchHomeData = useCallback(async () => {
        setLoading(true)
        const [walletData, proListData]: [Response<Wallet.AnchormanWalletData>, Response<Wallet.ExchangeProList[]>] = await Promise.all([
            getAnchormanWalletData(),
            getAnchormanWalletExchangeProList()
        ])
        setLoading(false)
        if (!walletData || !proListData) return
        if (walletData.code !== 200) {
            toast(walletData.message)
            return
        }

        if (proListData.code !== 200) {
            toast(proListData.message)
            return
        }
        setState({
            ...refState.current,
            ...walletData.data,
            proList: proListData.data,
        })
    }, [])
    /* cash兑换给自己 */
    const fetchCashExchangeMyself = useCallback(async () => {
        const prodId = refState.current.selectedPro?.prodId
        if (!prodId) return
        try {
            const res = await cashExchange({ tagUid: Number(refUser.current?.uid) || 0, prodId, goldType: 2 }) as Response
            // 请求超时或断网
            if (res.code.toString() === 'ECONNABORTED' || res.code.toString() === 'ERR_NETWORK' || res.message.includes('timeout')) {
                goWarningPage(res)
                return;
            }
            if (res.code !== 200) {
                toast(res.message)
                return
            }
            goSuccessPage()
        }
        catch (error) {
            goWarningPage(error)
        }
    }, [])
    /* 请求成功跳转事件 */
    const goSuccessPage = useCallback(() => {
        setBusinessStatus(BusinessConst.CoinChange)
        navigate(`/wallet/anchorman-wallet-detail/success/${refState.current.user?.erbanNo}/${refState.current.selectedPro?.goldNum}`);
    }, [])
    /* 请求失败跳转事件 */
    const goWarningPage = useCallback((res: any) => {
        abnormalReportCashExchange(res, { userId: refState.current.user?.uid, inputNum: refState.current.selectedPro?.goldNum.toString() });
        navigate(`/wallet/anchorman-wallet-detail/warning/${refState.current.user?.erbanNo}/${refState.current.selectedPro?.goldNum}`);
    }, [])
    /* how to use cash的四个按钮点击事件 */
    const handleButton = useCallback((id: number | undefined) => {
        switch (id) {
            case 1:
                navigate('/wallet/cash-to-coins')
                break;
            case 2:
                setVisible(true)
                fetchMyUser()
                break;
            case 3:
                navigate('/agent-recharge/cash-transfer')
                break;
            case 4:
                navigate(`/wallet/private-wallet?withdrawalFee=${refState.current.withdrawalFee}`)
                break;
            default:
                break;
        }
    }, [])
    /* 兑换 */
    const handleNext = useCallback(() => {
        if (refVisible.current) fetchCashExchangeMyself()
    }, [])
    /* 初始化请求数据 */
    useEffect(() => {
        fetchHomeData()
        getUserInfo().then((auth) => {
            if (auth) {
                setUser(auth)
            }
        })
    }, [fetchHomeData])
    const handleCardDetail = useCallback(async (titleNum: number) => {
        // 路径参数:title选中gem/cash一级标题，tab选中二级标题
        switch (titleNum) {
            case 1:
                navigate(`/guild/wallet-data/member-detail/${refUser.current?.uid}`)
                break;
            case 2:
                navigate(`/guild/wallet-data/member-detail/${refUser.current?.uid}?title=1`)
                break;
            case 3:
                navigate(`/guild/wallet-data/member-detail/${refUser.current?.uid}?title=1`)
                break;
            default:
                break;
        }
    }, [])
    // const handleCard4Rule = useCallback(() => {
    //     navigate(`/wallet/anchorman-wallet-detail/rule/card4`)
    // }, [])
    // const jumpTextClick = useCallback(() => {
    //     // navigate("/")
    //     toast('不用测')
    // }, [])
    return (
        <div className='pg-wallet-anchorman-detail'>
            {/* jumpText='Common.Rule' jumpTextClick={jumpTextClick} */}
            <WalletHeader title='Wallet.AgencyGemAndCash' bgPenetration={true} content={
                <CardItem detailClick={handleCardDetail} className='card1' contentNum={state.anchorDiamondNum?.toString()} contentIcon={ImgGem} title='Wallet.AgencyGemAndCashCardTitle1' titleNum={1} extraContent={
                    <Highlight keys={[state.diamondChangeRate?.toString(), state.cashChangeBase?.toString()]} color="#FFF100" className='extra-content'>
                        {t('Wallet.AgencyGemAndCashCard2Tips', { cashChangeBase: state.cashChangeBase, diamondChangeRate: state.diamondChangeRate })}
                    </Highlight>
                }></CardItem>
            }></WalletHeader>
            <div className="content">
                <CardItem detailClick={handleCardDetail} className='card2' contentNum={`$${state.cashAmount}`} contentIcon={ImgMoney} title='Wallet.AgencyGemAndCashCardTitle2' titleNum={2}
                ></CardItem>
                <CardItem detailClick={handleCardDetail} className='card3' onlyDetail={true} title='Wallet.AgencyGemAndCashCardTitle3' titleNum={3} extraContent={
                    <div className='extra-content'>
                        {/* 代理才展示入口 */}
                        {(state.userTypeRole === 4 || state.userTypeRole === 5) && <HrefButton text='Wallet.AgencyGemAndCashCard3Href1' onClick={() => handleButton(1)} />}
                        <HrefButton text='Wallet.AgencyGemAndCashCard3Href2' onClick={() => handleButton(2)} />
                        <HrefButton text='Wallet.AgencyGemAndCashCard3Href3' onClick={() => handleButton(3)} />
                        {/*cash提现开关:1开 0关 */}
                        {!!state.cashWithdrawalSwitch && <HrefButton text='Wallet.AgencyGemAndCashCard3Href4' onClick={() => handleButton(4)} />}
                    </div>
                }></CardItem>
                {/* <CardItem className='card4' hideDetailContent={true} title='Wallet.AgencyGemAndCashCardTitle4' titleExtra={<div className='read-more' onClick={handleCard4Rule} >({t('Guild.AgencyReward.Readmore')})</div>} titleNum={4} extraContent={
                    <div className='extra-content'>
                        <div className='tips'>
                            {new Array(3).fill(0).map((_, i) => <div key={i}>
                                <span>{t(`Wallet.AgencyGemAndCashCard4Tips${i + 1}`, { residualQuota: state.residualQuota, totalQuota: state.totalQuota })}</span>
                            </div>)}
                        </div>
                        <AwardProgress current={state.monthMoney} />
                    </div>
                }></CardItem> */}
            </div >
            {loading && <Loading />
            }
            <PopupSwiper
                money={state.cashAmount}
                state={state}
                setState={setState}
                title="Wallet.ToMyself"
                visible={visible}
                setVisible={setVisible}
                handleNext={handleNext}
                searchUserDisable={true}
            />
        </div >
    )
}

export default AnchormanWalletDetail