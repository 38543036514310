import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Header from '@/components/Header';
import SelectMonthTime from '@/components/SelectMonthTime';
import moment from 'moment';
import AgencyRewardUserInfo from './components/AgencyRewardUserInfo';
import AgencyRewardIncomeTable from './components/AgencyRewardIncomeTable';
import clsx from 'clsx';
import Pane from '@/components/Pane';
import RuleTable from './components/RuleTable';
import { getRewardMonthData, getMemberGemIncomeList } from '@/utils/request';
import { toast } from '@/utils/bridge';
import ScrollList from '@/components/ScrollList'
import CmpLoading from '@/components/Loading'
import { useTrans } from '@/hooks/useTranslation';
import './AgencyReward.scss'
import TabButtons from '../Components/TabButtons';
import { useRefState } from '@/hooks';

function AgencyReward() {
    const { t } = useTrans()
    const [loading, setLoading] = useState(false)



    const [selectedTab, setSelectedTab, refSelectedTab] = useRefState<number>(() => {
        return moment().isBefore(moment().date(16)) ? 0 : 1;
    })

    const [time, setTime] = useState<string>(moment().startOf('month').format('YYYY-MM'))
    const refTime = useRef(time)
    refTime.current = time

    const expectedShow = useMemo(() => {
        const currentHalf = moment().isBefore(moment().date(16)) ? 0 : 1
        // 当前选择的时间是不是当前月。
        const isCurrentMonth = time === moment().startOf('month').format('YYYY-MM')
        return isCurrentMonth ? currentHalf === selectedTab : time === moment().startOf('month').format('YYYY-MM')
    }, [selectedTab, time])

    const [calculateItemIndex, setCalculateItemIndex] = useState(1)

    const [rewardMonthData, setRewardMonthData] = useState<Guild.GuildRewardMonthData>({
        coverPicUrl: '',
        guildName: '',
        guildNo: 0,
        levelNumber: 0,
        agencyRewardRate: 0,
        rewardGem: 0,
        agencyGemIncome: null,
        subAgencyTotalRewardGem: 0
    })
    const refRewardMonthData = useRef(rewardMonthData)
    refRewardMonthData.current = rewardMonthData

    const [memberGemIncomeList, setMemberGemIncomeList] = useState<{
        list: Guild.GuildMemberGemIncomeList[]
        page: number
        hasMore: boolean
    }>({
        list: [],
        page: 1,
        hasMore: true
    })
    const refMemberGemIncomeList = useRef(memberGemIncomeList)
    refMemberGemIncomeList.current = memberGemIncomeList

    const tabTitle = useMemo(() => {
        return ['Guild.AgencyReward.FirstHalf', 'Guild.AgencyReward.SecondHalf']
    }, [])

    const fetchRewardMonthData = useCallback(async () => {
        const { code, data, message } = await getRewardMonthData(refTime.current.replace(/-/g, ''), refSelectedTab.current + 1) as Response<Guild.GuildRewardMonthData>
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setRewardMonthData(data)
        setMemberGemIncomeList(prev => ({
            ...prev,
            hasMore: true
        }))
    }, [])
    const fetchMemberGemIncomeList = useCallback(async () => {
        if (refRewardMonthData.current.agencyGemIncome === null) {
            setMemberGemIncomeList({
                ...refMemberGemIncomeList.current,
                list: [],
                page: 1,
                hasMore: false
            })
            return
        }
        if (!refMemberGemIncomeList.current.hasMore) return
        const { code, data, message } = await getMemberGemIncomeList(refTime.current.replace(/-/g, ''), refSelectedTab.current + 1, refMemberGemIncomeList.current.page) as Response<PageList<Guild.GuildMemberGemIncomeList>>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setMemberGemIncomeList(prev => ({
            ...prev,
            list: [
                ...(refMemberGemIncomeList.current.page === 1 ? [] : refMemberGemIncomeList.current.list),
                ...(data.list || [])
            ],
            page: data.whetherLastPage ? refMemberGemIncomeList.current.page : refMemberGemIncomeList.current.page + 1,
            hasMore: !data.whetherLastPage
        }))
    }, [])

    useEffect(() => {
        setLoading(true)
        setMemberGemIncomeList({
            ...refMemberGemIncomeList.current,
            list: [],
            page: 1,
            hasMore: true
        })
        setRewardMonthData({
            coverPicUrl: '',
            guildName: '',
            guildNo: 0,
            levelNumber: 0,
            agencyRewardRate: 0,
            rewardGem: 0,
            agencyGemIncome: null,
            subAgencyTotalRewardGem: 0
        })
        fetchRewardMonthData()
    }, [time, selectedTab])

    return <>
        <div className='pg-guild-agency-reward pg'>
            <Header title={t('Guild.AgencyReward.Title').toString()} ></Header>
            <SelectMonthTime time={time} setTime={setTime} elProps={{ min: new Date('2024-11-01') }}></SelectMonthTime>
            <TabButtons titleItems={tabTitle} selectedTab={selectedTab} setSelectedTab={setSelectedTab}></TabButtons>
            <AgencyRewardUserInfo expected={expectedShow} rewardMonthData={rewardMonthData} calculateItemIndex={calculateItemIndex} setCalculateItemIndex={setCalculateItemIndex}></AgencyRewardUserInfo>
            <div className={clsx({ hide: calculateItemIndex !== 1 })}>
                <ScrollList
                    hasMore={memberGemIncomeList.hasMore}
                    loadMore={fetchMemberGemIncomeList}
                >
                    <AgencyRewardIncomeTable items={memberGemIncomeList.list}></AgencyRewardIncomeTable>
                </ScrollList>
            </div>
            <div className={clsx('pg-guild-agency-reward__level', { hide: calculateItemIndex !== 2 })} >
                <Pane title={t('Guild.AgencyReward.AgencyLevel')}>
                    <RuleTable now={rewardMonthData.levelNumber || 1}></RuleTable>
                </Pane>
            </div>
            {loading && <CmpLoading />}
        </div>
    </>
}

export default AgencyReward