//判断是不是阿语
export const isAr = (str: string) => {
    return /[\u0600-\u06FF\u0750-\u077F]/.test(str);
}
//px转rem
export function px2rem(px: number) {
    return `${px / 37.5}rem`;
}
// 获取用户uid和ticket
export function getAuthInfo() {
    let auth
    try {
        const authStr = localStorage.getItem('auth') || ''
        auth = JSON.parse(authStr)
    } catch (e) {
        return
    }
    return auth
}