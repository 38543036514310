import React, { useCallback, useState, ChangeEvent, useMemo, useRef, useEffect } from 'react'
import WalletHeader from '@/pages/Wallet/components/Header';
import { TextArea } from 'antd-mobile'
import Pane from '@/components/Pane';
import ImgTen from '@/images/ten.png';
import ImgPeople from '@/images/people.png';
import ImgCoin from '@/images/coin.png';
import clsx from 'clsx';
import SafeArea from "@/components/SafeArea"
import { isEmpty } from 'lodash-es';
import { uploadAvatar } from '@/utils/bridge'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { guildCreateApply, getGuildCreateConf, getGuildInfoFromId } from '@/utils/request';
import { toast } from '@/utils/bridge';
import Loading from '@/components/Loading';
import { useTrans } from '@/hooks/useTranslation';
import './CreateAgencySubmit.scss'

function CreateAgencySubmit() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const [loading, setLoading] = useState<boolean>(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [goldNum, setGoldNum] = useState(0)
    const [guildInfo, setGuildInfo] = useState<Guild.GuildInfoFromId>()
    const [state, setState] = useState<{
        guildName: string,
        coverPicUrl: string,
        guildSlogan?: string,
        inviteUid?: string
    }>({
        guildName: '',
        coverPicUrl: '',
    })
    const refState = useRef(state)
    refState.current = state

    const fetchGuildInfoFromId = useCallback(async (inviteUid: string) => {
        const { code, data, message } = await getGuildInfoFromId(inviteUid) as Response<Guild.GuildInfoFromId>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setGuildInfo(data)
    }, [])
    const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...refState.current,
            guildName: e.target.value,
        })
    }, [])
    const handleUpload = useCallback(async () => {
        const url = await uploadAvatar()
        if (url) {
            setState({
                ...refState.current,
                coverPicUrl: url
            })
        }
    }, [])
    const isCanCreate = useMemo(() => {
        let flag = true
        if (isEmpty(state.guildName) || isEmpty(state.coverPicUrl)) {
            flag = false
        }
        return flag
    }, [state])
    const handleConfirm = useCallback(async () => {
        if (refLoading.current) return
        refState.current.inviteUid = searchParams.get('inviteUid') || undefined
        setLoading(true)
        const { code, message } = await guildCreateApply(refState.current)
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        navigate('/guild/create/audit')
    }, [])
    const fetchConf = useCallback(async () => {
        const { code, data, message } = await getGuildCreateConf() as Response<Guild.GuildCreateConf>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setGoldNum(data.goldNum)
    }, [])
    const handleClearGuildInfo = useCallback(() => {
        setGuildInfo({} as Guild.GuildInfoFromId)
    }, [])
    const handleGuildInfoUpload = useCallback(() => {
        sessionStorage.setItem('create-agency-submit-state', JSON.stringify(refState.current));
        navigate(`/guild/agency/search`)
    }, [])
    useEffect(() => {
        fetchConf()
        const stateParams = sessionStorage.getItem('create-agency-submit-state')
        if (stateParams) {
            setState({ ...JSON.parse(stateParams) })
        }
    }, [])
    useEffect(() => {
        const inviteUid = searchParams.get('inviteUid')
        if (inviteUid) {
            fetchGuildInfoFromId(inviteUid)
        }
    }, [searchParams])
    return (
        <div className='pg-guild-create-agency-submit'>
            {loading && <Loading />}
            <WalletHeader title={'Guild.CreateAgency.Title'}></WalletHeader>
            <div className="content">
                <div className="upload">
                    {!state.coverPicUrl && <div className="btn" onClick={handleUpload}>
                        <img src={ImgTen}></img>
                    </div>}
                    {state.coverPicUrl && <div className="img">
                        <img src={state.coverPicUrl}></img>
                    </div>}
                </div>
                <Pane title={t('Guild.CreateAgency.AgencyName')}>
                    <div className='input-name'>
                        <input type="text" value={state.guildName} onChange={(e) => handleNameChange(e)} placeholder={t('Guild.CreateAgency.AgencyName').toString()} />
                    </div>
                </Pane>
                <Pane title={t('Guild.CreateAgency.AgencySlogan')}>
                    <div className='input-slogan'>
                        <TextArea
                            placeholder={t('Guild.CreateAgency.AgencySlogan').toString()}
                            value={state.guildSlogan}
                            onChange={val => {
                                setState(prevState => ({
                                    ...prevState,
                                    guildSlogan: val
                                }))
                            }}
                        />
                    </div>
                </Pane>
                <Pane title={t('Guild.CreateAgency.AgencyIntroducer')}>
                    {guildInfo?.guildNo ? (<div className='user-info'>
                        <img className='avatar' src={guildInfo.coverPicUrl}></img>
                        <div className="info">
                            <span>{guildInfo.guildName}</span>
                            <div className="middle">
                                <span>ID:{guildInfo.guildNo}</span>
                                <div className='people-icon'>
                                    <img src={ImgPeople} alt="" />
                                    <span>{guildInfo.memberCount}</span>
                                </div>
                            </div>
                            <span>{guildInfo.guildSlogan}</span>
                        </div>
                        <div className='cancel' onClick={handleClearGuildInfo}></div>
                    </div>) : <div className="user-info upload">
                        <div className="btn" onClick={handleGuildInfoUpload}>
                            <img src={ImgTen}></img>
                        </div>
                    </div>}
                </Pane>
                <button className={clsx('create-btn', { 'create-activate-btn': isCanCreate })} disabled={!isCanCreate} onClick={handleConfirm}>
                    <span>{t('Guild.CreateAgency.CreateAgency')}</span>
                    <img src={ImgCoin}></img>
                    <span>{goldNum}</span>
                </button>
                <SafeArea position='bottom' />
            </div>
        </div>
    )
}

export default CreateAgencySubmit