import React, { useCallback } from 'react'
import './Warning.scss';
import { useNavigate, useParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import StatusUserInfo from '@/pages/AgentRecharge/components/StatusUserInfo';
import { getUserInfo } from '@/utils/bridge';
function Warning() {
    const navigate = useNavigate()
    const { appID, inputNum } = useParams()
    const goDetails = useCallback(async () => {
        const auth = await getUserInfo()
        navigate(`/guild/wallet-data/member-detail/${auth?.uid}?title=1`)
    }, [])
    return (
        <div className='pg-anchorman-wallet-detail-warning pg-fa'>
            <Status title='Wallet.AgencyGemAndCash' status='warning' paneTitle='Wallet.CashExchangeWarningTips' goDetails={goDetails}>
                <StatusUserInfo state={{ appID, inputNum }}></StatusUserInfo>
            </Status>
        </div >
    )
}

export default Warning