import { Helmet } from 'react-helmet-async'

export type MetaProps = {
  title?:string
}

export default function Meta(props:MetaProps) {
  const { title } = props

  return <Helmet>
    <title>{ title || 'Privacy' }</title>
    {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0"></meta> */}
  </Helmet>
}