import React, { useCallback, useEffect, useState } from 'react'
import './CreateAdvocacySecond.scss'
import { useTrans } from '@/hooks/useTranslation'
import Pane from '@/components/Pane';
import ImgDiamondBling from '@/images/diamond-bling.png';
import { toast } from '@/utils/bridge';
import { getGuildCreateConf } from '@/utils/request';
import { useDesign } from '@/hooks/useDesign';

const { prefixCls } = useDesign('cmp-guild-create-advocacy-second')

interface PropsType {
    handleNext: () => void
    handlePrevious: () => void
}

function CreateAdvocacySecond({ handleNext, handlePrevious }: PropsType) {
    const { t } = useTrans()

    const [state, setState] = useState<Guild.GuildCreateConf>()

    const fetchGetGuildCreateConf = useCallback(async () => {
        const { code, message, data } = await getGuildCreateConf() as Response<Guild.GuildCreateConf>
        if (code !== 200) {
            toast(message)
            return
        }
        setState(data || {})
    }, [])
    useEffect(() => {
        fetchGetGuildCreateConf()
    }, [])
    return (
        <div className={`${prefixCls}`}>
            <main>
                <Pane title={t('Guild.CreateAgency.NewAgencyAssessmentCriteria')} className='pane-prefix'>
                    <span className={`${prefixCls}__title-info`}>{t('Guild.CreateAgency.NewAgencyAssessmentCriteriaInfo')}</span>
                </Pane>
                <Pane title={t('Guild.CreateAgency.AssessmentPeriodConditions')} className='pane-prefix'>
                    <div className={`${prefixCls}__conditions`}>
                        <div className={`${prefixCls}__conditions-title`}>
                            {t('Guild.CreateAgency.AssessmentTime')}
                        </div>
                        <span className={`${prefixCls}__conditions-days`}>{state?.guildAssessmentDate} {t('Guild.CreateAgency.days')}</span>
                        <div className={`${prefixCls}__conditions-line`}></div>
                        <div className={`${prefixCls}__conditions-title`}>
                            {t('Guild.CreateAgency.YouCanPassFollowingConditions')}
                        </div>
                        <span className={`${prefixCls}__conditions-task`}>{t('Guild.CreateAgency.Task1')}</span>
                        <div className={`${prefixCls}__conditions-hosts`}>
                            -<span>{state?.guildAssessmentAddAnchorNum}</span>{t('Guild.CreateAgency.RecruitingHostsIncludingAgents')}
                        </div>
                        <div className={`${prefixCls}__conditions-gems`}>
                            -<span className={`${prefixCls}__conditions-diamondNum`}>{state?.guildAssessmentReciveGiftNum}</span><img className={`${prefixCls}__conditions-gems-img`} src={ImgDiamondBling} alt="" />：{t('Guild.CreateAgency.TheGemsReceivedByEntire')}
                        </div>
                        <span className={`${prefixCls}__conditions-task`}>{t('Guild.CreateAgency.Task2')}</span>
                        <div className={`${prefixCls}__conditions-gems`}>
                            -<span className={`${prefixCls}__conditions-diamondNum`}>{state?.guildAssessmentReciveGiftNum2}</span><img className={`${prefixCls}__conditions-gems-img`} src={ImgDiamondBling} alt="" />：{t('Guild.CreateAgency.TheGemsReceivedByEntire')}
                        </div>
                        <div className={`${prefixCls}__conditions-line`}></div>
                        <div className={`${prefixCls}__conditions-title`}>
                            {t('Guild.CreateAgency.Rewards')}:
                        </div>
                        <div className={`${prefixCls}__conditions-rewards`}>
                            <img src={state?.guildAssessmentVipRewardIcon} alt="" className={`${prefixCls}__conditions-rewards-vip`} />&nbsp;+&nbsp;<span className={`${prefixCls}__conditions-diamondNum`}>{state?.guildAssessmentReward}</span><img className={`${prefixCls}__conditions-gems-img`} src={ImgDiamondBling} alt="" />
                        </div>
                    </div>
                </Pane>
                <Pane title={t('Guild.CreateAgency.CanNotPass')} className='pane-prefix'>
                    <span className={`${prefixCls}__title-info`}>{t('Guild.CreateAgency.YourAgencyWillBeDissolved')}</span>
                </Pane>
            </main>
            <div className={`${prefixCls}__btns`}>
                <div onClick={handlePrevious}>{t('Common.Previous')}</div>
                <span></span>
                <div onClick={handleNext}>{t('Common.Next')}</div>
            </div>
        </div>
    )
}

export default CreateAdvocacySecond