import React, { useCallback, useEffect, useState } from 'react'

import WalletHeader from '@/pages/Wallet/components/Header';
import Pane from '@/components/Pane';
import Highlight from '@/components/Highlight';

import { useTrans } from '@/hooks/useTranslation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageConst } from '@/utils/constant';
import { openPage, toast } from '@/utils/bridge'
import { getUsersListV2 } from '@/services/agentRecharge'
import { debounce } from 'lodash-es';

import ImageArrow from '@/images/arrow.png'

import './PrivateWallet.scss'

function PrivateWallet() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [params] = useSearchParams()

    const [state, setState] = useState<{ usersListV2: AgentRecharge.UsersListV2[], withdrawalFee: string }>({
        usersListV2: [],
        withdrawalFee: ''
    })

    const goToIM = useCallback(debounce((item: AgentRecharge.UsersListV2) => {
        // 需要传 uid、昵称、imid
        openPage(PageConst.IMChat, item.uid, { receiveName: item.nick, receiveImId: item.easeMobId })
    }, 300), [])


    const fetchGetUsersListV2 = useCallback(async () => {
        const res = await getUsersListV2()

        const { code, message, data } = res as Response<AgentRecharge.UsersListV2[]>
        if (code !== 200) {
            toast(message)
            return
        }

        setState(prevState => ({
            ...prevState,
            usersListV2: data
        }));
    }, [])

    useEffect(() => {
        fetchGetUsersListV2()
    }, [])

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            withdrawalFee: params.get('withdrawalFee') || '0'
        }));
    }, [params])
    return (
        <div className='pg-private-wallet'>
            <WalletHeader title='Wallet.Withdraw.Title' jumpText={'Wallet.Withdraw.Withdraw'} jumpTextClick={() => navigate('/wallet/withdraw')}></WalletHeader>
            <div className="pg-private-wallet-content">
                <Pane title={t('Wallet.Notices')} className='pane-prefix'>
                    <div className="pg-private-wallet-content__notices">
                        <span className="pg-private-wallet-content__notices-title">
                            {t('Wallet.Withdrawal')}
                        </span>
                        <span className="pg-private-wallet-content__notices-fee">
                            {t('Wallet.Fee', { fee: `${(Number(state.withdrawalFee) * 100).toFixed(0)}%` })}
                        </span>
                        <Highlight keys={['7']} color="#FFAF00" >
                            {t('Wallet.And7WorkingDaysProcessing')}
                        </Highlight>
                        <div className="pg-private-wallet-content__notices-line"></div>
                        <span className="pg-private-wallet-content__notices-title">
                            {t('Wallet.2ShippingAgent')}
                        </span>
                        <span className="pg-private-wallet-content__notices-fee">
                            {t('Wallet.0Fee')}
                        </span>
                        <Highlight keys={['3']} color="#FFAF00" >
                            {t('Wallet.And3WorkingDaysProcessing')}
                        </Highlight>
                        <div className="pg-private-wallet-content__notices-line"></div>
                        <span className="pg-private-wallet-content__notices-tips">{t('Wallet.WithdrawingMoneyByYourself')}</span>
                    </div>
                </Pane>
                <Pane title={t('Wallet.ShippingAgent')} className='pane-prefix'>
                    <div className="pg-private-wallet-content__shipping">
                        {state.usersListV2.map((item) => {
                            return <div onClick={() => goToIM(item)} key={item.uid} className="pg-private-wallet-content__shipping-item">
                                <div className="pg-private-wallet-content__shipping-item__top">
                                    <img className="pg-private-wallet-content__shipping-item__avatar" src={item.avatar} alt="" />
                                    <div>
                                        <span>{item.nick}</span>
                                        <span>ID:{item.erbanNo}</span>
                                    </div>
                                    <img className="pg-private-wallet-content__shipping-item__arrow" src={ImageArrow} alt="" />
                                </div>
                                <div className="pg-private-wallet-content__shipping-item__bottom">
                                    <span>{t('Wallet.SupportCountry')}</span>
                                    {item.agencyCountryList?.map((item, i) => {
                                        return <div key={i} className="pg-private-wallet-content__shipping-item__country">
                                            <img src={item.countryNationalFlag} alt="" />
                                            <span>({item.countryCode})</span>
                                        </div>
                                    })}
                                </div>

                            </div>
                        })}
                    </div>
                </Pane>
            </div>
        </div>
    )
}

export default PrivateWallet
