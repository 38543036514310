import { useEffect, useState } from 'react'
import ImageListEmpty from '@/images/list-empty.png'
import moment from 'moment';
import { useTrans } from '@/hooks/useTranslation';
import './AgencyRewardIncomeTable.scss'

type PropsType = {
  items: Guild.GuildMemberGemIncomeList[]
}

export default function AgencyRewardIncomeTable(props: PropsType) {
  const { t } = useTrans()
  const [list, setList] = useState<Guild.GuildMemberGemIncomeList[]>([])

  useEffect(() => {
    setList(props.items)
  }, [props])

  return (
    <div className="cmp-agency-reward-income-table">
      <table>
        <thead>
          <tr>
            <th>{t('Common.Date')}</th>
            <th>{t('Common.Member')}</th>
            <th>
              <div className='day-hours'>
                <span>{t('Common.Day')}/</span>
                <span>{t('Common.Hours')}</span>
              </div>
            </th>
            <th>{t('Common.Supporters')}</th>
            <th>{t('Guild.AgencyReward.GiftGem')}</th>
          </tr>
        </thead>
        <tbody>
          {list.length !== 0 ? list.map((item, i) => (
            <tr key={i}>
              <td>{moment(item?.statisticsTime).format('YYYY-MM-DD')}</td>
              <td>
                <div className='member'>
                  <span>{item?.memberNick}</span>
                  <span>ID:{item?.memberErbanNo}</span>
                </div>
              </td>
              <td>
                <div className='day-hours'>
                  <span>{item?.whetherValidDays ? 1 : 0}d/</span>
                  <span>{item?.micBroadcastDuration === 0 ? 0 : (item?.micBroadcastDuration / 60).toFixed(1)}h</span>
                </div>
              </td>
              <td>{item.supportersNum}</td>
              <td>
                <div><span>+{item.diamondNum}</span><div className='img'></div></div>
              </td>
            </tr>
          ))
            :
            <>
              <tr>
                <td colSpan={5} rowSpan={2}>
                  <div className='empty'>
                    <img src={ImageListEmpty}></img>
                    <span>{t('Common.NoData')}</span>
                  </div>
                </td>
              </tr>
            </>
          }
        </tbody>
      </table>
    </div>
  )
}
