import { Modal } from 'antd-mobile'
import { useTrans } from '@/hooks/useTranslation';
import './DetailDialog.scss';

interface PropsType {
    visible: boolean
    setVisible: (visible: boolean) => void
    handleApplyGuild: (isJoin: boolean) => void
}
function DetailDialog({ visible, setVisible, handleApplyGuild }: PropsType) {
    const { t } = useTrans()
    return <>
        <Modal
            visible={visible}
            content={
                <>
                    <div className='detail__dialog-content'>
                        {new Array(3).fill(0).map((_, i) => (
                            <div className="dialog-item" key={i}>
                                {t(`Guild.JoinAgencyTips${i + 1}`)}
                            </div>
                        ))}
                        <div className="dialog-btn">
                            <div className="cancel" onClick={() => setVisible(false)}>{t('Common.Cancel')}</div>
                            <div className="activate-btn" onClick={() => handleApplyGuild(true)}>{t('Common.Join')}</div>
                        </div>
                    </div>
                </>}
            title={t('Guild.JoinAgencyTipsTitle')}
        />
    </>
}

export default DetailDialog