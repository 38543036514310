import { Calendar, Popup } from 'antd-mobile'
import { memo, useState } from 'react'
import ImageCalendar from '@/images/calendar2.png'
import ImageDelta from '@/images/delta.png'
import './SelectDayTime.scss'
import moment from 'moment'

type SelectTimeRangeProps = {
  time: string
  setTime: (time: string) => void
}
function SelectDayTime({ time, setTime }: SelectTimeRangeProps) {
  const [visible, setVisible] = useState(false)

  return <>
    <div className='cmp-select-day-time' onClick={() => setVisible(true)}>
      <img className='cmp-select-day-time__calendar' alt='delta' src={ImageCalendar} />
      <span className='cmp-select-day-time__time'>{`(${time})`}</span>
      <img className='cmp-select-day-time__delta' alt='delta' src={ImageDelta} />
    </div>
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false)
      }}
      onClose={() => {
        setVisible(false)
      }}
    >
      <Calendar
        value={new Date(time)}
        selectionMode='single'
        defaultValue={new Date(time)}
        onChange={val => {
          if (!val) return
          setVisible(false)
          setTime(moment(val).format("YYYY-MM-DD"))
        }}
      />
    </Popup>
  </>
}

export default memo(SelectDayTime)