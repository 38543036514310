import React, { useCallback, useRef } from 'react'
import Header from '@/components/Header'
import './CreateAdvocacy.scss'
import { useTrans } from '@/hooks/useTranslation'
import { useNavigate, useSearchParams } from 'react-router-dom';
import CreateAdvocacyFirst from './Components/CreateAdvocacyFirst';
import CreateAdvocacySecond from './Components/CreateAdvocacySecond';
import CreateAdvocacyThird from './Components/CreateAdvocacyThird';
import { Swiper } from 'antd-mobile';
import { useRefState } from '@/hooks/useRefState';

function CreateAdvocacy() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const refSwiper = useRef<React.ElementRef<typeof Swiper>>(null)

    const [swiperTab, setSwiperTab, refSwiperTab] = useRefState(0)

    const handleNext = useCallback(() => {
        if (refSwiperTab.current < 2) {
            setSwiperTab(++refSwiperTab.current)
            refSwiper.current?.swipeTo(refSwiperTab.current)
            return
        } else {
            const url = searchParams.get('inviteUid') ? `/guild/create/submit?inviteUid=${searchParams.get('inviteUid')}` : '/guild/create/submit'
            navigate(url)
        }
    }, [])

    const handlePrevious = useCallback(() => {
        if (refSwiperTab.current > 0) {
            setSwiperTab(--refSwiperTab.current)
            refSwiper.current?.swipeTo(refSwiperTab.current)
        }
    }, [])

    return (
        <div className='pg-guild-create-advocacy'>
            <Header
                title={t('Guild.CreateAgency.Title').toString()}
            />
            {swiperTab === 0 && <CreateAdvocacyFirst  {...{ handleNext }} />}
            {swiperTab === 1 && <CreateAdvocacySecond {...{ handleNext, handlePrevious }} />}
            {swiperTab === 2 && <CreateAdvocacyThird {...{ handleNext, handlePrevious }} />}
        </div>
    )
}

export default CreateAdvocacy