import { memo } from 'react'
import clsx from 'clsx'
import { useTrans } from '@/hooks/useTranslation'
import './TabButtons.scss'

interface PropsType {
    titleItems: string[]
    selectedTab: number
    setSelectedTab: (selectedTab: number) => void
}
function TabButtons({ titleItems, selectedTab, setSelectedTab }: PropsType) {
    const { t } = useTrans()
    return (
        <div className='cmp-guild-tab-buttons'>
            <div className="cmp-guild-tab-buttons__tab">
                {titleItems.map((name, i) => (
                    <div
                        className={clsx('cmp-guild-tab-buttons__tab-item', {
                            selected: selectedTab === i,
                            scroll: titleItems.length > 3
                        })}
                        onClick={() => setSelectedTab(i)}
                        key={i}>
                        <div className="tab-item__name">{t(name)}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default memo(TabButtons)