import { memo, useCallback } from 'react'
import clsx from 'clsx'
import ImgExpected from '@/pages/Guild/images/expected.webp';
import ImgReceived from '@/pages/Guild/images/received.webp';
import { useTrans } from '@/hooks/useTranslation';
import './CalculateData.scss'

interface PropsType {
    className?: string
    calculateData: { key: string, value: string | number }[]
    status?: {
        expected: boolean
    }
    calculateItemIndex?: number
    setCalculateItemIndex?: (i: number) => void
}
function CalculateData(props: PropsType) {
    const { t } = useTrans()
    const handleItemClick = useCallback((i: number) => {
        if (i === 0) return
        props.setCalculateItemIndex && props.setCalculateItemIndex(i)
    }, [props])
    return (
        <div className={clsx('cmp-guild-wallet-data-calculate-data', props.className)}>
            <div className="content">
                {props.calculateData.map((item, i) => (
                    <div className={clsx("item", { selected: props.calculateItemIndex === i })} key={i} onClick={() => handleItemClick(i)}>
                        <div className="top">
                            <span>{item.value}</span>
                        </div>
                        <span className="bottom">{t(item.key)}</span>
                    </div>
                ))}
            </div>
            {props.status &&
                <div className="status">
                    <img src={props.status.expected ? ImgExpected : ImgReceived}></img>
                    <span>{t(`Guild.AgencyReward.${props.status.expected ? 'Expected' : 'Received'}`)}</span>
                </div>
            }
        </div>
    )
}

export default memo(CalculateData)