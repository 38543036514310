import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import Status from '@/pages/Wallet/components/Status';
import Card from './Card';
import { getUserInfo, toast } from '@/utils/bridge';
import { getWithdrawalAccount, getWithdrawalConf } from '@/services/wallet';
import clsx from 'clsx';
import ImgMoney from '@/images/money.webp';
import { useSearchParams } from 'react-router-dom';
import { useTrans } from '@/hooks/useTranslation';
import './Warning.scss';

function Warning() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const paramsInputNum = useRef(params.get('inputNum'))
    paramsInputNum.current = params.get('inputNum')

    const [state, setState] = useState<{
        withdrawalFee: number
        withdrawalArrival: number
    } & Wallet.WithdrawalAccount>()
    const goDetails = useCallback(async () => {
        const auth = await getUserInfo()
        navigate(`/guild/wallet-data/member-detail/${auth?.uid}?title=1`)
    }, [])
    /*获取cash配置、当前用户提现账号信息 */
    const fetchHomeData = useCallback(async () => {
        const [confData, withdrawalAccountData]: [Response<Wallet.WithdrawalConf>, Response<Wallet.WithdrawalAccount>] = await Promise.all([
            getWithdrawalConf(),
            getWithdrawalAccount()
        ])

        if (confData.code !== 200) {
            toast(confData.message)
            return
        }
        if (withdrawalAccountData.code !== 200) {
            toast(withdrawalAccountData.message)
            return
        }
        setState({
            ...withdrawalAccountData.data,
            ...confData.data
        })
    }, [])
    useEffect(() => {
        fetchHomeData()
    }, [])
    return (
        <div className='pg-wallet-withdraw-warning pg-fa'>
            <Status title='Wallet.Withdraw.Title' status='warning' paneTitle='Wallet.Withdraw.WarningTips' goDetails={goDetails}>
                <div className={clsx('by-myself', { hide: false })}>
                    {state &&
                        <Card hideEdit={true} cardInfo={{ ...state }} />
                    }
                    <div className='money-info'>
                        <img src={ImgMoney}></img>
                        <span>{paramsInputNum.current}</span>
                    </div>
                    {state &&
                        <div className="fee">
                            <div>{t('Wallet.Withdraw.Fee', { fee: state.withdrawalFee * 100 + '%' })}</div>
                            <div>{t('Wallet.Withdraw.ArrivalDays', { day: state.withdrawalArrival })}</div>
                        </div>
                    }
                </div>
                {/* <div className='by-agency'>
                    <Card status={1} />
                </div> */}
            </Status>
        </div >
    )
}

export default Warning