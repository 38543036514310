import { HelmetProvider } from 'react-helmet-async'
import { useTrans } from '@/hooks/useTranslation'
import Meta from '../../components/Meta'
import './Turntable.scss'

export default function RuleTurntable() {
  const { t } = useTrans()

  return (
    <HelmetProvider>
      <Meta title={t('Guild.Level.UserLevelDescription').toString()} />
      <div className="pg-rule-turntable">
        {['Rule1', 'Rule2', 'Rule3', 'Rule4', 'Rule5', 'Rule6'].map((key) => (
          <div className="pg-rule-turntable__item" key={key}>
            {t(`Turntable.Rule.${key}`)}
          </div>
        ))}
      </div>
    </HelmetProvider>
  )
}
