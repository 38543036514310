import { useCallback, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import MemberCard from '@/pages/Guild/Components/MemberCard'
import { useNavigate } from 'react-router-dom';
import SafeArea from '@/components/SafeArea';
import { getGuildMemberMonthBill } from '@/utils/request';
import { toast } from '@/utils/bridge'
import Loading from '@/components/Loading'
import NumberCard from '../Components/NumberCard'
import ImgGem from '@/images/gem.png';
import ImgMoney from '@/images/money.webp';
import ScrollList from '@/components/ScrollList'
import { useTrans } from '@/hooks/useTranslation'
import './MemberList.scss'

function MemberList() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [memberBillList, setMemberBillList] = useState<{
        list: Guild.GuildMemberMonthBill[]
        page: number
        hasMore: boolean
        empty: boolean
    }>({
        list: [],
        page: 1,
        hasMore: true,
        empty: false
    })
    const refMemberBillList = useRef(memberBillList)
    refMemberBillList.current = memberBillList

    const fetchMemberMonthBill = useCallback(async () => {
        if (!refMemberBillList.current.hasMore) return
        setLoading(true)
        const { code, data, message } = await getGuildMemberMonthBill(refMemberBillList.current.page) as Response<PageList<Guild.GuildMemberMonthBill>>
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setMemberBillList({
            ...refMemberBillList.current,
            list: [
                ...(refMemberBillList.current.page === 1 ? [] : refMemberBillList.current.list),
                ...(data.list || [])
            ],
            page: data.whetherLastPage ? refMemberBillList.current.page : refMemberBillList.current.page + 1,
            hasMore: !data.whetherLastPage
        })
    }, [])
    // useEffect(() => {
    //     fetchMemberMonthBill()
    // }, [])
    const handleDetail = useCallback((memberId: string) => {
        navigate('/guild/wallet-data/member-detail/' + memberId)
    }, [])
    return (
        <HelmetProvider>
            <Meta title={t('Guild.WalletData').toString()} />
            <div className='pg-guild-wallet-data-member-list'>
                <Header title={t('Guild.WalletData').toString()} />
                <div className="content">
                    <ScrollList hasMore={memberBillList.hasMore} loadMore={fetchMemberMonthBill} empty={memberBillList.empty}>
                        {memberBillList.list.map((item, i) =>
                            <div className="list-item" key={i}>
                                <MemberCard
                                    memberId={Number(item.uid)}
                                    avatar={item.avatar}
                                    username={item.nick}
                                    erbanNo={item.erbanNo}
                                    rightBtn={
                                        {
                                            text: 'Common.Details',
                                            onRightBtn: () => handleDetail(item.uid)
                                        }
                                    }
                                />
                                <div className='balance'>
                                    <div className="left">
                                        <span>{t('Guild.GemsBalance')}</span>
                                        <img src={ImgGem} alt="" />
                                        <span>{item.gemBalance}</span>
                                    </div>
                                    <div className='line'></div>
                                    <div className="right">
                                        <span>{t('Wallet.CashBalance')}</span>
                                        <img src={ImgMoney} alt="" />
                                        <span>{item.cashBalance}</span>
                                    </div>
                                </div>
                                <NumberCard
                                    items={[
                                        {
                                            key: 'Guild.TodaysGemIncome',
                                            val: `+${item.todayGemIncome || 0}`
                                        },
                                        {
                                            key: 'Guild.YesterdaysGemIncome',
                                            val: `+${item.yesDayGemIncome || 0}`
                                        },
                                        {
                                            key: 'Guild.ThisMonthlyGemIncome',
                                            val: `+${item.thisMonthDiamondIncome || 0}`
                                        }
                                    ]}
                                />
                                <NumberCard
                                    class="last"
                                    items={[
                                        {
                                            key: 'Guild.TodaysCashIncome',
                                            val: `+${item.todayCashIncome || 0}`
                                        },
                                        {
                                            key: 'Guild.YesterdaysCashIncome',
                                            val: `+${item.yesDayCashIncome || 0}`
                                        },
                                        {
                                            key: 'Guild.ThisMonthlyCashIncome',
                                            val: `+${item.thisMonthCashAmountIncome || 0}`
                                        }
                                    ]}
                                />
                            </div>)}
                    </ScrollList>
                </div>
                <SafeArea position='bottom'></SafeArea>
            </div>
            {loading && <Loading />}
        </HelmetProvider>

    )
}

export default MemberList