import { HelmetProvider } from "react-helmet-async"
import Meta from "../../components/Meta"
import InfoList from "./Components/InfoList"
import GuildHeader from './Components/GuildHeader'
import ImageGuildCup from '../../images/guild-cup.png'
import ImageGuildMembers from '../../images/guild-members.png'
import ImageGuildFire from '../../images/guild-fire.png'
import './CreateAudit.scss'
import { useCallback, useEffect, useState } from "react"
import { getGuildApplyInfo } from "../../utils/request"
import GuildLoading from "../../components/Loading"
import clsx from "clsx"
import { toast, openPage } from "../../utils/bridge"
import { useTrans } from "@/hooks/useTranslation"
import SafeArea from "../../components/SafeArea"
import { PageConst } from '@/utils/constant';
export default function CreateAudit() {
  const { t } = useTrans()
  const [loading, setLoading] = useState(true)
  const [guild, setGuild] = useState<Guild.GuildApplyInfo>()

  const fetchGuildApplyInfo = useCallback(async () => {
    const { code, message, data } = await getGuildApplyInfo() as Response<Guild.GuildApplyInfo>
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }
    setGuild(data)
  }, [])
  useEffect(() => {
    fetchGuildApplyInfo()
  }, [fetchGuildApplyInfo])
  const backArrowClick = useCallback(() => {
    openPage(PageConst.Mine)
  }, [openPage])
  return <HelmetProvider>
    <Meta title={t("Guild.Tips.GuildPage").toString()} />
    <div className={clsx("pg pg-guild-create-audit", {
      loading
    })}>
      <GuildHeader
        title={t("Guild.Tips.GuildPage").toString()}
        guild={guild}
        backArrowClick={backArrowClick}
        infoCardItems={[
          { image: ImageGuildCup, text: '-' },
          { image: ImageGuildMembers, text: '-' },
          { image: ImageGuildFire, text: '-' },
        ]} />
      <InfoList items={[
        { key: 'Founder', val: guild?.guildNo || '' }
      ]} />
      <SafeArea position="bottom" />
      <div className="pg-guild-create-audit__bottom">
        <div className="pg-guild-create-audit__btn-apply">
          {t('Guild.UnderReview')}
        </div>
        <SafeArea position="bottom" />
      </div>
      {loading && <GuildLoading />}
    </div>
  </HelmetProvider>
}
