import { service } from '../utils/net'

// 获取赠予友情卡列表
export function getGiveList() {
  return service<Response<LoveCard.GiveList>>({
    method: 'GET',
    url: '/intimate/pay/gifted/card'
  }).then((res) => res.data)
}

// 获取赠予友情卡列表
export function getMyCard() {
  return service<Response<LoveCard.Item>>({
    method: 'GET',
    url: '/intimate/pay/own/card'
  }).then((res) => res.data)
}

// 获取友情卡详情
export function getDetail(cardId: string) {
  return service<Response<LoveCard.Item>>({
    method: 'GET',
    url: '/intimate/pay/card/info',
    params: { cardId }
  }).then((res) => res.data)
}

// 获取可赠予用户列表
export function getFriends() {
  return service<Response<LoveCard.FriendResponse>>({
    method: 'GET',
    url: '/intimate/pay/recommend'
  }).then((res) => res.data)
}

// 赠送亲密卡
export function giveCard(params: LoveCard.CreateParams) {
  return service<Response<LoveCard.FriendResponse>>({
    method: 'POST',
    url: '/intimate/pay/give/card',
    params
  }).then((res) => res.data)
}

// 获取卡片消费记录
export function getCardConsumeRecords(params: LoveCard.ComsumeRecordsParams) {
  return service<Response<LoveCard.ComsumeRecordResponse>>({
    method: 'POST',
    url: '/intimate/pay/card/consume/record',
    params
  }).then((res) => res.data)
}

// 解绑
export function unbind(cardId: number) {
  return service<Response<LoveCard.ComsumeRecordResponse>>({
    method: 'POST',
    url: '/intimate/pay/unbind/card',
    params: {
      cardId
    }
  }).then((res) => res.data)
}

// 修改亲密卡
export function modifyCard(cardId: string, goldLimit: number) {
  return service<Response<LoveCard.ComsumeRecordResponse>>({
    method: 'POST',
    url: '/intimate/pay/update/card',
    params: {
      cardId,
      goldLimit
    }
  }).then((res) => res.data)
}
