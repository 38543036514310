import React, { useCallback } from 'react'
import './CashTransferSuccess.scss';
import { useNavigate, useParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import StatusUserInfo from './components/StatusUserInfo';
import { getUserInfo } from '@/utils/bridge';

function CashTransferSuccess() {
    const navigate = useNavigate()
    const { appID, inputNum } = useParams()
    const goDetails = useCallback(async () => {
        const auth = await getUserInfo()
        navigate(`/guild/wallet-data/member-detail/${auth?.uid}?title=1`)
    }, [])
    const goToWallet = useCallback(() => {
        navigate("/wallet/anchorman-wallet-detail")
    }, [])
    return (
        <div className='agent-recharge-cash-transfer-success pg-fa'>
            <Status successRightBtnClick={goToWallet} successRightBtnText='Wallet.GoToWallet' title='AgentRecharge.CashTransfer' status='success' successText='AgentRecharge.SendSuccess' paneTitle='Wallet.CheckAccountSend' goDetails={goDetails}>
                <StatusUserInfo state={{ appID, inputNum }}></StatusUserInfo>
            </Status>
        </div >
    )
}

export default CashTransferSuccess