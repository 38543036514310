import React, { memo, useCallback } from 'react'
import { Popup } from 'antd-mobile'
import { px2rem } from '@/utils/tool';
import ImageArrowGray from '@/images/arrow-gray.png'
import { useTrans } from '@/hooks/useTranslation';
import './AnchormanWalletDetailPopup.scss';

type PropsType = {
    visible: boolean
    setVisible: (visible: boolean) => void
    title: string
    titleExtra?: string
    arrow?: boolean
    children: React.ReactNode
    handleBack?: () => void
    handleCancel?: () => void
}
function AnchormanWalletDetailPopup({ visible, setVisible, children, title, titleExtra, handleBack, handleCancel, arrow }: PropsType) {
    const { t } = useTrans()
    const handleThisCancel = useCallback(() => {
        handleCancel && handleCancel()
        setVisible(false)
    }, [handleCancel])
    return (
        <div>
            <Popup
                visible={visible}
                className="pg-wallet-anchorman-detail cmp-anchorman-wallet-detail-popup"
                bodyStyle={{
                    backgroundColor: '#fff',
                    borderTopLeftRadius: px2rem(12),
                    borderTopRightRadius: px2rem(12)
                }}>
                <div className="cmp-anchorman-wallet-detail-popup-header">
                    <div className="cmp-anchorman-wallet-detail-popup-title"><span>{t(title)}</span><span>{titleExtra}</span></div>
                    <div className="cmp-anchorman-wallet-detail-popup-cancel" onClick={handleThisCancel}>
                        {t('Common.Cancel')}
                    </div>
                    {arrow && <img alt="arrow" src={ImageArrowGray} className='cmp-anchorman-wallet-detail-popup-arrow' onClick={handleBack} />}
                </div>
                <div className="cmp-anchorman-wallet-detail-popup-content">
                    {children}
                </div>
            </Popup>
        </div>
    )
}

export default memo(AnchormanWalletDetailPopup)