import React from 'react'
import './CreateAdvocacyThird.scss'
import { useTrans } from '@/hooks/useTranslation'
import Pane from '@/components/Pane';
import Highlight from '@/components/Highlight';
import clsx from 'clsx';
import { useDesign } from '@/hooks/useDesign';

const { prefixCls, createClass } = useDesign('cmp-guild-create-advocacy-third')

interface PropsType {
    handleNext: () => void
    handlePrevious: () => void
}

function CreateAdvocacyThird({ handleNext, handlePrevious }: PropsType) {
    const { t } = useTrans()
    return (
        <div className={`${prefixCls}`}>
            <main>
                <Pane title={t('Guild.CreateAgency.AgentRightsAndInterests')} className='pane-prefix'>
                    <div className={createClass('__interests')}>
                        <span className={createClass('__interests-title')}>
                            {t('Guild.CreateAgency.BasicRightsAndInterests')}
                        </span>
                        <span className={createClass('__interests-info')}>
                            <Highlight keys={['20%']} color="#FFAF00" className={createClass('__bold')}>
                                {t('Guild.CreateAgency.UpToShareAgencyGems1')}
                            </Highlight>
                        </span>
                        <div className={createClass('__interests-line')}></div>
                        <span className={createClass('__interests-title')}>
                            {t('Guild.CreateAgency.ActivityRightsAndInterests')}
                        </span>
                        {new Array(5).fill(0).map((_, i) => {
                            return <span key={i} className={clsx(createClass('__interests-info'), { 'mb-unset': i === 4 })}>
                                <Highlight keys={['20%', '15%', '18%']} color="#FFAF00" className={createClass('__bold')}>
                                    {t(`Guild.CreateAgency.ActivityRightsAndInterests${i}`)}
                                </Highlight>
                            </span>
                        })}
                    </div>
                </Pane>
                <span className={createClass('__tips')}>{t('Guild.CreateAgency.ForMorePleaseStuffs')}</span>
                {/* <span className={createClass('__tips')} dangerouslySetInnerHTML={{ __html: t('Guild.CreateAgency.WhatsApp') }}>
                </span> */}
                <span className={createClass('__tips')}>
                    <Highlight keys={['1000']} color="#fff" className={clsx(createClass('__bold'), createClass('__no-margin'))}>
                        {t('Guild.CreateAgency.CustomerServiceRoom')}
                    </Highlight>
                </span>
            </main>
            <div className={createClass('__btns')}>
                <div onClick={handlePrevious}>{t('Common.Previous')}</div>
                <span></span>
                <div onClick={handleNext}>{t('Common.Next')}</div>
            </div>
        </div>
    )
}

export default CreateAdvocacyThird