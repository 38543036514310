import React, {
  forwardRef,
  memo, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react'
import SelectedIcon from '@/images/wallet/selected.png'
import UnselectIcon from '@/images/wallet/unselect.png'
import { isEqual } from 'lodash-es';
import { useTrans } from '@/hooks/useTranslation'
import './TargetOrg.scss'

type TargetOrgRefType = {
  targetOrgId: number
  targetOrgName: string
}

type PropsType = {
  targetOrgName: string
  targetOrgList: Wallet.BankTransferTargetOrg[]
  onCancel?: () => void
}

function TargetOrg(props: PropsType, ref: any) {
  const { t } = useTrans()
  const [targetOrg, setTargetOrg] = useState<Wallet.BankTransferTargetOrg>()
  const refTargetOrg = useRef(targetOrg)
  refTargetOrg.current = targetOrg

  const [targetOrgList, setTargetOrgList] = useState<Wallet.BankTransferTargetOrg[]>([])

  const handleSelect = useCallback((item: Wallet.BankTransferTargetOrg) => {
    setTimeout(() => {
      props.onCancel && props.onCancel()
    }, 0)
    if (isEqual(refTargetOrg.current, item)) return
    setTargetOrg({
      ...refTargetOrg.current,
      ...item
    })
  }, [props])

  useEffect(() => {
    setTargetOrgList(props.targetOrgList)
    setTargetOrg({
      targetOrgId: 0,
      targetOrgName: props.targetOrgName
    })
  }, [props])

  useImperativeHandle(ref, () => targetOrg)

  return (
    <div className="cmp-collection-information-target-org">
      <div className="cmp-collection-information-target-org-title">{t('Wallet.BankName')}</div>
      <div className="cmp-collection-information-target-org-list">
        {targetOrgList.map((item, i) => (
          <div
            key={i}
            className="cmp-collection-information-target-org-item"
            onClick={() => handleSelect(item)}>
            <div className="cmp-collection-information-target-org-item-left">
              <span>{item.targetOrgName}</span>
            </div>
            <img
              className="cmp-collection-information-target-org-item-select"
              alt="img"
              src={
                props.targetOrgName === item.targetOrgName
                  ? SelectedIcon
                  : UnselectIcon
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(forwardRef<TargetOrgRefType, PropsType>(TargetOrg))
