import { useTrans } from "@/hooks/useTranslation"
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Meta from '../../components/Meta'
import { getRoomFlow, getRoomFlowRecord } from './request'
import { NumberCard2 } from '../Guild/Components/NumberCard'
import RoomDataSelectTime from './components/RoomDataSelectTime'
import './RoomData.scss'
import { toast } from '../../utils/bridge'
import { formatCharmValue } from '../../utils/format'
import MemberCard from '../Guild/Components/MemberCard'
import RoomDataTable from './components/RoomDataTable'
import { isEmpty } from 'lodash-es';
interface RecordDTOListItem {
  gem: number,
  lastGem: number,
  rank: number
}
interface RecordDTOListType {
  today: RecordDTOListItem
  weekly: RecordDTOListItem
  monthly: RecordDTOListItem
}
enum RecordDTOListItemTypeEnum {
  Yesterday = 0,
  Today = 1,
  LastWeek = 2,
  ThisWeek = 3,
  LastMonth = 4,
  ThisMonth = 5
}
export default function RoomData() {
  const { t } = useTrans()
  const [roomData, setRoomData] = useState<Room.RoomData>({} as Room.RoomData)
  const [recordDTOList, setRecordDTOList] = useState<RecordDTOListType>({} as RecordDTOListType)
  const [flowRecordList, setFlowRecordList] = useState<Room.RoomFlowRecord[]>([] as Room.RoomFlowRecord[])
  const [time, setTime] = useState<string>(moment().startOf('month').format('YYYY-MM'))
  const refTime = useRef(time)
  refTime.current = time
  const roomId = useRef(localStorage.getItem('secretRoomId') || '')
  // 改变时间
  const handleTimeRangeChange = useCallback((timeVal: string) => {
    setTime(timeVal)
  }, [])
  //房间信息
  const fetchRoomData = useCallback(async () => {
    //缓存
    const sessionRoomData = JSON.parse(sessionStorage.getItem(`roomData_${roomId.current}`) as string)
    const sessionRecordDTOList = JSON.parse(sessionStorage.getItem(`recordDTOList_${roomId.current}`) as string)
    if (!isEmpty(sessionRoomData) && !isEmpty(sessionRecordDTOList)) {
      setRecordDTOList(sessionRecordDTOList)
      setRoomData(sessionRoomData)
      return
    }
    const res = await getRoomFlow()
    const { code, message, data } = res as Response<Room.RoomData>
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return
    const recordDTOListTemp: RecordDTOListType = {
      today: {
        gem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.Today)?.totalDiamond || 0,
        lastGem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.Yesterday)?.totalDiamond || 0,
        rank: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.Today)?.ranking || 0
      },
      weekly: {
        gem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.ThisWeek)?.totalDiamond || 0,
        lastGem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.LastWeek)?.totalDiamond || 0,
        rank: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.ThisWeek)?.ranking || 0
      },
      monthly: {
        gem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.ThisMonth)?.totalDiamond || 0,
        lastGem: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.LastMonth)?.totalDiamond || 0,
        rank: data.recordDTOList.find(item => item.type === RecordDTOListItemTypeEnum.ThisMonth)?.ranking || 0
      }
    };
    sessionStorage.setItem(`recordDTOList_${roomId.current}`, JSON.stringify(recordDTOListTemp))
    sessionStorage.setItem(`roomData_${roomId.current}`, JSON.stringify(data))
    setRecordDTOList(recordDTOListTemp)
    setRoomData(data)
  }, [])
  //table数据请求
  const fetchRoomFlowRecord = useCallback(async () => {
    //缓存
    const sessionFlowRecordList = JSON.parse(sessionStorage.getItem(`recordList_${roomId.current}_${refTime.current}`) as string)
    if (!isEmpty(sessionFlowRecordList)) {
      setFlowRecordList(sessionFlowRecordList)
      return
    }
    const res = await getRoomFlowRecord(refTime.current)
    const { code, message, data } = res as Response<Room.RoomFlowRecord[]>
    if (code !== 200) {
      toast(message)
      return
    }
    if (!data) return

    const newData = [] as Room.RoomFlowRecord[];

    const startDate = moment(refTime.current + '-01');
    const endDate = startDate.clone().endOf('month');

    // 生成给定月份范围内的所有日期
    const allDates = [];
    const currentDate = startDate;

    while (currentDate.isSameOrBefore(endDate, 'day')) {
      allDates.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    // 遍历数据，检查日期是否存在，并在正确的位置插入缺失的日期
    for (let i = 0; i < allDates.length; i++) {
      const currentDate = allDates[i];

      const existingItem = data.find(item => item.createDay === currentDate);
      if (existingItem) {
        newData.push(existingItem);
      } else {
        // 插入缺失的日期，并将totalDiamond设置为0
        newData.push({
          createDay: currentDate,
          totalDiamond: 0,
        });
      }
    }

    // 更新data数组为新的数据数组
    data.length = 0;
    data.push(...newData);

    // 现在data数组中应该包含所有日期，按顺序排列，缺失的日期也已插入，totalDiamond已设置为0
    sessionStorage.setItem(`recordList_${roomId.current}_${refTime.current}`, JSON.stringify(data))
    setFlowRecordList(data)
  }, [])

  useEffect(() => {
    fetchRoomData()
  }, [])
  useEffect(() => {
    fetchRoomFlowRecord()
  }, [fetchRoomFlowRecord, time])

  return (
    <HelmetProvider>
      <Meta title={t('Room.RoomData').toString()} />
      <div className="pg pg-room-data">
        {!!roomData.roomId && (
          <>
            <MemberCard
              memberId={roomData.roomId}
              avatar={roomData.avatar}
              username={roomData.title}
              erbanNo={roomData.roomNo?.toString()}
            />
            <NumberCard2
              items={[
                {
                  key: 'Room.TodayGem',
                  val: formatCharmValue(recordDTOList.today.gem)
                },
                {
                  key: 'Room.YesterdayGem',
                  val: formatCharmValue(recordDTOList.today.lastGem)
                },
                {
                  key: 'Room.TodayRank',
                  val: recordDTOList.today.gem === 0 ? '-' : recordDTOList.today.rank ? `NO.${recordDTOList.today.rank}` : '-'
                },
                {
                  key: 'Room.WeeklyGem',
                  val: formatCharmValue(recordDTOList.weekly.gem)
                },
                {
                  key: 'Room.LastWeeklyGem',
                  val: formatCharmValue(recordDTOList.weekly.lastGem)
                },
                {
                  key: 'Room.WeeklyRank',
                  val: recordDTOList.weekly.gem === 0 ? '-' : recordDTOList.weekly.rank ? `NO.${recordDTOList.weekly.rank}` : '-'
                },
                {
                  key: 'Room.ThisMonthlyGem',
                  val: formatCharmValue(recordDTOList.monthly.gem)
                },
                {
                  key: 'Room.LastMonthlyGem',
                  val: formatCharmValue(recordDTOList.monthly.lastGem)
                },
                {
                  key: 'Room.ThisMonthlyRank',
                  val: recordDTOList.monthly.gem === 0 ? '-' : recordDTOList.monthly.rank ? `NO.${recordDTOList.monthly.rank}` : '-'
                },
              ]}
            />
            <RoomDataSelectTime
              time={time}
              onChange={handleTimeRangeChange}
            />
          </>
        )}
        {!!flowRecordList.length && <RoomDataTable items={flowRecordList} />}
      </div>
    </HelmetProvider>
  )
}
