import clsx from 'clsx'
import './Pane.scss'
import React from 'react'

type GuildPaneProps = {
  title: string
  titleExtra?: React.ReactNode//标题后额外内容
  children: any
  className?: string
  onClick?: () => void
}

export default function Pane(props: GuildPaneProps) {
  return <div className={clsx('cmp-pane', props.className)} onClick={props.onClick}>
    <div className='cmp-pane__title'>
      <span>{props.title}</span>
      {props.titleExtra && props.titleExtra}
    </div>
    {props.children}
  </div>
}