import React, { useCallback, useEffect, useRef, useState } from 'react'
import { px2rem } from '@/utils/tool';
import { goBack } from '@/utils/bridge';
import { getMyUserInfo } from '@/utils/request';
import { Popup } from 'antd-mobile'
import TransferContent from './components/TransferContent';
import { useCoinsTransfer } from './hooks/useCoinsTransfer';
import { toast, alert } from '@/utils/bridge';
import { useSearchParams } from 'react-router-dom';
import { useTrans } from '@/hooks/useTranslation';
import './CoinsTransferTransfer.scss';

function CoinsTransferTransfer() {
    const { t } = useTrans()
    const [params] = useSearchParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [transferVisible, setTransferVisibleVisible] = useState(true)
    const [state, setState] = useState<{
        user?: AgentRecharge.UserInfo
        inputNum?: string
        agencyMaxCount?: number
        agencyMsg?: string
    }>({
        inputNum: ''
    })
    const refState = useRef(state)
    refState.current = state
    // 转账请求
    const { handleRecharge, hanndleAmountChange, fetchConf } = useCoinsTransfer(state, setState, transferVisible, setTransferVisibleVisible, setLoading);
    const fetchUserInfo = useCallback(async () => {
        if (!Number(params.get('uid'))) return
        const res = await getMyUserInfo(Number(params.get('uid')))

        const { code, message, data } = res as Response<AgentRecharge.UserInfo[]>
        if (code !== 200) {
            toast(message)
            return
        }
        setState(prevState => ({
            ...prevState,
            user: data[0]
        }))
    }, [params])

    const confirmRecharge = useCallback(async () => {
        const ret = await alert(t('AgentRecharge.AlertConfirmTransferCoin', { coin: refState.current.inputNum }))
        if (!ret) return
        handleRecharge()
    }, [handleRecharge])
    useEffect(() => {
        fetchUserInfo()
        fetchConf()
    }, [fetchUserInfo, fetchConf])
    return (
        <div className='pg-agent-recharge-coins-transfer-transfer'>
            <Popup
                visible={true}
                className="pg-agent-recharge-coins-transfer-transfer pg-agent-recharge-coins-transfer-transfer-top-up-popup__popup"
                bodyStyle={{
                    backgroundColor: '#fff',
                    height: px2rem(533),
                    borderTopLeftRadius: px2rem(12),
                    borderTopRightRadius: px2rem(12)
                }}
                maskStyle={{ background: 'rgba(0, 0, 0, 0.4)' }}
            >
                <div className="pg-agent-recharge-coins-transfer-transfer-top-up-popup__popup-header">
                    <div className="pg-agent-recharge-coins-transfer-transfer-top-up-popup__popup-title">{t('AgentRecharge.CoinsTransfer')}</div>
                    <div className="pg-agent-recharge-coins-transfer-transfer-top-up-popup__popup-cancel" onClick={goBack}>
                        {t('Common.Cancel')}
                    </div>
                </div>
                <div className="pg-agent-recharge-coins-transfer-transfer-top-up-popup__popup-content">
                    <TransferContent isBD={true} AttentionInfo2TipName='AgentRecharge.Coins' AttentionInfo2TipAction={'AgentRecharge.Transferred'} readOnly={false} state={state} hanndleAmountChange={hanndleAmountChange} transferVisible={transferVisible} loading={loading} handleRechage={confirmRecharge} agencyMsg={state.agencyMsg}></TransferContent>
                </div>
            </Popup>
        </div >
    )
}

export default CoinsTransferTransfer