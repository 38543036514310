import React, { memo } from 'react'
import './Status.scss';
import ImgSuccess from '@/images/status-success.png';
import ImgWarning from '@/images/status-warning.png';
import StatusBanner from '@/pages/Wallet/components/StatusBanner';
import { useTrans } from "@/hooks/useTranslation";
import SafeArea from '@/components/SafeArea';
import { goBack } from "@/utils/bridge"
import WalletHeader from '@/pages/Wallet/components/Header';
import Pane from '@/components/Pane';

interface PropsType {
    status: 'success' | 'warning'
    title: string,
    successText?: string //成功提示文案
    successRightBtnText?: string//成功第二个按钮文案
    successRightBtnClick?: () => void//成功第二个按钮点击事件
    paneTitle: string //二级标题
    goDetails?: () => void
    children?: React.ReactNode
}
function Status(props: PropsType) {
    const { t } = useTrans()
    return (
        <div className='cmp-wallet-status'>
            <WalletHeader title={props.title}></WalletHeader>
            {props.status === 'success' && <StatusBanner image={ImgSuccess} description={props.successText || ''} descriptionStyle={{ color: '#15EEB8' }}></StatusBanner>}
            {props.status === 'warning' && <StatusBanner image={ImgWarning} description={'Common.Warning'} descriptionStyle={{ color: '#F6B53C' }}></StatusBanner>}
            <Pane title={t(props.paneTitle)}>
                {props.children}
            </Pane>
            <div className="bottom-btn">
                {props.status === 'success' &&
                    <>
                        <div className="left" onClick={props.goDetails}>{t('Wallet.GotoDetails')}</div>
                        <div className="right" onClick={props.successRightBtnClick ? props.successRightBtnClick : goBack}>{t(props.successRightBtnText || '')}</div>
                    </>
                }
                {props.status === 'warning' && <div className="right" onClick={props.goDetails}>{t('Wallet.GotoDetails')}</div>}
            </div>
            <SafeArea position='bottom' />
        </div >
    )
}

export default memo(Status)