import { Toast } from 'antd-mobile'
import { useCallback } from 'react'
import ImageCopy from '../../../images/copy.png'
import { useTrans } from '@/hooks/useTranslation'
import './HelpItem.scss'

type HelpItemProps = {
  image: string
  name: string
  value: string
}

export default function HelpItem(props: HelpItemProps) {
  const { t } = useTrans()

  const handleCopy = useCallback(() => {
    const ele = document.createElement('input')
    ele.setAttribute('type', 'textarea')
    document.body.appendChild(ele)
    ele.value = props.value
    ele.select()
    ele.setSelectionRange(0, ele.value.length)
    document.execCommand('copy')
    document.body.removeChild(ele)

    Toast.show({
      content: t('Copy success!')
    })
  }, [props, t])

  return (
    <div className="cmp-help-item">
      <img className="cmp-help-item__image" alt="help" src={props.image} />
      <span className="cmp-help-item__name">{props.name}</span>
      <span className="cmp-help-item__value" onClick={handleCopy}>
        {props.value}
        <img className="cmp-help-item__copy" src={ImageCopy} alt="copy" />
      </span>
    </div>
  )
}
