import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import Card from './Card';
import ImgMoney from '@/images/money.webp';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { getWithdrawalAccount, getWithdrawalConf } from '@/services/wallet'
import { getUserInfo, toast } from '@/utils/bridge';
import { useTrans } from '@/hooks/useTranslation';
import './Success.scss';

function Success() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const paramsInputNum = useRef(params.get('inputNum'))
    paramsInputNum.current = params.get('inputNum')

    const [state, setState] = useState<{
        withdrawalFee: number
        withdrawalArrival: number
    } & Wallet.WithdrawalAccount>()
    const goDetails = useCallback(async () => {
        const auth = await getUserInfo()
        navigate(`/guild/wallet-data/member-detail/${auth?.uid}?title=1`)
    }, [])
    /*获取cash配置、当前用户提现账号信息 */
    const fetchHomeData = useCallback(async () => {
        const [confData, withdrawalAccountData]: [Response<Wallet.WithdrawalConf>, Response<Wallet.WithdrawalAccount>] = await Promise.all([
            getWithdrawalConf(),
            getWithdrawalAccount()
        ])

        if (confData.code !== 200) {
            toast(confData.message)
            return
        }
        if (withdrawalAccountData.code !== 200) {
            toast(withdrawalAccountData.message)
            return
        }
        setState({
            ...withdrawalAccountData.data,
            ...confData.data
        })
    }, [])
    useEffect(() => {
        fetchHomeData()
    }, [])
    return (
        <div className='pg-wallet-withdraw-success pg-fa'>
            <Status successRightBtnText='Wallet.Withdraw.ExchangeAgain' title='Wallet.Withdraw.Title' status='success' successText='Wallet.Withdraw.ExchangeSuccess' paneTitle='Wallet.Withdraw.ConfirmWithdrawalAccount' goDetails={goDetails}>
                <div className={clsx('by-myself', { hide: false })}>
                    {state &&
                        <Card hideEdit={true} cardInfo={{ ...state }} />}
                    <div className='money-info'>
                        <img src={ImgMoney}></img>
                        <span>{paramsInputNum.current}</span>
                    </div>
                    {state &&
                        <div className="fee">
                            <div>
                                <Trans i18nKey='Wallet.Withdraw.Fee' values={{ fee: (state.withdrawalFee * 100) + '%' }}>
                                    <span />
                                </Trans>
                            </div>
                            <div>{t('Wallet.Withdraw.ArrivalDays', { day: state.withdrawalArrival })}</div>
                        </div>
                    }
                </div>
                {/* <div className='by-agency'>
                    <Card status={1} />
                </div> */}
            </Status>
        </div >
    )
}

export default Success