import { useCallback, ChangeEvent, useEffect, memo, useRef } from 'react'
import searchIcon from '@/images/guild-HistoricalDataSearch-icon.png';
import { useTrans } from '@/hooks/useTranslation';
import './HistoricalDataSearch.scss';

type SearchProps = {
    keywords: string
    setKeywords: (name: string) => void
    handleSearch: () => void
}
function HistoricalDataSearch({ keywords, setKeywords, handleSearch }: SearchProps) {
    const { t } = useTrans()
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null)
    const searchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setKeywords(e.target.value.trim())
        },
        []
    )
    useEffect(() => {
        timer.current && clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            handleSearch()
        }, 200)
    }, [keywords])

    return (
        <div className='search-root'>
            <div className='content'>
                <img src={searchIcon} className='icon'></img>
                <input className='inp' placeholder={t('Guild.Searchplaceholder').toString()} value={keywords} onChange={searchChange}></input>
            </div>
        </div>
    )
}

export default memo(HistoricalDataSearch)