import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Header from '@/components/Header';
import { useNavigate } from 'react-router';
import SelectMonthTime from '@/components/SelectMonthTime';
import moment from 'moment';
import IdentityCard from './components/IdentityCard';
import ClickCard from './components/ClickCard';
import ScrollTable from '@/components/ScrollTable';
import ImgGem from '@/images/gem.png';
import ImageArrowDown from '@/images/arrowdown.png'
import ImageArrow from '@/images/arrow.png'
import AgencyRewardGrade from '@/pages/Guild/AgencyReward/components/AgencyRewardGrade';
import { Popup } from 'antd-mobile';
import ScrollList from '@/components/ScrollList';
import MemberCard from '../Guild/Components/MemberCard';
import { getUserInfo } from '@/utils/bridge';
import Share from './components/Share';
import clsx from 'clsx';
import { useTrans } from '@/hooks/useTranslation';
import './BdSystem.scss'

function BdSystem() {
    const { t } = useTrans()
    const navigate = useNavigate()

    const [user, setUser] = useState<UserBasicInfo>()
    const [viewShare, setViewShare] = useState<boolean>(false)

    const [time, setTime] = useState<string>(moment().startOf('month').format('YYYY-MM'))
    const refTime = useRef(time)
    refTime.current = time

    const [bdList, setBdList] = useState<{
        page: number
        hasMore: boolean
        visible: boolean
        list: Guild.MemberItem[]
    }>({ page: 1, hasMore: true, visible: false, list: [] })
    const refBdList = useRef(bdList)
    refBdList.current = bdList

    const [hostsList, setHostsList] = useState<{
        pageNum: number
        hasMore: boolean
        visible: boolean
        list: Guild.Fans[]
    }>({ pageNum: 1, hasMore: true, visible: false, list: [] })
    const refHostsList = useRef(hostsList)
    refHostsList.current = hostsList

    const fetchBDList = useCallback(() => {
        console.log('bd list');
        // setBdList({
        //     ...refBdList.current,
        //     list: [
        //       ...(refBdList.current.page === 1 ? [] : refBdList.current.list),
        //       ...(data.list || [])
        //     ],
        //     hasMore: !data.whetherLastPage,
        //     page: data.whetherLastPage
        //       ? refBdList.current.page
        //       : refBdList.current.page + 1
        //   })
    }, [])
    const fetchHostsList = useCallback(() => {
        console.log('hosts list');
    }, [])

    /* 切换身份 */
    const isManager = useMemo(() => {
        return time === '1' ? false : true
    }, [time])

    const handleSwitchIdentity = useCallback(() => {
        console.log('switch');
        isManager ? setTime('1') : setTime('2')
    }, [isManager])

    /* 邀请按钮点击 */
    const handleInvite = useCallback(async (memberId: number) => {
        //   const res = await inviteMember(memberId)
        //   if (res instanceof Error) {
        //     return
        //   }

        //   const { code, message } = res as Response
        //   if (code !== 200) {
        //     toast(message)
        //     return
        //   }

        //   toast(t('Guild.Invite.InviteSuccess'))
    }, [])

    useEffect(() => {
        getUserInfo().then((auth) => {
            if (auth) {
                setUser(auth)
            }
        })
    }, [])

    // wip


    const columns = useMemo(() => {
        return [
            {
                header: 'Common.Agency', key: 'age', render: (text: string, row: any) =>
                    <div className='special-content__user'>
                        <span>{row.city}</span>
                        <span>ID:{row.age}</span>
                    </div>
            },
            { header: 'Guild.AgencyReward.LevelRate', key: 'city', render: (text: string, row: any) => <AgencyRewardGrade levelNumber={1} agencyRewardRate={0.2} /> },
            {
                header: 'BDCommission.ExpectedIncome', key: 'city4', render: (text: string, row: any) =>
                    <>
                        <div className='special-content__topup'>
                            <img src={ImgGem} alt="" />
                            <span>{text}</span>
                        </div>
                    </>
            },
        ];
    }, [])

    const data = [
        { age: 25, city: 'New York', city4: '4' },
        { age: 30, city: 'Los Angeles', city4: '4' },
        { age: 22, city: 'Chicago', city4: '4' },
        { age: 22, city: 'Chicago', city4: '4' },
        { age: 222344, city: 'Chicago', city4: '4' },
    ];
    return <>
        <div className='pg-bd-system pg-guild'>
            <header className={clsx({ 'header-manager': isManager })}>
                <Header
                    title={isManager ? t('BDCommission.BDManagerData').toString() : t('BDCommission.BDData').toString()}
                />
                <div className='pg-bd-system-header-content'>
                    <IdentityCard
                        avatar="https://picsum.photos/200"
                        nick={'sdddsa'}
                        erbanNo='123456'
                        identity={isManager ? 'Manager' : 'BD'}
                        hasSwitch={true}
                        switchClick={handleSwitchIdentity}
                    />
                    {isManager ?
                        // 管理员看的总的数据
                        <div className='pg-bd-system-manager-card'>
                            <ClickCard
                                top={{
                                    topNum: '10',
                                    topTitle: t('BDCommission.TotalAgencyNum'),
                                    onClick: () => navigate('/bd/agency-data')
                                }}
                                bottom={{
                                    bottomTitle: t('Common.Invite'),
                                    isBtn: true,
                                    onClick: () => setViewShare(true)
                                }}
                            />
                            <ClickCard
                                top={{
                                    topNum: '10',
                                    topTitle: t('BDCommission.BD'),
                                    onClick: () => setBdList({ ...refBdList.current, visible: true })
                                }}
                                bottom={{
                                    bottomTitle: t('Common.Add'),
                                    isBtn: true,
                                    onClick: () => setHostsList({ ...refHostsList.current, visible: true })
                                }}
                            />
                        </div> :
                        // 自己的数据
                        <div className='pg-bd-system-self-card'>
                            <ClickCard
                                top={{
                                    topNum: '10',
                                    topTitle: t('BDCommission.TotalAgencyNum'),
                                    onClick: () => navigate('/bd/agency-data')
                                }}
                                bottom={{
                                    bottomTitle: t('Common.Invite'),
                                    isBtn: true,
                                    onClick: () => setViewShare(true)
                                }}
                            />
                            <ClickCard
                                top={{
                                    topNum: '10',
                                    onClick: () => { navigate(`/guild/wallet-data/member-detail/${user?.uid}`) }
                                }}
                                bottom={{
                                    bottomTitle: t('BDCommission.GemReward')
                                }}
                            />
                        </div>
                    }
                </div>
            </header>
            <main>
                <SelectMonthTime time={time} setTime={setTime}></SelectMonthTime>
                {/* <ScrollList
                        hasMore={memberGemIncomeList.hasMore}
                        loadMore={fetchMemberGemIncomeList}
                    > */}
                {/* <AgencyRewardIncomeTable items={list}></AgencyRewardIncomeTable> */}
                <ScrollTable columns={columns} data={data || []} />
                {/* </ScrollList> */}
            </main>
        </div>
        {/* BD List */}
        <Popup
            className="pg-bd-system__bd-list"
            visible={bdList.visible}
            onMaskClick={() => setBdList({ ...bdList, visible: false })}
            bodyStyle={{
                backgroundColor: '#261A27'
            }}>
            <div className="pg-bd-system__bd-list-header">
                <div className="pg-bd-system__bd-list-title">
                    {t('BDCommission.BDList')}
                </div>
                <div
                    className="pg-bd-system__bd-list-close"
                    onClick={() => setBdList({ ...bdList, visible: false })}>
                    <img
                        className="pg-bd-system__bd-list-close-down"
                        alt="member list close"
                        src={ImageArrowDown}
                    />
                </div>
            </div>
            <div className="pg-bd-system__bd-list-content">
                <ScrollList
                    hasMore={bdList.hasMore}
                    loadMore={fetchBDList}
                    empty={!bdList.list.length}
                    emptyText={t('Guild.Tips.NoOverallDataYet')}>
                    {bdList.list.map((item, i) => (
                        <MemberCard
                            memberId={item.uid}
                            avatar={item.avatar}
                            username={item.nick}
                            icons={item.medalIconList}
                            role={item.memberRole}
                            // onClick={handleMemberCardClick}
                            key={i}
                        />
                    ))}
                </ScrollList>
            </div>
        </Popup>
        {/* Hosts list */}
        <Popup
            className="pg-bd-system__hosts-list"
            visible={hostsList.visible}
            onMaskClick={() => setHostsList({ ...hostsList, visible: false })}
            bodyStyle={{
                backgroundColor: '#261A27'
            }}>
            <div className="pg-bd-system__hosts-header">
                <div className="pg-bd-system__hosts-title">{t('BDCommission.HostsList')}</div>
                <div
                    className="pg-bd-system__hosts-close"
                    onClick={() => setHostsList({ ...hostsList, visible: false })}>
                    <img alt="member list close" src={ImageArrow} />
                </div>
            </div>
            <div className="pg-bd-system__hosts-content">
                <ScrollList
                    hasMore={hostsList.hasMore}
                    loadMore={fetchHostsList}
                    empty={!hostsList.list.length}
                    emptyText={t('Guild.Tips.NoOverallDataYet')}>
                    {hostsList.list.map((item, i) => (
                        <MemberCard
                            memberId={item.uid}
                            avatar={item.avatar}
                            username={item.nick}
                            icons={[item.experLevelIcon, item.charmLevelIcon].filter(
                                Boolean
                            )}
                            key={i}
                            invite={{
                                enable: item.allowGuildInvite,
                                onInvite: handleInvite
                            }}
                        />
                    ))}
                </ScrollList>
            </div>
        </Popup>
        {/* Share */}
        <Share openView={setViewShare} view={viewShare} />
    </>
}

export default BdSystem