import { memo, useCallback, useRef, useState } from 'react'
import ImgGem from '@/images/gem.png';
import ImgCoin from '@/images/coin.png';
import ConvertibleGemDialog from './ConvertibleGemDialog';
import { Modal } from 'antd-mobile'
import { useTrans } from '@/hooks/useTranslation';
import './ConvertibleGemList.scss'

interface PropsType {
    handleConfirm: (chargeProdId: string) => void
    chargeProdList: Wallet.ChargeProdListItem[]
}
function ConvertibleGemList(props: PropsType) {
    const { t } = useTrans()
    const [visible, setVisible] = useState(false)
    const [chargeProdId, setChargeProdId] = useState('')
    const itemInfo = useRef({ goldNum: 0, anchorDiamondNum: 0 })
    const handleConfirm = useCallback(() => {
        setVisible(false)
        props.handleConfirm(chargeProdId)
    }, [props.handleConfirm, chargeProdId])
    const handleGemClick = useCallback((item: Wallet.ChargeProdListItem) => {
        itemInfo.current = {
            goldNum: item.goldNum,
            anchorDiamondNum: item.anchorDiamondNum
        }
        setVisible(true)
        setChargeProdId(item.chargeProdId)
    }, [])
    return (
        <div className='wallet-convertible-gem-list'>
            {props.chargeProdList.map((item, i) => (
                <div className="gem-item" key={i} onClick={() => handleGemClick(item)}>
                    <div className="coin">
                        <img src={ImgCoin}></img>
                        <span>{item.goldNum}</span>
                    </div>
                    <div className="gem">
                        <img src={ImgGem}></img>
                        <span>{item.anchorDiamondNum}</span>
                    </div>
                </div>
            ))}
            <Modal
                visible={visible}
                title={t('Common.TopUp')}
                content={<ConvertibleGemDialog handleConfirm={handleConfirm} setVisible={setVisible} itemInfo={itemInfo.current} />}
            />
        </div>
    )
}

export default memo(ConvertibleGemList)