import { useTrans } from "@/hooks/useTranslation"
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import ScrollList from '@/components/ScrollList'
import { getAgencyChargeRewardList, getAgencyChargeReward } from '@/utils/request'
import { NumberCard2 } from '../Components/NumberCard'
import SelectTimeRange from '../../../components/SelectTimeRange'
import './AgentRewardData.scss'
import { toast } from '@/utils/bridge'
import { formatCharmValue } from '@/utils/format'
import MemberCard from '../Components/MemberCard'
import NumberCard from '../Components/NumberCard'
import AgentRewardDataTable from './components/AgentRewardDataTable'

export default function AgentRewardData() {
  const { t } = useTrans()
  const [time, setTime] = useState<{ start: Date; end: Date }>({
    start: moment().startOf('month').toDate(),
    end: moment().startOf('date').toDate()
  })
  const refTime = useRef(time)
  refTime.current = time

  const [listInfo, setListInfo] = useState<{
    agencyChargeRewardData: Guild.GuildAgencyChargeReward | null
    list: Guild.GuildAgencyChargeRewardList[]
    page: number
    hasMore: boolean
  }>({ page: 1, hasMore: true, list: [], agencyChargeRewardData: null })
  const refListInfo = useRef(listInfo)
  refListInfo.current = listInfo

  const handleTimeRangeChange = useCallback((start: Date, end: Date) => {
    setListInfo({
      ...refListInfo.current,
      page: 1,
      hasMore: true
    })
    setTime({ start, end })
  }, [])

  const fetchAgencyChargeRewardList = useCallback(async () => {
    if (!refListInfo.current.hasMore) return

    const startTime = moment(refTime.current.start)
      .startOf('day')
      .format('YYYY-MM-DD')
    const endTime = moment(refTime.current.end)
      .startOf('day')
      .format('YYYY-MM-DD')
    const res = await getAgencyChargeRewardList({
      startTime,
      endTime,
      pageNum: refListInfo.current.page
    })

    const { code, message, data } = res as Response<PageList<Guild.GuildAgencyChargeRewardList>>

    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setListInfo(prev => (
      {
        ...prev,
        list: [
          ...(refListInfo.current.page === 1 ? [] : refListInfo.current.list),
          ...(data?.list || [])
        ],
        page: data.whetherLastPage
          ? refListInfo.current.page
          : refListInfo.current.page + 1,
        hasMore: !data.whetherLastPage
      }
    ))
  }, [])

  const fetchAgencyChargeReward = useCallback(async () => {
    const { code, data, message } = await getAgencyChargeReward() as Response<Guild.GuildAgencyChargeReward>

    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setListInfo((prev => (
      {
        ...prev,
        agencyChargeRewardData: data
      }
    )))
  }, [])
  useEffect(() => {
    fetchAgencyChargeReward()
  }, [fetchAgencyChargeReward])

  useEffect(() => {
    fetchAgencyChargeRewardList()
  }, [fetchAgencyChargeRewardList, time])

  return (
    <HelmetProvider>
      <Meta title={t('Guild.AgentRewardData').toString()} />
      <div className="pg pg-agent-reward-data">
        <Header title={t('Guild.AgentRewardData').toString()} />
        {listInfo.agencyChargeRewardData && (
          <>
            <div className='user-info'>
              <MemberCard
                memberId={Number(listInfo.agencyChargeRewardData.uid || 0)}
                avatar={listInfo.agencyChargeRewardData.avatar}
                username={listInfo.agencyChargeRewardData.nick}
                erbanNo={listInfo.agencyChargeRewardData.erbanNo}
              />
              <NumberCard2
                items={[
                  {
                    key: 'Guild.TodaysAgentReward',
                    val: formatCharmValue(listInfo.agencyChargeRewardData.todayRewardGem || 0)
                  },
                  {
                    key: 'Guild.YesterdaysTotalAgentReward',
                    val: formatCharmValue(
                      listInfo.agencyChargeRewardData.yesterdayRewardGem || 0
                    )
                  }
                ]}
              />
            </div>
            <SelectTimeRange
              startTime={time.start}
              endTime={time.end}
              onChange={handleTimeRangeChange}
            />
            <NumberCard
              items={[
                {
                  key: 'Guild.ThisMonthlyAgentReward',
                  val: formatCharmValue(
                    listInfo.agencyChargeRewardData.thisMonthRewardGem || 0
                  )
                },
                {
                  key: 'Guild.ThisMonthlyCoins',
                  val: formatCharmValue(
                    listInfo.agencyChargeRewardData.thisMonthRewardCoins || 0
                  )
                },
                {
                  key: 'Guild.ThisMonthlyUsers',
                  val: formatCharmValue(listInfo.agencyChargeRewardData.thisMonthChargeUser || 0)
                }
              ]}
            />
            <NumberCard
              class="last"
              items={[
                {
                  key: 'Guild.LastMonthlyAgentReward',
                  val: formatCharmValue(
                    listInfo.agencyChargeRewardData.lastMonthRewardGem || 0
                  )
                },
                {
                  key: 'Guild.LastMonthlyCoins',
                  val: formatCharmValue(
                    listInfo.agencyChargeRewardData.lastMonthRewardCoins || 0
                  )
                },
                {
                  key: 'Guild.LastMonthlyUsers',
                  val: formatCharmValue(listInfo.agencyChargeRewardData.lastMonthChargeUser || 0)
                }
              ]}
            />
          </>
        )}
        <div className="pg-agent-reward-data__list">
          <ScrollList
            hasMore={listInfo.hasMore}
            loadMore={fetchAgencyChargeRewardList}
          >
            <AgentRewardDataTable items={listInfo.list} />
          </ScrollList>
        </div>
      </div>
    </HelmetProvider>
  )
}
