import React, { memo } from 'react'
import ImgTransitionArrow from '@/images/transition-arrow.png';
import './CurrencyExchange.scss'
interface PropsType {
    before: number
    after: number
}
function CurrencyExchange({ before, after }: PropsType) {
    return (
        <div className='cmp-wallet-currency-exchange'>
            <div className="cmp-wallet-currency-exchange__content">
                <div className="left">
                    <div className="img">
                        <div className='icon-left'></div>
                    </div>
                    <div className="gem">
                        <div className='icon-left-small'></div>
                        <span>{before}</span>
                    </div>
                </div>
                <img src={ImgTransitionArrow} className='middle' />
                <div className="right">
                    <div className="img">
                        <div className='icon-right'></div>
                    </div>
                    <div className="coin">
                        <div className='icon-right-small'></div>
                        <span>{after}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(CurrencyExchange)