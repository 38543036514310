import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useTrans } from '@/hooks/useTranslation'
import Meta from '@/components/Meta'
import './index.scss'
import { getApks } from '@/services/download'
import { toast } from '@/utils/bridge'

export default function Download() {
  const { t } = useTrans()
  const refTimeout = useRef<NodeJS.Timeout>()
  const [link, setLink] = useState<string>()

  const handleDownload = useCallback(() => {
    if (link)
      window.location.href = link;
  }, [link])

  const handleGetLink = useCallback(async () => {
    const res = await getApks()

    const { code, message, data } = res as Response<Download.Item>
    if (code !== 200) {
      toast(message)
      return
    }

    if (window.isAndroid) {
      setLink(data.officialAndApk)
    } else {
      setLink(data.officialAndApk)
    }
  }, [])

  useEffect(() => {
    handleGetLink()

    function onVisibilityChange() {
      if (document.hidden) {
        clearTimeout(refTimeout.current)
      }
    }
    window.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange)
    }
  }, [])
  return <HelmetProvider>
    <Meta title={t('Guild.Tips.GuildPage').toString()} />
    <div className="pg-dl">
      <div className="pg-dl__btn" onClick={handleDownload} />
    </div>
    {link && <iframe style={{ display: 'none' }} src={link} title='download' />}
  </HelmetProvider>
}