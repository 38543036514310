import { useCallback, useState, ChangeEvent, useRef, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import WalletHeader from '@/pages/Wallet/components/Header';
import Pane from '@/components/Pane';
import clsx from 'clsx';
import ImgPeople from '@/images/people.png';
import { getGuildInfoFromInviteCode } from '@/utils/request';
import { toast } from '@/utils/bridge';
import { useTrans } from '@/hooks/useTranslation';
import './CreateAgency.scss'

function CreateAgency() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [state, setState] = useState<{ guildInfo: Guild.GuildInfoFromInviteCode | undefined, inputCode: string }>({
        guildInfo: undefined,
        inputCode: ''
    })
    const refState = useRef(state)
    refState.current = state
    const handleCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setState(prevState => ({
            ...prevState,
            inputCode: e.target.value
        }))
    }, [])
    const handleClearGuildInfo = useCallback(() => {
        setState({
            ...refState.current,
            guildInfo: undefined,
            inputCode: ''
        })
    }, [])
    const handleConfirm = useCallback(() => {
        navigate(`/guild/create/submit/${refState.current.inputCode}`)
    }, [])
    const handleInputConfirm = useCallback(async () => {
        if (!refState.current.inputCode) return
        const { code, data, message } = await getGuildInfoFromInviteCode(refState.current.inputCode) as Response<Guild.GuildInfoFromInviteCode>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setState({
            ...refState.current,
            guildInfo: data
        })
    }, [getGuildInfoFromInviteCode])

    useEffect(() => {
        const guildInviteCode = params.get('guildInviteCode')
        if (guildInviteCode) {
            refState.current.inputCode = guildInviteCode
            handleInputConfirm()
        }
    }, [params])
    return (
        <div className='pg-guild-create-agency'>
            <WalletHeader title={'Guild.CreateAgency.Title'}></WalletHeader>
            <div className="content">
                <Pane title={t('Guild.CreateAgency.InvitedAgencys')} className='pane-prefix'>
                    <span className='input-tips'>{t('Guild.CreateAgency.InputTips')}</span>
                    <div className="box">
                        {!state.guildInfo && <div className="input-box">
                            <input type="text" value={state.inputCode} onChange={(e) => handleCodeChange(e)} placeholder={t('Guild.CreateAgency.InputCode').toString()} />
                            <div className="confirm" onClick={handleInputConfirm}>{t('Common.Confirm')}</div>
                        </div>}
                        {state.guildInfo && (<div className='input-user'>
                            <img className='avatar' src={state.guildInfo.coverPicUrl}></img>
                            <div className="info">
                                <span>{state.guildInfo.guildName}</span>
                                <div className="middle">
                                    <span>ID:{state.guildInfo.guildNo}</span>
                                    <div className='people-icon'>
                                        <img src={ImgPeople} alt="" />
                                        <span>{state.guildInfo.memberCount}</span>
                                    </div>
                                </div>
                                <span>{state.guildInfo.guildSlogan}</span>
                            </div>
                            <div className='cancel' onClick={handleClearGuildInfo}></div>
                        </div>)}
                    </div>
                    <span className='input-tips-check'>{t('Guild.CreateAgency.InputConfirm')}</span>
                </Pane>
                <Pane title={t('Guild.CreateAgency.UninvitedAgencys')} className='pane-prefix'>
                    <span className='tips-please-contact'>{t('Guild.CreateAgency.PleaseContact')}</span>
                    <span className='tips-contact-info'>{t('Guild.CreateAgency.ContactInformation')}</span>
                    <span className='tips-whatapp'>{t('Guild.CreateAgency.Whatapp')}</span>
                    <span className='tips-whatapp'>{t('Guild.CreateAgency.Whatapp2')}</span>
                </Pane>
                <div className='place'></div>
                <button className={clsx('confirm-btn', { 'btn-blue': state.guildInfo })} disabled={!state.guildInfo} onClick={handleConfirm}>{t('Common.Confirm')}</button>
            </div>
        </div>
    )
}

export default CreateAgency