import clsx from "clsx"
import { useCallback, useState } from "react"
import ImageArrow from '../images/arrow.png'
import ImageMessage from '../images/icon-message.webp'
import ImageSetting from '../images/icon-setting.webp'
import ImageMore from '../images/icon-more.webp'
import { goBack } from "../utils/bridge"
import SafeArea from "./SafeArea"
import { useTrans } from "@/hooks/useTranslation"
import './Header.scss'

type HeaderProps = {
  title?: string
  moreItems?: Guild.MoreItem[]
  hasMore?: boolean
  showMsgIcon?: boolean
  showSettingIcon?: boolean
  customAction?: any
  onMoreItemClick?: (name: string) => void
  backArrowClick?: () => void //自定义返回箭头点击事件
  hideArrow?: boolean //是否隐藏返回箭头
  arrowImage?: string//自定义箭头图片
  onShowMessage?: () => void
  onShowSetting?: () => void
}

export default function Header(props: HeaderProps) {
  const { t } = useTrans()
  const [showMore, setShowMore] = useState<boolean>(false)

  const handleMoreItemClick = useCallback((name: string) => {
    if (!props.onMoreItemClick) return
    setShowMore(false)
    props.onMoreItemClick(name)
  }, [props])

  const handleNavigateBack = useCallback(() => {
    goBack()
  }, [])

  return (
    <div className="header-root">
      <SafeArea position="top" />
      <div className="cmp-header">
        {!props.hideArrow &&
          <div className="cmp-header__arrow" onClick={props.backArrowClick ? props.backArrowClick : handleNavigateBack}>
            <img alt="arrow" src={props.arrowImage || ImageArrow} />
          </div>
        }
        <div className="cmp-header__actions">
          {props.showMsgIcon && <div className="cmp-header__icon" onClick={props.onShowMessage}>
            <img alt="message" src={ImageMessage} />
          </div>}
          {props.showSettingIcon && <div className="cmp-header__icon" onClick={props.onShowSetting}>
            <img alt="setting" src={ImageSetting} />
          </div>}
          {props.hasMore && <div className="cmp-header__icon" onClick={() => setShowMore(!showMore)}>
            <img alt="more" src={ImageMore} />
          </div>}
        </div>
        <div className="cmp-header__title">{props.title && t(props.title)}</div>
        {props.moreItems && (
          <div className={clsx("cmp-header__more-pane", { hide: !showMore })}>
            {props.moreItems.map((item, i) => (
              <div
                className="cmp-header__more-item"
                key={i}
                onClick={() => handleMoreItemClick(item.val)}
              >
                {t(item.name)}
              </div>
            ))}
          </div>
        )}
        {props.customAction}
      </div>
    </div>
  );
}