import { useMemo, useState } from 'react'
import Header from '@/components/Header';
import NumberCard from '@/pages/Guild/Components/NumberCard'
import ScrollTable from '@/components/ScrollTable';
import clsx from 'clsx';
import { useTrans } from '@/hooks/useTranslation';
import './AgencyData.scss'

function AgencyData() {
    const { t } = useTrans()
    const [numberCardItemSelect, setNumberCardItemSelect] = useState(0)

    const columns = useMemo(() => {
        return [
            {
                header: 'Common.Agency', key: 'age', render: (text: string, row: any) =>
                    <div className='special-content__user'>
                        <span>{row.city}</span>
                        <span>ID:{row.age}</span>
                    </div>
            },
            { header: 'BDCommission.InviteDate', key: 'city' },
            {
                header: 'BDCommission.CreateAgencyDate', key: 'city4', render: (text: string, row: any) => <span>{row.age}</span>
            },
        ];
    }, [])

    const data = [
        { age: 222344, city: 'New York', city4: '4' },
        { age: 222344, city: 'Los Angeles', city4: '4' },
        { age: 222344, city: 'Chicago', city4: '4' },
        { age: 222344, city: 'Chicago', city4: '4' },
        { age: 222344, city: 'Chicago', city4: '4' },
    ];
    return (
        <div className='pg-bd-agency-data pg-guild'>
            <Header
                title={t('BDCommission.AgencyData').toString()}
            />
            <NumberCard
                class={'first-cmp-number-card'}
                items={[
                    {
                        key: 'BDCommission.AgencyNum',
                        val: 222 || 0,
                        itemClick: () => setNumberCardItemSelect(0),
                        className: numberCardItemSelect === 0 ? 'selected' : undefined
                    },
                    {
                        key: 'BDCommission.AfterDaysNum',
                        val: 3333 || 0,
                        itemClick: () => setNumberCardItemSelect(1),
                        className: numberCardItemSelect === 1 ? 'selected' : undefined
                    }
                ]}
            />
            <NumberCard
                items={[
                    {
                        key: 'BDCommission.InviteNum',
                        val: 222 || 0,
                        itemClick: () => setNumberCardItemSelect(2),
                        className: numberCardItemSelect === 2 ? 'selected' : undefined
                    },
                    {
                        key: 'BDCommission.CreateAgency',
                        val: 3333 || 0,
                        itemClick: () => setNumberCardItemSelect(3),
                        className: numberCardItemSelect === 3 ? 'selected' : undefined
                    }
                ]}
            />
            <div className={clsx('pg-bd-agency-data__table', { hide: numberCardItemSelect !== 0 })}>
                <ScrollTable columns={columns} data={data || []} />
            </div>
            {/* <div className={clsx('pg-bd-agency-data__table', { hide: numberCardItemSelect !== 1 })}>
                <ScrollTable columns={columns} data={data || []} />
            </div>
            <div className={clsx('pg-bd-agency-data__table', { hide: numberCardItemSelect !== 2 })}>
                <ScrollTable columns={columns} data={data || []} />
            </div>
            <div className={clsx('pg-bd-agency-data__table', { hide: numberCardItemSelect !== 3 })}>
                <ScrollTable columns={columns} data={data || []} />
            </div> */}
        </div>
    )
}

export default AgencyData