import { useTranslation } from "react-i18next";
import type { TOptions } from "i18next";

const defaultOptions = {
  APP: process.env.REACT_APP_DISPLAY_NAME
}

export function useTrans(
  ns?: string | string[],
  options?: { useSuspense?: boolean }
) {

  const { t, ...rest } = useTranslation(ns, options)
  const myT = (key: string, userOptions?: TOptions) => {
    // 合并默认options和用户提供的options  
    const finalOptions = { ...defaultOptions, ...userOptions };
    return t(key, finalOptions);
  };
  return { ...rest, t: myT }
}