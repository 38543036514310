import React, {
  memo,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import { useTrans } from "@/hooks/useTranslation"
import './Country.scss'
import SelectedIcon from '@/images/wallet/selected.png'
import UnselectIcon from '@/images/wallet/unselect.png'

// const refCountry = useRef<React.ElementRef<typeof CountryList>>(null)内使用的类型
type CountryDataProps = {
  countryCode: string
  countryName: string
  countryNationalFlag: string
  currencyList: string[]
}
// 本组件要求的props类型
type CountryProps = {
  countryCode: string
  countryList: Wallet.Country[]
  handleOpenCountry?: () => void
}

const Country = (props: CountryProps, ref: any) => {
  const { t } = useTrans()
  const [country, setCountry] = useState<Wallet.Country>()
  const [countryList, setCountryList] = useState<Wallet.Country[]>([])

  useEffect(() => {
    setCountryList(props.countryList)
  }, [props])
  useEffect(() => {
    setCountry(props.countryList.filter(item => item.countryCode === props.countryCode)[0])
  }, [countryList])
  const handleSelectCountry = (country: Wallet.Country) => {
    setCountry(country)

    setTimeout(() => {
      props?.handleOpenCountry?.()
    }, 0)
  }

  useImperativeHandle(ref, () => country)

  return (
    <div className="cmp-collection-information-country">
      <div className="cmp-collection-information-country-title">{t('Common.Country')}</div>
      <div className="cmp-collection-information-country-option">{t('Common.Option')}</div>
      <div className="cmp-collection-information-country-list">
        {countryList?.map((item) => (
          <div
            key={item.countryCode}
            className="cmp-collection-information-country-item"
            onClick={() => handleSelectCountry(item)}>
            <div className="cmp-collection-information-country-item-left">
              <img
                className="cmp-collection-information-country-item-select"
                alt="img"
                src={item.countryNationalFlag}
              />
              <span>{item.countryName}</span>
            </div>
            <img
              className="cmp-collection-information-country-item-select"
              alt="img"
              src={
                country?.countryCode === item.countryCode
                  ? SelectedIcon
                  : UnselectIcon
              }
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(forwardRef<CountryDataProps, CountryProps>(Country))
