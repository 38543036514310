import { DatePicker } from 'antd-mobile'
import { useState } from 'react'
import ImageCalendar from '@/images/calendar2.png'
import ImageDelta from '@/images/delta.png'
import './SelectMonthTime.scss'
import moment from 'moment'

type PropsType = {
  time: string
  setTime: (time: string) => void
  elProps?: Record<string, any>
}

export default function SelectMonthTime({ time, setTime, elProps = {} }: PropsType) {
  const [visible, setVisible] = useState(false)

  return <>
    <div className='cmp-select-month-time' onClick={() => setVisible(true)}>
      <img className='cmp-select-month-time__calendar' alt='delta' src={ImageCalendar} />
      <span className='cmp-select-month-time__time'>{time}</span>
      <img className='cmp-select-month-time__delta' alt='delta' src={ImageDelta} />
    </div>
    <DatePicker
      visible={visible}
      onClose={() => {
        setVisible(false)
      }}
      precision='month'
      max={new Date()}
      onConfirm={val => {
        setTime(moment(val).format('YYYY-MM'))
      }}
      {...elProps}
    />
  </>
}