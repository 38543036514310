import clsx from 'clsx';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Pane from '../../components/Pane';
import SafeArea from '../../components/SafeArea';
import { getPayList, getUserDetail, recharge } from '../../services/thirdRecharge';
import { toast } from '../../utils/bridge';
import ImageCoin from '../../images/coin.png'
import Loading from '../../components/Loading';
import { useTrans } from "@/hooks/useTranslation"
import './index.scss';

export default function ThirdRecharge() {
  const { t } = useTrans()
  const [loading, setLoading] = useState<boolean>(false)
  const refLoading = useRef(loading)
  refLoading.current = loading

  const [state, setState] = useState<{
    appID: string
    user?: AgentRecharge.UserInfo
    selectedPay: number
    payList: ThirdRecharge.PayItem[]
    selectedCoin?: number
  }>({
    appID: '', payList: [], selectedPay: 0
  })
  const refState = useRef(state)
  refState.current = state
  const refAppID = useRef<HTMLInputElement>(null)

  const handleFetchUser = useCallback(async () => {
    if (refLoading.current) return

    setLoading(true)
    const res = await getUserDetail({ erbanNo: refState.current.appID })
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<AgentRecharge.UserInfo>
    if (code !== 200) {
      toast(message)
      return
    }

    setState({
      ...refState.current,
      user: data
    })
  }, [])

  const handleClearUser = useCallback(() => {
    setState({
      ...refState.current,
      user: undefined,
      appID: '',
      selectedCoin: undefined
    })
  }, [])

  const hanndleAppIDChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...refState.current,
      appID: e.target.value
    })
  }, [])

  const handleFetchPayList = useCallback(async () => {
    if (refLoading.current) return

    setLoading(true)
    const res = await getPayList()
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message, data } = res as Response<ThirdRecharge.PayItem[]>
    if (code !== 200) {
      toast(message)
      return
    }

    setState({
      ...refState.current,
      payList: data,
      selectedPay: 0
    })
  }, [])

  const handleSelectCoin = useCallback((i: number) => {
    setState({
      ...refState.current,
      selectedCoin: i
    })
  }, [])

  const handleConfirm = useCallback(async () => {
    if (refLoading.current) return

    if (!refState.current.user) {
      toast(t('Guild.Tips.PleaseSpecifyUser'))
      return
    }

    const selectPay = refState.current.payList[refState.current.selectedPay]
    if (!selectPay) {
      toast(t('Guild.Tips.PleaseSelectOne'))
      return
    }

    const selectCoin = Number.isInteger(refState.current.selectedCoin) && selectPay.prodList[refState.current.selectedCoin as number]
    if (!selectCoin) {
      toast(t('Guild.Tips.PleaseSelectOne'))
      return
    }

    setLoading(true)
    const res = await recharge({
      uid: refState.current.user.uid,
      chargeProdId: selectCoin.chargeProdId,
      channelId: selectPay.channelId
    })
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    toast(t('Common.Loading'))
    const { code, message, data } = res as Response<{ redirectUrl: string }>
    if (code !== 200) {
      toast(message)
      return
    }

    window.location.href = data.redirectUrl
  }, [])

  useEffect(() => {
    handleFetchPayList()
  }, [handleFetchPayList])

  return <div className="pg-recharge">
    {loading && <Loading />}
    <Pane title={t('Guild.Tips.PleaseCheckYourAccountInformation')} className='first'>
      <div className='pg-recharge__input'>
        {!state.user && <input ref={refAppID} type='text' onChange={hanndleAppIDChange} placeholder={t('Guild.Tips.EnterAppID').toString()} value={state.appID} />}
        {state.user && <div className='pg-recharge__user'>
          <img className='pg-recharge__user-avatar' alt='img' src={state.user.avatar} />
          <span className='pg-recharge__user-name'>{state.user.nick}</span>
        </div>}
        {!state.user && <div className='pg-recharge__confirm' onClick={handleFetchUser}>{t('Common.Confirm')}</div>}
        {state.user && <div className='pg-recharge__cancel' onClick={handleClearUser}>{t('Common.Cancel')}</div>}
      </div>
    </Pane>
    <Pane title={t('Common.Method')}>
      <div className='pg-recharge__pay'>
        {state.payList[state.selectedPay] && <img className="pg-recharge__pay-icon" src={state.payList[state.selectedPay].channelPicUrl} alt='img' />}
      </div>
      <div className='pg-recharge__coin'>
        {state.payList[state.selectedPay]?.prodList.map((item, i) => <div className={clsx('pg-recharge__coin-item', {
          selected: i === state.selectedCoin
        })} onClick={() => handleSelectCoin(i)} key={i}>
          <img alt='img' className='pg-recharge__coin-icon' src={ImageCoin} />
          <span className='pg-recharge__coin-num'>{item.goldNum}</span>
          <span className='pg-recharge__coin-price'>USD {item.money}</span>
        </div>)}
      </div>
    </Pane>
    <SafeArea position='bottom' />
    <div className='pg-recharge__bottom'>
      <div className='pg-recharge__btn' onClick={handleConfirm}>{t('Common.TopUp')}</div>
      <SafeArea position='bottom' />
    </div>
  </div>
}