import React, { memo, forwardRef, useState, useRef, useImperativeHandle, useCallback, useEffect } from 'react'
import ImgArrow from '@/images/arrow.png';
import { isEqual } from 'lodash-es';
import Loading from '@/components/Loading'
import { getPayChannelList } from '@/services/wallet'
import { toast } from '@/utils/bridge';
import { useTrans } from '@/hooks/useTranslation';
import './Channel.scss'

type ChannelRefType = {
    channelId: string
    channelName: string
    channelPict: string
    channelExtendPictArr: string
    channelType: number | null
}
interface PropsType {
    onCancel?: () => void
    countryCode: string
    channelId: string
}
function Channel({ onCancel, countryCode, channelId }: PropsType, ref: any) {
    const { t } = useTrans()
    const [loading, setLoading] = useState<boolean>(false)
    const [channel, setChannel] = useState<Wallet.Channel & { countryCode: string }>()
    const refChannel = useRef(channel)
    refChannel.current = channel

    const [channelList, setChannelList] = useState<Wallet.Channel[]>([])

    // 获取支付列表
    const fetchChannelList = async () => {
        setLoading(true)
        const res = await getPayChannelList(countryCode)
        setLoading(false)
        const { code, message, data } = res as Response<Wallet.Channel[]>
        if (code !== 200) {
            toast(message)
            return
        }
        setChannelList([...data])
    }

    useEffect(() => {
        if (refChannel.current?.channelId !== channelId || refChannel.current?.countryCode !== countryCode) {
            fetchChannelList()
        }
        setChannel({
            channelId: channelId,
            channelName: '',
            channelPict: '',
            channelExtendPictArr: '',
            countryCode: countryCode,
            channelType: null
        })
    }, [channelId, countryCode])

    useImperativeHandle(ref, () => channel)

    const handleSelectChannel = useCallback((item: any) => {
        setTimeout(() => {
            onCancel && onCancel()
        }, 0)
        if (isEqual(refChannel.current, item)) return
        setChannel({
            ...refChannel.current,
            ...item
        })
    }, [onCancel])
    return (
        <div className='cmp-collection-information-channel'>
            {loading && <Loading />}

            <div className="cmp-collection-information-channel-header">
                <span>{t('Wallet.SelectChannel')}</span>
                <span onClick={onCancel}>{t('Common.Cancel')}</span>
            </div>
            <div className='cmp-collection-information-channel-content'>
                {channelList.map((item, i) =>
                    <div className="cmp-collection-information-channel-content__item" key={i} onClick={() => handleSelectChannel(item)}>
                        <div className="left">
                            {item.channelPict && <img src={item.channelPict} alt="" />}
                            {item.channelExtendPictArr && item.channelExtendPictArr.split(';').map((imgItem, i) =>
                                <img src={imgItem} key={i}></img>)}
                        </div>
                        <div className="right">
                            <span>{item.channelName}</span>
                            <img src={ImgArrow}></img>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(forwardRef<ChannelRefType, PropsType>(Channel))