import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import clsx from 'clsx'
import MemberDetailList from './MemberDetailList';
import ScrollTabs from '@/components/ScrollTabs';
import ImgExclamation from '@/images/exclamation.png';
import { useECharts } from '@/hooks/useECharts';
import { px2rem } from '@/utils/tool';
import moment from 'moment';
import { formatCharmValue } from '@/utils/format';
import { useTrans } from '@/hooks/useTranslation';
import './MemberDetailData.scss'

interface PropsType {
    titleItems: string[]
    selectedTab: number
    setSelectedTab: (selectedTab: number) => void
    hasMore: () => void
    listInfo: {
        list: Guild.GuildMemberBillRecordListItem[]
        hasMore: boolean
    },
    loading?: boolean
    hide?: boolean
    className?: string
    time: { start: Date; end: Date },
    chartsData: Array<{ key: number, value: number }>
    currentData: {
        index: number
        time: string
    }
    setCurrentData: (currentData: {
        index: number
        time: string
    }) => void
    clearListInfo: () => void
    fetchMemberBillRecordList: () => void
}
function MemberDetailData({ clearListInfo, time, currentData, setCurrentData, chartsData, titleItems, hide, className, selectedTab, setSelectedTab, listInfo, hasMore, loading, fetchMemberBillRecordList }: PropsType) {
    const { t } = useTrans()

    const refCurrentData = useRef(currentData)
    refCurrentData.current = currentData

    const chartRef = useRef<HTMLDivElement | null>(null);
    const { setOptions, getInstance } = useECharts(chartRef.current as HTMLDivElement)

    const handleTabsClick = useCallback((tab: number) => {
        setSelectedTab(tab)
    }, [])

    // 获取最近7天的时间范围并格式化日期为 'MM-DD'，返回一个字符串数组
    const getFormattedRecent7Days = useMemo(() => {
        const { start, end } = time;
        const formattedDates: string[] = [];

        // 遍历日期范围并格式化
        for (let current = moment(start); current.isSameOrBefore(end); current.add(1, 'day')) {
            formattedDates.push(moment(current.toDate()).format('MM-DD'));
        }

        return formattedDates
    }, [time])
    const refGetFormattedRecent7Days = useRef(getFormattedRecent7Days)
    refGetFormattedRecent7Days.current = getFormattedRecent7Days

    /* 数据格式化 */
    const seriesData = useMemo(() => {
        let data: any[] = []
        data = getFormattedRecent7Days.map(month => {
            return { key: month, value: 0 }
        })
        getFormattedRecent7Days.forEach((month, index) => {
            chartsData.forEach(item => {
                // console.log('month', moment(item.key.toString()).format('YYYY'));
                if (item.key.toString().slice(-4) === month.split('-').join('')) {
                    data[index].value = item.value || 0
                }
            })
        })
        //清除之前添加的样式
        const objectIndex = data.findIndex(item => typeof item?.value === 'object')
        if (objectIndex !== -1) {
            data.splice(objectIndex, 1, {
                key: data[objectIndex]?.key,
                value: data[objectIndex]?.value?.value
            })
        }
        //给点击的当前柱子添加选中样式
        data.splice(currentData.index, 1, {
            key: data[currentData.index]?.key,
            value: {
                value: data[currentData.index]?.value,
                itemStyle: {
                    color: '#10EBBE'//柱子颜色
                },
                label: {
                    color: '#10EBBE'//柱子上面文字颜色
                }
            }
        })
        return data.map(item => item?.value)
    }, [currentData, chartsData, getFormattedRecent7Days])

    /* currentData初始化 */
    useEffect(() => {
        // - 默认选中当天
        // - 如果时间区间不包含当天，则默认选择最后一天
        const today = moment().format('MM-DD')
        const findIndex = getFormattedRecent7Days.findIndex(item => item === today)
        const index = findIndex === -1 ? getFormattedRecent7Days.length - 1 : findIndex
        setCurrentData({
            index,
            time: getFormattedRecent7Days[index]
        })
    }, [getFormattedRecent7Days])

    /* 监听柱状图柱子点击事件 */
    useEffect(() => {
        getInstance()?.on('selectchanged', function (params: any) {
            const index = params.selected[0]?.dataIndex[0]
            if (index === undefined || refCurrentData.current.index === index) return
            clearListInfo()
            setCurrentData({
                index,
                time: refGetFormattedRecent7Days.current[index]
            })
        });
    }, [getInstance])

    /* 设置echarts配置项 */
    useEffect(() => {
        const limit = (refGetFormattedRecent7Days.current.length < 20 ? 7 : refGetFormattedRecent7Days.current.length < 50 ? 3 : refGetFormattedRecent7Days.current.length < 200 ? 1 : 0) + (100 / refGetFormattedRecent7Days.current.length)
        const dataZoomStart = currentData.index / (refGetFormattedRecent7Days.current.length) * 100 - limit
        const dataZoomEnd = (currentData.index + 1) / (refGetFormattedRecent7Days.current.length) * 100 + limit
        setOptions({
            xAxis: {
                data: refGetFormattedRecent7Days.current,
                axisTick: { show: false }, // 隐藏坐标轴刻度
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.4)' // 设置坐标轴刻度字的颜色
                    }
                },
                axisLabel: {
                    interval: 0, // 0 表示所有标签都显示
                    fontSize: px2rem(10), // 设置字体大小
                    color: function (value, index) {
                        return value === currentData.time ? '#10EBBE' : 'rgba(255, 255, 255, 0.4)';
                    }
                },
            },
            yAxis: {
                splitLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.10)' // 设置刻度线上的分隔线颜色
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.6)' // 设置坐标轴刻度字的颜色
                    }
                },
                axisLabel: {
                    fontSize: px2rem(11), // 设置字体大小
                    formatter: function (value: number) {
                        // 将较大的数值格式化为缩略形式
                        return formatCharmValue(value);
                    }
                },
            },
            // 控制图表大小
            grid: {
                left: '0%',
                right: '5%',
                containLabel: true
            },
            dataZoom: [{
                type: 'inside', // 设置为 'inside' 表示内部拖动（'slider'出现条型滚动区域）
                start: dataZoomStart,// 开始显示的范围%
                end: dataZoomEnd, // 结束显示的范围%
                zoomLock: true,  // 设置为 true，保持缩放比例
                zoomOnMouseWheel: false,//鼠标滚轮不能触发缩放
                preventDefaultMouseMove: false,//解决移动端影响手机上下滑动问题
            }],
            series: [
                {
                    data: seriesData,
                    type: 'bar',
                    itemStyle: {
                        color: '#7B8A87'//柱子颜色
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: '#AEABB0',//柱子上面文字颜色
                    },
                    selectedMode: 'single',//选中模式：单选
                    // select: {
                    //     label: {
                    //         color: '#10EBBE'
                    //     },
                    //     itemStyle: {
                    //         color: '#10EBBE',
                    //         borderColor: 'none'
                    //     },
                    // }
                }
            ]
        })
    }, [setOptions, seriesData, currentData])
    return (
        <div className={clsx(`cmp-guild-wallet-data-member-detail-data ${className || ''}`, { hide })}>
            <div ref={chartRef} style={{ width: '100%', height: px2rem(300), marginTop: px2rem(-30), marginBottom: px2rem(-50), direction: 'ltr' }}></div>
            <div className='echarts-tips'>
                <img src={ImgExclamation} alt="" />
                <div>
                    <span>{t('Guild.ClickTheChartForThatDay')}</span>
                    <span>{t('Guild.ThisChartShowsIncome')}</span>
                </div>
            </div>
            <div className='details-title'>
                <span>{currentData.time} {t('Guild.DataDetails')}</span>
            </div>
            <div className='details'>
                <ScrollTabs tabsTitle={titleItems} selectedTab={selectedTab} handleTabsClick={handleTabsClick}></ScrollTabs>
                <MemberDetailList fetchMemberBillRecordList={fetchMemberBillRecordList} loading={loading} listInfo={listInfo} hasMore={hasMore}></MemberDetailList>
            </div>
        </div>
    )
}

export default memo(MemberDetailData)