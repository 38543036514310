import { GuildStore } from "./guild";
import { LoveCardStore } from "./lovecard";

type StoreProps = {
  children: any
}

export default function Store({ children }: StoreProps) {
  return <GuildStore>
    <LoveCardStore>
      {children}
    </LoveCardStore>
  </GuildStore>
}