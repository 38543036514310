import { useEffect, useState } from 'react'
import { formatGuildTime } from '@/utils/format'
import ImageListEmpty from '@/images/list-empty.png'
import { useTrans } from '@/hooks/useTranslation'
import './MemberDataTable.scss'

type MemberDataItem = {
  diamondNum: number
  micBroadcastDuration: number
  whetherValidDays?: boolean
  statisticsTime: number
  supportersNum: number
  dateTimeStr?: string
}

type MemberDataTableProps = {
  items: MemberDataItem[]
}

export default function MemberDataTable(props: MemberDataTableProps) {
  const { t } = useTrans()
  const [list, setList] = useState<MemberDataItem[]>([])

  useEffect(() => {
    setList(props.items)
  }, [props])

  return (
    <div className="cmp-mdt">
      <table>
        <thead>
          <tr>
            <th>{t('Common.Date')}</th>
            <th>{t('Common.Days')}</th>
            <th>{t('Common.Hours')}</th>
            <th>{t('Common.Supporters')}</th>
            <th>{t('Guild.LivingGem')}</th>
          </tr>
        </thead>
        <tbody>
          {list.length !== 0 ? list.map((item, i) => (
            <tr key={i}>
              <td>{item?.dateTimeStr}</td>
              <td>{Number(item?.whetherValidDays || 0)}</td>
              <td>{formatGuildTime(item.micBroadcastDuration).toLowerCase()}</td>
              <td>{item.supportersNum}</td>
              <td>
                <div><span>+{item.diamondNum}</span><div className='img'></div></div>
              </td>
            </tr>
          )) :
            <>
              <tr>
                <td colSpan={5} rowSpan={2}>
                  <div className='empty'>
                    <img src={ImageListEmpty}></img>
                    <span>{t('Common.NoData')}</span>
                  </div>
                </td>
              </tr>
            </>}
        </tbody>
      </table>
    </div>
  )
}
