import ImageCoin from '@/images/coin.png'
import ImagePlus from '../Images/plus.png'
import { useNavigate } from 'react-router'
import { useCallback, useEffect, useRef, useState } from 'react'
import LoveCardUser from './User'
import moment from 'moment'
import ImageBackground from '../Images/CardBackground.png'
import { useTrans } from '@/hooks/useTranslation'
import './Card.scss'

interface LoveCardItemProps {
  item: LoveCard.Item
  action: 'view' | 'unbind' | 'unbindConfirm'
  onUnbind?: () => void
}

export function LoveCardItem(props: LoveCardItemProps) {
  const { t } = useTrans()
  const navigate = useNavigate()
  const [state, setState] = useState<{
    user: LoveCard.User
  }>()
  const refState = useRef(state)
  refState.current = state

  const handleClick = useCallback(() => {
    navigate(`/wallet/love-card/${props.item.cardId}`)
  }, [])

  useEffect(() => {
    if (!props.item.isGiver) {
      setState({
        ...refState.current,
        user: {
          uid: props.item.giverUid,
          avatar: props.item.giverAvatar,
          nick: props.item.giverNick,
          erbanNo: props.item.giverErbanNo
        }
      })
    } else {
      setState({
        ...refState.current,
        user: {
          uid: props.item.uid,
          avatar: props.item.avatar,
          nick: props.item.nick,
          erbanNo: props.item.erbanNo
        }
      })
    }
  }, [])

  return <div className="cmp-lovecard-item" onClick={props.action === 'view' ? handleClick : undefined}>
    <img className='cmp-lovecard-item__bg' src={ImageBackground} />
    <div className="cmp-lovecard-item__header">
      {state?.user && <LoveCardUser user={state.user} showGiveFrom={!props.item.isGiver} />}
    </div>
    <div className="cmp-lovecard-item__main">
      {['view', 'unbind'].includes(props.action) && <>
        <div className='cmp-lovecard-item__main-title'>{t('Wallet.LoveCard.RemainingAmount')}</div>
        <div className='cmp-lovecard-item__main-info'>
          <img className='cmp-lovecard-item__main-info-icon' src={ImageCoin} />
          <span className='cmp-lovecard-item__main-info-coins'>{props.item.goldNum}</span>
          {props.action === 'view' && <div className='cmp-lovecard-item__main-info-action'>{t('Wallet.LoveCard.View')}</div>}
          {props.action === 'unbind' && <div className='cmp-lovecard-item__main-info-action' onClick={props.onUnbind}>{t('Wallet.LoveCard.Unbind')}</div>}
        </div>
      </>}
      {['unbindConfirm'].includes(props.action) && <>
        <div className='cmp-lovecard-item__main-title'>{t('Wallet.LoveCard.WithusedDays')}</div>
        <div className='cmp-lovecard-item__main-value'>{t('Wallet.LoveCard.WithusedDaysInfo', { days: props.item.giveDays })}</div>
        <div className='cmp-lovecard-item__main-title'>{t('Wallet.LoveCard.CoinsUsed')}</div>
        <div className='cmp-lovecard-item__main-value'>{props.item.isGiver ? props.item.erbanNo : props.item.giverErbanNo}</div>
      </>}
    </div>
    <div className="cmp-lovecard-item__bottom">
      {props.action !== 'unbindConfirm' ? <div className='cmp-lovecard-item__bottom-limit'>{t('Wallet.LoveCard.Limit', { coins: props.item.goldLimit })}</div> :
        <div className='cmp-lovecard-item__bottom-limit'>
          <span>{t('Wallet.LoveCard.Spent', {
            appId: props.item.erbanNo,
            coins: props.item.useGoldNum
          })}</span>
          <img className='cmp-lovecard-item__coin' src={ImageCoin} />
        </div>}
      <div className='cmp-lovecard-item__bottom-time'>{t('Wallet.LoveCard.GiveTime', { time: moment(props.item.giveTime).format('YYYY-MM-DD') })}</div>
    </div>
  </div>
}

export function LoveCardCreate() {
  const { t } = useTrans()
  const navigate = useNavigate()

  const handleCreate = useCallback(() => {
    navigate('/wallet/love-card/friends')
  }, [])

  return <div className='cmp-lovecard-create' onClick={handleCreate}>
    <div className='cmp-lovecard-create__title'>{t('Wallet.LoveCard.CreateTitle')}</div>
    <div className='cmp-lovecard-create__text'>{t('Wallet.LoveCard.CreateText')}</div>
    <img className='cmp-lovecard-create__plus' src={ImagePlus} />
  </div>
}