import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import MemberCard from '@/pages/Guild/Components/MemberCard'
import { NumberCard2 } from '@/pages/Guild/Components/NumberCard'
import { formatCharmValue } from '@/utils/format'
// import SelectTimeRange from '@/pages/Guild/Components/SelectTimeRange'
import moment from 'moment'
// import TabButtons from '@/pages/Guild/Components/TabButtons'
import TaskProgress from '../Components/TaskProgress';
import SafeArea from '@/components/SafeArea';
import { useParams } from 'react-router'
import { getMissionUserDetail } from '@/utils/request';
import { toast } from '@/utils/bridge';
import Loading from '@/components/Loading'
import SelectDayTime from '@/components/SelectDayTime';
import { useTrans } from '@/hooks/useTranslation'
import './MemberDetail.scss'

function MemberDetail() {
    const { t } = useTrans()
    const [loading, setLoading] = useState(true)
    // const [selectedTab, setSelectedTab] = useState<number>(0)
    // const refSelectedTab = useRef(selectedTab)
    // refSelectedTab.current = selectedTab

    const { memberId = '' } = useParams()
    const refMemberId = useRef(memberId)
    refMemberId.current = memberId

    const [time, setTime] = useState(moment().startOf('day').format('YYYY-MM-DD'));
    const refTime = useRef(time);
    refTime.current = time;

    const [missionUserDetail, setMissionUserDetail] = useState<Guild.GuildMissionUserDetail>({
        nick: '',
        avatar: '',
        uid: 0,
        erbanNo: '',
        taskReward: 0,
        taskCompleted: 0,
        taskTodayReward: 0,
        taskYesterdayReward: 0,
        missionList: []
    })

    const fetchMissionUserDetail = useCallback(async () => {
        if (!refMemberId.current) return
        setLoading(true)
        const startDate = moment(refTime.current)
            .startOf('day')
            .format('YYYY-MM-DD')
        const endDate = moment(refTime.current)
            .startOf('day')
            .format('YYYY-MM-DD')
        // const missionStatus = refSelectedTab.current + 1
        const { code, data, message } = await getMissionUserDetail({ startDate, endDate, memberUid: Number(refMemberId.current) }) as Response<Guild.GuildMissionUserDetail>
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setMissionUserDetail(data)
    }, [])
    useEffect(() => {
        fetchMissionUserDetail()
    }, [fetchMissionUserDetail, time])
    // const handleRemove = useCallback(() => {
    //     console.log('remove');
    // }, [])
    return (
        <HelmetProvider>
            <Meta title={t('Guild.TaskData').toString()} />
            <div className='pg-guild-task-data-member-detail'>
                <Header title={t('Guild.TaskData').toString()} />
                <div className="content">
                    <div className="top">
                        <MemberCard
                            memberId={missionUserDetail.uid}
                            avatar={missionUserDetail.avatar}
                            username={missionUserDetail.nick}
                            erbanNo={missionUserDetail.erbanNo}
                        // rightBtn={
                        //     {
                        //         text: 'Common.Remove',
                        //         onRightBtn: handleRemove
                        //     }
                        // }
                        />
                        <NumberCard2
                            items={[
                                {
                                    key: 'Guild.TodaysRewardIncome',
                                    val: formatCharmValue(missionUserDetail.taskTodayReward || 0)
                                },
                                {
                                    key: 'Guild.YesterdaysRewardIncome',
                                    val: formatCharmValue(
                                        missionUserDetail.taskYesterdayReward || 0
                                    )
                                }
                            ]}
                        />
                    </div>
                    <div className="middle">
                        <SelectDayTime time={time} setTime={setTime}></SelectDayTime>
                        <div className='middle__card2'>
                            <NumberCard2
                                items={[
                                    {
                                        key: 'Guild.TaskReward',
                                        val: missionUserDetail.taskReward || 0
                                    },
                                    {
                                        key: 'Guild.Completed',
                                        val: missionUserDetail.taskCompleted || 0
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    {!!missionUserDetail.missionList.length &&
                        <div className="bottom">
                            {/* <TabButtons titleItems={['Guild.InProgress', 'Guild.Completed', 'Guild.Unfinished']} selectedTab={selectedTab} setSelectedTab={setSelectedTab}></TabButtons> */}
                            <TaskProgress missionList={missionUserDetail.missionList}></TaskProgress>
                        </div>
                    }
                </div>
                {loading && <Loading />}
                <SafeArea position='bottom'></SafeArea>
            </div>
        </HelmetProvider>

    )
}

export default MemberDetail