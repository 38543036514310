import React, { memo } from 'react'
import './StatusBanner.scss'
import { useTrans } from "@/hooks/useTranslation"
interface PropsType {
    image: any
    description: string
    descriptionStyle?: any
}
function StatusBanner({ image, description, descriptionStyle }: PropsType) {
    const { t } = useTrans()
    return (
        <div className='cmp-status-banner'>
            <img src={image}></img>
            <span style={descriptionStyle}>{t(description)}</span>
        </div>
    )
}

export default memo(StatusBanner)