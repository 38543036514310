import { memo, useCallback } from 'react'
import clsx from 'clsx'
import { useNavigate } from 'react-router'
import { useTrans } from '@/hooks/useTranslation'
import './Card.scss'

interface PropsType {
    // status: number//1有信息，2by公会，3没有account信息（绿色）
    hideEdit?: boolean
    cardInfo: Wallet.WithdrawalAccount
}
function Card(props: PropsType) {
    const { t } = useTrans()
    const navigate = useNavigate()
    const handleEdit = useCallback(() => {
        navigate('/wallet/collection-information')
    }, [])
    return (
        <div className='cmp-wallet-withdraw-card'>
            <div className={clsx("cmp-wallet-withdraw-card__main", { 'main2': !props.cardInfo.accountNo })}>
                <div className="top">
                    <div className="title">{t('Wallet.Withdraw.WithdrawInformation')}</div>
                    {!props.hideEdit && <div className={clsx("edit", { 'activate-btn': !props.cardInfo.accountNo })} onClick={handleEdit}>{t('Common.Edit')}</div>}
                </div>
                <div className="content">
                    {/* 三种状态切换 */}
                    {props.cardInfo.accountNo ?
                        <div className={clsx("by-myself", { hide: false })}>
                            <div className="item">
                                <span className='title'>{t('Common.Country')}</span>
                                <div className='info'>{props.cardInfo.countryName}</div>
                            </div>
                            <div className="item">
                                <span className='title'>{t('Wallet.Channel')}</span>
                                <div className='info'>
                                    {props.cardInfo.channelPict && <img src={props.cardInfo.channelPict}></img>}
                                    {props.cardInfo.channelExtendPictArr && props.cardInfo.channelExtendPictArr.split(';').map((url, i) =>
                                        <img src={url} key={i}></img>
                                    )}
                                    <span>{props.cardInfo.channelName}</span>
                                </div>
                            </div>
                            <div className="item">
                                <span className='title'>{t('Wallet.Account')}</span>
                                <div className='info'>{props.cardInfo.accountNo}</div>
                            </div>
                            <div className="item">
                                <span className='title'>{t('Wallet.FullName')}</span>
                                <div className='info'>{props.cardInfo.fullName}</div>
                            </div>
                        </div>
                        :
                        <div className={clsx("dont-have", { hide: false })}>
                            <span>{t('Wallet.Withdraw.DontHaveAaccountInformation')}</span>
                        </div>
                    }
                    {/* <div className={clsx("by-agency", { hide: true })}>
                        <span>{t('Wallet.Withdraw.WithdrawByAgency')}</span>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default memo(Card)