import './InfoList.scss'

type InfoListItem = {
  key:string
  val:string
}

type InfoListProps = {
  items:InfoListItem[]
}

export default function InfoList(props:InfoListProps) {
  return <div className='cmp-info-list'>
    {props.items.map(item => <div className='cmp-info-list__item' key={item.key}>
      <div className='cmp-info-list__item-key'>{item.key}</div>
      <div className='cmp-info-list__item-val'>{item.val}</div>
    </div>)}
  </div>
}