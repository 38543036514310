import { HelmetProvider } from 'react-helmet-async'
import Header from '../../components/Header'
import Meta from '../../components/Meta'
import { useCallback, useEffect, useRef, useState } from 'react'
import MemberCard from './Components/MemberCard'
// import HistoricalCard from './Components/NumberCard'
import FormItemList from './Components/FormItemList'
import { getGuildMemberDetail, kickOutGuild } from '../../utils/request'
import GuildLoading from '../../components/Loading'
import { useParams } from 'react-router'
import moment from 'moment'
import { goBack, alert } from '../../utils/bridge'
import { toast } from '../../utils/bridge'
import SafeArea from '../../components/SafeArea'
import { useTrans } from '@/hooks/useTranslation'
// import { formatCharmValue, formatGuildTime } from '../../utils/format'
import './Member.scss'

export default function GuildMember() {
  const { t } = useTrans()
  // const navigate = useNavigate()
  const { memberId } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [state, setState] = useState<{
    member: Guild.GuileMemberItem | null
  }>({
    member: null
  })
  const refState = useRef(state)
  refState.current = state

  const refHasKickoutAction = useRef(false)
  refHasKickoutAction.current = !!(refState.current.member && refState.current.member.userRole < refState.current.member.memberRole)

  const fetchMyGuildDetail = useCallback(async () => {
    const res = await getGuildMemberDetail(Number(memberId))
    setLoading(false)

    const { code, message, data } = res as Response<Guild.GuileMemberItem>
    if (code !== 200) {
      toast(message)
      return
    }

    setState({
      ...refState.current,
      member: data
    })
  }, [memberId])

  const handleKickOut = useCallback(async () => {
    const ret = await alert(t('Are you sure kick out this user?'))
    if (!ret) {
      return
    }

    if (!refState.current.member) return

    const res = await kickOutGuild(refState.current.member.memberUid)
    if (res instanceof Error) {
      return
    }

    const { code, message } = res as Response<Guild.GuileMemberItem>
    if (code !== 200) {
      toast(message)
      return
    }

    toast(t('Common.Success'))
    goBack()
  }, [t])

  // const handleHistoricalCardClick = useCallback(async () => {
  //   navigate('/guild/historical/member/' + memberId)
  // }, [navigate, memberId])

  useEffect(() => {
    fetchMyGuildDetail()
  }, [fetchMyGuildDetail])

  return <HelmetProvider>
    <Meta title={t("Common.Edit").toString()} />
    <div className='pg pg-guild-member'>
      <Header
        title={t('Guild.Tips.GuildMember').toString()}
        customAction={refHasKickoutAction.current ? <div className="pg-guild-member__btn-kickout" onClick={handleKickOut}>{t('Common.KickOut')}</div> : undefined}
      />
      <MemberCard
        memberId={refState.current.member?.memberUid || 0}
        avatar={refState.current.member?.memberAvatar || ''}
        username={refState.current.member?.memberNick || ''}
        icons={refState.current.member?.medalIconList || []} />
      {/* <HistoricalCard items={[
        { key: 'Guild.TotalGem', val: formatCharmValue(refState.current.member?.diamondNum || 0) },
        { key: 'Common.Time', val: formatGuildTime(refState.current.member?.micBroadcastDuration || 0) },
        { key: 'Common.ValidDay', val: refState.current.member?.validDays?.toString() || '0' }
      ]} onClick={handleHistoricalCardClick} /> */}
      <div className='pg-guild-member__line' />
      <FormItemList
        items={[
          { key: 'Guild.Tips.TimeToJoinGuild', val: refState.current.member?.joinGuildTime ? moment(refState.current.member.joinGuildTime).format('YYYY-M-D') : '', field: 'guildId' },
          { key: 'Common.JoiningDay', val: refState.current.member?.joinGuildDays.toString() || '', field: 'guildName' },
          { key: 'Guild.Tips.LastLoginTime', val: refState.current.member?.lastLoginTime ? moment(refState.current.member?.lastLoginTime).format('YYYY-M-D') : '', field: 'guildSlogan' }
        ]} />
      <SafeArea position='bottom' />
      {loading && <GuildLoading />}
    </div>
  </HelmetProvider>
}