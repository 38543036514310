import { useCallback, useMemo, useRef, useState } from 'react'
import Header from '@/components//Header';
import ImgQuestion from '@/images/question.png';
import ImgCoin from '@/images/coin.png';
import ImgPeople from '@/images/guild-people-icon.webp';
import { useNavigate } from 'react-router';
import TabButtons from '../Components/TabButtons';
import SelectMonthTime from '@/components/SelectMonthTime';
import moment from 'moment';
import ExpectedPendingContent from './components/ExpectedPendingContent';
import clsx from 'clsx';
import RankListItem from './components/RankListItem';
import ScrollTabs from '@/components/ScrollTabs';
import NumberCard from '@/pages/Guild/Components/NumberCard'
import MyListItem from './components/MyListItem';
import { useTrans } from '@/hooks/useTranslation';
import './InviteData.scss'

function InviteData() {
    const { t } = useTrans()
    const navigate = useNavigate()

    const [selectedTab, setSelectedTab] = useState<number>(2)
    const [selectedRankTab, setSelectedRankTab] = useState<number>(0)
    const [selectedMyTab, setSelectedMyTab] = useState<number>(0)

    const [time, setTime] = useState<string>(moment().startOf('month').format('YYYY-MM'))
    const refTime = useRef(time)
    refTime.current = time

    const titleItems = useMemo(() => {
        // wip权限判断显示几个tab
        return ['Common.Expected', 'Common.Pending', 'Common.My', 'Common.Rank']
    }, [])
    const rankTabTitleItems = useMemo(() => {
        return ['Guild.InviteData.TopUpRanking', 'Guild.InviteData.InvitationRanking']
    }, [])
    const myTabTitleItems = useMemo(() => {
        return ['Guild.InviteData.GoldDetails', 'Guild.InviteData.Inviter']
    }, [])

    const goToRule = useCallback(() => {
        navigate('/guild')
    }, [])

    // wip
    const rankItems = [
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        },
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        },
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        },
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        },
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        },
        {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        }, {
            nick: 'Dasdadadsad',
            avatar: 'https://picsum.photos/200',
            num: 9999
        }
    ]
    const userItem = [
        {
            nick: 'user',
            avatar: 'https://picsum.photos/200',
            num: 10000
        }
    ]
    return (
        <div className='pg-guild-invite-data pg-guild'>
            <Header title={t('Guild.InviteData.Title').toString()} customAction={<div className='pg-guild-invite-data__header-img' onClick={goToRule}><img src={ImgQuestion} ></img></div>}></Header>
            <main>
                <SelectMonthTime time={time} setTime={setTime}></SelectMonthTime>
                <TabButtons titleItems={titleItems || []} selectedTab={selectedTab} setSelectedTab={setSelectedTab}></TabButtons>
                {/* Expected */}
                <div className={clsx('hide-div', { hide: selectedTab !== 0 })}>
                    <ExpectedPendingContent time={time} />
                </div>
                {/* Pending */}
                <div className={clsx('hide-div', { hide: selectedTab !== 1 })}>
                    <ExpectedPendingContent time={time} />
                </div>
                {/* My */}
                <div className={clsx('hide-div', { hide: selectedTab !== 2 })}>
                    <NumberCard
                        items={[
                            {
                                key: 'Guild.InviteData.TotalTopUp',
                                val: 222 || 0
                            },
                            { key: 'BDCommission.InviteNum', val: 3333 || 0 }
                        ]}
                    />
                    <NumberCard
                        items={[
                            {
                                key: 'Guild.InviteData.OfficalTopUp',
                                val: 222 || 0
                            },
                            { key: 'Guild.InviteData.OtherTopUp', val: 3333 || 0 }
                        ]}
                    />
                    <ScrollTabs selectedTab={selectedMyTab} handleTabsClick={setSelectedMyTab} tabsTitle={myTabTitleItems} />
                    <MyListItem items={rankItems} />
                </div>
                {/* Rank */}
                <div className={clsx('hide-div', { hide: selectedTab !== 3 })}>
                    <RankListItem icon={selectedRankTab === 0 ? ImgCoin : ImgPeople} items={userItem} rank={1} />
                    <ScrollTabs selectedTab={selectedRankTab} handleTabsClick={setSelectedRankTab} tabsTitle={rankTabTitleItems} />
                    <RankListItem icon={selectedRankTab === 0 ? ImgCoin : ImgPeople} items={rankItems} />
                </div>
            </main>
        </div>
    )
}

export default InviteData