/* 数据上报 */
import { reportTrackData, getUserInfo } from '@/utils/bridge';
import moment from 'moment';

interface StateType {
    userId: string | undefined
    inputNum: string | undefined
}
//代理充值异常上报
export const abnormalReportAgent = async (err: any, state: StateType) => {
    const auth = await getUserInfo();
    const reportTrackDataMsg = {
        eventName: '代理充值',
        properties: {
            title: '代理充值',
            msg: `充值代理uid：${state?.userId}；用户uid：${auth?.uid}；金币数：${state.inputNum}；时间：${moment().format('YYYY-MM-DD HH:mm:ss')}；errrorMsg：${err?.message}`,
            warn: err?.code
        }
    };
    reportTrackData(reportTrackDataMsg);
}

// 主播兑换金币异常上报
export const abnormalReportCashExchange = async (err: any, state: StateType) => {
    const auth = await getUserInfo();
    const reportTrackDataMsg = {
        eventName: '主播兑换金币',
        properties: {
            title: '主播兑换金币',
            msg: `主播兑换金币代理uid：${state?.userId}；用户uid：${auth?.uid}；金币数：${state.inputNum}；时间：${moment().format('YYYY-MM-DD HH:mm:ss')}；errrorMsg：${err?.message}`,
            warn: err?.code
        }
    };
    reportTrackData(reportTrackDataMsg);
}

//提现异常上报
export const abnormalReportWithdraw = async (err: any, state: StateType) => {
    const auth = await getUserInfo();
    const reportTrackDataMsg = {
        eventName: '提现',
        properties: {
            title: '提现',
            msg: `提现uid：${auth?.uid}；金钱：${state.inputNum}；时间：${moment().format('YYYY-MM-DD HH:mm:ss')}；errrorMsg：${err?.message}`,
            warn: err?.code
        }
    };
    reportTrackData(reportTrackDataMsg);
}