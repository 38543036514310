import React, { memo, useEffect } from 'react';
import clsx from 'clsx';
import ScreenTypes from '@/pages/Wallet/components/ScreenTypes';
import InfoList from '@/pages/Wallet/components/InfoList';
import useBillRecordInfoList from './hooks/useBillRecordInfoList ';
import './PearlDetail.scss'

interface PropsType {
    linkageData: Wallet.FirstBillTree;
    tab?: number;
    type?: number;
    hide?: boolean;
}

function PearlDetail(props: PropsType) {
    const { loading, time, handleTimeRangeChange, listInfo, setBillTypeId, fetchBillList } = useBillRecordInfoList();
    useEffect(() => {
        setBillTypeId(props.linkageData && props.linkageData.billTypeId);
    }, [props.linkageData]);

    return (
        <div className={clsx('pg-wallet-pearl-detail', { hide: props.hide })}>
            <ScreenTypes time={time} setTime={handleTimeRangeChange} selectTypeHide={true} tipHide={true} />
            <InfoList hasMore={fetchBillList} listInfo={listInfo} pageTitle="pearl" loading={loading}></InfoList>
        </div>
    );
}

export default memo(PearlDetail);
