import { Calendar, Popup } from 'antd-mobile'
import { useEffect, useState } from 'react'
import moment from 'moment'
import ImageCalendar from '@/images/calendar.png'
import ImageDelta from '@/images/delta.png'
import './SelectTimeRange.scss'

type SelectTimeRangeProps = {
  startTime: Date
  endTime: Date
  onChange?: (startTime: Date, endTime: Date) => void
  min?: Date
  max?: Date
}

export default function SelectTimeRange(props: SelectTimeRangeProps) {
  const [time, setTime] = useState<[Date, Date]>([props.startTime, props.endTime])
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setTime([props.startTime, props.endTime])
  }, [props])

  return <>
    <div className='cmp-select-time-range' onClick={() => setEditing(true)}>
      <img className='cmp-select-time-range__calendar' alt='delta' src={ImageCalendar} />
      <span className='cmp-select-time-range__time'>{moment(time[0]).format('YYYY-M-D')} ~ {moment(time[1]).format('YYYY-M-D')}</span>
      <img className='cmp-select-time-range__delta' alt='delta' src={ImageDelta} />
    </div>
    <Popup visible={editing} onMaskClick={() => setEditing(false)}>
      <Calendar
        defaultValue={time}
        selectionMode='range'
        min={props.min}
        max={props.max || new Date()}
        onChange={val => {
          if (!val) return
          if (val[0].toString() !== val[1].toString()) setEditing(false)
          setTime(val)
          props.onChange && props.onChange(val[0], val[1])
        }} />
    </Popup>
  </>
}