import React, { memo, useCallback } from 'react'
import SafeArea from "@/components/SafeArea"
import ImageArrow from '@/images/arrow.png'
import { goBack } from "@/utils/bridge"
import clsx from 'clsx'
import { useTrans } from '@/hooks/useTranslation'
import './Header.scss'

type HeaderProps = {
    title?: string  //标题
    titleItems?: string[] //多级title
    selectedTitle?: number //多级title选中的title
    setSelectedTitle?: (selectedTitle: number) => void
    jumpText?: string    //右边导航跳转文案
    jumpTextClick?: () => void //文案跳转事件
    bgPenetration?: boolean //是否背景渗透
    content?: React.ReactNode//额外内容
}
function Header(props: HeaderProps) {
    const { t } = useTrans()
    const handleNavigateBack = useCallback(() => {
        goBack()
    }, [])
    return <>
        <div className={clsx("wallet-header-root", { walletDetailBgPenetration: props.bgPenetration })}>
            <SafeArea position='top' />
            <div className='wallet-header'>
                <SafeArea position='top' />
                <div className="wallet-header__content">
                    <div className="wallet-header__arrow" onClick={handleNavigateBack}>
                        <img src={ImageArrow} />
                    </div>
                    {props.title && !props.titleItems && <div className="wallet-header__title">{t(props.title)}</div>}
                    {props.titleItems && !props.title &&
                        <div className='wallet-header__title-items'>
                            {props.titleItems.map((item, i) => (
                                <div className={clsx('wallet-header__title-items-item', {
                                    selected: i === props.selectedTitle
                                })} key={i} onClick={() => props.setSelectedTitle && props.setSelectedTitle(i)}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    }
                    {props.jumpText && <div className="wallet-header__details" onClick={props.jumpTextClick}>
                        {t(props.jumpText)}
                    </div>}
                </div>
            </div>
            {/* 额外内容 */}
            {props.content && props.content}
        </div>
    </>
}

export default memo(Header)