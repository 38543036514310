import { memo, useCallback, ChangeEvent } from 'react'
import ImgArrow from '@/images/arrow.png';
import clsx from 'clsx';
import { useTrans } from '@/hooks/useTranslation';
import './Input.scss';

interface PropsType {
    title: string
    name?: string
    className?: string
    value?: string | number
    setValue?: (state: any) => void
    placeholder?: string
    isArrow?: boolean
    onClick?: () => void
    infoImg?: string
    infoExtendImg?: string[]
    infoSpan?: string
    readOnly?: boolean
}
function Input(props: PropsType) {
    const { t } = useTrans()
    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        props.setValue && props.setValue((prevState: any) => {
            const obj = { ...prevState }
            if (props.name) {
                obj[props.name] = e.target.value
            }
            return obj
        })
    }, [])
    return (
        <div className={clsx('cmp-collection-information-input', props.className)}>
            <span className='cmp-collection-information-input__title'>{t(props.title)}</span>
            <div className='cmp-collection-information-input__input-box' onClick={props.onClick && props.onClick}>
                {!props.infoImg && !props.infoSpan && <input type="text" readOnly={props.isArrow || props.readOnly} className='cmp-collection-information-input__input' placeholder={props.placeholder ? props.placeholder : t('Wallet.Input').toString()} onChange={handleInputChange} value={props.value || ''}
                />}
                {props.isArrow && <img src={ImgArrow} className='cmp-collection-information-input__arrow'></img>}
                {props.infoSpan &&
                    <div className="cmp-collection-information-input__info">
                        {props.infoImg && <img src={props.infoImg}></img>}
                        {!!props.infoExtendImg?.length && props.infoExtendImg.map((url, i) => <img src={url} key={i}></img>)}
                        <span>{props.infoSpan}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default memo(Input)