import { useCallback, useEffect, useState } from 'react'
import ImageCamera from '../../../images/camera.png'
import { uploadAvatar } from '../../../utils/bridge'
import './UpdateAvatar.scss'

type UpdateAvatarProps = {
  avatar:string
  onChange?:(file:string) => void
}

export default function UpdateAvatar(props:UpdateAvatarProps) {
  const [ avatar, setAvatar ] = useState(props.avatar)
  const handleUpload = useCallback(async() => {
    const file = await uploadAvatar()
    if (file) {
      props.onChange && props.onChange(file)
    }
  }, [ props ])

  useEffect(() => {
    setAvatar(props.avatar)
  }, [ props ])

  return <div className='cmp-update-avatar' onClick={handleUpload}>
    {avatar && <img className='cmp-update-avatar__pic' alt='update avatar' src={avatar + '?w=216&h=216'} />}
    {avatar && <img className='cmp-update-avatar__camera' alt='camera' src={ImageCamera} />}
  </div>
}