import { memo, useCallback, useEffect, useMemo } from 'react'
import { Popup } from 'antd-mobile'
import ImageFaceBook from '@/images/facebook.webp'
import ImageWhatApp from '@/images/whats-app.webp'
import ImageCopyLink from '@/images/copy-link.webp'
import { shareApp, toast } from '@/utils/bridge'
import { downloadLink } from '@/utils/constant'
import { useClipboard } from '@/hooks/useClipboard';
import { useTrans } from '@/hooks/useTranslation'
import './index.scss'

interface ShareProps {
  openView: (v: boolean) => void
  view: boolean
}

function index(props: ShareProps) {
  const { view, openView } = props
  const { t } = useTrans()
  const { copy, copied } = useClipboard()

  const shareDataList = useMemo(() => {
    return [
      { text: t('Guild.Invite.Facebook'), type: 'facebook', img: ImageFaceBook },
      { text: t('Guild.Invite.WhatsApp'), type: 'whatsapp', img: ImageWhatApp },
      { text: t('Guild.Invite.CopyLink'), type: 'copy', img: ImageCopyLink }
    ]
  }, [])

  const handleShare = useCallback((type: string) => {
    if (type === 'copy') {
      copy(downloadLink || '')
      return
    }
    shareApp(type)
  }, [])

  useEffect(() => {
    if (copied) {
      toast(t('Guild.Invite.CopySuccess'))
    }
  }, [copied])
  return (
    <Popup
      visible={view}
      className="share"
      onMaskClick={() => {
        openView(false)
      }}>
      <div className="share-content">
        <div className="title">{t('Guild.Invite.Share')}</div>
        <div className="share-list">
          {shareDataList.map((item, i) => (
            <div
              className="share-list-item"
              key={i}
              onClick={() => handleShare(item.type)}>
              <img src={item.img} />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </Popup>
  )
}

export default memo(index)
