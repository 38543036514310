import { LoveCardItem } from "./Components/Card"
import { useNavigate, useParams } from "react-router"
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import LoveCardHeader from './Components/Header'
import moment from 'moment'
import ImageTimeIcon from './Images/calandar.png'
import ImageCoin from '@/images/coin.png'
import ImageArrow from './Images/arrow.png'
import ScrollList from '@/components/ScrollList'
import { getCardConsumeRecords, getDetail, unbind } from '@/services/LoveCard'
import { getUserInfo, toast } from '@/utils/bridge'
import { Calendar, Popup } from 'antd-mobile'
import Pane from '@/components/Pane'
import { useTrans } from '@/hooks/useTranslation'
import "./Detail.scss";

interface RecordListProps {
  cardId: string
}

function RecordList(props: RecordListProps) {
  const { t } = useTrans()
  const [state, setState] = useState<{
    hasMore: boolean,
    records: LoveCard.ComsumeRecord[],
    time: number
    visible: boolean
  }>({
    hasMore: true,
    records: [],
    time: Date.now(),
    visible: false
  })
  const refState = useRef(state)
  refState.current = state

  const fetchRecordList = useCallback(async (reset: boolean) => {
    if (!props.cardId) return

    const lastRecordId = reset ? null : refState.current.records.length ? refState.current.records[refState.current.records.length - 1].recordId : null
    const res = await getCardConsumeRecords({
      cardId: Number(props.cardId),
      lastRecordId,
      pageSize: 20,
      date: moment(refState.current.time).format('YYYY-MM-DD')
    })
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    setState({
      ...refState.current,
      records: [...(reset ? [] : refState.current.records), ...(res.data?.list || [])],
      hasMore: !res.data?.whetherLastPage || false
    })
  }, [])

  const handleSelectTime = useCallback(() => {
    setState({
      ...refState.current,
      visible: true
    })
  }, [])

  const handleCloseTimeSelector = useCallback(() => {
    setState({
      ...refState.current,
      visible: false
    })
  }, [])

  useEffect(() => {
    fetchRecordList(true)
  }, [state.time])

  return <div className='cmp-record__list'>
    <div className='cmp-record__list-selector'>
      <div className='cmp-record__list-selector-time' onClick={handleSelectTime}>
        <img className='cmp-record__list-selector-time-icon' src={ImageTimeIcon} />
        <span>{moment(state.time).format('YYYY-MM-DD')}</span>
        <img className='cmp-record__list-selector-time-arrow' src={ImageArrow} />
      </div>
    </div>
    <div className='cmp-record__list-tips'>{t('Wallet.LoveCard.ListTips')}</div>
    <ScrollList hasMore={state.hasMore} loadMore={fetchRecordList} empty={!state.records.length} emptyText={t('Wallet.LoveCard.NoMore')}>
      {state.records.map((record, i) => <Record item={record} key={i} />)}
    </ScrollList>
    <Popup
      visible={state.visible}
      onMaskClick={handleCloseTimeSelector}
      onClose={handleCloseTimeSelector}
    >
      <Calendar
        value={new Date(state.time)}
        selectionMode='single'
        defaultValue={new Date}
        onChange={val => {
          if (!val) return
          handleCloseTimeSelector()
          setState({
            ...state,
            time: val.valueOf()
          })
        }}
      />
    </Popup>
  </div>
}

interface RecordProps {
  item: LoveCard.ComsumeRecord
}

function Record(props: RecordProps) {
  const { t } = useTrans()
  return <div className='cmp-record__item'>
    <div className='cmp-record__item-main'>
      <div className='cmp-record__item-title'>
        {props.item.consumeDetailName}
      </div>
      <div className='cmp-record__item-coins'>
        <span>{props.item.consumeAmount > 0 ? '+' : ''}{props.item.consumeAmount}</span><img src={ImageCoin} />
      </div>
    </div>
    <div className='cmp-record__item-sub'>
      <div className='cmp-record__item-type'>
        {t('Wallet.LoveCard.Type', { type: props.item.consumeTypeName })}
      </div>
      <div className='cmp-record__item-time'>{moment(props.item.consumeTime).format('YYYY-MM-DD HH:mm:ss')}</div>
    </div>
  </div>
}

interface UnbindProps {
  card: LoveCard.Item
  onClose: () => void
}

function Unbind(props: UnbindProps) {
  const { t } = useTrans()
  const navigate = useNavigate()
  const { card } = props

  const handleUnbind = useCallback(async () => {
    const res = await unbind(card.cardId)
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    toast(t('Common.Success'))
    navigate('/wallet/love-card')
  }, [])

  return <div className='cmp-unbind'>
    <div className='cmp-unbind__title'>
      {t('Wallet.LoveCard.Unbind')}
      <span onClick={props.onClose}>{t('Wallet.LoveCard.Cancel')}</span>
    </div>
    <Pane title={t('Wallet.LoveCard.UnbindTitle')}>
      <LoveCardItem item={card} action='unbindConfirm' />
    </Pane>
    <div className='cmp-unbind__bottom'>
      <div className='cmp-unbind__btn-unbind' onClick={handleUnbind}>{t('Wallet.LoveCard.Unbind')}</div>
      <div className='cmp-unbind__btn-confirm' onClick={props.onClose}>{t('Wallet.LoveCard.UnbindConfirm')}</div>
    </div>
  </div>
}

export default function LoveCardDetail() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const { cardId } = useParams()
  const [state, setState] = useState<{
    item?: LoveCard.Item
    showLimit: boolean
    showUnbindPop: boolean
  }>({
    showLimit: false,
    showUnbindPop: false
  })
  const refState = useRef(state)
  refState.current = state

  const getCard = useCallback(async (cardId: string) => {
    const res = await getDetail(cardId)
    if (res.code !== 200) {
      toast(res.message)
      return
    }
    if (!res.data) return

    const state = {
      ...refState.current, item: res.data
    }

    const user = await getUserInfo()
    if (Number(user?.uid) === res.data.giverUid) {
      state.showLimit = true
      state.item = { ...state.item, isGiver: true }
    } else {
      state.item = { ...state.item, isGiver: false }
    }

    setState(state)

  }, [])

  const showUnbindPop = useCallback(() => {
    setState({
      ...refState.current,
      showUnbindPop: true
    })
  }, [])

  const hideUnbindPop = useCallback(() => {
    setState({
      ...refState.current,
      showUnbindPop: false
    })
  }, [])

  const handleUpdateCard = useCallback(() => {
    navigate('/wallet/love-card/modify/' + cardId)
  }, [])

  useEffect(() => {
    if (!cardId) return
    getCard(cardId)
  }, [])

  return <div className="pg-lovecard-detail">
    <LoveCardHeader />
    {state.item ? <><LoveCardItem item={state.item} action='unbind' onUnbind={showUnbindPop} />
      {state.showLimit && <div className="pg-lovecard-detail__limit" onClick={handleUpdateCard}>
        <div className="pg-lovecard-detail__limit-title">{t('Wallet.LoveCard.PaymentLimit')}</div>
        <div className='pg-lovecard-detail__limit-info'>
          {t('Wallet.LoveCard.PaymentLimitMonth', {
            limit: state.item.goldLimit
          })}
        </div>
      </div>}</> : <Fragment />}
    {cardId && <RecordList cardId={cardId} />}
    <Popup
      visible={state.showUnbindPop}
      onMaskClick={hideUnbindPop}
      onClose={hideUnbindPop}
      bodyStyle={{
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
      }}
    >
      {state.item && <Unbind card={state.item} onClose={hideUnbindPop} />}
    </Popup>
  </div>
}