import { useCallback, useEffect, useState } from 'react'
import ConvertibleGemList from './components/Gem/ConvertibleGemList';
import { NumberCard2 } from '@/pages/Guild/Components/NumberCard';
import { formatCharmValue } from '@/utils/format';
import Pane from '@/components/Pane';
import ImgGem from '@/images/gem.png';
import { getGemAnchorDiamond, gemBuyCoin } from '@/services/wallet';
import { toast, setBusinessStatus } from '@/utils/bridge'
import { BusinessConst } from '@/utils/constant'
import GemRuleTable from './components/Gem/GemRuleTable';
import WalletHeader from './components/Header';
import { useNavigate } from 'react-router'
import GemBanner from './components/Banner';
import { useTrans } from '@/hooks/useTranslation';
import './Gem.scss'

function Gem() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [state, setState] = useState<Wallet.GemAnchorDiamond>({
        chargeProdList: [],
        isOpenExchange: false,
        exchangeRules: [],
        monthGem: 0,
        exchangeGem: 0,
        canExchangeGem: 0,
        anchorDiamondNum: 0
    })

    const onDetailClick = useCallback(() => {
        navigate("/wallet/detail?title=1")
    }, [])
    useEffect(() => {
        fetchHomeData()
    }, [])
    const fetchHomeData = useCallback(async () => {
        const res = await getGemAnchorDiamond()
        const { code, message, data } = res as Response<Wallet.GemAnchorDiamond>
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setState(data)
    }, [getGemAnchorDiamond])
    const handleConfirm = useCallback(async (chargeProdId: string) => {
        const { code, message } = await gemBuyCoin({ chargeProdId }) as Response
        if (code !== 200) {
            toast(message)
            return
        }
        setBusinessStatus(BusinessConst.GemExchange)
        fetchHomeData()
    }, [fetchHomeData, gemBuyCoin])
    return (
        <div className='pg-wallet-gem'>
            <WalletHeader title='Common.Gem' jumpText='Common.Details' bgPenetration={true} jumpTextClick={onDetailClick} content={<GemBanner amount={state.anchorDiamondNum || 0}></GemBanner>}></WalletHeader>
            <NumberCard2
                items={[
                    {
                        key: 'Wallet.ExchangeGem',
                        val: formatCharmValue(state.exchangeGem || 0)
                    },
                    {
                        key: 'Wallet.ThisMonthlyGem',
                        val: formatCharmValue(state.monthGem || 0)
                    }
                ]}
            />
            <Pane title={t('Wallet.CurrentlyConvertibleGems')} titleExtra={
                <div className='title-extra'>
                    <img src={ImgGem}></img>
                    <span>{state.canExchangeGem || 0}</span>
                </div>}>
                <ConvertibleGemList handleConfirm={handleConfirm} chargeProdList={state.chargeProdList}></ConvertibleGemList>
            </Pane>
            <Pane title={t('Wallet.ExchangeRules')}>
                <GemRuleTable tableData={state.exchangeRules}></GemRuleTable>
            </Pane>
        </div>
    )
}

export default Gem