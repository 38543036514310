import { service } from "../utils/net";

export function getUserDetail(params: any) {
  return service<Response<AgentRecharge.UserInfo>>({
    url: '/agency/search/user',
    params
  }).then(res => res.data).catch(e => e)
}

export function recharge(params: { targetUid: string, goldNum: number }) {
  return service<Response<{ purseGoldNum: number }>>({
    method: 'POST',
    url: '/agency/gold/recharge',
    params
  }).then(res => res.data).catch(e => e)
}

export function getUserPurse() {
  return service<Response<AgentRecharge.UserPurse>>({
    url: '/agency/user/purse'
  }).then(res => res.data).catch(e => e)
}

export function getConf() {
  return service<Response<AgentRecharge.Conf>>({
    url: '/agency/conf'
  }).then(res => res.data).catch(e => e)
}

// 国家列表
export function getCountryList(searchKey?: string) {
  return service<Response<AgentRecharge.CountryList[]>>({
    method: 'GET',
    url: 'country/info/list',
    params: {
      searchKey
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 充值代理人列表(新)
export function getUsersListV2() {
  return service<Response<AgentRecharge.UsersListV2[]>>({
    method: 'GET',
    url: 'agency/getUsersListV2',
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// Cash提现列表
export function withdrawalList() {
  return service<Response<AgentRecharge.WithdrawalList[]>>({
    method: 'GET',
    url: '/withdrawal/list',
  })
    .then((res) => res.data)
    .catch((e) => e)
}

// 选择代理国家
export function agencyUpdateAgencyCountry(countryCodes: string) {
  return service<Response>({
    method: 'POST',
    url: '/agency/updateAgencyCountry',
    params: {
      countryCodes // 多个用,分隔
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}


// 代理给提现用户打招呼
export function messageSendGreet(toUserEaseMobId: string) {
  return service<Response>({
    method: 'POST',
    url: '/message/send/greet',
    params: {
      toUserEaseMobId,
    }
  })
    .then((res) => res.data)
    .catch((e) => e)
}