import { useCallback } from 'react'
import { useTrans } from '@/hooks/useTranslation'
import './User.scss'

interface LoveCardUserProps {
  user: LoveCard.User
  showGiveFrom?: boolean
  onClick?: (user: LoveCard.User) => void
}

export default function LoveCardUser(props: LoveCardUserProps) {
  const { t } = useTrans()

  const handleClick = useCallback(() => {
    props.onClick && props.onClick(props.user)
  }, [])

  return <div className="cmp-user" onClick={handleClick}>
    <img className="cmp-user__avatar" src={props.user.avatar} />
    <div className="cmp-user__info">
      <span className="cmp-user__nick">{props.showGiveFrom ? t('Wallet.LoveCard.GiveFrom', { nick: props.user.nick }) : props.user.nick}</span>
      <span className="cmp-user__id">{t('Wallet.LoveCard.ID', { id: props.user.erbanNo })}</span>
    </div>
  </div>
}