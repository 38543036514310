import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { getWithdrawList } from '../../../utils/request'
import moment from 'moment'
import { toast } from '../../../utils/bridge'
import ImageArrow from '../../../images/arrow.png'
import clsx from 'clsx'
import { DatePicker } from 'antd-mobile'
import ImageArrow2 from '../images/arrow2.png'
import ScrollList from '../../../components/ScrollList'
import { useTrans } from '@/hooks/useTranslation'
import './Withdraw.scss'

type WithdrawProps = {
  onClose: () => void
}

type ListProps = {
  page: number
  empty: boolean
  hasMore: boolean
  totalDiamondNum: number
  list: Guild.WithdrawInfo[]
}

function getMonthTimestamp(month?: number): number {
  month = month || Number(moment().format('MM'))
  const monthStr = month >= 10 ? month.toString() : `0${month}`
  return moment(moment().format(`YYYY-${monthStr}-01`))
    .local()
    .valueOf()
}

function Withdraw(props: WithdrawProps) {
  const { t } = useTrans()
  const [processingList, setProcessingList] = useState<ListProps>({
    page: 0,
    empty: false,
    hasMore: true,
    totalDiamondNum: 0,
    list: []
  })
  const refProcessingList = useRef<ListProps>(processingList)
  refProcessingList.current = processingList

  const [processedList, setProcessedList] = useState<ListProps>({
    page: 0,
    empty: false,
    hasMore: true,
    totalDiamondNum: 0,
    list: []
  })
  const refProcessedList = useRef<ListProps>(processedList)
  refProcessedList.current = processedList

  const [fallList, setFallList] = useState<ListProps>({
    page: 0,
    empty: false,
    hasMore: true,
    totalDiamondNum: 0,
    list: []
  })
  const refFallList = useRef<ListProps>(fallList)
  refFallList.current = fallList

  const [withdrawalStatus, setWithdrawalStatus] = useState<number>(0)
  const refWithdrawalStatus = useRef(withdrawalStatus)
  refWithdrawalStatus.current = withdrawalStatus

  const [time, setTime] = useState<number>(getMonthTimestamp())
  const refTime = useRef(time)
  refTime.current = time

  const [selectTime, setSelectTime] = useState<boolean>(false)

  const handleGetWithdrawList = useCallback(
    async (withdrawalStatus: number, time: number, reset: boolean) => {
      let params: Guild.WithdrawParam
      let pageNum: number
      const pageSize = 10
      switch (withdrawalStatus) {
        default:
          return
        case 0:
          if (!reset && !refProcessingList.current.hasMore) return

          pageNum = reset ? 1 : refProcessingList.current.page + 1
          params = {
            pageNum,
            pageSize,
            withdrawalStatus,
            startTimeMillis: time,
            endTimeMillis: moment(time).add(1, 'month').valueOf()
          }
          break
        case 1:
          if (!reset && !refProcessedList.current.hasMore) return

          pageNum = reset ? 1 : refProcessedList.current.page + 1
          params = {
            pageNum,
            pageSize,
            withdrawalStatus,
            startTimeMillis: time,
            endTimeMillis: moment(time).add(1, 'month').valueOf()
          }
          break
        case 2:
          if (!reset && !refFallList.current.hasMore) return

          pageNum = reset ? 1 : refFallList.current.page + 1
          params = {
            pageNum,
            pageSize,
            withdrawalStatus,
            startTimeMillis: time,
            endTimeMillis: moment(time).add(1, 'month').valueOf()
          }
          break
      }
      const res = await getWithdrawList(params)
      if (res instanceof Error) {
        return
      }

      const { code, message, data } = res
      if (code !== 200) {
        toast(message)
        return
      }

      const { list, whetherLastPage, totalDiamondNum } = data
      switch (withdrawalStatus) {
        case 0:
          setProcessingList({
            page: pageNum,
            empty: pageNum === 1 && !list.length,
            hasMore: !whetherLastPage,
            totalDiamondNum:
              refProcessingList.current.totalDiamondNum || totalDiamondNum,
            list: [
              ...(pageNum === 1 ? [] : refProcessingList.current.list),
              ...list
            ]
          })
          break
        case 1:
          setProcessedList({
            page: pageNum,
            empty: pageNum === 1 && !list.length,
            hasMore: !whetherLastPage,
            totalDiamondNum:
              refProcessedList.current.totalDiamondNum || totalDiamondNum,
            list: [
              ...(pageNum === 1 ? [] : refProcessedList.current.list),
              ...list
            ]
          })
          break
        case 2:
          setFallList({
            page: pageNum,
            empty: pageNum === 1 && !list.length,
            hasMore: !whetherLastPage,
            totalDiamondNum:
              refFallList.current.totalDiamondNum || totalDiamondNum,
            list: [...(pageNum === 1 ? [] : refFallList.current.list), ...list]
          })
          break
        default:
      }
    },
    []
  )

  useEffect(() => {
    handleGetWithdrawList(withdrawalStatus, time, true)
  }, [handleGetWithdrawList, time, withdrawalStatus])

  return (
    <div className="cmp-withdraw">
      <div className="cmp-withdraw__header">
        <div className="cmp-withdraw__title">{t('Guild.MemberWithdraw')}</div>
        <div className="cmp-withdraw__close" onClick={props.onClose}>
          <img alt="member list close" src={ImageArrow} />
        </div>
      </div>
      <div className="cmp-withdraw__tab">
        {['Processing', 'Processed', 'Fail'].map((name, i) => (
          <div
            className={clsx('cmp-withdraw__tab-item', {
              selected: withdrawalStatus === i
            })}
            onClick={() => setWithdrawalStatus(i)}
            key={i}>
            <div className="tab-item__name">{t(`Guild.${name}`)}</div>
          </div>
        ))}
      </div>
      <div className="cmp-withdraw__bar">
        <div className="cmp-withdraw__bar-left">
          <div className="cmp-withdraw__bar-box" />
          <div
            className="cmp-withdraw__bar-total"
            dangerouslySetInnerHTML={{
              __html: t('Guild.TotalGem2', {
                total: processingList.totalDiamondNum
              }).toString()
            }}
          />
        </div>
        <div className="cmp-withdraw__bar-right">
          <div className="time" onClick={() => setSelectTime(true)}>
            <span>{moment(time).format('YYYY-MM')}</span>
            <img alt="img" src={ImageArrow2} />
          </div>
        </div>
      </div>
      <div className="cmp-withdraw__list">
        {withdrawalStatus === 0 && (
          <ScrollList
            hasMore={processingList.hasMore}
            loadMore={() => handleGetWithdrawList(0, time, false)}
            empty={processingList.empty}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {processingList.list.map((item, i) => (
              <div className="cmp-withdraw__item card" key={i}>
                <div className="card__info">
                  <div className="card__info-left info">
                    <img alt="img" className="info__avatar" src={item.avatar} />
                    <div className="info__basic">
                      <div className="info__basic-nick">{item.nick}</div>
                      <div className="info__basic-id">ID: {item.erbanNo}</div>
                    </div>
                  </div>
                  <div className="card__info-right info">
                    <div className="info__time">
                      {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div className="info__status">{t('Guild.Processing')}</div>
                  </div>
                </div>
                <div
                  className="card__gem"
                  dangerouslySetInnerHTML={{
                    __html: t('Guild.WithdrawGem', {
                      total: item.diamondNum
                    }).toString()
                  }}></div>
              </div>
            ))}
          </ScrollList>
        )}
        {withdrawalStatus === 1 && (
          <ScrollList
            hasMore={processedList.hasMore}
            loadMore={() => handleGetWithdrawList(1, time, false)}
            empty={processedList.empty}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {processedList.list.map((item, i) => (
              <div className="cmp-withdraw__item card" key={i}>
                <div className="card__info">
                  <div className="card__info-left info">
                    <img alt="img" className="info__avatar" src={item.avatar} />
                    <div className="info__basic">
                      <div className="info__basic-nick">{item.nick}</div>
                      <div className="info__basic-id">ID: {item.erbanNo}</div>
                    </div>
                  </div>
                  <div className="card__info-right info">
                    <div className="info__time">
                      {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div className="info__status done">
                      {t('Guild.Processed')}
                    </div>
                  </div>
                </div>
                <div
                  className="card__gem"
                  dangerouslySetInnerHTML={{
                    __html: t('Guild.WithdrawGem', {
                      total: item.diamondNum
                    }).toString()
                  }}></div>
              </div>
            ))}
          </ScrollList>
        )}
        {withdrawalStatus === 2 && (
          <ScrollList
            hasMore={fallList.hasMore}
            loadMore={() => handleGetWithdrawList(2, time, false)}
            empty={fallList.empty}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {fallList.list.map((item, i) => (
              <div className="cmp-withdraw__item card" key={i}>
                <div className="card__info">
                  <div className="card__info-left info">
                    <img alt="img" className="info__avatar" src={item.avatar} />
                    <div className="info__basic">
                      <div className="info__basic-nick">{item.nick}</div>
                      <div className="info__basic-id">ID: {item.erbanNo}</div>
                    </div>
                  </div>
                  <div className="card__info-right info">
                    <div className="info__time">
                      {moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div className="info__status fail">{t('Guild.Fail')}</div>
                  </div>
                </div>
                <div
                  className="card__gem"
                  dangerouslySetInnerHTML={{
                    __html: t('Guild.WithdrawGem', {
                      total: item.diamondNum
                    }).toString()
                  }}></div>
              </div>
            ))}
          </ScrollList>
        )}
      </div>
      <DatePicker
        visible={selectTime}
        onClose={() => setSelectTime(false)}
        precision="month"
        max={new Date()}
        defaultValue={moment(time).toDate()}
        onConfirm={(val) => {
          setTime(moment(val).valueOf())
        }}
      />
    </div>
  )
}

export default memo(Withdraw)
