import { ChangeEvent } from 'react';
import Pane from '@/components/Pane';
// import SafeArea from '@/components/SafeArea';
import './TransferContent.scss';
import clsx from 'clsx';
import { isAr } from '@/utils/tool';
import { Trans } from 'react-i18next';
import { useTrans } from "@/hooks/useTranslation"

interface PropsType {
  transferVisible?: boolean
  state: {
    appID?: string
    user?: AgentRecharge.UserInfo
    inputNum?: string
  }
  loading?: boolean
  readOnly?: boolean
  agencyMsg?: string
  AttentionInfo2TipName?: string
  AttentionInfo2TipAction?: string
  AmountSend?: string
  confirmText?: string
  isBD: boolean
  hanndleAppIDChange?: (e: ChangeEvent<HTMLInputElement>) => void
  handleFetchUser?: () => void
  handleClearUser?: () => void
  hanndleAmountChange?: (e: ChangeEvent<HTMLInputElement>) => void
  handleRechage?: () => void
}
export default function TransferContent({ AmountSend, confirmText, transferVisible, readOnly, state, loading, agencyMsg, AttentionInfo2TipName, AttentionInfo2TipAction, handleRechage, hanndleAppIDChange, handleFetchUser, handleClearUser, hanndleAmountChange, isBD }: PropsType) {
  const { t } = useTrans()
  return <div className={clsx("cmp-transfer-content", { isBD })}>
    <Pane title={confirmText ? t(confirmText || '', {
      text: state.user ? t('AgentRecharge.Check') : t('AgentRecharge.Confirm')
    }) : t('Wallet.ConfirmUserID')} className='user-input'>
      <div className='cmp-transfer-content__input'>
        {!state.user && <div className='cmp-transfer-content__input-inp'>
          <input type='tel' inputMode="numeric" pattern="\d*" onChange={hanndleAppIDChange ? hanndleAppIDChange : () => { }} placeholder={t('AgentRecharge.UserID').toString()} value={state.appID} />
        </div>}
        {state.user && <div className='cmp-transfer-content__user'>
          <img className='cmp-transfer-content__user-avatar' alt='img' src={state.user.avatar} />
          <div className="cmp-transfer-content__user-info">
            <span className={clsx('cmp-transfer-content__user-info-name', { isAr: isAr(state.user.nick) })}>{state.user.nick}</span>
            <span className='cmp-transfer-content__user-info-id'>{'ID:' + state.user.erbanNo}</span>
          </div>
        </div>}
        {!state.user && <div className='cmp-transfer-content__confirm' onClick={handleFetchUser}>{t('Common.Confirm')}</div>}
        {state.user && !transferVisible && <div className='cmp-transfer-content__cancel' onClick={handleClearUser}></div>}
      </div>
      {!state.user && <span className='tips'>{t('AgentRecharge.CheckConfirm')}</span>}
    </Pane>
    {state.user &&
      <Pane title={AmountSend ? t(AmountSend || '', { text: state.inputNum ? t('AgentRecharge.Check') : t('AgentRecharge.Enter') }) : t('AgentRecharge.AmountTransfer')}>
        <div className="cmp-transfer-content__amount">
          <input readOnly={readOnly} type='tel' inputMode="numeric" pattern="\d*" onChange={hanndleAmountChange ? hanndleAmountChange : () => { }} placeholder={agencyMsg} value={state.inputNum} />
        </div>
      </Pane>
    }
    {isBD && <div className={clsx('cmp-transfer-content__bottom', { 'bottom-clsx': state.user })} >
      <div className="info-box">
        <span className="info-tip">{t('AgentRecharge.AttentionInfo1')}</span>
        <span className="info-tip">
          <Trans i18nKey="AgentRecharge.AttentionInfo2" values={{ strong: t('AgentRecharge.AttentionInfo2Strong') }}>
            <strong />
          </Trans>
        </span>
        <span className="info-tip">{t('AgentRecharge.AttentionInfo3')}</span>
        <button className={clsx('cmp-transfer-content__bottom-confirm', { 'transfer-loading': loading })} disabled={loading} onClick={handleRechage}>{loading ? AmountSend ? t('AgentRecharge.BigSend') : t('AgentRecharge.Transfer') + '...' : AmountSend ? t('AgentRecharge.BigSend') : t('AgentRecharge.Transfer')}</button>
        {/* <SafeArea position='bottom' /> */}
      </div>
    </div>}
    {!isBD && <div className={clsx('cmp-transfer-content__bottom', { 'bottom-clsx': state.user })} >
      <div className="info-box">
        <span className="info-tip">{t('AgentRecharge.AttentionInfo1')}</span>
        <span className="info-tip">{t('AgentRecharge.AttentionInfo4')}</span>
        <span className="info-tip">{t('AgentRecharge.AttentionInfo5')}</span>
      </div>
      <button className={clsx('cmp-transfer-content__bottom-confirm', { 'transfer-loading': loading })} disabled={loading} onClick={handleRechage}>{loading ? AmountSend ? t('AgentRecharge.BigSend') : t('AgentRecharge.Transfer') + '...' : AmountSend ? t('AgentRecharge.BigSend') : t('AgentRecharge.Transfer')}</button>
      {/* <SafeArea position='bottom' /> */}
    </div>}
  </div>
}