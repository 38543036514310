import { HelmetProvider } from "react-helmet-async"
import Meta from "../../components/Meta"
import Announce from "./Components/Announce"
import InfoList from "./Components/InfoList"
import Top3 from "./Components/Top3"
import GuildHeader from './Components/GuildHeader'
import ImageGuildCup from '../../images/guild-cup.png'
import ImageGuildMembers from '../../images/guild-members.png'
import ImageGuildFire from '../../images/guild-fire.png'
import './Detail.scss'
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router"
import { applyGuild, getGuildDetail } from "../../utils/request"
import GuildLoading from "../../components/Loading"
import clsx from "clsx"
import { toast } from "../../utils/bridge"
import { useTrans } from "@/hooks/useTranslation"
import SafeArea from "../../components/SafeArea"
import DetailDialog from "./Components/DetailDialog"

export default function GuildDetail() {
  const { t } = useTrans()
  const [loading, setLoading] = useState(true)
  const { guildId } = useParams()
  const [guild, setGuild] = useState<Guild.Item>()
  const [visible, setVisible] = useState(false)
  const fetchGuildDetail = useCallback(async () => {
    const res = await getGuildDetail(guildId || '')
    setLoading(false)

    const { code, message, data } = res
    if (code !== 200) {
      toast(message)
      return
    }

    setGuild(data)
  }, [guildId])

  const handleApplyGuild = useCallback(async (isJoin: boolean) => {
    setVisible(true)
    if (!isJoin) {
      return
    }
    setVisible(false)
    setLoading(true)
    const res = await applyGuild(guildId || '')
    setLoading(false)

    if (res instanceof Error) {
      return
    }

    const { code, message } = res
    if (code !== 200) {
      toast(message)
      return
    }

    toast(t('Guild.Tips.ApplicationSubmitted'))
  }, [guildId])

  useEffect(() => {
    fetchGuildDetail()
  }, [fetchGuildDetail])

  return <HelmetProvider>
    <Meta title={t("Guild.Tips.GuildPage").toString()} />
    <div className={clsx("pg pg-guild-detail", {
      loading
    })}>
      <GuildHeader
        title={t("Guild.Tips.GuildPage").toString()}
        guild={guild && {
          guildName: guild.guildName,
          guildSlogan: guild.guildSlogan,
          coverPicUrl: guild.coverPicUrl,
          guildNo: guild.guildId.toString(),
          guildLevelIcon: guild.guildLevelIcon,
        }}
        infoCardItems={[
          { image: ImageGuildCup, text: guild?.rankNo ? `No.${guild.rankNo}` : '' },
          { image: ImageGuildMembers, text: (guild?.memberCount || '').toString() },
          { image: ImageGuildFire, text: (guild?.powerValue || '').toString() },
        ]} />
      <Announce text={guild?.guildSlogan || ''} />
      <InfoList items={[
        { key: 'Guild ID', val: (guild?.guildNo || '').toString() },
        { key: 'Founder', val: guild?.founderNick || '' }
      ]} />
      {guild?.guildStar?.length && <Top3 items={guild.guildStar} />}
      <SafeArea position="bottom" />
      <div className="pg-guild-detail__bottom">
        <div className="pg-guild-detail__btn-apply" onClick={() => { handleApplyGuild(false) }}>
          <div className="pg-guild-detail__btn-apply-in">{t('Guild.Tips.JoinTheGuild')}</div>
        </div>
        <SafeArea position="bottom" />
      </div>
      {loading && <GuildLoading />}
      {visible && <DetailDialog visible={visible} setVisible={setVisible} handleApplyGuild={handleApplyGuild} />}
    </div>
  </HelmetProvider>
}
