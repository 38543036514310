import React, { memo, useMemo } from 'react'
// import ImageLevel1 from '@/images/LV1.webp'
// import ImageLevel2 from '@/images/LV2.webp'
// import ImageLevel3 from '@/images/LV3.webp'
// import ImageLevel4 from '@/images/LV4.webp'
// import ImageLevel5 from '@/images/LV5.webp'
import clsx from 'clsx'
import ImgNowIcon from '@/images/now-icon.webp';
import { formatCharmValue } from '@/utils/format';
import { useTrans } from '@/hooks/useTranslation';
import './RuleTable.scss'

function RuleTable({ now }: { now?: number }) {
    const { t } = useTrans()

    const levelItems = useMemo(() => {
        return [
            { rate: '6%', level: 'D', experience: `0-${formatCharmValue(1000000)}` },
            { rate: '12%', level: 'C', experience: `${formatCharmValue(1000000)}-${formatCharmValue(5000000)}` },
            { rate: '14%', level: 'B', experience: `${formatCharmValue(5000000)}-${formatCharmValue(25000000)}` },
            { rate: '16%', level: 'A', experience: `${formatCharmValue(25000000)}-${formatCharmValue(80000000)}` },
            { rate: '20%', level: 'S', experience: `${formatCharmValue(80000000)}+` }
        ]
    }, [])
    return (
        <div className='cmp-guild-agency-reward-rule-table'>
            <table cellPadding={0} cellSpacing={0}>
                <thead><tr>
                    {['Common.Rate', 'Common.Level', 'Guild.AgencyReward.GemIncome'].map(key => <th key={key}>{t(key)}</th>)}
                </tr></thead>
                <tbody>{levelItems.map((item, i) => <tr key={i} className={clsx({ selected: now === i + 1 })}>
                    <td>
                        <div>
                            <img src={ImgNowIcon}></img>
                            <span>{item.rate}</span>
                        </div>
                    </td>
                    <td className='lv-box'>
                        <div>
                            {/* <img alt='img' src={item.level} /> */}
                            <span>{item.level}</span>
                        </div>
                    </td>
                    <td>{item.experience}</td>
                </tr>)}</tbody>
            </table>
        </div>
    )
}

export default memo(RuleTable)