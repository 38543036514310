import ImagePkRuleEn from './images/cp-rule.webp'
import ImagePkRuleAr from './images/cp-rule-ar.webp'
import WalletHeader from '../Wallet/components/Header';
import { useTrans } from '@/hooks/useTranslation';
import './Cp.scss';

export default function RuleTurntable() {
  const { i18n } = useTrans()

  return (
    <div className='pg-rule-cp'>
      <WalletHeader title='CP' />
      <img src={i18n.language === 'ar' ? ImagePkRuleAr : ImagePkRuleEn} />
    </div>
  )
}
