import { HelmetProvider } from 'react-helmet-async'
import Meta from '../../components/Meta'
import ImageWhatsAPP from '../../images/whatsAPP.png'
import ImageGmail from '../../images/gmail.png'
import Pane from '../../components/Pane'
import HelpItem from './Components/HelpItem'
import SafeArea from '../../components/SafeArea'
import { useTrans } from '@/hooks/useTranslation'
import './Index.scss'

const helpItems = [
  { title: 'Help.CustomerServiceWhatsAPP', image: ImageWhatsAPP, name: 'WhatsAPP', value: process.env.REACT_APP_WHATSAPP! },
  { title: 'Help.OfficialEmailAddress', image: ImageGmail, name: 'Email', value: process.env.REACT_APP_EMAIL! }
]

export default function Help() {
  const { t } = useTrans()

  return <HelmetProvider>
    <Meta title={t('Common.Help').toString()} />
    <SafeArea position='top' />
    <div className='pg-help'>
      <div className='pg-help__content'>
        {helpItems.map((item, i) => <Pane title={t(item.title)} key={i}>
          <HelpItem name={item.name} image={item.image} value={item.value} />
        </Pane>)}
      </div>
    </div>
    <SafeArea position='bottom' />
  </HelmetProvider>
}