import { useTrans } from "@/hooks/useTranslation"
import './NumberCard.scss'
import clsx from 'clsx'

type NumberCardProps = {
  class?: string | object
  items: Guild.NumberCardItem[]
  onClick?: () => void
}

export default function NumberCard(props: NumberCardProps) {
  const { t } = useTrans()
  return (
    <div
      className={clsx('cmp-number-card', props.class)}
      onClick={props.onClick}>
      {props.items.map((item) => (
        <div className={clsx("cmp-number-card__item", item.className)} key={item.key} onClick={item.itemClick && item.itemClick}>
          <div className="cmp-number-card__item-val">{item.val}</div>
          <div className="cmp-number-card__item-key">{t(item.key)}</div>
        </div>
      ))}
    </div>
  )
}

export function NumberCard2(props: NumberCardProps) {
  const { t } = useTrans()
  return (
    <div
      className={clsx('cmp-number-card2', props.class)}
      onClick={props.onClick}>
      {props.items.map((item) => (
        <div className={clsx("cmp-number-card2__item", item.className)} key={item.key} onClick={item.itemClick && item.itemClick}>
          <div className="cmp-number-card2__item-val">{item.val}</div>
          <div className="cmp-number-card2__item-key">{t(item.key)}</div>
        </div>
      ))}
    </div>
  )
}
