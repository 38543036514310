import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '@/components/Header'
import Meta from '@/components/Meta'
import MemberCard from '@/pages/Guild/Components/MemberCard'
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import SelectTimeRange from '@/components/SelectTimeRange'
import SafeArea from '@/components/SafeArea';
import { NumberCard2 } from '../Components/NumberCard'
import { getMissionMemberList } from '@/utils/request';
import { toast } from '@/utils/bridge';
import Loading from '@/components/Loading'
import { useTrans } from '@/hooks/useTranslation'
import './MemberList.scss'

function MemberList() {
    const { t } = useTrans()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [time, setTime] = useState<{ start: Date; end: Date }>({
        start: moment().startOf('month').toDate(),
        end: moment().startOf('date').toDate()
    })
    const refTime = useRef(time)
    refTime.current = time

    const [missionMemberList, setMissionMemberList] = useState<Guild.GuildMissionMemberList[]>([])
    const handleTimeRangeChange = useCallback((start: Date, end: Date) => {
        setTime({ start, end })
    }, [])
    const fetchMissionMemberList = useCallback(async () => {
        setLoading(true)
        const startDate = moment(refTime.current.start)
            .startOf('day')
            .format('YYYY-MM-DD')
        const endDate = moment(refTime.current.end)
            .startOf('day')
            .format('YYYY-MM-DD')
        const { code, data, message } = await getMissionMemberList({ startDate, endDate }) as Response<Guild.GuildMissionMemberList[]>
        setLoading(false)
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setMissionMemberList(data)
    }, [])
    const handleDetail = useCallback((memberId: number) => {
        navigate('/guild/task-data/member-detail/' + memberId)
    }, [])
    useEffect(() => {
        fetchMissionMemberList()
    }, [fetchMissionMemberList, time])
    return (
        <HelmetProvider>
            <Meta title={t('Guild.TaskData').toString()} />
            <div className='pg-guild-task-data-member-list'>
                <Header title={t('Guild.TaskData').toString()} />
                <div className="content">
                    <SelectTimeRange
                        startTime={time.start}
                        endTime={time.end}
                        onChange={handleTimeRangeChange}
                    />
                    {missionMemberList.map((item, i) => <div className="list-item" key={i}>
                        <MemberCard
                            memberId={item.uid}
                            avatar={item.avatar}
                            username={item.nick}
                            erbanNo={item.erbanNo}
                            rightBtn={
                                {
                                    text: 'Common.Details',
                                    onRightBtn: () => handleDetail(item.uid)
                                }
                            }
                        />
                        <NumberCard2
                            items={[
                                {
                                    key: 'Guild.TaskReward',
                                    val: item.taskReward || 0
                                },
                                {
                                    key: 'Guild.Completed',
                                    val: item.taskCompleted || 0
                                }
                            ]}
                        />
                    </div>)}

                    <SafeArea position='bottom'></SafeArea>
                </div>
                {loading && <Loading />}
            </div>
        </HelmetProvider>

    )
}

export default MemberList