import clsx from 'clsx'
import ImageTop1Cover from '../../../images/guild-top1-avatar.png'
import ImageTop2Cover from '../../../images/guild-top2-avatar.png'
import ImageTop3Cover from '../../../images/guild-top3-avatar.png'
import ImageTop1Bg from '../../../images/guild-top1-bg.png'
import ImageTop2Bg from '../../../images/guild-top2-bg.png'
import ImageTop3Bg from '../../../images/guild-top3-bg.png'
import './Top3.scss'
import { useEffect, useState } from 'react'

type TopItem = {
  avatar:string
}

type Top3Props = {
  items:TopItem[]
}

type Top3RenderItem = {
  avatar:string
  cover:string
  bg:string
}

const coverItems = [ ImageTop1Cover, ImageTop2Cover, ImageTop3Cover ]
const bgItems = [ ImageTop1Bg, ImageTop2Bg, ImageTop3Bg ]

export default function Top3(props:Top3Props) {
  const [ items, setItems ] = useState<Top3RenderItem[]>([])

  useEffect(() => {
    setItems(props.items.map((item, i) => ({
      avatar: item.avatar,
      cover: coverItems[i],
      bg: bgItems[i]
    })))
  }, [ props.items ])  

  return <div className='cmp-top3'>
    {items.map((item, i) => <div className={clsx('cmp-top3__item', 'item' + i)} key={i}>
      <img alt="top bg" className='cmp-top3__item-bg' src={item.bg} />
      <div className='cmp-top3__item-avatar'>
        {item.avatar && <img alt="top avatar" className='cmp-top3__item-avatar-img' src={item.avatar} />}
        <img alt="top cover" className='cmp-top3__item-avatar-cover' src={item.cover + '?w=116&h=116'} />
      </div>
    </div>)}
  </div>
}