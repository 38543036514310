import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';
import ImageListEmpty from '@/images/list-empty.png'
import { px2rem } from '@/utils/tool';
import { useTrans } from '@/hooks/useTranslation';
import './ScrollTable.scss'

interface Column {
    header: string;
    key: string;
    render?: (text: any, record: Record<string, any>) => React.ReactNode;
}

interface Props {
    columns: Column[];
    data: Record<string, any>[];
    className?: string
}

const ScrollTable: FC<Props> = ({ columns, data, className }) => {
    const { t } = useTrans()
    const refScrollTable = useRef<HTMLDivElement | null>(null)
    const refEmpty = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const rect = refScrollTable.current?.getBoundingClientRect()
        refEmpty.current?.style.setProperty('--w', px2rem(rect?.width || 0))
    }, [])
    return (
        <div className={clsx("cmp-scroll-table", className)} ref={refScrollTable}>
            <div className={clsx('cmp-scroll-table__content', { 'scroll': columns.length > 3 })}>
                <table>
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index} >{t(column.header)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length !== 0 ? data.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {columns.map((column, colIndex) => (
                                    <td key={colIndex}>
                                        {column.render
                                            ? column.render(row[column.key], row)
                                            : row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        )) :
                            <>
                                <tr>
                                    <td colSpan={columns.length} style={{ padding: 'unset' }}>
                                        <div className='cmp-scroll-table__empty' ref={refEmpty}>
                                            <img src={ImageListEmpty}></img>
                                            <span>{t('Common.NoData')}</span>
                                        </div>
                                    </td>
                                </tr>
                            </>}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ScrollTable;
