import { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import useBillRecordInfoList from './useBillRecordInfoList ';

function useDetailLogic(linkageData: Wallet.FirstBillTree, initialTab = 0, initialType = 0) {
    const {
        setFirstRender,
        loading,
        time,
        setTime,
        handleTimeRangeChange,
        listInfo,
        setListInfo,
        billTypeId,
        setBillTypeId,
        fetchBillList,
    } = useBillRecordInfoList();

    //二级分类选项
    const [selectedTab, setSelectedTab] = useState<number>(initialTab);
    const selectedTabRef = useRef(selectedTab);
    selectedTabRef.current = selectedTab;
    //三级分类选项
    const [selectedType, setSelectedType] = useState<number>(initialType);
    const selectedTypeRef = useRef(selectedType);
    selectedTypeRef.current = selectedType;
    //三级分类数目
    const [typeItems, setTypeItems] = useState<Wallet.ThirdBillTree[]>([]);
    //三级分类id
    const billTypeIdRef = useRef(billTypeId)
    billTypeIdRef.current = billTypeId
    /* url参数切换二、三级分类（使用tab=1,type=1）：未完善，还需title比较，发请求 */
    // useEffect(() => {
    //     if (initialTab !== 0) {
    //         const validInitialTab = initialTab >= 0 && initialTab < linkageData?.secondBillTree?.length ? initialTab : 0;
    //         setSelectedTab(validInitialTab)
    //         setBillTypeId(linkageData?.secondBillTree[validInitialTab]?.thirdBillTree[0]?.billTypeId)
    //     }
    // }, [initialTab, linkageData])
    // useEffect(() => {
    //     if (initialType !== 0) {
    //         const validInitialType = initialType >= 0 && initialType < (linkageData?.secondBillTree && linkageData?.secondBillTree[selectedTabRef.current]?.thirdBillTree?.length) ? initialType : 0;
    //         setSelectedType(validInitialType)
    //         setBillTypeId(typeItems[validInitialType]?.billTypeId)
    //     }
    // }, [initialType, linkageData, typeItems])

    /* 初始化数据 */
    useEffect(() => {
        if (linkageData?.secondBillTree && linkageData?.secondBillTree[selectedTabRef.current]?.thirdBillTree) {
            const billTypeId = linkageData?.secondBillTree[selectedTabRef.current].thirdBillTree[0]?.billTypeId;
            billTypeIdRef.current = billTypeId;
            setTypeItems(
                linkageData?.secondBillTree[selectedTabRef.current]?.thirdBillTree?.map(item => ({
                    billTypeName: item?.billTypeName,
                    billTypeId: item?.billTypeId
                }))
            );
            setBillTypeId(linkageData?.secondBillTree[selectedTabRef.current]?.thirdBillTree[0]?.billTypeId)
        }
    }, [linkageData]);

    /* 二级分类切换 */
    const handleTabsClick = useCallback((key: number) => {
        if (selectedTabRef.current === key) return
        setFirstRender(true)
        setTypeItems(
            linkageData.secondBillTree[key]?.thirdBillTree?.map(item => ({
                billTypeName: item?.billTypeName,
                billTypeId: item?.billTypeId
            }))
        );
        setSelectedTab(key);
        setListInfo({
            list: [],
            init: true,
            hasMore: true
        });
        //初始化时间，初始化三级分类
        setSelectedType(0)
        setTime(moment().startOf('day').format('YYYY-MM-DD'))
        //重新设置三级分类id
        setBillTypeId(linkageData?.secondBillTree[key]?.thirdBillTree[0]?.billTypeId)
    }, [linkageData]);

    /* 三级分类confirm确定事件 */
    const handleTypeConfirm = useCallback((i: number) => {
        if (selectedTypeRef.current === i) return
        setFirstRender(true)
        setBillTypeId(typeItems[i]?.billTypeId)
        setListInfo({
            list: [],
            init: true,
            hasMore: true
        });
        setSelectedType(i);
    }, [typeItems]);
    return {
        loading,
        selectedTab,
        selectedType,
        time,
        handleTimeRangeChange,
        typeItems,
        listInfo,
        handleTabsClick,
        handleTypeConfirm,
        fetchBillList
    };
}

export default useDetailLogic;
