import { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { InfoCardItem } from './InfoCard'
import ImageHome from '@/images/icon-home.webp'
import ImageUser from '@/images/icon-user.webp'
import ImagePlus from '@/images/icon-plus.webp'
import { useTrans } from '@/hooks/useTranslation';
import './GuildHeader.scss'

interface AgencyCardProps {
  item?: Guild.GuildApplyInfo
}

function AgencyCard(props: AgencyCardProps) {
  const { t } = useTrans()
  const [agency, setAgency] = useState<Guild.GuildApplyInfo>()

  useEffect(() => {
    setAgency(props.item)
  }, [props.item])

  return <div className='cmp-card-agency'>
    {agency && <><div className='cmp-card-agency__info'>
      <div className='cmp-card-agency__info-avatar'>
        <div className='cmp-card-agency__info-avatar-inner'>
          <img src={agency.coverPicUrl} />
        </div>
      </div>
      <div className='cmp-card-agency__info-text'>
        <span className='cmp-card-agency__info-name'>{agency.guildName}</span>
        <span className='cmp-card-agency__info-id'>{t('Guild.ID', { id: agency.guildNo })}</span>
      </div>
    </div>
      <div className='cmp-card-agency__slogan'>{agency.guildSlogan}</div>
      <img className='cmp-card-agency__icon' src={agency.guildLevelIcon} />
    </>}
  </div>
}

interface MemberProps {
  item: Guild.MemberItem
}

function Member(props: MemberProps) {
  return <div className='cmp-member'>
    <div className='cmp-member__avatar'>
      <div className='cmp-member__avatar-inner'>
        <img src={props.item.avatar} />
      </div>
      {props.item.memberRole === 1 && <img className='cmp-member__icon' src={ImageHome} />}
      {props.item.memberRole === 2 && <img className='cmp-member__icon' src={ImageUser} />}
    </div>
    <div className='cmp-member__name'>{props.item.nick}</div>
  </div>
}

interface MemberCardProps {
  totalMember: number
  members: Guild.MemberItem[]
  onInvite?: () => void
  onViewMember?: () => void
}

function MemberCard(props: MemberCardProps) {
  const { t } = useTrans()
  const [totalMember, setTotalMember] = useState(0)
  const [members, setMembers] = useState<Guild.MemberItem[]>([])

  useEffect(() => {
    // 如果管理超过4个
    const managerCount = props.members.filter(item => [1, 2].includes(item.memberRole)).length
    const count = Math.max(4, managerCount)
    setMembers(props.members.slice(0, count))
  }, [props.members])

  useEffect(() => {
    setTotalMember(props.totalMember)
  }, [props.totalMember])

  return <div className='cmp-card-member'>
    <div className='cmp-card-member__top'>
      <div className='cmp-card-member__top-title'>
        {t('Common.Member')}
      </div>
      <div className='cmp-card-member__top-count' onClick={props.onViewMember}>
        {t('Guild.ViewMember', { n: totalMember })}
      </div>
    </div>
    <div className='cmp-card-member__box'>
      <div className='cmp-card-member__list'>
        {members.map(member => <Member item={member} key={member.uid} />)}
      </div>
      <img className='cmp-card-member__plus' src={ImagePlus} onClick={props.onInvite} />
    </div>

  </div>
}

type GuildHeaderProps = {
  paddingTop?: string
  guild?: Guild.GuildApplyInfo
  level?: number
  levelProgress?: number
  title?: string
  infoCardItems: InfoCardItem[]
  totalMember?: number
  children?: any
  onCardClick?: (index: number) => void
  showMore?: boolean
  showMsgIcon?: boolean
  showSettingIcon?: boolean
  moreItems?: Guild.MoreItem[]
  members?: Guild.MemberItem[]
  onMoreItemClick?: (name: string) => void
  backArrowClick?: () => void
  onShowMessage?: () => void
  onShowSetting?: () => void
  onInvite?: () => void
  onViewMember?: () => void
}

export default function GuildHeader(props: GuildHeaderProps) {
  const [params, setParams] = useState<GuildHeaderProps>(props)
  const [agency, setAgency] = useState<Guild.GuildApplyInfo>()
  const [members, setMembers] = useState<Guild.MemberItem[]>([])
  const [totalMember, setTotalMember] = useState<number>(0)

  useEffect(() => {
    setAgency(props.guild)
  }, [props.guild])

  useEffect(() => {
    setMembers(props.members || [])
  }, [props.members])

  useEffect(() => {
    setTotalMember(props.totalMember || 0)
  }, [props.totalMember])

  return <div className="cmp-guild-header" style={{ paddingTop: params.paddingTop }}>
    <Header
      title={params.title}
      hasMore={params.showMore}
      showMsgIcon={props.showMsgIcon}
      showSettingIcon={props.showSettingIcon}
      moreItems={params.moreItems}
      onMoreItemClick={params.onMoreItemClick}
      backArrowClick={params.backArrowClick}
      onShowMessage={params.onShowMessage}
      onShowSetting={params.onShowSetting}
    />
    <div className='cmp-guild-header__content'>
      {/* <div className='cmp-guild-header__avatar'>
        {params.guildAvatar && <img src={params.guildAvatar + '?w=216&h=216'} alt='guild avatar' />}
        <div className='cmp-guild-header__avatar-mask'></div>
      </div>
      <div className='cmp-guild-header__name'>{params.guildName}</div>
      {params.children}
      {params.infoCardItems && <InfoCard
        items={params.infoCardItems}
        onCardClick={params.onCardClick} />} */}
      <AgencyCard item={agency} />
      <MemberCard totalMember={totalMember} members={members} onInvite={props.onInvite} onViewMember={props.onViewMember} />
    </div>
  </div>
}