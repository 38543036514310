import { memo } from 'react'
import ImgX from '@/images/x-icon.png';
import ImgRight from '@/images/guild-right.png';
import SafeArea from '@/components/SafeArea';
import { useTrans } from '@/hooks/useTranslation';
import './GemRuleTable.scss'

function GemRuleTable({ tableData }: { tableData: Wallet.ExchangeRulesItem[] }) {
    const { t } = useTrans()
    return <>
        <div className='wallet-gem-rule-table'>
            <div className='table-head'>
                <div>
                    {t('Wallet.Gems')}
                </div>
                <div>
                    {t('Wallet.Proportion')}
                </div>
                <div>
                    {t('Common.Gem')}
                </div>
                <div>
                    {t('Wallet.Progress')}
                </div>
            </div>
            {tableData.map((item, i) => (
                <div className='table-item' key={i}>
                    <div>{item.gems}</div>
                    <div>{item.proportion}</div>
                    <div>{item.gem}</div>
                    <div>
                        {item.hasChange ? <img src={ImgRight}></img> : <img src={ImgX}></img>}
                    </div>
                </div>
            ))}
        </div>
        <SafeArea position='bottom' />
    </>
}

export default memo(GemRuleTable)