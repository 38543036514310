import React, { memo, useCallback, useEffect, useState } from 'react'
import './StatusUserInfo.scss'
import clsx from 'clsx';
import { isAr } from '@/utils/tool';
import { getUserDetail } from '@/services/agentRecharge';
import { toast } from '@/utils/bridge';
interface PropsType {
    state: {
        appID: string | undefined
        inputNum: string | undefined
    }
}
function StatusUserInfo({ state }: PropsType) {
    const [user, setUser] = useState<AgentRecharge.UserInfo>()
    const fetchUser = useCallback(async () => {
        const res = await getUserDetail({ erbanNo: state.appID })

        const { code, message, data } = res as Response<AgentRecharge.UserInfo>
        if (code !== 200) {
            toast(message)
            return
        }
        setUser(data)
    }, [state])
    useEffect(() => {
        fetchUser()
    }, [fetchUser])
    return (
        <div className='cmp-agent-recharge-status-user-info'>
            {user && <>
                <div className='info'>
                    <div className="user">
                        <img src={user.avatar} alt="" />
                        <div className="detail-info">
                            <span className={clsx('name', { isAr: isAr(user.nick) })}>{user.nick}</span>
                            <span className='id'>{'ID:' + user.erbanNo}</span>
                        </div>
                    </div>
                    <div className="coin">
                        <div className='img'></div>
                        <span>{state.inputNum}</span>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default memo(StatusUserInfo)