import { createContext, Dispatch, useReducer } from "react"

const initialState: {
  selectedFriend?: LoveCard.User
  isUpdate: boolean
} = {
  isUpdate: false
}

interface ILoveCardContextProps {
  state: typeof initialState
  dispatch: Dispatch<any> | null
}

const reducer = (state: typeof initialState, action: ReduceAction) => {
  switch (action.type) {
    default:
      return state
    case 'update':
      return {
        ...state,
        ...action.data
      }
  }
}

export const LoveCardStore = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <LoveCardContext.Provider value={{ state, dispatch }}>
    {children}
  </LoveCardContext.Provider>
}

export const LoveCardContext = createContext({ state: initialState, dispatch: null } as ILoveCardContextProps)