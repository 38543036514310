import ImageArrow from '../../../images/arrow.png'
import ImageGuildMaster from '../../../images/guild-master.png'
import ImageGuidlManager from '../../../images/guild-manager.png'
import React, { useCallback, useEffect, useState } from 'react'
import { PageConst, defaultAvatarSrc } from '../../../utils/constant'
import { openPage } from '../../../utils/bridge'
import { useTrans } from '@/hooks/useTranslation'
import { isAr } from '@/utils/tool';
import moment from 'moment'
import clsx from 'clsx'
import './MemberCard.scss'

type MemberCardProps = {
  myRole?: number
  memberId: number
  avatar: string
  username: string
  erbanNo?: string
  icons?: string[]
  role?: number
  time?: number
  showArrow?: boolean
  onClick?: (memberId: number) => void
  invite?: {
    enable: boolean
    onInvite: (memberId: number) => void
  }
  remove?: {
    onRemove: (memberId: number) => void
  }
  manager?: {
    onManager: (memberId: number, role: number) => void
  },
  rightBtn?: {
    text?: string
    onRightBtn?: (memberId: number) => void
  }
  infoExtra?: React.ReactNode
}

export default function MemberCard(props: MemberCardProps) {
  const { t } = useTrans()
  const [isImageError, setIsImageError] = useState(false)
  const [myRole, setMyRole] = useState(props.myRole)

  const getRoleImage = useCallback(() => {
    return props.role === 1 ? ImageGuildMaster : ImageGuidlManager
  }, [props])

  const handleManager = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    props.manager?.onManager && props.manager?.onManager(props.memberId, props.role || 0)
  }, [props])

  const handleClick = useCallback(() => {
    props.onClick && props.onClick(props.memberId)
  }, [props])
  const handleonRightBtnClick = useCallback(() => {
    props.rightBtn?.onRightBtn && props.rightBtn?.onRightBtn(props.memberId)
  }, [props])

  const handleInvite = useCallback(() => {
    props.invite?.enable && props.invite?.onInvite(props.memberId)
  }, [props])

  // const handleRemove = useCallback(() => {
  //   props.remove?.onRemove(props.memberId)
  // }, [props])

  const handleImageError = () => {
    setIsImageError(true)
  }

  const handleClickAvatar = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      openPage(PageConst.PersonalHome, props.memberId)
    },
    [props]
  )

  useEffect(() => {
    setMyRole(props.myRole)
  }, [props.myRole])

  return (
    <div className="cmp-member-card" onClick={handleClick}>
      <div className="cmp-member-card__info">
        <div className="cmp-member-card__info-avatar">
          {props.avatar && (
            <img
              alt="avatar"
              onClick={handleClickAvatar}
              src={
                (isImageError || props.avatar.endsWith('webp')) ? defaultAvatarSrc : props.avatar + '?w=100&h=100'
              }
              onError={handleImageError}
            />
          )}
        </div>
        <div className="cmp-member-card__info-basic">
          <div
            className={clsx('cmp-member-card__info-name', {
              withIcons: !!props.icons?.length
            }, { isAr: isAr(props.username) })}>
            {props.username}
          </div>
          {!!props.icons?.length && (
            <div className="cmp-member-card__info-icons">
              {props.icons.map((icon) => (
                <img alt="icon" src={icon} key={icon} />
              ))}
            </div>
          )}
          {props.erbanNo && (
            <div className="cmp-member-card__info-No">ID: {props.erbanNo}</div>
          )}
        </div>
        {props.infoExtra}
      </div>
      {/* 管理员按钮 */}
      {myRole === 1 && props.role && props.role !== 1 &&
        <div className={clsx("cmp-member-card__manager", { 'cancel': props.role === 2 })} onClick={handleManager}>
          <span>{t(`Common.${props.role === 2 ? 'Cancel' : 'Set'}`)}</span>
        </div>
      }
      {/* 身份icon标志 */}
      {
        props.role && [1, 2].includes(props.role) && (
          <div className="cmp-member-card__role">
            <img alt="role" src={getRoleImage()} />
          </div>
        )
      }
      {
        props.showArrow && (
          <div className="cmp-member-card__arrow">
            <img alt="arrow" src={ImageArrow} />
          </div>
        )
      }
      {
        props.time && (
          <div className="cmp-member-card__time">
            {moment(props.time).format('YYYY-M-D')}
          </div>
        )
      }
      {
        props.invite && (
          <div
            className={clsx('cmp-member-card__invite', {
              disabled: !props.invite.enable
            })}
            onClick={handleInvite}>
            {t('Common.Invite')}
          </div>
        )
      }
      {
        props.rightBtn && (
          <div className="cmp-member-card__right-btn" onClick={handleonRightBtnClick}>
            {t(props.rightBtn.text || '')}
          </div>
        )
      }
      {/* {props.remove && (
        <div className="cmp-member-card__remove" onClick={handleRemove}>
          {t('Common.Remove')}
        </div>
      )} */}
    </div >
  )
}
