import { memo, useCallback, useState, forwardRef, useImperativeHandle, useMemo } from 'react'
import clsx from 'clsx'
import ImageCoin from '@/images/coin.png'
import { cloneDeep } from 'lodash-es';
import { useTrans } from '@/hooks/useTranslation';
import './CoinExchangeSelect.scss'

type PropsType = {
    prodList: {
        // cash兑换列表
        prodId?: string,
        cashAmount?: number,
        goldNum?: number
    }[]
    customText?: string//自定义名称
    handleSelectCoin?: (i: number) => void
}
function CoinExchangeSelect({ prodList, handleSelectCoin, customText }: PropsType, ref: any) {
    const { t } = useTrans()
    const [selectedCoin, setSelectedCoin] = useState<number | undefined>()
    const handleThisSelectCoin = useCallback((i: number) => {
        handleSelectCoin && handleSelectCoin(i)
        setSelectedCoin(i)
    }, [])
    /* 初始化选中项 */
    const initSelected = useCallback(() => {
        setSelectedCoin(undefined)
    }, [])
    useImperativeHandle(ref, () => ({
        initSelected,
    }), [initSelected])
    const proList = useMemo(() => {
        const arr = cloneDeep(prodList)
        if (customText) {
            arr.push({
                prodId: '',
                cashAmount: 0,
                goldNum: 0
            })
        }
        return arr
    }, [customText, prodList])
    return (
        <div className='wallet-coin-exchange-select'>
            <div className='wallet-coin-exchange-select__coin'>
                {proList.map((item, i) => <div className={clsx('wallet-coin-exchange-select__coin-item', {
                    selected: i === selectedCoin
                })} onClick={() => handleThisSelectCoin(i)} key={i}>
                    {item.prodId ? <>
                        <div className='wallet-coin-exchange-select__coin-box'>
                            <img alt='img' className='wallet-coin-exchange-select__coin-icon' src={ImageCoin} />
                            <span className='wallet-coin-exchange-select__coin-num'>{item.goldNum}</span>
                        </div>
                        <span className='wallet-coin-exchange-select__coin-price'>{t('Common.Cash')} {item.cashAmount}</span>
                    </>
                        : <span className='wallet-coin-exchange-select__coin-custom-text'>{t(customText || '')}</span>
                    }
                </div>)}
            </div>
        </div>
    )
}

export default memo(forwardRef(CoinExchangeSelect))