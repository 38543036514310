import { useEffect, useRef, useState } from 'react'
import ImageHowToPlay from '../../images/HowtoPlay.png'
import ImageDeclaration from '../../images/Declaration.png'
import ImageProbability from '../../images/probability.png'
import ImageHowToPlayAr from '../../images/HowtoPlay-ar.png'
import ImageDeclarationAr from '../../images/Declaration-ar.png'
import ImageProbabilityAr from '../../images/probability-ar.png'
import { useTrans } from '@/hooks/useTranslation'
import './rule.scss'

type ProbabilityItem = {
  key: string
  val: string
}

export default function MagicLampRule() {
  const { t, i18n } = useTrans()
  const [state, setState] = useState<{
    probability1: ProbabilityItem[]
    probability2: ProbabilityItem[]
  }>({
    probability1: [],
    probability2: []
  })
  const refState = useRef(state)
  refState.current = state

  useEffect(() => {
    setState({
      ...refState.current,
      probability1: [
        { key: t('MagicLamp.Rule.Star'), val: '58.344461%' },
        { key: t('MagicLamp.Rule.Like'), val: '35.55649%' },
        { key: t('MagicLamp.Rule.Kiss'), val: '3.76%' },
        { key: t('MagicLamp.Rule.Lipstick'), val: '1.2009%' },
        { key: t('MagicLamp.Rule.AladdinLamp'), val: '0.593%' },
        { key: t('MagicLamp.Rule.LuxuryBag'), val: '0.31%' },
        { key: t('MagicLamp.Rule.IslamicCastle'), val: '0.16%' },
        { key: t('MagicLamp.Rule.RedSportsCar'), val: '0.001%' },
        { key: t('MagicLamp.Rule.MyCamel'), val: '0.015%' },
        { key: t('MagicLamp.Rule.RichMan'), val: '0.009%' },
        { key: t('MagicLamp.Rule.AladdinLamp'), val: '0.025%' },
        { key: t('MagicLamp.Rule.GoldenChariot'), val: '0.025%' }
      ],
      probability2: [
        { key: t('MagicLamp.Rule.SendLove'), val: '58.27851%' },
        { key: t('MagicLamp.Rule.Donut'), val: '33.76149%' },
        { key: t('MagicLamp.Rule.ManyCash'), val: '6.51%' },
        { key: t('MagicLamp.Rule.HoldingFlowers'), val: '1.2009%' },
        { key: t('MagicLamp.Rule.LuxuryClock'), val: '0.09%' },
        { key: t('MagicLamp.Rule.CandyRocket'), val: '0.05%' },
        { key: t('MagicLamp.Rule.SwordOfGuardian'), val: '0.025%' },
        { key: t('MagicLamp.Rule.TigerTransformation'), val: '0.025%' },
      ]
    })
  }, [t])

  return <div className='pg-magic-lamp-rule'>
    <div className='pg-magic-lamp-rule__title'>
      <img alt='img' src={i18n.language === 'ar' ? ImageHowToPlayAr : ImageHowToPlay} />
    </div>
    <div className='pg-magic-lamp-rule__content'>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.HowToPlay1")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.HowToPlay2")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.HowToPlay3")}</div>
    </div>
    <div className='pg-magic-lamp-rule__title'>
      <img alt='img' src={i18n.language === 'ar' ? ImageDeclarationAr : ImageDeclaration} />
    </div>
    <div className='pg-magic-lamp-rule__content'>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.Declaration1")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.Declaration2")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.Declaration3")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.Declaration4")}</div>
      <div className='pg-magic-lamp-rule__content-item'>{t("MagicLamp.Rule.Declaration5")}</div>
    </div>
    <div className='pg-magic-lamp-rule__title'>
      <img alt='img' src={i18n.language === 'ar' ? ImageProbabilityAr : ImageProbability} />
    </div>
    <div className='pg-magic-lamp-rule__content'>
      <table cellPadding={0} cellSpacing={0}>
        <thead><tr>
          <th colSpan={2}>{t('MagicLamp.Rule.ProbabilityTitle1')}</th>
        </tr></thead>
        <tbody>
          {state.probability1.map((item, i) => <tr key={i}>
            <td className='key'>{item.key}</td>
            <td className='val'>{item.val}</td>
          </tr>)}
        </tbody>
      </table>
      <table cellPadding={0} cellSpacing={0}>
        <thead><tr>
          <th colSpan={2}>{t('MagicLamp.Rule.ProbabilityTitle2')}</th>
        </tr></thead>
        <tbody>
          {state.probability2.map((item, i) => <tr key={i}>
            <td className='key'>{item.key}</td>
            <td className='val'>{item.val}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>
}