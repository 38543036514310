import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTrans } from "@/hooks/useTranslation"
import './Country.scss'
import SelectedIcon from '@/images/wallet/purple-right.png'
import UnselectIcon from '@/images/wallet/unselect.png'
import ImageArrowDown from '@/images/arrowdown.png'
import { SearchBar } from 'antd-mobile'
import { getCountryList, agencyUpdateAgencyCountry } from '@/services/agentRecharge'
import { toast } from '@/utils/bridge'
import Loading from '@/components/Loading';

type CountryProps = {
  countryCode: string
  openCountryPopup: (bol: boolean) => void
  confirmCountry: (country: string) => void
}

const Country = (props: CountryProps) => {
  const { t } = useTrans()
  const [countryCode, setCountryCode] = useState<string[]>([])

  const [countryList, setCountryList] = useState<AgentRecharge.CountryList[]>([])
  const refCountryList = useRef(countryList)
  refCountryList.current = countryList

  const [loading, setLoading] = useState(false)

  const refTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (refCountryList.current.length === 0) {
      fetchCountryList()
    }
  }, [])

  useEffect(() => {
    setCountryCode(props.countryCode?.split(','))
  }, [props.countryCode])

  // 获取国家列表
  const fetchCountryList = useCallback(async (searchKey?: string) => {
    setLoading(true)
    const res = await getCountryList(searchKey)
    setLoading(false)
    const { code, message, data } = res as Response<AgentRecharge.CountryList[]>
    if (code !== 200) {
      toast(message)
      return
    }
    setCountryList([...data])
  }, [])

  const handleSelectCountry = useCallback((country: AgentRecharge.CountryList) => {
    setCountryCode((prev) => {
      if (prev.includes(country.countryCode)) {
        return prev.filter((item) => item !== country.countryCode)
      }
      return [...prev, country.countryCode]
    })
  }, [])

  const handleSearchChange = useCallback((keywords: string) => {
    refTimer.current && clearTimeout(refTimer.current)
    refTimer.current = setTimeout(() => {
      fetchCountryList(keywords)
    }, 500)
  }, [])

  const handleConfirm = useCallback(async () => {
    const params = countryCode?.join(',')
    const res = await agencyUpdateAgencyCountry(params) as Response
    const { code, message } = res
    if (code !== 200) {
      toast(message)
      return
    }
    props.confirmCountry(params)
  }, [props.confirmCountry, countryCode])

  const handleClose = useCallback(() => {
    setCountryCode(props.countryCode.split(','))
    props.openCountryPopup(false)
  }, [props.openCountryPopup, props.countryCode])

  return (
    <div className="cmp-coins-transfer-country">
      <div className="cmp-coins-transfer-country-title">
        <img
          className="cmp-coins-transfer-country-close-down"
          alt="member list close"
          src={ImageArrowDown}
          onClick={handleClose}
        />
        <span className="cmp-coins-transfer-country-title-text">
          {t('Common.Country')}
        </span>
        <span onClick={handleConfirm} className="cmp-coins-transfer-country-confirm">{t('Common.Confirm')}</span>
      </div>
      <SearchBar placeholder={t('AgentRecharge.SearchForCountry').toString()} onChange={handleSearchChange} />
      <div className="cmp-coins-transfer-country-list">
        {countryList?.map((item) => (
          <div
            key={item.countryCode}
            className="cmp-coins-transfer-country-item"
            onClick={() => handleSelectCountry(item)}>
            <div className="cmp-coins-transfer-country-item-left">
              <img
                className="cmp-coins-transfer-country-item-select"
                alt="img"
                src={item.countryNationalFlag}
              />
              <span>{item.countryName}</span>
            </div>
            <img
              className="cmp-coins-transfer-country-item-select"
              alt="img"
              src={
                countryCode.includes(item.countryCode)
                  ? SelectedIcon
                  : UnselectIcon
              }
            />
          </div>
        ))}
      </div>
      {loading && <Loading />}
    </div>
  )
}

export default memo(Country)
