import React, { useCallback } from 'react'
import './Success.scss';
import { useNavigate, useParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import StatusUserInfo from '@/pages/AgentRecharge/components/StatusUserInfo';
import { getUserInfo, goBack } from '@/utils/bridge';

function Success() {
    const navigate = useNavigate()
    const { appID, inputNum } = useParams()
    const goDetails = useCallback(async () => {
        const auth = await getUserInfo()
        navigate(`/guild/wallet-data/member-detail/${auth?.uid}?title=1`)
    }, [])
    const goWallet = useCallback(() => {
        goBack()
    }, [])
    return (
        <div className='pg-anchorman-wallet-detail-success pg-fa'>
            <Status successRightBtnText='Wallet.GoToWallet' successRightBtnClick={goWallet} title='Wallet.AgencyGemAndCash' status='success' successText='Wallet.Withdraw.ExchangeSuccess' paneTitle='Wallet.CheckAccountExchange' goDetails={goDetails}>
                <StatusUserInfo state={{ appID, inputNum }}></StatusUserInfo>
            </Status>
        </div >
    )
}

export default Success