import React, { memo, useMemo, useState } from 'react'
import CalculateData from '@/pages/Guild/Components/CalculateData';
import ScrollList from '@/components/ScrollList';
import clsx from 'clsx';
import ScrollTable from '@/components/ScrollTable';
import ImgCoin from '@/images/coin.png';
import ImgGem from '@/images/gem.png';
import Pane from '@/components/Pane';
import NumberCard from '../../Components/NumberCard';
import RuleTable from './RuleTable';
import moment from 'moment';
import { useTrans } from '@/hooks/useTranslation';
import './ExpectedPendingContent.scss'

interface PropsType {
    time: string
}

function ExpectedPendingContent({ time }: PropsType) {
    const { t } = useTrans()

    const [calculateItemIndex, setCalculateItemIndex] = useState(1)
    const calculateData = useMemo(() => {
        return [{
            key: 'Guild.InviteData.SettledReward',
            value: 0
        }, {
            key: 'Guild.InviteData.OtherGoldTopUp',
            value: 0
        },
        {
            key: 'Guild.InviteData.RewardRate',
            value: `${(1 * 100).toFixed(0)}%` || '0%'
        }, {
            key: 'Guild.InviteData.TopUpHistorySettledReward',
            value: 0
        }]
    }, [])
    const columns1 = useMemo(() => {
        return [
            { header: 'Common.Date', key: 'name', render: (text: string, row: any) => <div>{row.age}</div> },
            {
                header: 'Common.Users', key: 'age', render: (text: string, row: any) =>
                    <div className='special-content__user'>
                        <span>{row.city}</span>
                        <span>ID:{row.age}</span>
                    </div>
            },
            { header: 'Common.Member', key: 'city' },
            { header: 'Wallet.Channel', key: 'city2' },
            {
                header: 'Guild.InviteData.TopUp', key: 'city3', render: (text: string) =>
                    <div className='special-content__topup'>
                        <span>+{text}</span>
                        <img src={ImgCoin} alt="" />
                    </div>
            },
        ];
    }, [])
    const data1 = [
        { name: 'John', age: 25, city: 'New York', city2: '2', city3: '3', },
        { name: 'Alice', age: 30, city: 'Los Angeles', city2: '2', city3: '3', },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', },
    ];
    const columns2 = useMemo(() => {
        return [
            { header: 'Common.Date', key: 'name', render: (text: string, row: any) => <div>{row.age}</div> },
            {
                header: 'Common.Users', key: 'age', render: (text: string, row: any) =>
                    <div className='special-content__user'>
                        <span>{row.city}</span>
                        <span>ID:{row.age}</span>
                    </div>
            },
            { header: 'Common.Member', key: 'city' },
            { header: 'Wallet.Channel', key: 'city2' },
            { header: 'Common.Reward', key: 'city3', render: (text: string) => <span style={{ color: '#fff' }}>{text}</span> },
            {
                header: 'Guild.InviteData.TopUp', key: 'city4', render: (text: string, row: any) =>
                    <>
                        <div className='special-content__topup'>
                            <span>+{text}</span>
                            <img src={ImgGem} alt="" />
                        </div>
                        <span>{t('Common.Pending') + ':' + row.age}</span>
                    </>
            },
        ];
    }, [])
    const data2 = [
        { name: 'John', age: 25, city: 'New York', city2: '2', city3: '3', city4: '4' },
        { name: 'Alice', age: 30, city: 'Los Angeles', city2: '2', city3: '3', city4: '4' },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', city4: '4' },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', city4: '4' },
        { name: 'Bob', age: 22, city: 'Chicago', city2: '2', city3: '3', city4: '4' },
    ];
    return (
        <div className='cmp-guild-invite-data-expected-pending-content'>
            <CalculateData status={{ expected: time === moment().startOf('month').format('YYYY-MM') }} calculateData={calculateData} calculateItemIndex={calculateItemIndex} setCalculateItemIndex={setCalculateItemIndex}></CalculateData>
            <div className={clsx({ hide: calculateItemIndex !== 1 })}>
                {/* <ScrollList
                        hasMore={memberGemIncomeList.hasMore}
                        loadMore={fetchMemberGemIncomeList}
                    > */}
                {/* <AgencyRewardIncomeTable items={list}></AgencyRewardIncomeTable> */}
                <ScrollTable columns={columns1} data={data1 || []} />
                {/* </ScrollList> */}
            </div>
            <div className={clsx({ hide: calculateItemIndex !== 2 })}>
                <Pane title={t('Guild.InviteData.InviteRate')}>
                    <NumberCard
                        items={[
                            {
                                key: 'Guild.InviteData.TopUpUsers',
                                val: 0,
                            },
                            {
                                key: 'Guild.InviteData.TotalTopUpCoins',
                                val: 0
                            }
                        ]}
                    />
                    <RuleTable now={1}></RuleTable>
                </Pane>
            </div>
            <div className={clsx({ hide: calculateItemIndex !== 3 })}>
                {/* <ScrollList
                        hasMore={memberGemIncomeList.hasMore}
                        loadMore={fetchMemberGemIncomeList}
                    > */}
                {/* <AgencyRewardIncomeTable items={list}></AgencyRewardIncomeTable> */}
                <ScrollTable columns={columns2} data={data2 || []} />
                {/* </ScrollList> */}
            </div>
        </div>
    )
}

export default memo(ExpectedPendingContent)