import { useTrans } from "@/hooks/useTranslation"
import FormItem from './FormItem'
import './FormItemList.scss'

type FormItemProps = {
  key: string
  val: string
  field: string
  editable?: boolean
}

type FormItemListProps = {
  items: FormItemProps[]
  onItemClick?: (key: string, val: string, field: string) => void
  onItemChange?: (key: string, val: string, field: string) => void
}

export default function FormItemList(props: FormItemListProps) {
  const { t } = useTrans()
  return <div className='cmp-form-item-list'>
    {props.items.map(item => <FormItem
      name={t(item.key)} val={item.val} key={item.key} field={item.field}
      editable={item.editable} onClick={props.onItemClick} onChange={props.onItemChange} />)}
  </div>
}