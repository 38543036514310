import React from 'react'
import './CreateAdvocacyFirst.scss'
import { useTrans } from '@/hooks/useTranslation'
import Pane from '@/components/Pane';
import ImgHowToCreateAgency1 from '@/pages/Guild/images/how-to-create-agency1.png';
import ImgHowToCreateAgency2 from '@/pages/Guild/images/how-to-create-agency2.png';
import { useDesign } from '@/hooks/useDesign';

const { prefixCls } = useDesign('cmp-guild-create-advocacy-first')

interface PropsType {
    handleNext: () => void
}

function CreateAdvocacyFirst({ handleNext }: PropsType) {
    const { t } = useTrans()
    return (
        <div className={`${prefixCls}`}>
            <main>
                <Pane title={t('Guild.CreateAgency.HowToCreateAgency')} className='pane-prefix'>
                    <div className={`${prefixCls}__how`}>
                        <span className={`${prefixCls}__how-title`}>{t('Guild.CreateAgency.HowToCreateAgency1')}</span>
                        <div className={`${prefixCls}__how-box`}>
                            <img src={ImgHowToCreateAgency1} alt="" />
                            <span className={`${prefixCls}__how-text1`}>1.{t('Common.Me')}</span>
                            <span className={`${prefixCls}__how-text2`}>2.{t('Common.Agency')}</span>
                        </div>
                        <span className={`${prefixCls}__how-title`}>{t('Guild.CreateAgency.HowToCreateAgency2')}</span>
                        <div className={`${prefixCls}__how-box`}>
                            <img src={ImgHowToCreateAgency2} alt="" />
                            <span className={`${prefixCls}__how-text1`}>1.{t('Guild.CreateAgency.InviteAgency')}</span>
                            <span className={`${prefixCls}__how-text2`}>2.{t('Guild.CreateAgency.ClickEnter')}</span>
                        </div>
                    </div>
                </Pane>
            </main>
            <div className={`${prefixCls}__btn`} onClick={handleNext}>
                {t('Common.Next')}
            </div>
        </div >
    )
}

export default CreateAdvocacyFirst