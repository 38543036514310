import Pane from "@/components/Pane"
import { getFriends } from "@/services/LoveCard"
import { toast } from "@/utils/bridge"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import LoveCardUser from './Components/User'
import LoveCardHeader from './Components/Header'
import { LoveCardContext } from '@/store/lovecard'
import { useNavigate } from 'react-router'
import { useTrans } from '@/hooks/useTranslation'
import './Friends.scss'

export default function LoveCardFriends() {
  const { t } = useTrans()
  const { dispatch } = useContext(LoveCardContext)
  const navigate = useNavigate()
  const [state, setState] = useState<{
    recommend: LoveCard.User[]
    allFriends: LoveCard.User[]
  }>({
    recommend: [],
    allFriends: []
  })
  const refState = useRef(state)
  refState.current = state

  const getFriendList = useCallback(async () => {
    const res = await getFriends()
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    setState({
      ...refState.current,
      recommend: res.data.recommend || [],
      allFriends: res.data.allFriends || []
    })
  }, [])

  const handleSelectFriend = useCallback((friend: LoveCard.User) => {
    dispatch && dispatch({
      type: 'update',
      data: {
        selectedFriend: friend,
        isUpdate: false
      }
    })
    navigate('/wallet/love-card/create')
  }, [dispatch])

  useEffect(() => {
    getFriendList()
  }, [])

  return <div className="pg-lovecard-friends">
    <LoveCardHeader />
    <div className='pg-lovecard-friends__main'>
      <Pane title={t("Wallet.LoveCard.Recommend")}>
        {state.recommend.map(item => <div className='pg-lovecard-friends__item' key={item.erbanNo}>
          <LoveCardUser user={item} onClick={handleSelectFriend} />
        </div>)}
      </Pane>
      <Pane title={t('Wallet.LoveCard.AllFriends')}>
        {state.allFriends.map(item => <div className='pg-lovecard-friends__item' key={item.erbanNo}>
          <LoveCardUser user={item} onClick={handleSelectFriend} />
        </div>)}
      </Pane>
    </div>
  </div>
}