import React, { useCallback } from 'react'
import './CoinsTransferSuccess.scss';
import { useNavigate, useParams } from 'react-router-dom'
import Status from '@/pages/Wallet/components/Status';
import StatusUserInfo from './components/StatusUserInfo';

function CoinsTransferSuccess() {
    const navigate = useNavigate()
    const { appID, inputNum } = useParams()
    const goDetails = useCallback(() => {
        navigate("/agent-recharge/coins-transfer/coin-detail")
    }, [])
    return (
        <div className='agent-recharge-coins-transfer-success pg-fa'>
            <Status successRightBtnText='Wallet.TransferAgain' title='AgentRecharge.CoinsTransfer' status='success' successText='AgentRecharge.TransferSuccess' paneTitle='Wallet.CheckAccountExchange' goDetails={goDetails}>
                <StatusUserInfo state={{ appID, inputNum }}></StatusUserInfo>
            </Status>
        </div >
    )
}

export default CoinsTransferSuccess