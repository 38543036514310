import { useCallback, useEffect, useMemo, useState } from 'react'
import ImageFaceBook from '@/images/facebook.webp'
import ImageWhatApp from '@/images/whats-app.webp'
import ImageCopyLink from '@/images/copy-link.webp'
import ImgInviteHostImg1 from '../images/invite-host-img1.webp';
import ImgInviteHostImg2 from '../images/invite-host-img2.webp';
import ImgInviteHostImg3 from '../images/invite-host-img3.webp';
import Header from '@/components//Header';
import { useClipboard } from '@/hooks/useClipboard';
import { getUserInfo, shareApp, toast } from '@/utils/bridge'
import config from '@/config';
import { getMyUserInfo } from '@/utils/request';
import { useTrans } from '@/hooks/useTranslation';
import './InviteHost.scss'

function InviteHost() {
    const { t } = useTrans()
    const { copy, copied } = useClipboard()
    const [user, setUser] = useState<AgentRecharge.UserInfo>()

    const fetchUserInfo = useCallback(() => {
        getUserInfo().then(async (auth) => {
            if (auth) {
                const res = await getMyUserInfo(auth.uid)

                const { code, message, data } = res as Response<AgentRecharge.UserInfo[]>

                if (code !== 200) {
                    toast(message)
                    return
                }
                setUser({
                    ...data[0]
                })
            }
        })
    }, [])

    const shareDataList = useMemo(() => {
        return [
            { text: t('Guild.Invite.Facebook'), type: 'facebook', img: ImageFaceBook },
            { text: t('Guild.Invite.WhatsApp'), type: 'whatsapp', img: ImageWhatApp },
            { text: t('Guild.Invite.CopyLink'), type: 'copy', img: ImageCopyLink }
        ]
    }, [])
    const handleShare = useCallback((type: string) => {
        if (type === 'copy') {
            copy(`${config.inviteFriendsUrl}/${user?.erbanNo}`)
            return
        }
        shareApp(type, `${config.inviteFriendsUrl}/${user?.erbanNo}`)
    }, [user])

    useEffect(() => {
        if (copied) {
            toast(t('Guild.Invite.CopySuccess'))
        }
    }, [copied])

    useEffect(() => {
        fetchUserInfo()
    }, [])

    return (
        <div className='pg-guild-invite-host pg-guild'>
            <Header title={t('Guild.Invite.InviteHost.HeaderTitle').toString()}></Header>
            <div className='pg-guild-invite-host__share'>
                {shareDataList.map((item, i) => (
                    <div
                        className="pg-guild-invite-host__share-item"
                        key={i}
                        onClick={() => handleShare(item.type)}>
                        <img src={item.img} />
                        <span>{item.text}</span>
                    </div>
                ))}
            </div>
            <div className='pg-guild-invite-host__tips'>
                <span className='pg-guild-invite-host__tips-title'>{t('Guild.Invite.InviteHost.OperationGuide')}</span>
                {new Array(3).fill(0).map((_, i) => <span key={i} className='pg-guild-invite-host__tips-item'>{t(`Guild.Invite.InviteHost.OperationGuideTip${i + 1}`)}</span>)}
                <img src={ImgInviteHostImg1} alt="" />
                {new Array(3).fill(0).map((_, i) => <span key={i} className='pg-guild-invite-host__tips-item'>{t(`Guild.Invite.InviteHost.OperationGuideTip${i + 1 + 3}`)}</span>)}
                <div>
                    <img src={ImgInviteHostImg2} alt="" />
                    <img src={ImgInviteHostImg3} alt="" />
                </div>
            </div>
        </div>
    )
}

export default InviteHost