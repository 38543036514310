import React, { memo, useMemo } from 'react'
import MemberCard from '@/pages/Guild/Components/MemberCard'
import CalculateData from '@/pages/Guild/Components/CalculateData';
import AgencyRewardGrade from './AgencyRewardGrade';
import './AgencyRewardUserInfo.scss'

interface PropsType {
    rewardMonthData: Guild.GuildRewardMonthData
    calculateItemIndex: number
    expected: boolean
    setCalculateItemIndex: (i: number) => void
}
function AgencyRewardUserInfo(props: PropsType) {
    const calculateData = useMemo(() => {
        return [{
            key: 'Guild.AgencyReward.Commission',
            value: props.rewardMonthData.rewardGem || 0
        }, {
            key: 'Guild.AgencyReward.MyAgencyGemIncome',
            value: props.rewardMonthData.agencyGemIncome || 0
        },
        {
            key: 'Guild.AgencyReward.MyAgencyCommissionRate',
            value: `${(props.rewardMonthData.agencyRewardRate * 100).toFixed(0)}%` || '0%'
        }]
    }, [props])

    return (
        <div className='cmp-agency-reward-user-info'>
            <div className="content">
                <div className="user">
                    <MemberCard
                        memberId={props.rewardMonthData.guildNo}
                        avatar={props.rewardMonthData.coverPicUrl}
                        username={props.rewardMonthData.guildName}
                        erbanNo={props.rewardMonthData.guildNo.toString()}
                        infoExtra={<AgencyRewardGrade levelNumber={props.rewardMonthData.levelNumber || 1} agencyRewardRate={props.rewardMonthData.agencyRewardRate || 0} />}
                    />
                    <CalculateData calculateData={calculateData} status={{ expected: !props.expected }} calculateItemIndex={props.calculateItemIndex} setCalculateItemIndex={props.setCalculateItemIndex}></CalculateData>
                </div>
            </div>
        </div >
    )
}

export default memo(AgencyRewardUserInfo)