import { getGiveList, getMyCard } from "@/services/LoveCard"
import { toast } from "@/utils/bridge"
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { LoveCardCreate, LoveCardItem } from "./Card"
import './List.scss'

type ListProps = {
  type: string
}

type ListHandle = {
  fetch: () => void
}

function LoveCardList(props: ListProps, ref: any) {
  const [state, setState] = useState<{
    list: LoveCard.Item[]
  }>({
    list: []
  })
  const refState = useRef(state)
  refState.current = state

  useImperativeHandle(ref, () => ({ fetch: props.type === 'Give' ? handleFetchGiveList : handleFetchRecvList }))

  const handleFetchGiveList = useCallback(async () => {
    const res = await getGiveList()
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    const { cardList } = res.data || {}

    setState({
      ...refState.current,
      list: (cardList || []).map(item => ({ ...item, isGiver: true }))
    })

  }, [])

  const handleFetchRecvList = useCallback(async () => {
    const res = await getMyCard()
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    setState({
      ...refState.current,
      list: res.data ? [{ ...res.data, isGiver: false }] : [],
    })

  }, [])

  useEffect(() => {
    const fn = props.type === 'Give' ? handleFetchGiveList : handleFetchRecvList
    fn()
  }, [])

  return <div className='cmp-lovecard-list'>
    {state.list.map(item => <LoveCardItem item={item} key={item.cardId} action="view" />)}
    {props.type === 'Give' && <LoveCardCreate />}
  </div>
}

export default memo(forwardRef<ListHandle, ListProps>(LoveCardList))
