import ImageArrow from '@/images/arrow.png'
import { useCallback } from 'react'
import { goBack } from '@/utils/bridge'
import { useTrans } from '@/hooks/useTranslation'
import './Header.scss'

export default function LoveCardHeader() {
  const { t } = useTrans()

  const handleBack = useCallback(() => {
    goBack()
  }, [])

  return <div className="cmp-lovecard-header">
    <div className="cmp-lovecard-header__line">
      <img className="cmp-lovecard-header__back" src={ImageArrow} onClick={handleBack} />
      <span>{t('Wallet.LoveCard.Title')}</span>
    </div>
  </div>
}