import React, { memo } from 'react'
import './TypeItems.scss'
import clsx from 'clsx'
interface PropsType {
    typeItems: Wallet.ThirdBillTree[]
    selectedType: number
    setSelectedType: (i: number) => void
}
function TypeItems({ typeItems, selectedType, setSelectedType }: PropsType) {
    return (
        <div className='wallet-cmp-type-items'>
            {typeItems.map((item, key) =>
                <div key={key} className={clsx("item", { selected: key === selectedType })} onClick={() => setSelectedType(key)}>
                    <span>{item.billTypeName}</span>
                </div>
            )}
        </div>
    )
}

export default memo(TypeItems)