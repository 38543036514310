import { service } from '../../utils/net'
//拿到加密后的房间id（后端自行拼接url参数）
const secretRoomId = localStorage.getItem('secretRoomId') || ''

// 获取房间流水数据
export function getRoomFlow() {
  return service<Response<Room.RoomData>>({
    url: '/room/flow',
    params: { secretRoomId }
  })
    .then((res) => res.data)
    .catch((e) => e)
}
// 房间流水记录table
export function getRoomFlowRecord(date: string) {
  return service<Response<Room.RoomFlowRecord>>({
    url: '/room/flow/record',
    params: { secretRoomId, date }
  })
    .then((res) => res.data)
    .catch((e) => e)
}