import { useRef, useEffect, useCallback, useState } from 'react'
import type { EChartsOption } from 'echarts'
import echarts from '@/utils/lib/echarts'

export function useECharts(elRef: HTMLDivElement, theme = 'default') {
  const [chartInstance, setChartInstance] = useState<echarts.ECharts | null>(null)
  const refChartInstance = useRef(chartInstance)
  refChartInstance.current = chartInstance

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null)

  /* echarts配置函数 */
  const setOptions = useCallback((options: EChartsOption) => {
    if (chartInstance?.isDisposed() || !chartInstance) return
    chartInstance?.setOption(options, true)
  }, [chartInstance])

  /* 窗口变化函数 */
  const resize = useCallback(() => {
    timer.current && clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      if (refChartInstance.current?.isDisposed() || !refChartInstance.current) return
      refChartInstance.current?.resize({
        animation: {
          duration: 300,
          easing: 'quadraticIn',
        },
      })
    }, 300)
  }, [])

  /* 初始化echats函数 */
  const initCharts = useCallback(() => {
    if (!refChartInstance.current && elRef) {
      refChartInstance.current = echarts.init(elRef, theme)
      setChartInstance(refChartInstance.current)
      window.addEventListener('resize', resize)
    }
  }, [elRef, theme])

  /* 获取echarts实例 */
  const getInstance = useCallback(() => {
    if (!refChartInstance.current || refChartInstance.current?.isDisposed()) {
      initCharts()
    }
    return refChartInstance.current
  }, [initCharts])

  /* 初始化echarts */
  useEffect(() => {
    initCharts()
  }, [initCharts])

  /* 卸载销毁 */
  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current)
      window.removeEventListener('resize', resize)
      !refChartInstance.current?.isDisposed() && refChartInstance.current?.dispose()
      refChartInstance.current = null
      setChartInstance(null)
    }
  }, [])

  return {
    setOptions,
    resize,
    echarts,
    getInstance,
  };
}