import React, { memo, useMemo, useRef } from 'react'
import './GiftProgress.scss'
import clsx from 'clsx'

interface GiftBoxProps {
    current: number//当前
    target: number//需要的数值
    reward: number//奖励数
    className?: string
}
interface GiftProgressProps {
    userCompleteNum: number
    taskProgressData: {
        target: number//需要的数值
        reward: number//奖励数
        currentProgress?: number//当前进度
    }[]
}
function GiftBox(props: GiftBoxProps) {
    return <>
        <div className={clsx('cmp-guild-gift-progress__gift-box', props.className)}>
            <div className={clsx('cmp-guild-gift-progress__gift-box-reward', { 'hide-reward': props.current < props.target })}>
                <span>{props.reward}</span>
                <div className='img'></div>
            </div>
            <div className={clsx('cmp-guild-gift-progress__gift-box-gray', { 'open': props.current >= props.target })}></div>
            <span className={clsx('cmp-guild-gift-progress__gift-box-target', { 'open-target': props.current >= props.target })}>{props.target}</span>
        </div>
    </>
}
function GiftProgress(props: GiftProgressProps) {
    const renderArr = useMemo(() => {
        const renderArr = props.taskProgressData.map(item => ({ currentProgress: 0, ...item }))
        let tempUserCurrent = props.userCompleteNum
        let tempProgressEnd = 0
        renderArr.map(item => {
            const progressEnd = item.target
            const currentProgress = (tempUserCurrent / (progressEnd - tempProgressEnd) * 100).toFixed(2)
            item.currentProgress = Number(currentProgress) < 0 ? 0 : Number(currentProgress)
            tempUserCurrent = props.userCompleteNum - progressEnd
            tempProgressEnd = progressEnd
        })
        return renderArr
    }, [props])
    return <>
        {renderArr.map((item, index) =>
            <div key={index} className='cmp-guild-gift-progress' style={{ zIndex: `${100 - index}` }}>
                {item.currentProgress < 100 && item.currentProgress !== 0 &&
                    <div className='cmp-guild-gift-progress__curr' style={{ left: `${item.currentProgress}%` }}>{props.userCompleteNum}</div>
                }
                <div className='cmp-guild-gift-progress__gift-content'>
                    <GiftBox current={props.userCompleteNum} target={item.target} reward={item.reward} />
                </div>
                <div className='cmp-guild-gift-progress__bar'>
                    <div className='cmp-guild-gift-progress__bar-fill' style={{ width: `${item.currentProgress}%` }}>
                    </div>
                </div>
            </div>
        )}
    </>
}

export default memo(GiftProgress)