// import React from 'react';
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
// import Privacy from './pages/Privacy'
// import PrivacyDescription from './pages/Privacy/description'
// import PrivacyDevice from './pages/Privacy/device'
// import PrivacyTerms from './pages/Privacy/terms'
// import PrivacyAccount from './pages/Privacy/account'
// import PrivacyCommunity from './pages/Privacy/community'
// import PrivacyVirtualTerms from './pages/Privacy/virtual_items'
import GuildDetail from './pages/Guild/Detail'
import GuildMine from './pages/Guild/Mine'
import GuildMemberDetail from './pages/Guild/Member'
import GuildSetting from './pages/Guild/Setting'
import GuildMemberHistorical from './pages/Guild/MemberHistoricalData'
import RuleLevel from './pages/Rule/Level'
import GuildHistoricalData from './pages/Guild/HistoricalData'
import Help from './pages/Help/Index'
import { ConfigProvider } from 'antd-mobile'
import arLocale from './locales/antd-mobile/ar'
import enLocale from 'antd-mobile/cjs/locales/en-US'
import Store from './store'
import GuildRule from './pages/Guild/Rule'
import MagicLampRule from './pages/MagicLamp/rule'
import RuleTurntable from './pages/Rule/Turntable'
import Download from './pages/Download'
import VipRule from './pages/Rule/VipRule'
import RulePk from './pages/Rule/Pk'
import RuleCp from './pages/Rule/Cp'
import Update from './pages/Update'
import RoomData from './pages/Room/RoomData'
import ThirdRecharge from './pages/ThirdRecharge'
import AgentRechargeCoinsTransfer from './pages/AgentRecharge/CoinsTransfer'
import AgentRechargeCoinsTransferSuccess from './pages/AgentRecharge/CoinsTransferSuccess'
import AgentRechargeCoinsTransferWarning from './pages/AgentRecharge/CoinsTransferWarning'
import AgentRechargeCoinsTransferCoinDetail from './pages/AgentRecharge/CoinsTransferCoinDetail'
import AgentRechargeCoinsTransferTransfer from './pages/AgentRecharge/CoinsTransferTransfer'
import AgentRechargeCashTransfer from './pages/AgentRecharge/CashTransfer'
import AgentRechargeCashTransferSuccess from './pages/AgentRecharge/CashTransferSuccess'
import AgentRechargeCashTransferWarning from './pages/AgentRecharge/CashTransferWarning'
import WalletDetail from './pages/Wallet/Detail'
import WalletGem from './pages/Wallet/Gem'
import WalletAnchormanWalletDetail from './pages/Wallet/AgencyGemAndCash/AnchormanWalletDetail'
import GuildCreateAgency from './pages/Guild/CreateAgency'
import GuildCreateAgencySubmit from './pages/Guild/CreateAgencySubmit'
import GuildAgencySearch from './pages/Guild/AgencySearch'
import GuildCreateAgencyAudit from './pages/Guild/CreateAudit'
import GuildCreateAdvocacy from './pages/Guild/CreateAdvocacy'
import WalletAnchormanWalletDetailSuccess from './pages/Wallet/AgencyGemAndCash/components/AnchormanWalletDetail/Success'
import WalletAnchormanWalletDetailWarning from './pages/Wallet/AgencyGemAndCash/components/AnchormanWalletDetail/Warning'
import WalletWithdraw from './pages/Wallet/AgencyGemAndCash/Withdraw'
import GuildWalletDataMemberList from './pages/Guild/WalletData/MemberList'
import GuildWalletDataMemberDetail from './pages/Guild/WalletData/MemberDetail'
import GuildTaskDataMemberList from './pages/Guild/TaskData/MemberList'
import GuildTaskDataMemberDetail from './pages/Guild/TaskData/MemberDetail'
import GuildAgentRewardData from './pages/Guild/AgencyReward/AgentRewardData'
import GuildAgentReward from './pages/Guild/AgencyReward/AgencyReward'
import GuildAgencyRewardRule from './pages/Guild/AgencyReward/Rule'
import WalletWithdrawSuccess from './pages/Wallet/AgencyGemAndCash/components/Withdraw/Success'
import WalletWithdrawWarning from './pages/Wallet/AgencyGemAndCash/components/Withdraw/Warning'
import WalletCollectionInformation from './pages/Wallet/AgencyGemAndCash/CollectionInformation'
import WalletAnchormanWalletDetailCard4Rule from './pages/Wallet/AgencyGemAndCash/components/AnchormanWalletDetail/Card4Rule'
import WalletCashToCoins from './pages/Wallet/AgencyGemAndCash/CashToCoins'
import BDBdSystem from './pages/BDCommission/BdSystem';
import BDAgencyData from './pages/BDCommission/AgencyData';
import GuildInviteData from './pages/Guild/Invite/InviteData';
import GuildInviteHost from './pages/Guild/Invite/InviteHost';
import AutoScrollToTop from './components/AutoScrollToTop';
import LoveCard from './pages/Wallet/LoveCard/Home'
import LoveCardDetail from './pages/Wallet/LoveCard/Detail'
import LoveCardFriends from './pages/Wallet/LoveCard/Friends'
import LoveCardCreate from './pages/Wallet/LoveCard/Create'
import PrivateWallet from './pages/Wallet/AgencyGemAndCash/PrivateWallet'
import './components/i18n'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const locale = window.lang === 'ar' ? arLocale : enLocale
root.render(
  <ConfigProvider locale={locale}>
    <Store>
      <BrowserRouter>
        <AutoScrollToTop>
          <Routes>
            <Route path="/">
              <Route path="/bd">
                <Route index element={<BDBdSystem />} />
                <Route path="agency-data" element={<BDAgencyData />} />
              </Route>
              {/* <Route path="/privacy">
                <Route index element={<Privacy />} />
                <Route path="terms" element={<PrivacyTerms />} />
                <Route path="device" element={<PrivacyDevice />} />
                <Route path="description" element={<PrivacyDescription />} />
                <Route path="account" element={<PrivacyAccount />} />
                <Route path="community" element={<PrivacyCommunity />} />
                <Route path="virtual-terms" element={<PrivacyVirtualTerms />} />
              </Route> */}
              <Route path="/guild">
                <Route index element={<GuildMine />} />
                <Route path="setting" element={<GuildSetting />} />
                <Route path=":guildId" element={<GuildDetail />} />
                <Route path="member/:memberId" element={<GuildMemberDetail />} />
                <Route path="historical" element={<GuildHistoricalData />} />
                <Route
                  path="historical/member/:memberId"
                  element={<GuildMemberHistorical />}
                />
                <Route path="rule" element={<GuildRule />} />
                <Route path="create" element={<GuildCreateAgency />} />
                <Route path="create/audit" element={<GuildCreateAgencyAudit />} />
                <Route path="create/advocacy" element={<GuildCreateAdvocacy />} />
                <Route path="create/submit" element={<GuildCreateAgencySubmit />} />
                <Route path="agency/search" element={<GuildAgencySearch />} />
                <Route path="wallet-data" >
                  <Route path="member-list" element={<GuildWalletDataMemberList />} />
                  <Route path="member-detail/:memberId" element={<GuildWalletDataMemberDetail />} />
                </Route>
                <Route path="task-data" >
                  <Route path="member-list" element={<GuildTaskDataMemberList />} />
                  <Route path="member-detail/:memberId" element={<GuildTaskDataMemberDetail />} />
                </Route>
                <Route path="agency-reward" element={<GuildAgentReward />} />
                <Route path="agency-reward/rule" element={<GuildAgencyRewardRule />} />
                <Route path="agent-reward-data" element={<GuildAgentRewardData />} />
                <Route path='invite'>
                  <Route path="invite-data" element={<GuildInviteData />} />
                  <Route path="invite-host" element={<GuildInviteHost />} />
                </Route>
              </Route>
              <Route path="/room">
                <Route path="room-data" element={<RoomData />} />
              </Route>
              <Route path="/magic-lamp">
                <Route path="rule" element={<MagicLampRule />} />
              </Route>
              <Route path="/agent-recharge">
                <Route path='coins-transfer' element={<AgentRechargeCoinsTransfer />} />
                <Route path="coins-transfer/success/:appID/:inputNum" element={<AgentRechargeCoinsTransferSuccess />} />
                <Route path="coins-transfer/warning/:appID/:inputNum" element={<AgentRechargeCoinsTransferWarning />} />
                <Route path="coins-transfer/coin-detail" element={<AgentRechargeCoinsTransferCoinDetail />} />
                <Route path="coins-transfer/transfer" element={<AgentRechargeCoinsTransferTransfer />} />
                <Route path='cash-transfer' element={<AgentRechargeCashTransfer />} />
                <Route path="cash-transfer/success/:appID/:inputNum" element={<AgentRechargeCashTransferSuccess />} />
                <Route path="cash-transfer/warning/:appID/:inputNum" element={<AgentRechargeCashTransferWarning />} />
              </Route>
              <Route path="/wallet">
                <Route path="detail" element={<WalletDetail />} />
                <Route path="gem" element={<WalletGem />} />
                <Route
                  path="anchorman-wallet-detail"
                  element={<WalletAnchormanWalletDetail />}
                />
                <Route
                  path="anchorman-wallet-detail/rule/card4"
                  element={<WalletAnchormanWalletDetailCard4Rule />}
                />
                <Route path="anchorman-wallet-detail/success/:appID/:inputNum" element={<WalletAnchormanWalletDetailSuccess />} />
                <Route path="anchorman-wallet-detail/warning/:appID/:inputNum" element={<WalletAnchormanWalletDetailWarning />} />
                <Route path="withdraw" element={<WalletWithdraw />} />
                <Route path="withdraw/success" element={<WalletWithdrawSuccess />} />
                <Route path="withdraw/warning" element={<WalletWithdrawWarning />} />
                <Route path="collection-information" element={<WalletCollectionInformation />} />
                <Route path="cash-to-coins" element={<WalletCashToCoins />} />
                <Route path="love-card" element={<LoveCard />} />
                <Route path="love-card/:cardId" element={<LoveCardDetail />} />
                <Route path="love-card/create" element={<LoveCardCreate />} />
                <Route path="love-card/modify/:cardId" element={<LoveCardCreate />} />
                <Route path="love-card/friends" element={<LoveCardFriends />} />
                <Route path="private-wallet" element={<PrivateWallet />} />
              </Route>
              <Route path="/recharge">
                <Route index element={<ThirdRecharge />} />
              </Route>
              <Route path="/rule">
                <Route path="vip" element={<VipRule />} />
                <Route path="level" element={<RuleLevel />} />
                <Route path="turntable" element={<RuleTurntable />} />
                <Route path="pk" element={<RulePk />} />
                <Route path="cp" element={<RuleCp />} />
              </Route>
              <Route path="/Help">
                <Route index element={<Help />} />
              </Route>
              <Route path="/download">
                <Route index element={<Download />} />
              </Route>
              <Route path="/update">
                <Route index element={<Update />} />
              </Route>
            </Route>
          </Routes>
        </AutoScrollToTop>
      </BrowserRouter>
    </Store>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
