import { useEffect, useState } from 'react'
import ImageListEmpty from '@/images/list-empty.png'
import moment from 'moment'
import { useTrans } from '@/hooks/useTranslation'
import './AgentRewardDataTable.scss'


type AgentRewardDataTableProps = {
  items: Guild.GuildAgencyChargeRewardList[]
}

export default function AgentRewardDataTable(props: AgentRewardDataTableProps) {
  const { t } = useTrans()
  const [list, setList] = useState<Guild.GuildAgencyChargeRewardList[]>([])

  useEffect(() => {
    setList(props.items)
  }, [props])

  return (
    <div className="cmp-agent-reward-data-table">
      <table>
        <thead>
          <tr>
            <th>{t('Common.Date')}</th>
            <th>{t('Common.Member')}</th>
            <th>{t('Guild.Coins')}</th>
            <th>{t('Guild.AgentReward')}</th>
          </tr>
        </thead>
        <tbody>
          {list.length !== 0 ? list.map((item, i) => (
            <tr key={i}>
              <td>{moment(item?.createTime).format('YYYY-MM-DD')}</td>
              <td>
                <div className='member'>
                  <span>{item.nick}</span>
                  <span>ID:{item.erbanNo}</span>
                </div>
              </td>
              <td>{item.coins}</td>
              <td>
                <div><span>+{item.gemReward}</span><div className='img'></div></div>
              </td>
            </tr>
          ))
            :
            <>
              <tr>
                <td colSpan={4} rowSpan={2}>
                  <div className='empty'>
                    <img src={ImageListEmpty}></img>
                    <span>{t('Common.NoData')}</span>
                  </div>
                </td>
              </tr>
            </>
          }
        </tbody>
      </table>
    </div>
  )
}
