import HelmetProviders from '../../components/HelmetProviders'
import { useTrans } from '@/hooks/useTranslation'
import './VipRule.scss'

export default function RuleTurntable() {
  const { t } = useTrans()

  return (
    <HelmetProviders title={t(`VipRule.Title`)}>
      <div className="pg-rule-vip">
        {['Rule1', 'Rule2', 'Rule3', 'Rule4'].map((key) => (
          <div className="pg-rule-vip-item" key={key}>
            <div className="pg-rule-vip-item-title">
              <div className="pg-rule-vip-item-title-icon" />
              <span>{t(`VipRule.Rule.T${key}`)}</span>
            </div>
            <span className="pg-rule-vip-item-content">
              {t(`VipRule.Rule.${key}`)}
            </span>
          </div>
        ))}
        <div className="pg-rule-vip-tip">{t(`VipRule.Tip`)}</div>
      </div>
    </HelmetProviders>
  )
}
