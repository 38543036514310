import { memo } from 'react'
import ImgGem from '@/images/gem.png';
import ImgCoin from '@/images/coin.png';
import ImgTransitionArrow from '@/images/transition-arrow.png';
import { useTrans } from '@/hooks/useTranslation';
import './ConvertibleGemDialog.scss'

interface PropsType {
    handleConfirm: () => void
    setVisible: (visible: boolean) => void
    itemInfo: { goldNum: number, anchorDiamondNum: number }
}
function ConvertibleGemDialog({ handleConfirm, setVisible, itemInfo }: PropsType) {
    const { t } = useTrans()

    return (
        <div className='wallet-convertible-gem-dialog'>
            <div className="wallet-convertible-gem-dialog__content">
                <div className="left">
                    <div className="img">
                        <img src={ImgGem}></img>
                    </div>
                    <div className="gem">
                        <img src={ImgGem}></img>
                        <span>{itemInfo.anchorDiamondNum}</span>
                    </div>
                </div>
                <img src={ImgTransitionArrow} className='middle' />
                <div className="right">
                    <div className="img">
                        <img src={ImgCoin}></img>
                    </div>
                    <div className="coin">
                        <img src={ImgCoin}></img>
                        <span>{itemInfo.goldNum}</span>
                    </div>
                </div>
            </div>
            <span className='wallet-convertible-gem-dialog__tips'>{t('Wallet.ConvertibleGemDialogTips')}</span>
            <div className="wallet-convertible-gem-dialog__btn">
                <div className="cancel" onClick={() => setVisible(false)}>{t('Common.Cancel')}</div>
                <div className="top-up" onClick={handleConfirm}>{t('Common.TopUp')}</div>
            </div>
        </div>
    )
}

export default memo(ConvertibleGemDialog)