import { memo } from 'react'
import SelectDayTime from '@/components/SelectDayTime';
import SelectType from '@/pages/Wallet/components/SelectType';
import { useTrans } from '@/hooks/useTranslation';
import './ScreenTypes.scss'

interface PropsType {
    time: string
    setTime: (time: string) => void
    coinSelectedType?: number
    handleTypeConfirm?: (selectedType: number) => void
    typeItems?: Wallet.ThirdBillTree[]
    selectTypeHide?: boolean
    tipHide?: boolean
}
function ScreenType({ time, setTime, coinSelectedType, handleTypeConfirm, typeItems, selectTypeHide, tipHide }: PropsType) {
    const { t } = useTrans()

    return (
        <div className='wallet-screen-type'>
            <div className="control">
                <SelectDayTime time={time} setTime={setTime}></SelectDayTime>
                {!selectTypeHide && <SelectType coinSelectedType={coinSelectedType || 0} handleTypeConfirm={handleTypeConfirm || (() => { })} typeItems={typeItems || []}></SelectType>}
            </div>
            {!tipHide && <div className="tips">
                <span> {t('Wallet.ViewedTips')}</span>
            </div>}
        </div>
    )
}

export default memo(ScreenType)