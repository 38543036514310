import { useCallback, useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import Header from '../../components/Header'
import Meta from '../../components/Meta'
import SelectTimeRange from '../../components/SelectTimeRange'
import MemberHistoricalCard from './Components/MemberHistoricalCard'
import moment from 'moment'
import { getGuildHistoricalData } from '../../utils/request'
import ScrollList from '../../components/ScrollList'
import { useNavigate } from 'react-router'
import { toast } from '../../utils/bridge'
import SafeArea from '../../components/SafeArea'
import { formatCharmValue, formatGuildTime } from '../../utils/format'
import Search from './Components/HistoricalDataSearch'
import { useTrans } from '@/hooks/useTranslation'
import './HistoricalData.scss'

export default function HistoricalData() {
  const { t } = useTrans()
  const navigate = useNavigate()
  const [time, setTime] = useState<{ start: Date; end: Date }>({
    start: moment().startOf('month').toDate(),
    end: moment().startOf('date').toDate()
  })
  const refTime = useRef(time)
  refTime.current = time
  const [listInfo, setListInfo] = useState<{
    list: Guild.HistoricalDataItem[]
    page: number
    hasMore: boolean
  }>({ page: 1, hasMore: true, list: [] })
  const refListInfo = useRef(listInfo)
  refListInfo.current = listInfo
  const [keywords, setKeywords] = useState('')
  const firstRender = useRef(true)
  const handleTimeRangeChange = useCallback((start: Date, end: Date) => {
    setTime({ start, end })
    setListInfo({
      ...refListInfo.current,
      page: 1,
      hasMore: true
    })
  }, [])

  const fetchHistoricalDataList = useCallback(async () => {
    if (!refListInfo.current.hasMore) return

    const startTime = moment(refTime.current.start)
      .startOf('day')
      .format('YYYYMMDD')
    const endTime = moment(refTime.current.end)
      .startOf('day')
      .format('YYYYMMDD')
    const res = await getGuildHistoricalData(
      Number(startTime),
      Number(endTime),
      refListInfo.current.page,
    )
    if (!res) return

    const { code, message, data } = res as Response<
      PageList<Guild.HistoricalDataItem>
    >
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return

    setListInfo({
      ...refListInfo.current,
      list: [
        ...(refListInfo.current.page === 1 ? [] : refListInfo.current.list),
        ...(data.list || [])
      ],
      page: data.whetherLastPage
        ? refListInfo.current.page
        : refListInfo.current.page + 1,
      hasMore: !data.whetherLastPage
    })
  }, [])

  const handleMemberHistoricalCardClick = useCallback(
    (memberId: number) => {
      if (!memberId) return
      navigate('/guild/historical/member/' + memberId)
    },
    [navigate]
  )
  const handleSearch = useCallback(async () => {
    if (isNaN(Number(keywords))) return
    if (!keywords) {
      if (firstRender.current) return
      refListInfo.current.hasMore = true
      refListInfo.current.page = 1
      fetchHistoricalDataList()
      return
    }
    firstRender.current = false
    const startTime = moment(refTime.current.start)
      .startOf('day')
      .format('YYYYMMDD')
    const endTime = moment(refTime.current.end)
      .startOf('day')
      .format('YYYYMMDD')
    const res = await getGuildHistoricalData(
      Number(startTime),
      Number(endTime),
      1,
      Number(keywords)
    )
    if (!res) return

    const { code, message, data } = res as Response<
      PageList<Guild.HistoricalDataItem>
    >
    if (code !== 200) {
      toast(message)
      return
    }

    if (!data) return
    setListInfo({
      list: [
        ...(data.list || [])
      ],
      page: 1,
      hasMore: !data.whetherLastPage
    })
  }, [keywords])

  useEffect(() => {
    fetchHistoricalDataList()
  }, [fetchHistoricalDataList, time])

  return (
    <HelmetProvider>
      <Meta title={t('Guild.MonthlyData').toString()} />
      <div className="pg pg-historical-data">
        <Header title={t('Guild.MonthlyData').toString()} />
        <SelectTimeRange
          startTime={time.start}
          endTime={time.end}
          onChange={handleTimeRangeChange}
        />
        <Search keywords={keywords} setKeywords={setKeywords} handleSearch={handleSearch} />
        <div className="pg-historical-data__content">
          <ScrollList
            hasMore={listInfo.hasMore}
            loadMore={fetchHistoricalDataList}
            empty={!listInfo.list.length}
            emptyText={t('Guild.Tips.NoOverallDataYet')}>
            {listInfo.list.map((item) => (
              <MemberHistoricalCard
                memberId={item.uid}
                avatar={item.avatar}
                username={item.nick}
                erbanNo={item.erbanNo?.toString()}
                historicalItems={[
                  {
                    key: 'Guild.TotalGem',
                    val: formatCharmValue(item.diamondNum || 0)
                  },
                  {
                    key: 'Common.Hours',
                    val: formatGuildTime(item.micBroadcastDuration || 0)
                  },
                  { key: 'Common.ValidDay', val: item.validDays || 0 }
                ]}
                key={item.uid}
                onClick={handleMemberHistoricalCardClick}
                rightBtn={
                  { text: 'Common.Details' }
                }
              />
            ))}
          </ScrollList>
        </div>
        <SafeArea position="bottom" />
      </div>
    </HelmetProvider>
  )
}
