import React, { memo, useCallback } from 'react'
import './RankListItem.scss'
import clsx from 'clsx'
interface PropsType {
    items: {
        nick: string,
        avatar: string,
        num: number
    }[]
    icon: string
    rank?: number
}
function RankListItem({ items, icon, rank }: PropsType) {
    const topSort = useCallback((i: number) => {
        return rank ? rank : i + 1
    }, [rank])
    return (
        <div className='cmp-guild-invite-data-rank-list-item'>
            {items.map((item, i) =>
                <div className='cmp-guild-invite-data-rank-list-item__item' key={i}>
                    <div className={clsx('cmp-guild-invite-data-rank-list-item__item-sort',
                        { 'top1': topSort(i) === 1, 'top2': topSort(i) === 2, 'top3': topSort(i) === 3 })
                    }>
                        <span>{topSort(i)}</span>
                    </div>
                    <div className='cmp-guild-invite-data-rank-list-item__item-avatar'>
                        <img src={item.avatar} alt="" />
                    </div>
                    <span className='cmp-guild-invite-data-rank-list-item__item-nick'>{item.nick}</span>
                    <div className='cmp-guild-invite-data-rank-list-item__item-num'>
                        <img src={icon} alt="" />
                        <span>{item.num}</span>
                    </div>
                </div>
            )
            }
        </div >
    )
}

export default memo(RankListItem)