import { HelmetProvider } from 'react-helmet-async'
import Header from '../../components/Header'
import Meta from '../../components/Meta'
import UpdateAvatar from './Components/UpdateAvatar'
import FormItemList from './Components/FormItemList'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { getGuildHomeDetail, updateGuildInfo } from '../../utils/request'
import GuildLoading from '../../components/Loading'
import './Setting.scss'
import { toast } from '../../utils/bridge'
import { GuildContext } from '../../store/guild'
import { useTrans } from "@/hooks/useTranslation"
import SafeArea from '../../components/SafeArea'

export default function GuildSetting() {
  const { t } = useTrans()
  const { state: guildState } = useContext(GuildContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>(guildState.guildImage)
  const [formItems, setFormItems] = useState([
    {
      key: 'Common.AppID',
      val: guildState.guildNo.toString(),
      field: 'guildId'
    },
    {
      key: 'Common.Name',
      val: guildState.guildName,
      field: 'guildName',
      editable: true
    },
    {
      key: 'Common.Slogan',
      val: guildState.guildSlogan,
      field: 'guildSlogan',
      editable: true
    }
  ])
  const refFormItems = useRef(formItems)
  refFormItems.current = formItems

  const handleAvatarChange = useCallback(async (file: string) => {
    const res = await updateGuildInfo({ coverPicUrl: file })
    if (!res) return

    const { code, message } = res as Response<unknown>
    if (code !== 200) {
      toast(message)
      return
    }

    setAvatar(file.replace(/^https?:/, ''))
  }, [])

  const handelFormItemChange = useCallback(
    async (key: string, val: string, field: string) => {
      const res = await updateGuildInfo({ [field]: val })
      if (!res) return

      const { code, message } = res as Response<unknown>
      if (code !== 200) {
        toast(message)
        return
      }

      const formItems = refFormItems.current.map((item) =>
        item.field === field ? { ...item, val } : item
      )
      setFormItems(formItems)
    },
    []
  )

  const fetchGuildHomeDetail = useCallback(async () => {
    const { code, message, data } = await getGuildHomeDetail()
    setLoading(false)
    if (code !== 200) {
      toast(message)
      return
    }

    setAvatar(data.coverPicUrl)
    setFormItems([
      { key: 'Common.AppID', val: data.guildNo.toString(), field: 'guildId' },
      {
        key: 'Common.Name',
        val: data.guildName.toString(),
        field: 'guildName',
        editable: true
      },
      {
        key: 'Common.Slogan',
        val: data.guildSlogan || '',
        field: 'guildSlogan',
        editable: true
      }
    ])
  }, [])

  useEffect(() => {
    if (guildState.guildId) return

    fetchGuildHomeDetail()
  }, [guildState.guildId])

  return (
    <HelmetProvider>
      <Meta title={t('Common.Edit').toString()} />
      <div className="pg pg-guild-setting">
        <Header title={t('Common.Edit').toString()} />
        <UpdateAvatar avatar={avatar} onChange={handleAvatarChange} />
        <FormItemList items={formItems} onItemChange={handelFormItemChange} />
        {loading && <GuildLoading />}
        <SafeArea position="bottom" />
      </div>
    </HelmetProvider>
  )
}
