import Header from '@/components/Header'
import { useTrans } from '@/hooks/useTranslation'
import './Card4Rule.scss'

export default function Card4Rule() {
  const { t } = useTrans()

  return <div className='cmp-anchorman-wallet-detail-card4-rule'>
    <Header title={t('Guild.Rule.Rules').toString()} />
    <div className='cmp-anchorman-wallet-detail-card4-rule__item'>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-title'>{t('Wallet.AgencyGemAndCashCard4Rule1')}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule1and2`)}</div>
    </div>
    <div className='cmp-anchorman-wallet-detail-card4-rule__item'>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-title'>{t('Wallet.AgencyGemAndCashCard4Rule2')}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule2and1`)}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule2and2`)}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule2and3`)}</div>
    </div>
    <div className='cmp-anchorman-wallet-detail-card4-rule__item'>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-title'>{t('Wallet.AgencyGemAndCashCard4Rule3')}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule3and1`)}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule3and2`)}</div>
    </div>
    <div className='cmp-anchorman-wallet-detail-card4-rule__item'>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-title'>{t('Wallet.AgencyGemAndCashCard4Rule4')}</div>
      <div className='cmp-anchorman-wallet-detail-card4-rule__item-content'>{t(`Wallet.AgencyGemAndCashCard4Rule4and1`)}</div>
    </div>
  </div >
}