import { Popup, TextArea } from 'antd-mobile'
import { useCallback, useEffect, useState } from 'react'
import ImageArrow from '../../../images/arrow.png'
import { useTrans } from '@/hooks/useTranslation'
import './FormItem.scss'

type FormItemProps = {
  name: string
  val: string
  field: string
  maxVal?: number
  editable?: boolean
  onClick?: (key: string, val: string, field: string) => void
  onChange?: (key: string, val: string, field: string) => void
}

export default function FormItem(props: FormItemProps) {
  const { t } = useTrans()
  const [editting, setEditting] = useState(false)
  const maxVal = props.maxVal || 100
  const [value, setValue] = useState(props.val.slice(0, maxVal))

  const handleValClick = useCallback(
    (key: string, val: string, field: string) => {
      if (!props.editable) return

      setEditting(true)

      if (!props.onClick) return

      props.onClick(key, val, field)
    },
    [props]
  )

  const handleConfirm = useCallback(() => {
    if (!props.onChange) return

    props.onChange(props.name, value, props.field)

    setEditting(false)
  }, [props, value])

  useEffect(() => {
    setValue(props.val.slice(0, maxVal))
  }, [props, maxVal])

  return (
    <div
      className="cmp-form-item"
      onClick={() => handleValClick(props.name, props.val, props.field)}>
      <div className="cmp-form-item__key">{props.name}</div>
      <div className="cmp-form-item__val">{props.val}</div>
      {props.editable && (
        <div
          className="cmp-form-item__arrow"
          onClick={() => handleValClick(props.name, props.val, props.field)}>
          <img src={ImageArrow} alt="arrow" />
        </div>
      )}
      <Popup
        visible={editting}
        className="cmp-form-item-popup fix-popup-safe-area"
        onMaskClick={() => setEditting(false)}
        bodyStyle={{
          backgroundColor: '#261A27',
          height: `${218 / window.viewRate}px`
        }}>
        <div className="cmp-form-item__popup-header">
          <div className="cmp-form-item__popup-title">{t(props.name)}</div>
          <div className="cmp-form-item__popup-confirm" onClick={handleConfirm}>
            {t('Confirm')}
          </div>
        </div>
        <div className="cmp-form-item__popup-content">
          <TextArea
            className="cmp-form-item__popup-textarea"
            placeholder={t('Please write something.') || ''}
            value={value}
            autoFocus
            onChange={(val) => {
              if (val.length > maxVal) return
              setValue(val)
            }}
          />
          <div className="cmp-form-item__popup-max">
            {value.length}/{maxVal}
          </div>
        </div>
      </Popup>
    </div>
  )
}
